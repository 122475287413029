import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import useKeypress from 'components/useKeyPress';
import {ReactComponent as ArrowIcon} from 'assets/icons/arrow-right.svg';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const SortButtonWrapper = styled.button`
	cursor: pointer;
	position: relative;
	&.visible {
		transform: translateX(0);
	}

	.sort-options-container {
		z-index: 999;
	}

	div.label-container {
		display: flex;
		flex-direction: row;
		align-content: center;
		justify-content: center;

		div {
			line-height: 20px;
			margin-right: 16px;
		}

		svg {
			height: 20px;
			width: 20px;
			stroke: #000000;
			stroke-width: 3px;
			transition: transform 0.1s ease-out;
		}
	}

	ul {
		position: absolute;
		display: none;
		top: 50px;
		left: 0px;
		color: ${p => p.theme.colors.ascent.primary};
		background-color: white;
		margin: unset;
		width: 100%;
		list-style-type: none;
		padding: 12px 0px;
		transition: transform 0.3s ease-out;

		@media ${device.laptop} { 
			top: 103px;
		}

		li {
			padding: 8px 0px;

			&:hover {
				background-color: ${p => p.theme.colors.ascent.primary};
				color: white;
			}
		}
	}

	&.visible {
		ul {
			display: block;
		}
		svg {
			transform: rotate(90deg);
		}
	}

	&:hover {
		div.label-container svg {
			stroke: ${p => p.theme.colors.white.primary};
			fill: ${p => p.theme.colors.white.primary};
		}
	}
`;

const SortButton = (props) => {

	const { visible, setVisible, options, value, setValue } = props;
	const wrapperRef = useRef(null);

	useKeypress('Escape', () => {
		if (visible) {
			console.log('escape');
		}
	});

	useEffect(() => {
		const handleClick = e => {
			if (!wrapperRef.current.contains(e.target)) {
				setVisible(false);
				return;
			}
		};
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, [setVisible]);

	
	const toggleVisible = () => {
		setVisible(!visible);
	};

	const onItemClick = (option) => {
		setValue(option);
	};

	return (
		<SortButtonWrapper 
			ref={wrapperRef} 
			className={visible ? 'visible' : ''} 
			onClick={toggleVisible}
		>
			<div className='label-container'>
				<div>{ value.label }</div>
				<ArrowIcon/>
			</div>
			<ul className='sort-options-container'>
				{options.map(option => {
					return (
						<li key={option.value} onClick={() => onItemClick(option)} >
							{option.label}
						</li>
					);
				})}
			</ul>
		</SortButtonWrapper>
	);
};

SortButton.propTypes = {
	visible: PropTypes.bool,
	setVisible: PropTypes.func,
	options: PropTypes.array,
	value: PropTypes.object,
	setValue: PropTypes.func
};

export default SortButton;