import React from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
	width: 100%;
	max-width: 1120px;
	margin: auto;
	position: relative;
	background-color: #384336;
	color: white;
	padding: 20px;
	box-sizing: border-box;
	@media ${device.laptop} {
		position: absolute;
		right: 50px;
		top: 50px;
		z-index: 10;
		width: 300px;
	}

	.title {
		font-weight: 700;
		font-size: 20px;
	}
`;

const OpenHouseSectionManual = (props) => {
	const { isLoading, openHouses } = props;

	const { t } = useTranslation();

	const getStartDate = (date, duration) => {
		const d = new Date(date);
		const startDate = d.toLocaleDateString("da-DK", {
			month: "numeric",
			day: "numeric",
		});
		const startTime = d.toLocaleTimeString("da-DK", {
			hour: "2-digit",
			minute: "2-digit",
		});
		d.setMinutes(d.getMinutes() + duration);
		const endTime = d.toLocaleTimeString("da-DK", {
			hour: "2-digit",
			minute: "2-digit",
		});
		return `ÅBENT HUS d. ${startDate}: ${startTime} - ${endTime}`;
	};

	return (
		<>
			{!isLoading && openHouses !== null && openHouses[0].schedule && (
				<Wrapper>
					<div>
						<div className='title'>
							<p>{t('properties.skovstykket.openhouse.text1')}</p>
							<p>{t('properties.skovstykket.openhouse.text2')}</p> 
							<p>{t('properties.skovstykket.openhouse.text3')}</p>
						</div>
						{openHouses[0].meetingLocation && (
							<div>
								{" "}
								Mødested: {openHouses[0].meetingLocation}{" "}
							</div>
						)}
						{openHouses[0].description && (
							<div> Bemærk: {openHouses[0].description} </div>
						)}
					</div>
				</Wrapper>
			)}
		</>
	);
};

OpenHouseSectionManual.propTypes = {
	isLoading: PropTypes.bool,
	openHouses: PropTypes.array,
};

OpenHouseSectionManual.defaultProps = {
	isLoading: true,
	openHouses: {},
};

export default OpenHouseSectionManual;
