import React, { useState } from "react";
import styled from "styled-components";
import ContactButton from "../ContactButton";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { device } from "helpers/device";
import Loader from "components/Loader";
import PropTypes from "prop-types";
import { ReactComponent as Grid } from "assets/properties/kgv/grid-green.svg";

const Wrapper = styled.div`
	width: 100%;
	margin: auto;
	max-width: 960px;
	background-color: #fef6e5;
	box-sizing: border-box;

	@media ${device.laptop} {
		padding-top: 80px;
		padding-bottom: 80px;
		padding-left: 80px;
		padding-right: 80px;
		box-sizing: content-box;
	}

	h2 {
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 31px;
	}
	p {
		font-size: 12px;
	}

	div.section-input {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-column-gap: 64px;
		grid-row-gap: 28px;
		margin-bottom: 40px;
		@media ${device.laptop} {
			grid-template-columns: 1fr 1fr;
		}

		div.input-wrapper {
			padding: 12px 0px;
			display: block;
		}

		div.input-wrapper.message {
			height: 100%;
			textarea {
				border: 1px solid ${(p) => p.theme.colors.ascent.primary};
				box-sizing: border-box;
				height: calc(100% - 48px);
				padding: 16px;
			}
			label {
				padding-bottom: 16px;
			}
		}

		input,
		textarea {
			padding: 4px 0px 8px;
			width: 100%;
			border: unset;
			border-bottom: 1px solid ${(p) => p.theme.colors.ascent.primary};
			font-size: 16px;
			color: ${(p) => p.theme.colors.ascent.primary};
			background-color: ${(props) => props.backgroundColor};
		}

		input[type="textarea"] {
			height: 200px;
		}

		label {
			font-size: 14px;
			display: block;
			color: ${(p) => p.theme.colors.text.secondary};
		}
	}

	.section-title {
		padding: 32px 16px;
	}

	.messageSent {
		text-align: center;
		padding-top: 130px;
		padding-bottom: 130px;
		padding-left: 80px;
		padding-right: 80px;
	}
	.grid {
		margin-top: 120px;
		margin-bottom: -30px;
	}

	@media ${device.laptop} {
		display: flex;
		.section-title {
			max-width: 340px;
		}
	}
`;

const Button = styled.div`
	margin-top: 50px;
	color: #fff;
	background-color: #003202;
	height: 42px;
	padding-left: 20px;
	width: fit-content;
	padding-right: 20px;

	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 20px;
`;

const FloatingOpenHouse = styled.div`
	padding: 14px 20px;
	background-color: #fff;
	color: #003202;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	width: 196px;
	margin-top: 24px;

	h2 {
		font-size: 16px;
		font-weight: 500;
		margin: 0;
	}

	@media ${device.laptop} {
		height: 140px;
		padding: 36px 30px;
		box-sizing: border-box;
		width: 340px;
	}
`;

const ContactSection = (props) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<>
				<div className='section-title'>
					<h2>
						Mangler du svar,
						<br /> eller vil du høre mere?
					</h2>
					<p>
						Vi sidder klar til at hjælpe dig i din beslutning om dit næste hjem. Så tøv ikke med at række
						ud.
					</p>
					<ContactButton />
					<p>Eller ring til os på 33 33 13 33</p>
				</div>
				{/* <FloatingOpenHouse>
					<h2>Åbent hus</h2>
					<p>Åbent hus hver torsdag kl 16:00-17:00.</p>
				</FloatingOpenHouse> */}
				<div className='grid'>
					<Grid />
				</div>
			</>
		</Wrapper>
	);
};

ContactSection.propTypes = {
	backgroundColor: PropTypes.string,
};

ContactSection.defaultProps = {
	backgroundColor: "#fff",
};

export default ContactSection;
