import React from 'react';
import styled from 'styled-components';
import background from 'assets/images/about-us-2.png';
import Title from 'components/Title';
import Line from 'components/Line';
import { device } from 'helpers/device';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	width: 100%;
	max-width: 1440px;
	margin: auto;
	display: flex;
	flex-direction: column;
	
	@media ${device.laptop} { 
		padding: 60px 0px;	
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	img {
		filter: drop-shadow(0px 22px 54px rgba(0, 0, 0, 0.2));
		width: 100%;

		@media ${device.laptop} { 
			margin-right: 30px;
		}
	}
`;
const ContentBox = styled.div`
	padding: 32px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 32px 16px;

	@media ${device.laptop} { 
		padding-left: 46px;
		padding-right: 80px;
	}
`;
const Text = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: -0.502564px;
	color: ${p => p.theme.colors.text.secondary};
	max-width: 490px;
	margin-bottom: 40px;
	
`;
const LimitedWidthTitle = styled(Title)`
	max-width: 428px;
`;



const Services = () => {

	const { t } = useTranslation();

	return (
		<Wrapper>
			<img src={background}/>
			<ContentBox>
				<Line/>
				<LimitedWidthTitle>{t('aboutUs.services.title')}</LimitedWidthTitle>
				<Text>{t('aboutUs.services.text')}</Text>
			</ContentBox>
		</Wrapper>
	);
};

export default Services;