import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import Title from 'components/Title';
import Line from 'components/Line';

const Wrapper = styled.div`
	padding: 0px 16px;	
	padding-top: 40px;
	width: 100%;
	max-width: 1120px;
	box-sizing: border-box;
	margin: auto;
	background-color: white;
	
	iframe {
		width: 100%; 
		height: 340px; 
		border: 0;
	}

	@media ${device.laptop} { 
		padding: 16px 0px;
		padding-left: 80px;
    	padding-right: 80px;

		iframe {
			height: 540px; 
		}
	}

	
`;

const VirtualTourSection = props => {
	const { virtualTourLink } = props;
	const { t } = useTranslation();

	return (
		<Wrapper>
			<Line />
			<Title>{t('tenancy.virtualTour')}</Title>
			<iframe 
				src={virtualTourLink} 
				allowFullScreen 
				allow="xr-spatial-tracking;"
			/>
		</Wrapper>
	);
};

VirtualTourSection.propTypes = {
	virtualTourLink: PropTypes.string
};

export default VirtualTourSection;