const imageMapJSON = [
	{
		'id': '1',
		'title': 'Building 3',
		'shape': 'poly',
		'name': '3',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': 'black',
		'preFillColor': 'transparent',
		'coords': [
			39,
			1610,
			324,
			1610,
			412,
			1522,
			409,
			1496,
			334,
			1492,
			327,
			1469,
			144,
			1447,
			20,
			1532,
			36,
			1617,
			43,
			1610,
			43,
			1610
		]
	},
	{
		'id': '2',
		'title': 'Building 3',
		'shape': 'poly',
		'name': '3',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': 'black',
		'preFillColor': 'transparent',
		'coords': [
			418, 1525, 508, 1441, 489, 1400, 245, 1378, 137, 1448, 334, 1459, 334, 1485, 412, 1485
		]
	}
];

export default imageMapJSON; 