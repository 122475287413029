import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use(async function (c) {
	c.params = { ...c.params, key: process.env.REACT_APP_API_KEY };
	return c;
}, function (error) {
	return Promise.reject(error);
});


export default axios;