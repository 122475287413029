import React from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
	position: absolute;
	left: 50%;
	top:50%;
	transform: translate(-50%,-50%);
	z-index: 10;
	width: 80px;
	height: 80px;
	// margin: 100px auto;
	
	div {
		display: inline-block;
		position: absolute;
		left: 8px;
		width: 16px;
		background: ${p => p.theme.colors.ascent.primary};
		animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	}
	div:nth-child(1) {
		left: 8px;
		animation-delay: -0.24s;
	}
	div:nth-child(2) {
		left: 32px;
		animation-delay: -0.12s;
	}
	div:nth-child(3) {
		left: 56px;
		animation-delay: 0;
	}
	@keyframes lds-facebook {
		0% {
			top: 8px;
			height: 64px;
		}
		50%, 100% {
			top: 24px;
			height: 32px;
		}
	}
`;


const Loader = () => {

	return (
		<LoaderWrapper>
			<div></div><div></div><div></div>
		</LoaderWrapper>
	);
};

export default Loader;