import React from 'react';
import styled from 'styled-components';
import ArrowButton from 'components/ArrowButton';
import LogoWhite from 'assets/images/norse-logo-white.png';
import linkedInIcon from 'assets/icons/linkedin.png';
import instagramIcon from 'assets/icons/instagram.png';
import facebookIcon from 'assets/icons/facebook.png';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';

const FooterWrapper = styled.footer`
	width: 100%;
	padding: 0px 16px;
	background-color: ${p => p.theme.colors.ascent.primary};
	box-sizing: border-box;

	.top, .bottom {
		max-width: 1200px;
		margin: auto;
	}

	.line {
		height: 1px;
		max-width: 1200px;
		margin: auto;
		background: #FFFFFF;
		mix-blend-mode: normal;
		opacity: 0.1;
	}

	.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 60px 0px;
		margin: auto;
		text-align: center;
		
		.slogan {
			font-size: 42px;
			font-weight: 700;
			color: ${p => p.theme.colors.white.primary};

			@media ${device.laptop} { 
				font-size: 42px;
			}

			span {
				color: ${p => p.theme.colors.ascent.secondary};
			}
		}
		.contact-button {
			display: none;
			@media ${device.laptop} { 
				display: block;
			}
		}
	}

	.bottom {
		padding: 40px 0px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		@media ${device.laptop} { 
			flex-direction: row;
		}

		.logo {
			max-width: 170px;
		}
		.text-link {
			font-size: 15px;
			font-weight: 700;
			padding: 8px;
			color: ${p => p.theme.colors.white.primary};
		}
		.social img {
			max-width: 24px;
			padding: 8px;
		}
	}
`;

const Footer = () => {

	const { t } = useTranslation();

	return (
		<FooterWrapper>
			<div className='top'>
				<div className='slogan'>{t('footer.sloganStart')}<span>{t('footer.sloganEnd')}</span></div>
				<a className='contact-button' href='/kontakt'><ArrowButton>{t('footer.buttonLabel')}</ArrowButton></a>
			</div>
			<div className='line'></div>
			<div className="bottom">
			    <a href='/' className=''><img className='logo' src={LogoWhite}/></a>
				<a href='/boliger' className='text-link'>{t('header.findTenancy')}</a>
				<a href='/om-os' className='text-link'>{t('header.aboutUs')}</a>
				<a href='/kontakt' className='text-link'>{t('header.contact')}</a>
				<a href='/privacy-policy' className='text-link'>{t('header.privacyPolicy')}</a>
				<div className='social'>
					<a target='_blank' rel="noreferrer" href='https://www.facebook.com/NorsePM'><img className='' src={facebookIcon}/></a>
					<a target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/norse-pm/'><img className='' src={linkedInIcon}/></a>
					<a target='_blank' rel="noreferrer" href='https://www.instagram.com/norsepm'><img className='' src={instagramIcon}/></a>
				</div>
			</div>
		</FooterWrapper>
	);
};

export default Footer;