import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	padding-top: 120px;
	width: 100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
	background-color: #FAFAFA;
	padding-bottom: 60px;
	max-width: 1280px;
	margin: auto;
	@media ${device.laptop} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 80px;
	}
`;
const FirstBox = styled.div`
	@media ${device.laptop} {
		margin-left: 100px;
	}
`;
const SecondBox = styled.div`
	@media ${device.laptop} {
		margin-right: 100px;
		position: relative;
		top: 150px;
	}
`;
const Line = styled.div`
	width: 80px;
	height: 5px;
	background-color: #C49A36;
	margin: auto;

	@media ${device.laptop} {
		margin: unset;
	}
`;
const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 140%;
	margin-top: 30px;
	text-align: center;	
	color: #3E5042;
	max-width: 350px;
	margin-left: auto;
    margin-right: auto;
	@media ${device.laptop} {
		text-align: left;
	}
`;
const Text = styled.p`
	color: #384336;
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	text-align: center;
	margin-bottom: 60px;
	max-width: 350px;
	margin: auto;
	margin-bottom: 100px;
	@media ${device.laptop} {
		text-align: left;
	}
`;


const FactSection2 = (props) => {

	const {
		title1,
		text1,
		title2,
		text2,
	} = props;

	return (
		<Wrapper>
			<FirstBox>
				<Line />
				<Title>{title1}</Title>
				<Text>{text1}</Text>
			</FirstBox>
			<SecondBox>
				<Line />
				<Title>{title2}</Title>
				<Text>{text2}</Text>
			</SecondBox>
		</Wrapper>
	);
};

FactSection2.propTypes = {
	title1: PropTypes.string,
	text1: PropTypes.string,
	title2: PropTypes.string,
	text2: PropTypes.string,
};

export default FactSection2;