import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";
import BookShowcasingButton from "../BookShowcasingButton";
import { ReactComponent as Logo } from "assets/properties/kgv/frederiksberg.svg";
import Image from "assets/properties/kgv/kgv-06.jpg";

const Wrapper = styled.div`
	position: relative;
	background-color: #003202;
	color: #fff;
	display: flex;
	display: grid;
	min-height: 400px;
	padding-top: 100px;
	padding-bottom: 100px;

	h2 {
		font-size: 24px;
		font-weight: 500;
	}
	p {
		font-size: 12px;
		font-weight: 400;
	}

	.inner {
		display: flex;
		flex-direction: column;
	}

	.section1 {
		margin-left: 20px;
		margin-right: 20px;
	}

	@media ${device.laptop} {
		width: 100vw;
		margin-left: -50vw;
		margin-right: -50vw;
		position: relative;
		left: 50%;
		right: 50%;
		background-color: #003202;

		.inner {
			width: 1120px;
			max-width: 1120px;
			margin: auto;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.section1 {
				max-width: 420px;
				margin-left: 0px;
				margin-right: 0px;
				order: 2;
			}

			img {
				order: 1;
				width: 593px;
				height: 559px;
				left: -77px;
        		position: relative;
			}
		}
	}
`;

const Button = styled.div`
	margin-top: 31px;
	color: #003202;
	background-color: #fff;
	height: 42px;
	padding-left: 20px;
	width: fit-content;
	padding-right: 20px;

	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-left: 20px;
	margin-bottom: 80px;
`;

const InfoSection2 = () => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<div className='inner'>
				<div className='section1'>
					<h2>En sjældenhed af en beliggenhed.</h2>
					<p>
						Med en placering i et af Frederiksbergs mest eftertragtede kvarterer er du tæt på alt, hvad du
						behøver. Du flytter ind i et etableret nabolag med masser af liv og charme. Din fornemmeste
						opgave bliver derfor blot at falde til – resten er der sørget for.
					</p>
					<BookShowcasingButton inversed />
				</div>
				<img height={337} width={318} src={Image} />
			</div>
		</Wrapper>
	);
};

export default InfoSection2;
