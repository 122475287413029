import axios from 'axios';

const instance = axios.create({
	timeout: 1000,
});

export default class GoogleTranslateService {

	static async translate(query) {
		try {
			const result = await instance.get('https://translation.googleapis.com/language/translate/v2', { params: { source: 'da', target: 'en', format: 'text', q: query, key: 'AIzaSyDNQcoZK4aCGfpcQ_k_69c9Rosftoox2RA' }});
			return result;
		}
		catch (err) {
			return null;
		}
	}	
}