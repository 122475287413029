import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import { device } from 'helpers/device';

const Wrapper = styled.div`
	width: 100%;
	max-width: 1120px;
	margin: auto;
	position: relative;
	background-color: #384336;
    color: white;
    padding: 20px;
	box-sizing: border-box;
	@media ${device.laptop} { 
		
	}

	.title {
		font-weight: 700;
		font-size: 20px;
	}
`;

const OpenHouseSection = props => {
	const { isLoading, openHouses } = props;

	const getStartDate = (date, duration) => {
		const d = new Date(date);
		const startDate = d.toLocaleDateString('da-DK', { month: 'numeric', day: 'numeric' });
		const startTime = d.toLocaleTimeString('da-DK', { hour: '2-digit', minute: '2-digit' });
		d.setMinutes(d.getMinutes() + duration);
		const endTime = d.toLocaleTimeString('da-DK', { hour: '2-digit', minute: '2-digit' });
		return `ÅBENT HUS d. ${startDate}: ${startTime} - ${endTime}`;
	};

	return (
		<>
			{!isLoading && openHouses !== null && (
				<Wrapper>
					<div>
						<div className="title"> {getStartDate(openHouses[0].schedule.startsAt, openHouses[0].schedule.duration)}</div>
						{openHouses[0].meetingLocation && (<div> Mødested: {openHouses[0].meetingLocation} </div>)}
						{openHouses[0].description && (<div> Bemærk: {openHouses[0].description} </div>)}
					</div>	
				</Wrapper>
			)}
		</>
	);
};

OpenHouseSection.propTypes = {
	isLoading: PropTypes.bool,
	openHouses: PropTypes.object,
};

OpenHouseSection.defaultProps = {
	isLoading: true,
	openHouses: {},
};

export default OpenHouseSection;