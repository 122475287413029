import React, { useMemo } from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';
import ImageMap from 'components/ImageMap/ImageMap';

const Wrapper = styled.div`
	height: 600px;
	width: 100%;
	display: flex;
	flex-direction: column;
    background: url(${props => props.background});
	background-size: cover;
	background-position: center;
	max-width: 1800px;
	margin: auto;
	margin-top: 50px;
	// clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%);
	max-width: 1280px;
	
	@media ${device.laptop} {
		// clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
		height: 100%;
	}
`;
const Line = styled.div`
	width: 80px;
	height: 5px;
	background-color: #C49A36;
	margin: auto;
	min-height: 5px;
`;
const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 140%;
	margin-top: 30px;
	text-align: center;	
	color: #3E5042;
	max-width: 350px;
	margin-left: auto;
    margin-right: auto;
`;
const Text = styled.p`
	color: #384336;
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	margin-bottom: 60px;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
`;

const ImageMapSection = (props) => {

	const {
		title,
		text,
		image,
		imageWidth,
		imageMapJSON,
		tenancies,
		loading
	} = props;

	return (
		<Wrapper>
			<Line />
			<Title>{title}</Title>
			<Text>{text}</Text>
			<ImageMap image={image} imageWidth={imageWidth} json={imageMapJSON} tenancies={tenancies} loading={loading} />
		</Wrapper>
	);
};

ImageMapSection.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
	imageMapJSON: PropTypes.array,
	imageWidth: PropTypes.number,
	tenancies: PropTypes.array,
	loading: PropTypes.bool
};

export default ImageMapSection;