import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const ModalButtonWrapper = styled.div``;

const ModalContentContainer = styled.div`
	max-width: 600px;
	min-width: 340px;
	overflow: auto;
`;

const CloseButton = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	svg {
		height: 60px;
		width: 60px;
	}
`;

const GalleryButton = (props) => {
	const [open, setOpen] = useState(false);

	const { label, slides } = props;

	return (
		<ModalButtonWrapper>
			<Button onClick={() => setOpen(true)}>{label}</Button>
			<Lightbox
				open={open}
				close={() => setOpen(false)}
				slides={slides}
			/>
		</ModalButtonWrapper>
	);
};

GalleryButton.propTypes = {
	label: PropTypes.string.isRequired,
	slides: PropTypes.array.isRequired,
};

export default GalleryButton;
