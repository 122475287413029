const imageMapJSON = [
	{
		"id": "1",
		"title": "Fåreager 1",
		"shape": "poly",
		"name": "1",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1550, 663, 1550, 402, 1553, 374, 1657, 371, 1660, 564, 1654, 663],
		"joratoId": "e340d6ad-29ea-4bb7-b849-a041013cc718"
	},
	{
		"id": "2",
		"title": "Fåreager 3",
		"shape": "poly",
		"name": "3",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1654, 662, 1759, 667, 1761, 373, 1660, 372]
	},
	{
		"id": "3",
		"title": "Fåreager 5",
		"shape": "poly",
		"name": "5",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1863, 665, 1761, 665, 1759, 371, 1896, 371, 1885, 604, 1858, 618],
		"joratoId": "b208f034-49ae-4633-9d5c-cdba95dd0ef5"
	},
	{
		"id": "4",
		"title": "Fåreager 7",
		"shape": "poly",
		"name": "7",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1858, 729, 1965, 729, 1976, 627, 1995, 608, 2000, 423, 1893, 420, 1888, 605, 1858, 616]
	},
	{
		"id": "5",
		"title": "Fåreager 9",
		"shape": "poly",
		"name": "9",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1965, 731, 2075, 731, 2080, 629, 2113, 605, 2116, 423, 2003, 421, 1998, 618, 1978, 632]
	},
	{
		"id": "6",
		"title": "Fåreager 11",
		"shape": "poly",
		"name": "11",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [2075, 732, 2190, 732, 2184, 628, 2223, 611, 2223, 424, 2119, 422, 2116, 600, 2080, 631]
	},
	{
		"id": "7",
		"title": "Fåreager 13",
		"shape": "poly",
		"name": "13",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [2113, 813, 2102, 1156, 2212, 1165, 2220, 997, 2267, 989, 2272, 807, 2152, 805],
		"joratoId": "88dd531b-73a4-4263-9d95-766079661345"
	},
	{
		"id": "8",
		"title": "Fåreager 15",
		"shape": "poly",
		"name": "15",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [2212, 1289, 2327, 1231, 2336, 1003, 2377, 989, 2379, 808, 2275, 808, 2267, 992, 2217, 1000]
	},
	{
		"id": "9",
		"title": "Fåreager 17",
		"shape": "poly",
		"name": "17",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [2330, 1234, 2415, 1160, 2445, 1086, 2448, 1001, 2487, 995, 2489, 808, 2379, 808, 2379, 992, 2338, 1003]
	},
	{
		"id": "10",
		"title": "Fåreager 19",
		"shape": "poly",
		"name": "19",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1737, 1014, 2064, 1023, 2061, 1127, 1737, 1124],
		"joratoId": "44587d6c-b454-40a7-a5d4-e89fc24119b7"
	},
	{
		"id": "11",
		"title": "Fåreager 21",
		"shape": "poly",
		"name": "21",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1734, 1126, 2061, 1131, 2058, 1233, 1737, 1233],
		"joratoId": "fbe94188-8c30-4286-a119-653da4c01efe"
	},
	{
		"id": "12",
		"title": "Fåreager 23",
		"shape": "poly",
		"name": "23",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1734, 1240, 2058, 1238, 2053, 1353, 2055, 1375, 1734, 1441],
		"joratoId": "490e3b57-272d-414e-8140-07711d476c70"
	},
	{
		"id": "13",
		"title": "Fåreager 25",
		"shape": "poly",
		"name": "25",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1338, 1099, 1663, 1101, 1676, 1115, 1676, 1209, 1336, 1206],
		"joratoId": "4192550d-1ab5-417a-be4b-e6cf4c0fad5e"
	},
	{
		"id": "14",
		"title": "Fåreager 27",
		"shape": "poly",
		"name": "27",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1336, 1209, 1676, 1212, 1674, 1311, 1338, 1311],
		"joratoId": "04b57913-796a-4044-9dbd-a794aace3f6d"
	},
	{
		"id": "15",
		"title": "Fåreager 29",
		"shape": "poly",
		"name": "29",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1341, 1315, 1674, 1315, 1668, 1439, 1338, 1450],
	},
	{
		"id": "16",
		"title": "Fåreager 31",
		"shape": "poly",
		"name": "31",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1303, 1449, 1303, 1195, 1201, 1195, 1204, 1452],
		"joratoId": "cd84b29e-3145-420d-8356-b1f4cfd479c7"
	},
	{
		"id": "17",
		"title": "Fåreager 33",
		"shape": "poly",
		"name": "33",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1094, 1439, 1201, 1453, 1201, 1197, 1091, 1197],
		"joratoId": "40f88d97-21bb-435e-a343-3f51a14cfce4"
	},
	{
		"id": "18",
		"title": "Fåreager 35",
		"shape": "poly",
		"name": "35",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [992, 1425, 1089, 1439, 1089, 1194, 990, 1197, 984, 1374]
	},
	{
		"id": "19",
		"title": "Fåreager 37",
		"shape": "poly",
		"name": "37",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [885, 1403, 987, 1417, 984, 1376, 987, 1148, 885, 1142],
		"joratoId": "32bbfcbb-a041-4e31-a8f4-44ac7faf3bd9"
	},
	{
		"id": "20",
		"title": "Fåreager 39",
		"shape": "poly",
		"name": "39",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [883, 1402, 795, 1386, 784, 1144, 883, 1144],
		"joratoId": "d7da3776-3c66-496f-ae5c-a6cc1717f141"
	},
	{
		"id": "21",
		"title": "Fåreager 41",
		"shape": "poly",
		"name": "41",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [784, 1146, 789, 1383, 545, 1289, 547, 1223, 657, 1218, 660, 1146]
	},
	{
		"id": "22",
		"title": "Fåreager 45",
		"shape": "poly",
		"name": "45",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [525, 1057, 475, 1077, 277, 1078, 217, 954, 475, 961, 525, 956],
		"joratoId": "f93672fd-9cdf-4fa7-b1e1-d8cb81b6b6f6"
	},
	{
		"id": "23",
		"title": "Fåreager 47",
		"shape": "poly",
		"name": "47",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [173, 857, 519, 854, 522, 953, 475, 958, 214, 953],
		"joratoId": "f04d1a64-5c58-44b4-88ae-59a1469160b1"
	},
	{
		"id": "24",
		"title": "Fåreager 49",
		"shape": "poly",
		"name": "49",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [173, 860, 288, 862, 291, 856, 467, 851, 475, 749, 159, 748],
		"joratoId": "643187b3-1d79-4cd4-8093-8b72b651852e"
	},
	{
		"id": "25",
		"title": "Fåreager 51",
		"shape": "poly",
		"name": "51",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [170, 637, 434, 642, 472, 651, 470, 744, 437, 744, 157, 747],
		"joratoId": "79cf58f6-bd42-4b4d-b4e6-7292e0436c38"
	},
	{
		"id": "26",
		"title": "Fåreager 53",
		"shape": "poly",
		"name": "53",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [560, 737, 841, 742, 860, 742, 862, 629, 838, 621, 558, 622]
	},
	{
		"id": "27",
		"title": "Fåreager 55",
		"shape": "poly",
		"name": "55",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [841, 512, 860, 532, 857, 625, 835, 620, 558, 620, 555, 521, 656, 518],
		"joratoId": "a5852970-9162-47d4-8efc-d0a23ef72a34"
	},
	{
		"id": "28",
		"title": "Fåreager 57",
		"shape": "poly",
		"name": "57",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [830, 342, 832, 403, 865, 434, 862, 527, 841, 516, 560, 521, 558, 422, 651, 392]
	},
	{
		"id": "29",
		"title": "Fåreager 59",
		"shape": "poly",
		"name": "59",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [1255, 743, 950, 740, 948, 633, 1261, 638]
	},
	{
		"id": "30",
		"title": "Fåreager 61",
		"shape": "poly",
		"name": "61",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [945, 631, 942, 532, 1068, 521, 1258, 518, 1258, 633]
	},
	{
		"id": "31",
		"title": "Fåreager 63",
		"shape": "poly",
		"name": "63",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [945, 535, 1071, 521, 1217, 518, 1217, 425, 948, 430],
		"joratoId": "ec171196-ab59-4b5d-ae88-4cbf42dfaf86"
	},
	{
		"id": "32",
		"title": "Fåreager 65",
		"shape": "poly",
		"name": "65",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "rgba(56, 67, 54, 0.5)",
		"coords": [950, 331, 1036, 296, 1220, 297, 1217, 425, 945, 425],
		"joratoId": "b6d715a7-12d3-4ab4-bfec-c2e8f612ce16"
	}
];

export default imageMapJSON; 