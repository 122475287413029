import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useTenancies from "features/tenancies";

import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeaturesSection";
import ImagesSection from "./components/ImagesSection";
import InfoSection from "./components/InfoSection";
import InfoSection2 from "./components/InfoSection2";
import FacilitiesSection from "./components/FacilitiesSection";
import ContactSection from "./components/ContactSection";
import ContactButton from "./components/ContactButton";
import Modal from "react-modal";

import PropertyOverview from "assets/properties/kgv/kgv-overview.jpg";
import imageTenancy01 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy02 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy03 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy04 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy05 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy06 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy07 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy08 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy09 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy10 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy11 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy12 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy13 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy14 from "assets/properties/kgv/kgv-02.jpg";
import imageTenancy15 from "assets/properties/kgv/kgv-02.jpg";
import imageProperty01 from "assets/properties/kgv/kgv-02.jpg";
import imageProperty02 from "assets/properties/kgv/kgv-02.jpg";
import imageProperty03 from "assets/properties/kgv/kgv-02.jpg";
import imageProperty04 from "assets/properties/kgv/kgv-02.jpg";
import imageProperty05 from "assets/properties/kgv/kgv-02.jpg";
import imageProperty06 from "assets/properties/kgv/kgv-02.jpg";
import imageProperty07 from "assets/properties/kgv/kgv-02.jpg";

import { ReactComponent as FacilityIcon1 } from "assets/properties/gefion/facilities/gefion-facility-1.svg";
import { ReactComponent as FacilityIcon2 } from "assets/properties/gefion/facilities/gefion-facility-2.svg";
import { ReactComponent as FacilityIcon3 } from "assets/properties/gefion/facilities/gefion-facility-3.svg";
import { ReactComponent as FacilityIcon4 } from "assets/properties/gefion/facilities/gefion-facility-4.svg";
import { ReactComponent as FacilityIcon5 } from "assets/properties/gefion/facilities/gefion-facility-5.svg";
import { ReactComponent as FacilityIcon6 } from "assets/properties/gefion/facilities/gefion-facility-6.svg";
import { ReactComponent as FacilityIcon8 } from "assets/properties/gefion/facilities/gefion-facility-8.svg";
import { ReactComponent as FacilityIcon9 } from "assets/icons/building.svg";
import { ReactComponent as FacilityIcon10 } from "assets/properties/gefion/facilities/gefion-facility-10.svg";
import { ReactComponent as FacilityIcon11 } from "assets/properties/gefion/facilities/gefion-facility-11.svg";
import { ReactComponent as FacilityIcon12 } from "assets/icons/parking.svg";
import { ReactComponent as FacilityIcon13 } from "assets/properties/gefion/facilities/gefion-facility-13.svg";
import { ReactComponent as FacilityIcon14 } from "assets/properties/gefion/facilities/gefion-facility-14.svg";
import { ReactComponent as FacilityIcon15 } from "assets/properties/gefion/facilities/gefion-facility-15.svg";
import { ReactComponent as FacilityIcon16 } from "assets/properties/gefion/facilities/gefion-facility-16.svg";
import ImageMapSectionWithListOverlay from "./components/ImageMapSectionWithListOverlay";
import imageMapJSON from "../KGV/components/imageMapJSON";
import ImageMapIsometrySection from "./components/ImageMapIsometrySection";

const Wrapper = styled.div`
	//max-width: 1120px;
	margin: 0 auto;
	padding: 0px 0px;
	background-color: #fef6e5;
	overflow: hidden;
`;

const Container = styled.div`
	max-width: 1120px;
	margin: 0 auto;
	padding: 0px 0px;
	background-color: white;
`;

const ModalImage = styled.img`
	max-width: 100%;
	padding-top: 16px;
`;
const ModalText = styled.p`
	padding-top: 16px;
	font-size: 18px;
`;
const TopInfo = styled.div`
	color: white;
	background-color: #384336;
	text-align: center;
	font-size: 20px;
	padding: 16px;
	cursor: pointer;
`;

const KGV2 = () => {
	const { t } = useTranslation();

	const propertyId = "63c9606a-baa0-4719-a39c-b77a2eea7b72";

	const { isTenanciesLoading, tenanciesData, loadTenanciesByPropertyId } = useTenancies();

	const contactSectionRef = useRef(null);
	const scrollToContactSection = () => {
		contactSectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const isometrySectionRef = useRef(null);
	const scrollToIsometrySection = () => {
		isometrySectionRef.current.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		loadTenanciesByPropertyId(propertyId);
	}, [loadTenanciesByPropertyId]);

	return (
		<Wrapper>
			{/* <TopInfo onClick={scrollToContactSection}>
				{t("properties.kgv2.infoText")}
			</TopInfo> */}

			

			<Container>
				<HeroSection buttonClick={scrollToIsometrySection} />
				<FeaturesSection />
				<ImagesSection />
				<InfoSection />
				<InfoSection2 />
				<div ref={isometrySectionRef}>
					<ImageMapIsometrySection 
						title={t("properties.skovstykket.ImageMapSection.title")}
						text={"Her kan du se et oversigtsbillede af Kong Georgs Vej. Klik på de forskellige bygninger for at se detaljer om lejemålene"}
						imageWidth={2560}
						imageMapJSON={imageMapJSON}
						tenancies={tenanciesData}
						loading={isTenanciesLoading}
						image={PropertyOverview}
					/>
					{/* <ImageMapSectionWithListOverlay
						title={t("properties.skovstykket.ImageMapSection.title")}
						text={"Her kan du se et oversigtsbillede af Kong Georgs Vej. Klik på de forskellige bygninger for at se detaljer om lejemålene"}
						imageWidth={2560}
						imageMapJSON={imageMapJSON}
						tenancies={tenanciesData}
						isTenanciesLoading={isTenanciesLoading}
						image={PropertyOverview}
					/> */}
				</div>
				<FacilitiesSection />
				<div ref={contactSectionRef}>
					<ContactSection />
				</div>
			</Container>
		</Wrapper>
	);
};

export default KGV2;
