// @ts-nocheck

import React, { Suspense, useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Edges } from "@react-three/drei";
import { useGesture } from "@use-gesture/react";
import PropTypes from "prop-types";
import TenancyCardSmall from "components/TenancyCardSmall";
import Loader from "components/Loader";
import Slider from "./Slider";
import { PerspectiveCamera } from "three";
import { device } from "helpers/device";
import { Vector3 } from "three";

const Wrapper = styled.div`
	height: 600px;
	width: 100%;
	border: none;
	position: relative;
`;

const ClickedTenancyContainer = styled.div`
	position: absolute;
	right: 40px;
	z-index: 10;
	top: 40px;
	@media (max-width: 480px) {
		right: unset;
		width: 90%;
		margin-right: 5%;
		margin-left: 5%;
	}
`;

const ModelWrapper = styled.div`
	height: 500px;
`;

const Isometry = (props) => {
	const [clickedTenancy, setClickedTenancy] = useState(false);
	const [hoveredBox, setHoveredBox] = useState(false);
	const [fov, setFov] = useState(35);

	const {
		tenancies,
		tenanciesJSON,
		scene: Scene,
		target,
		initialRotation,
		fov: fovProp,
	} = props;

	const controls = useRef();

	// const camera = new PerspectiveCamera(
	// 	fov,
	// 	window.innerWidth / window.innerHeight,
	// 	1,
	// 	1000
	// );

	useEffect(() => {
		const checkScreenSize = () => {
			const desktopQuery = window.matchMedia(device.laptop);
			if (desktopQuery.matches) {
				setFov(fovProp.desktop); // Set FOV for desktop
			} else {
				setFov(fovProp.mobile); // Set FOV for non-desktop devices
			}
		};

		// Check screen size at start and whenever it changes
		checkScreenSize();
		window.addEventListener("resize", checkScreenSize);

		// Clean up event listener on component unmount
		return () => window.removeEventListener("resize", checkScreenSize);
	}, []);

	function Box(props) {
		const baseColor = useMemo(() => {
			if (props.joratoId) {
				const temp = getTenancyDataFromId(props.joratoId);
				if (clickedTenancy.id === props.joratoId) {
					return "#C49A36";
				} else if (hoveredBox === props.joratoId) {
					return "#384336";
				} else if (temp === "Unavailable") {
					return "#8d4a58";
				} else if (temp.state === "Available") {
					return "#7eb080";
				} else if (temp.state === "Reserved") {
					return "#c6c61b";
				} else {
					return "#8d4a58";
				}
			}
		}, [props.joratoId, clickedTenancy, hoveredBox]);

		const bind = useGesture({
			onPointerOver: (state) => {
				setHoveredBox(
					state.event.intersections[0].eventObject.parent.joratoId
				);
			},
			onPointerLeave: (state) => {
				if (state.event.intersections[0]) {
					setHoveredBox(
						state.event.intersections[0].eventObject.parent.joratoId
					);
				} else {
					setHoveredBox(false);
				}
			},
			onClick: (state) => {
				const data = getTenancyDataFromId(
					state.event.intersections[0].eventObject.parent.joratoId
				);
				if (data.state === "Available" || data.state === "Reserved") {
					setClickedTenancy(data);
				}
			},
		});
		return (
			<group {...props}>
				<mesh {...bind()}>
					<boxGeometry attach='geometry' args={[1, 1, 1]} />
					<meshBasicMaterial
						attach='material'
						color={baseColor}
						opacity={0.5}
						transparent
					/>
					<Edges />
				</mesh>
			</group>
		);
	}

	const getTenancyDataFromId = (id) => {
		const tenancy = tenancies.find((tenancy) => tenancy.id === id);
		return tenancy ? tenancy : "Unavailable";
	};
	const onCloseClick = () => {
		setClickedTenancy(false);
	};
	const [val, setVal] = useState();
	const vec = new Vector3();
	function Rig() {
		return useFrame(({ camera }) => {
			const adjustedVal = val - initialRotation;
			let rotateX =
				fov * Math.sin((adjustedVal / 100) * 2 * Math.PI) +
				target[0];
			let rotateY = target[1]; // The y-coordinate of the camera stays the same as the center of the object
			let rotateZ =
				fov * Math.cos((adjustedVal / 100) * 2 * Math.PI) +
				target[2];
			vec.set(rotateX, rotateY, rotateZ);
			camera.position.lerp(vec, 0.05);
		});
	}

	// useEffect(() => {
	// 	if (controls.current) {
	// 		console.log('updated');
	// 		const adjustedVal = (val - initialRotation) / 100;
	// 		console.log(controls.current);
	// 		console.log(controls.current.azimuthAngle);
	// 		controls.current.azimuthAngle = adjustedVal * 2 * Math.PI;
	// 		console.log(controls.current.azimuthAngle);
	// 		controls.current.update();
	// 	}
	// }, [val]);

	return (
		<Wrapper>
			<ClickedTenancyContainer>
				{clickedTenancy && (
					<TenancyCardSmall
						key={clickedTenancy.id}
						name={clickedTenancy.title}
						image={clickedTenancy.images[0]?.original}
						price={clickedTenancy.monthlyRent.value}
						availableFrom={clickedTenancy.availableFrom}
						rooms={clickedTenancy.rooms}
						size={clickedTenancy.size.value}
						id={clickedTenancy.id}
						street={clickedTenancy.address.street}
						floorplan={clickedTenancy.floorPlans[0]?.url}
						onCloseClick={onCloseClick}
					/>
				)}
			</ClickedTenancyContainer>
			<ModelWrapper>
				<Suspense fallback={<Loader />}>
					<Canvas >
						<perspectiveCamera
							fov={10}
							aspect={window.innerWidth / window.innerHeight}
							near={1}
							far={1000}
						></perspectiveCamera>
						<ambientLight />
						<OrbitControls
							ref={controls}
							enableZoom={false}
							enableRotate={true}
							target={target}
							maxPolarAngle={1.35}
							minPolarAngle={1.35}
						/>
						<Rig />
						<Scene />
						{tenanciesJSON?.map((box, index) => (
							<Box
								key={index}
								position={box.position}
								scale={box.scale}
								joratoId={box.id}
								rotation={box.rotation}
							/>
						))}
					</Canvas>
					<Slider changeSlider={(val) => setVal(val)} />
				</Suspense>
			</ModelWrapper>
		</Wrapper>
	);
};

Isometry.propTypes = {
	tenancies: PropTypes.array,
	tenanciesJSON: PropTypes.array,
	scene: PropTypes.func,
	target: PropTypes.array,
	initialRotation: PropTypes.number,
	fov: PropTypes.object
};

Isometry.defaultProps = {
	tenancies: [],
	tenanciesJSON: [],
	scene: () => {},
	target: [0, 0, 0],
	initialRotation: 0,
	fov: {desktop: 35, mobile: 50}
};

export default Isometry;
