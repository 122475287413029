import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";
import { ReactComponent as FacilityIcon1 } from "assets/icons/rooms.svg";
import { ReactComponent as FacilityIcon2 } from "assets/icons/balcony.svg";
import { ReactComponent as FacilityIcon3 } from "assets/icons/building.svg";
import { ReactComponent as FacilityIcon4 } from "assets/icons/move-in.svg";

const Wrapper = styled.div`
	background-color: #fef6e5;
	display: flex;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 48px 16px;
	padding-top: 50px;
	padding-bottom: 60px;
	padding-left: 20px;
	padding-right: 20px;

	@media ${device.laptop} {
		grid-template-columns: repeat(4, minmax(0, 300px));
		grid-gap: 48px 16px;
	}

	svg {
		height: 44px;
		width: 44px;
		stroke: #003202;
	}
`;

const Feature = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	p {
		margin-top: 16px;
		width: 128px;
		font-size: 12px;
		text-align: center;
	}
`;

const Button = styled.div`
	margin-top: 31px;
	background-color: #003202;
	height: 42px;
	width: 263px;
	color: white;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FeaturesSection = () => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<Feature>
				<FacilityIcon1 />
				<p>2-5 værelses lejligheder</p>
			</Feature>
			<Feature>
				<FacilityIcon2 />
				<p>Altan eller tagterrasse i alle boliger</p>
			</Feature>
			<Feature>
				<FacilityIcon3 />
				<p>Bæredygtige byggematerialer</p>
			</Feature>
			<Feature>
				<FacilityIcon4 />
				<p>Indflytning fra december 2024</p>
			</Feature>
		</Wrapper>
	);
};

export default FeaturesSection;
