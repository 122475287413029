import React from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import Line from 'components/Line';
import { device } from 'helpers/device';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding: 32px 16px;	
	box-sizing: border-box;
	@media ${device.laptop} { 
		padding-left: 80px;
		padding-right: 80px;
	}
`;
const Text = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: -0.502564px;
	color: ${p => p.theme.colors.text.secondary};
	margin-bottom: 40px;
`;
const LimitedWidthTitle = styled(Title)`
	max-width: 428px;
`;



const Intro = () => {

	const { t } = useTranslation();

	return (
		<Wrapper>
			<Line/>
			<LimitedWidthTitle>{t('aboutUs.intro.title')}</LimitedWidthTitle>
			<Text>{t('aboutUs.intro.text')}</Text>
		</Wrapper>
	);
};

export default Intro;