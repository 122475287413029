import React from 'react';
import styled from 'styled-components';
import arrowIcon from 'assets/icons/arrow-white.png';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
	font-weight: 700;
	width: 160px;
	height: 40px;
	padding: unset;
	border: unset;
	background: #C49A36;
	font-size: 16px;
	color: white;
	transition-duration: 1s;

	img {
		padding-left: 16px;
		transition-duration: 0.4s;
	}

	&:hover {
		cursor: pointer;
		img {
			padding-left: 32px;
		}
	}
`;

const ButtonSmall = props => {
	return (
		<StyledButton onClick={props.onClick}>
			{props.children} <img className="icon" src={arrowIcon} />
		</StyledButton>
	);
};

ButtonSmall.propTypes = {
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func
};

export default ButtonSmall;