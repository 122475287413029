import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	margin: auto;
	padding: 16px 16px;	
	box-sizing: border-box;
	@media ${device.laptop} { 
		
	}
`;

const IconContainer = styled.div`
	width: 80px;
	height: 80px;
	padding: 32px 50px 32px 50px;
`;

const TextContainer = styled.div`
	width: 180px;
	text-align: center;
`;

const IconWithText = (props) => {

	const {
		icon, text
	} = props;

	return (
		<Wrapper>
			<IconContainer>
				{icon}
			</IconContainer>
			<TextContainer>
				{text}
			</TextContainer>
		</Wrapper>
	);
};

IconWithText.propTypes = {
	icon: PropTypes.object,
	text: PropTypes.string,
};

export default IconWithText;