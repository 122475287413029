import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useMapBoxSearch from 'features/mapbox-search';
import useKeypress from 'components/useKeyPress';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';

const SearchBoxWrapper = styled.div`
	display: flex;
	direction: column;
	justify-content: center;
    align-items: center;
	position: relative;
	padding: 24px;


	@media ${device.laptop} { 
		padding: 24px 24px 84px 24px;
	}

	input {
		font-size: 16px;
		height: 21px;
		padding: unset;
		border: unset;
		border: 2px solid #384336;
		padding: 13px 24px 12px 24px; 

		&:focus {
			outline: none;
		}
		@media ${device.laptop} { 
			width: 414px;
		}
	}

	button {
		height: 50px;
		padding: unset;
		padding: 0px 24px;
		min-width: 100px;
		border: unset;
		background: #384336;
		font-size: 16px;
		color: white;
		cursor: pointer;
	}

	ul {
		position: absolute;
		display: none;
		top: 74px;
		width: calc(100% - 58px);
		color: ${p => p.theme.colors.ascent.primary};
		background-color: white;
		margin: unset;
		list-style-type: none;
		padding: 0px;
		transition: transform 0.3s ease-out;
		border: 2px solid #384336;

		@media ${device.laptop} { 
			width: 562px;
		}

		&.visible {
			display: block;
			z-index: 99999999;
		}

		li {
			padding: 8px 16px;
			cursor: pointer;

			&:hover {
				background-color: ${p => p.theme.colors.ascent.primary};
				color: white;
			}
		}
	}
`;

const SearchBox = (props) => {
	const { searchQuery, setSearchQuery, updateSelectedPlace, updateUrl } = props;
	const [searchOptionsVisible, setSearchOptionsVisible] = useState(false);
	const {
		isLoading,
		queryData,
		getPlaces
	} = useMapBoxSearch();

	const wrapperRef = useRef(null);
	const getRedirectUri = () => {
		if (!isLoading && queryData?.length > 0) {
			return `/boliger/?q=${queryData[0].place_name_da}`;
		} else {
			return '/boliger';
		}
	};
	const { t } = useTranslation();

	useKeypress('Escape', () => {
		setSearchOptionsVisible(false);
	});
	useKeypress('Enter', () => {
		const r = getRedirectUri();	
		window.location.href = r;
	});

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleChange = (event) => {
		setSearchQuery(event.target.value);
		const encodedQuery = encodeURI(event.target.value);
		getPlaces(encodedQuery);
		setSearchOptionsVisible(true);
	};

	const handleClick = e => {
		if (!wrapperRef.current.contains(e.target)) {
			setSearchOptionsVisible(false);
			return;
		}
	};

	const onItemClick = (place) => {
		updateUrl(`/boliger/?q=${encodeURI(place.place_name_da)}`);
		if (updateSelectedPlace !== null) {
			updateSelectedPlace(place, 'place');
			setSearchOptionsVisible(false);
		}
	};

	return (
		<SearchBoxWrapper ref={wrapperRef} >
			<input 
				onChange={handleChange} 
				value={searchQuery} 
				placeholder={t('search.placeholder')}
			/>
			<ul className={searchOptionsVisible && queryData?.length > 0 ? 'visible' : ''}>
				{
					queryData !== null && queryData.map(option => {
						const displayName = option.place_name_da.replace(', Danmark', '');
						return (
							<li key={option.id} onClick={() => onItemClick(option)}>{displayName}</li>
						);
					})
				}
			</ul>
			<a href={getRedirectUri()}><button>{searchQuery === '' ? t('search.buttonLabel') : 'Søg'}</button></a>
		</SearchBoxWrapper>
	);
};

SearchBox.propTypes = {
	searchQuery: PropTypes.string,
	setSearchQuery: PropTypes.func,
	updateSelectedPlace: PropTypes.func,
	updateUrl: PropTypes.func,
};

SearchBox.defaultProps = {
	searchQuery: '',
	setSearchQuery: null,
	updateSelectedPlace: null,
	updateUrl: null,
};

export default SearchBox;