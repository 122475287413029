const imageMapJSON = [
	{
		"joratoId": "1a835251-0b02-4d68-b2c8-9a935c54d38f",
		"title": "Kong Georgs Vej 59A, st.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, st.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [256, 348, 257, 318, 300, 358, 363, 331, 367, 335, 379, 329, 379, 354, 301, 389]
	},
	{
		"joratoId": "34b5768f-df49-4f55-ada1-bf0e464acdcc",
		"title": "Kong Georgs Vej 59B, st. tv.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, st. tv.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [379, 353, 379, 328, 383, 325, 382, 321, 431, 300, 431, 289, 437, 288, 440, 289, 479, 272, 476, 269, 481, 265, 482, 297, 432, 320, 432, 329]
	},
	{
		"joratoId": "50eaf6c9-5e88-4148-8019-9ea3b8ef90ad",
		"title": "Kong Georgs Vej 59B, st. th.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, st. th.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [508, 256, 519, 251, 523, 252, 560, 234, 559, 232, 567, 229, 567, 260, 508, 287]
	},
	{
		"joratoId": "74c188f7-f943-4c25-bf1d-127cd48bdc7d",
		"title": "Kong Georgs Vej 59A, 1.th.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 1.th.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [202, 255, 202, 233, 241, 269, 241, 295]
	},
	{
		"joratoId": "5413812f-fdfd-4eb8-b682-48395041bc84",
		"title": "Kong Georgs Vej 59A, 1.mf.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 1.mf.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [241, 303, 241, 278, 301, 332, 360, 306, 366, 312, 378, 306, 378, 318, 383, 326, 368, 333, 363, 330, 300, 358]
	},
	{
		"joratoId": "ef35f3c0-9c4b-4b0d-8c39-2e306f1f3c32",
		"title": "Kong Georgs Vej 59A, 1.tv.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 1.tv.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [378, 307, 378, 319, 380, 323, 431, 299, 431, 278, 380, 300, 383, 302]
	},
	{
		"joratoId": "f8cf45a9-ff18-46da-bff3-0dfef0183830",
		"title": "Kong Georgs Vej 59B, 1. th.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 1. th.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [431, 287, 430, 265, 434, 262, 438, 268, 479, 250, 474, 244, 481, 241, 481, 266, 475, 269, 478, 271, 441, 288]
	},
	{
		"joratoId": "d3892acb-d60b-4395-a9b5-c2544a1bf087",
		"title": "Kong Georgs Vej 59B, 1. tv.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 1. tv.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [481, 266, 480, 242, 517, 226, 522, 231, 560, 213, 557, 207, 566, 204, 567, 228, 559, 233, 560, 234, 525, 250, 522, 250]
	},
	{
		"joratoId": "52f78d11-a279-4ebd-a0ca-72725c524df6",
		"title": "Kong Georgs Vej 59A, 2.th.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 2.th.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [202, 232, 202, 210, 242, 245, 241, 268]
	},
	{
		"joratoId": "f391aeee-540a-4185-9df8-256dd7743c46",
		"title": "Kong Georgs Vej 59A, 2.mf.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 2.mf.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [241, 278, 241, 257, 303, 307, 359, 282, 365, 287, 379, 282, 379, 298, 383, 304, 366, 310, 360, 306, 300, 332]
	},
	{
		"joratoId": "c647d007-eac4-478d-a4a9-b82c743c667d",
		"title": "Kong Georgs Vej 59A, 2.tv.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 2.tv.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [379, 300, 379, 284, 384, 281, 379, 275, 430, 252, 430, 278]
	},
	{
		"joratoId": "71b9a4e3-409e-42f8-b667-c221b49d7451",
		"title": "Kong Georgs Vej 59B, 2. th.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 2. th.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [430, 265, 430, 246, 436, 243, 439, 246, 479, 229, 475, 223, 480, 222, 480, 242, 471, 244, 479, 250, 442, 267, 436, 262]
	},
	{
		"joratoId": "e36aec9d-94f2-4a80-8e73-c81d86f5ac1c",
		"title": "Kong Georgs Vej 59B, 2. tv.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 2. tv.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [481, 241, 480, 221, 517, 205, 522, 208, 561, 191, 556, 186, 566, 181, 566, 204, 555, 208, 561, 213, 521, 230, 518, 225]
	},
	{
		"joratoId": "bed43467-123d-4acc-a268-1230be17f236",
		"title": "Kong Georgs Vej 59A, 3.th.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 3.th.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [243,244,202,209,203,183,227,208,228,183,264,166,272,175,296,163,308,174,314,187,329,180,384,236,299,274,246,230]
	},
	{
		"joratoId": "4014c3b4-9ee5-4d5a-a4d0-6108da5986c6",
		"title": "Kong Georgs Vej 59A, 3.mf.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 3.mf.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [302,307,282,290,242,259,242,253,246,230,299,273,381,238,382,272,377,274,384,281,365,288,362,282]
	},
	{
		"joratoId": "318fd08e-c1ed-4b2d-a605-a1ae9bf5c3e9",
		"title": "Kong Georgs Vej 59A, 3.tv.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59A, 3.tv.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [383,272,382,238,426,219,431,252]
	},
	{
		"joratoId": "da43bcb8-5637-4e36-87ae-694f4e95b6b3",
		"title": "Kong Georgs Vej 59B, 3.th.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 3.th.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [426, 220, 431, 245, 438, 242, 439, 246, 479, 228, 475, 224, 479, 221, 478, 198]
	},
	{
		"joratoId": "cee6f369-5aea-46f5-bdd1-7c7491e6178b",
		"title": "Kong Georgs Vej 59B, 3.tv.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 3.tv.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [479, 199, 479, 221, 520, 203, 523, 207, 560, 190, 555, 186, 567, 180, 566, 163, 559, 165, 563, 168, 523, 185, 519, 181]
	},
	{
		"joratoId": "0caa8fcd-03e6-4081-b8f8-3d52f0341df1",
		"title": "Kong Georgs Vej 59B, 4.th.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 4.th.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [384,237,329,180,368,163,377,132,400,122,400,105,425,93,468,139,481,195]
	},
	{
		"joratoId": "a06b77f6-ec61-43d2-b0a4-9767115d3bb8",
		"title": "Kong Georgs Vej 59B, 4.tv.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 4.tv.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [479, 198, 475, 175, 482, 178, 517, 162, 522, 165, 541, 156, 535, 152, 565, 139, 567, 162, 557, 166, 560, 168, 522, 185, 519, 181]
	},
	{
		"joratoId": "91ce0894-b2b2-437a-936a-c064886ee3db",
		"title": "Kong Georgs Vej 59B, 5.",
		"shape": "poly",
		"name": "Kong Georgs Vej 59B, 5.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [475, 174, 482, 177, 515, 162, 521, 165, 540, 157, 534, 152, 567, 138, 561, 105, 517, 61, 462, 85, 454, 80, 425, 92, 468, 137]
	},
	{
		"joratoId": "14d5e813-7eb2-4bc5-bbd0-e75fcb945830",
		"title": "Kong Georgs Vej 61A, st.th",
		"shape": "poly",
		"name": "Kong Georgs Vej 61A, st.th",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [500, 549, 437, 487, 437, 456, 501, 519, 519, 511, 521, 512, 535, 506, 535, 533]
	},
	{
		"joratoId": "1acf90f7-a1a1-4319-b670-270880fa9fb5",
		"title": "Kong Georgs Vej 61B, st.th",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, st.th",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [597, 478, 593, 473, 594, 457, 629, 440, 625, 436, 654, 423, 654, 452]
	},
	{
		"joratoId": "d918f16a-9468-41a0-afce-42b1093c3ee6",
		"title": "Kong Georgs Vej 61B, st.tv",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, st.tv",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [670, 444, 670, 416, 675, 419, 714, 402, 710, 398, 728, 391, 729, 417]
	},
	{
		"joratoId": "c4890f7d-3a64-4ce8-9ad5-71a1dc3443f9",
		"title": "Kong Georgs Vej 61A, 1.th",
		"shape": "poly",
		"name": "Kong Georgs Vej 61A, 1.th",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [437, 457, 437, 434, 501, 495, 534, 481, 534, 502, 538, 505, 521, 512, 519, 510, 501, 518]
	},
	{
		"joratoId": "93bdb07d-0568-4a15-ab43-7a7fc604b874",
		"title": "Kong Georgs Vej 61A, 1.tv",
		"shape": "poly",
		"name": "Kong Georgs Vej 61A, 1.tv",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [555, 494, 555, 472, 556, 470, 559, 472, 596, 456, 595, 457, 592, 474, 597, 479, 581, 485, 578, 483]
	},
	{
		"joratoId": "ba75ccbc-1da0-49b1-86dc-d92b09ce514a",
		"title": "Kong Georgs Vej 61B, 1.tv",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, 1.tv",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [598, 455, 594, 450, 594, 436, 598, 432, 629, 419, 625, 414, 654, 401, 654, 423, 625, 435, 629, 440]
	},
	{
		"joratoId": "01e3ec3f-2e37-4765-9657-73d8da5394b8",
		"title": "Kong Georgs Vej 61B, 1.th",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, 1.th",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [669, 416, 669, 396, 671, 392, 676, 397, 716, 379, 709, 375, 728, 367, 728, 390, 711, 398, 714, 402, 677, 418]
	},
	{
		"joratoId": "0bbb0330-e506-4505-8b76-90f86a4cd98e",
		"title": "Kong Georgs Vej 61A, 2.th",
		"shape": "poly",
		"name": "Kong Georgs Vej 61A, 2.th",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [436, 433, 437, 428, 446, 412, 464, 427, 465, 439, 501, 471, 533, 458, 534, 481, 502, 495]
	},
	{
		"joratoId": "1bf9ec7d-35d5-486d-8461-dc27de9b31cb",
		"title": "Kong Georgs Vej 61A, 2.tv",
		"shape": "poly",
		"name": "Kong Georgs Vej 61A, 2.tv",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [556, 468, 552, 465, 552, 449, 553, 444, 557, 451, 593, 435, 593, 450, 597, 455, 557, 472, 552, 449, 553, 444]
	},
	{
		"joratoId": "7e47a243-1309-41d2-a377-fb7ce53f868b",
		"title": "Kong Georgs Vej 61B, 2.tv",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, 2.tv",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [598, 431, 594, 427, 595, 413, 630, 397, 626, 392, 654, 380, 654, 400, 623, 414, 629, 418]
	},
	{
		"joratoId": "9d688f8a-cfc9-4a25-a3df-28547920f407",
		"title": "Kong Georgs Vej 61B, 2.th",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, 2.th",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [669, 393, 669, 373, 672, 372, 673, 376, 714, 358, 710, 353, 729, 345, 729, 367, 708, 375, 715, 380, 677, 397]
	},
	{
		"joratoId": "3fbbbed1-df71-4823-b961-54203417963a",
		"title": "Kong Georgs Vej 61A, 3.",
		"shape": "poly",
		"name": "Kong Georgs Vej 61A, 3.",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [501, 471, 463, 438, 465, 428, 446, 411, 458, 382, 510, 433, 594, 395, 594, 428, 599, 433, 559, 449, 556, 447]
	},
	{
		"joratoId": "3a8c9e1d-d574-4ce9-abdc-ed7a63ea00af",
		"title": "Kong Georgs Vej 61B, 3.tv",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, 3.tv",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [595, 411, 595, 394, 586, 385, 654, 355, 654, 379, 624, 392, 630, 396]
	},
	{
		"joratoId": "d89e1b80-bf9b-4cb8-ad93-13df36cfecbd",
		"title": "Kong Georgs Vej 61B, 3.th",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, 3.th",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [669, 372, 669, 348, 675, 353, 714, 336, 709, 331, 728, 323, 728, 345, 708, 353, 715, 357, 675, 375]
	},
	{
		"joratoId": "3132aeda-1ee3-46fc-9a50-cecf7b4bb717",
		"title": "Kong Georgs Vej 61B, 4.tv",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, 4.tv",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [511, 433, 459, 383, 463, 379, 463, 357, 493, 344, 504, 354, 517, 346, 518, 339, 529, 302, 562, 285, 563, 270, 616, 246, 671, 295, 671, 328, 656, 334, 655, 355, 585, 386, 596, 394]
	},
	{
		"joratoId": "10f7f33f-da22-4417-a8a8-2b8df103a987",
		"title": "Kong Georgs Vej 61B, 4.th",
		"shape": "poly",
		"name": "Kong Georgs Vej 61B, 4.th",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(255 0 0 / 50%)",
		"coords": [668, 346, 668, 329, 672, 329, 671, 297, 629, 256, 687, 229, 717, 260, 728, 299, 728, 323, 709, 331, 714, 336, 674, 353]
	}
];

export default imageMapJSON;