import { useState, useCallback } from 'react';
import OpenHouseService from 'services/api/open-houses';

const useTenancies = () => {

	const [isOpenHousesLoading, setIsOpenHousesLoading] = useState(true);
	const [openHousesData, setOpenHousesData] = useState(null);

	const loadOpenHouses = useCallback(async () => {
		const result = await OpenHouseService.getOpenHouses();
		if (result !== null) {

			setOpenHousesData({
				...result.data
			});
		} else {
			setOpenHousesData(null);
		}
		setIsOpenHousesLoading(false);
	}, []);

	const loadOpenHousesByPropertyIds = useCallback(async (propertyIds) => {
		const result = await OpenHouseService.getOpenHouses();
		if (result !== null) {
			const filtered = result.data.filter((item) => propertyIds.includes(item.propertyId));
			setOpenHousesData(filtered);
		} else {
			setOpenHousesData(null);
		}
		setIsOpenHousesLoading(false);
	}, []);

	return {
		isOpenHousesLoading,
		loadOpenHouses,
		loadOpenHousesByPropertyIds,
		openHousesData
	};
};

export default useTenancies;