import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useTenancies from "features/tenancies";
import useOpenHouses from "features/open-houses";

import HeroSection4 from "components/HeroSection4";
import FactSection1 from "components/FactSection1";
import CarouselSection1 from "components/CarouselSection1";
import FactSection2 from "components/FactSection2";
import FacilitiesSection1 from "components/FacilitiesSection1";
import ImageSection1 from "components/ImageSection1";
import ImageGridSection2 from "components/ImageGridSection2";
import AvailableTenanciesList from "components/AvailableTenanciesList";
import OpenHouseSection from "../Components/OpenHouseSection";
import OpenHouseSectionManual from "../Components/OpenHouseSectionManual/OpenHouseSectionManual";
import ContactSection from "components/ContactSection";
import Button from "components/Button";
import InterestListButton from "components/InterestListButton";
import imageMapJSON from "./components/imageMapJSON";
import PropertyOverview from "assets/properties/skovstykket/skovstykket-overview.jpg";
import background01 from "assets/properties/labyrinthen/background-maze.png";
import backgroundMovie from "assets/properties/skovstykket/skovstykket.mp4";
import imageProperty01 from "assets/properties/skovstykket/skovstykket-area-01.jpg";
import imageProperty02 from "assets/properties/skovstykket/skovstykket-property-02.jpg";
import imageProperty03 from "assets/properties/skovstykket/skovstykket-property-03.jpg";
import imageProperty04 from "assets/properties/skovstykket/skovstykket-property-04.jpg";
import imageProperty05 from "assets/properties/skovstykket/skovstykket-property-05.jpg";
import imageProperty06 from "assets/properties/skovstykket/skovstykket-property-06.jpg";
import imageProperty07 from "assets/properties/skovstykket/skovstykket-property-07.jpg";
import imageTenancy01 from "assets/properties/skovstykket/skovstykket-tenancy-01.jpg";
import imageTenancy02 from "assets/properties/skovstykket/skovstykket-tenancy-02.jpg";
import imageTenancy03 from "assets/properties/skovstykket/skovstykket-tenancy-03.jpg";
import imageTenancy04 from "assets/properties/skovstykket/skovstykket-tenancy-04.jpg";
import imageTenancy05 from "assets/properties/skovstykket/skovstykket-tenancy-05.jpg";
import imageTenancy06 from "assets/properties/skovstykket/skovstykket-tenancy-06.jpg";
import imageTenancy07 from "assets/properties/skovstykket/skovstykket-tenancy-07.jpg";
import imageTenancy08 from "assets/properties/skovstykket/skovstykket-tenancy-08.jpg";
import imageTenancy09 from "assets/properties/skovstykket/skovstykket-tenancy-09.jpg";
import imageTenancy10 from "assets/properties/skovstykket/skovstykket-tenancy-10.jpg";
import imageTenancy11 from "assets/properties/skovstykket/skovstykket-tenancy-11.jpg";
import imageTenancy12 from "assets/properties/skovstykket/skovstykket-tenancy-12.jpg";
import imageTenancy13 from "assets/properties/skovstykket/skovstykket-tenancy-13.jpg";
import imageTenancy14 from "assets/properties/skovstykket/skovstykket-tenancy-14.jpg";
import imageTenancy15 from "assets/properties/skovstykket/skovstykket-tenancy-15.jpg";
import floorplanApartment3 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-rooms.jpg";
import floorplanApartment4 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-rooms.jpg";
import floorplanTownhouse4 from "assets/properties/skovstykket/skovstykket-floorplans-townhouse-4-rooms.jpg";
import floorplanTownhouse5 from "assets/properties/skovstykket/skovstykket-floorplans-townhouse-5-rooms.jpg";
import floorplanTownhouse6 from "assets/properties/skovstykket/skovstykket-floorplans-townhouse-6-rooms.jpg";
import apartment3rooms01 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-01.jpg";
import apartment3rooms02 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-02.jpg";
import apartment3rooms03 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-03.jpg";
import apartment3rooms04 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-04.jpg";
import apartment3rooms05 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-05.jpg";
import apartment3rooms06 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-06.jpg";
import apartment3rooms07 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-07.jpg";
import apartment3rooms08 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-08.jpg";
import apartment3rooms09 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-09.jpg";
import apartment3rooms10 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-10.jpg";
import apartment3rooms11 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-11.jpg";
import apartment3rooms12 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-3-12.jpg";
import apartment4rooms01 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-01.jpg";
import apartment4rooms02 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-02.jpg";
import apartment4rooms03 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-03.jpg";
import apartment4rooms04 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-04.jpg";
import apartment4rooms05 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-05.jpg";
import apartment4rooms06 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-06.jpg";
import apartment4rooms07 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-07.jpg";
import apartment4rooms08 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-08.jpg";
import apartment4rooms09 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-09.jpg";
import apartment4rooms10 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-10.jpg";
import apartment4rooms11 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-11.jpg";
import apartment4rooms12 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-12.jpg";
import apartment4rooms13 from "assets/properties/skovstykket/skovstykket-floorplans-apartment-4-13.jpg";
import townhouse4rooms01 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-01.jpg";
import townhouse4rooms02 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-02.jpg";
import townhouse4rooms03 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-03.jpg";
import townhouse4rooms04 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-04.jpg";
import townhouse4rooms05 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-05.jpg";
import townhouse4rooms06 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-06.jpg";
import townhouse4rooms07 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-07.jpg";
import townhouse4rooms08 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-08.jpg";
import townhouse4rooms09 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-09.jpg";
import townhouse4rooms10 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-10.jpg";
import townhouse4rooms11 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-11.jpg";
import townhouse4rooms12 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-12.jpg";
import townhouse4rooms13 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-13.jpg";
import townhouse4rooms14 from "assets/properties/skovstykket/skovstykket-townhouse-4-rooms-14.jpg";
import townhouse5rooms01 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-01.jpg";
import townhouse5rooms02 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-02.jpg";
import townhouse5rooms03 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-03.jpg";
import townhouse5rooms04 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-04.jpg";
import townhouse5rooms05 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-05.jpg";
import townhouse5rooms06 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-06.jpg";
import townhouse5rooms07 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-07.jpg";
import townhouse5rooms08 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-08.jpg";
import townhouse5rooms09 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-09.jpg";
import townhouse5rooms10 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-10.jpg";
import townhouse5rooms11 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-11.jpg";
import townhouse5rooms12 from "assets/properties/skovstykket/skovstykket-townhouse-5-rooms-12.jpg";
import townhouse6rooms01 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-01.jpg";
import townhouse6rooms02 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-02.jpg";
import townhouse6rooms03 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-03.jpg";
import townhouse6rooms04 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-04.jpg";
import townhouse6rooms05 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-05.jpg";
import townhouse6rooms06 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-06.jpg";
import townhouse6rooms07 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-07.jpg";
import townhouse6rooms08 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-08.jpg";
import townhouse6rooms09 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-09.jpg";
import townhouse6rooms10 from "assets/properties/skovstykket/skovstykket-townhouse-6-rooms-10.jpg";
import { ReactComponent as StarsIcon } from "assets/icons/stars.svg";
import { ReactComponent as ResizeIcon } from "assets/icons/resize-arrows.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as ParkingIcon } from "assets/icons/parking2.svg";
import { ReactComponent as PlaygroundIcon } from "assets/icons/playground.svg";
import { ReactComponent as GreenAreasIcon } from "assets/icons/green-areas.svg";
import { ReactComponent as TerraceIcon } from "assets/icons/terrace.svg";
import { ReactComponent as ShedIcon } from "assets/icons/shed.svg";
import { ReactComponent as ChargerIcon } from "assets/icons/ev-charger.svg";
import { ReactComponent as BicycleIcon } from "assets/icons/bicycle.svg";
import { ReactComponent as WasherIcon } from "assets/icons/washer.svg";
import { ReactComponent as ClosetIcon } from "assets/icons/closet.svg";
import ImageMapSectionWithListOverlay from "./components/ImageMapSectionWithListOverlay";

const Wrapper = styled.div`
	margin: 0 auto;
	background-color: #fafafa;
	position: relative;
`;

const Skovstykket = () => {
	const [activeIsometry, setActiveIsometry] = useState(null);

	const { t } = useTranslation();

	const { isTenanciesLoading, tenanciesData, loadTenanciesByPropertyIds } = useTenancies();

	const { isOpenHousesLoading, loadOpenHouses, loadOpenHousesByPropertyIds, openHousesData } = useOpenHouses();

	const propertyIds = [
		"01d67e14-8447-4c3b-a5de-e47971d1a5f2", // correct, rest are wrong
		"44a19b00-c7ae-42df-b4f4-71ba6a1a0592",
	];

	useEffect(() => {
		loadTenanciesByPropertyIds(propertyIds);
	}, [loadTenanciesByPropertyIds]);

	useEffect(() => {
		loadOpenHousesByPropertyIds(propertyIds);
	}, [loadOpenHousesByPropertyIds]);

	const factSectionBoxes = [
		{
			pre: t("properties.skovstykket.factSection1.boxes.text1.pre"),
			bold: t("properties.skovstykket.factSection1.boxes.text1.bold"),
			after: t("properties.skovstykket.factSection1.boxes.text1.after"),
			icon: <StarsIcon />,
		},
		{
			pre: t("properties.skovstykket.factSection1.boxes.text2.pre"),
			bold: t("properties.skovstykket.factSection1.boxes.text2.bold"),
			after: t("properties.skovstykket.factSection1.boxes.text2.after"),
			icon: <BuildingIcon />,
		},
		{
			pre: t("properties.skovstykket.factSection1.boxes.text3.pre"),
			bold: t("properties.skovstykket.factSection1.boxes.text3.bold"),
			after: t("properties.skovstykket.factSection1.boxes.text3.after"),
			icon: <ResizeIcon />,
		},
	];

	const facilities = [
		{
			icon: <ParkingIcon />,
			text: t("properties.skovstykket.facilitySection.text1"),
		},
		{
			icon: <PlaygroundIcon />,
			text: t("properties.skovstykket.facilitySection.text2"),
		},
		{
			icon: <ShedIcon />,
			text: t("properties.skovstykket.facilitySection.text3"),
		},
		{
			icon: <ChargerIcon />,
			text: t("properties.skovstykket.facilitySection.text4"),
		},
		{
			icon: <TerraceIcon />,
			text: t("properties.skovstykket.facilitySection.text5"),
		},
		{
			icon: <ShedIcon />,
			text: t("properties.skovstykket.facilitySection.text6"),
		},
		{
			icon: <ClosetIcon />,
			text: t("properties.skovstykket.facilitySection.text7"),
		},
		{
			icon: <BicycleIcon />,
			text: t("properties.skovstykket.facilitySection.text8"),
		},
		{
			icon: <GreenAreasIcon />,
			text: t("properties.skovstykket.facilitySection.text9"),
		},
		{
			icon: <WasherIcon />,
			text: t("properties.skovstykket.facilitySection.text10"),
		},
	];

	const images = [imageTenancy07, imageTenancy10, imageTenancy12, imageTenancy11, imageTenancy14, imageTenancy15];

	const sliderImages = [imageProperty06, imageProperty04, imageProperty03];

	const floorplans = {
		'3apartment': [{ src: floorplanApartment3}, { src: townhouse5rooms01}, { src: townhouse5rooms02}, { src: townhouse5rooms03}, { src: townhouse5rooms04}, { src: townhouse5rooms05}, { src: townhouse5rooms06}, { src: townhouse5rooms07}, { src: townhouse5rooms08}, { src: townhouse5rooms09}, { src: townhouse5rooms10}, { src: townhouse5rooms11}, { src: townhouse5rooms12}],
		'4apartment': [{ src: floorplanApartment4}, { src: apartment4rooms01}, { src: apartment4rooms02}, { src: apartment4rooms03}, { src: apartment4rooms04}, { src: apartment4rooms05}, { src: apartment4rooms06}, { src: apartment4rooms07}, { src: apartment4rooms08}, { src: apartment4rooms09}, { src: apartment4rooms10}, { src: apartment4rooms11}, { src: apartment4rooms12}, { src: apartment4rooms13}],
		'4townhouse': [{ src: floorplanTownhouse4}, { src: townhouse4rooms01}, { src: townhouse4rooms02}, { src: townhouse4rooms03}, { src: townhouse4rooms04}, { src: townhouse4rooms05}, { src: townhouse4rooms06}, { src: townhouse4rooms07}, { src: townhouse4rooms08}, { src: townhouse4rooms09}, { src: townhouse4rooms10}, { src: townhouse4rooms11}, { src: townhouse4rooms12}, { src: townhouse4rooms13}, { src: townhouse4rooms14}],
		'5townhouse': [{ src: floorplanTownhouse5}, { src: apartment3rooms01}, { src: apartment3rooms02}, { src: apartment3rooms03}, { src: apartment3rooms04}, { src: apartment3rooms05}, { src: apartment3rooms06}, { src: apartment3rooms07}, { src: apartment3rooms08}, { src: apartment3rooms09}, { src: apartment3rooms10}, { src: apartment3rooms11}, { src: apartment3rooms12}],
		'6townhouse': [{ src: floorplanTownhouse6}, { src: townhouse6rooms01}, { src: townhouse6rooms02}, { src: townhouse6rooms03}, { src: townhouse6rooms04}, { src: townhouse6rooms05}, { src: townhouse6rooms06}, { src: townhouse6rooms07}, { src: townhouse6rooms08}, { src: townhouse6rooms09}, { src: townhouse6rooms10}],
	};

	const contactSectionRef = useRef(null);
	const scrollToContactSection = () => {
		contactSectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const isometrySectionRef = useRef(null);
	const scrollToIsometrySection = () => {
		isometrySectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const onImageMapClick = (area) => {
		setActiveIsometry(parseInt(area.id));
	};
	const onReturnClick = () => {
		setActiveIsometry(null);
	};

	return (
		<Wrapper>
			{/* {openHousesData?.length > 0 && (
				<OpenHouseSectionManual
					isLoading={isOpenHousesLoading}
					openHouses={openHousesData}
				/>
			)} */}
			<HeroSection4
				background={backgroundMovie}
				backgroundType={"video"}
				title={t("properties.skovstykket.title")}
				text={t("properties.skovstykket.subtitle")}
				primaryButtons={<InterestListButton propertyId={propertyIds[0]} />} // this is broken, as we now have multiple propertyIds
				secondaryButtons={<Button onClick={scrollToContactSection}>{t("properties.general.contactUs")}</Button>}
			/>
			<div ref={isometrySectionRef}>
				<ImageMapSectionWithListOverlay
					title={t("properties.skovstykket.ImageMapSection.title")}
					text={t("properties.skovstykket.ImageMapSection.text")}
					imageWidth={2560}
					imageMapJSON={imageMapJSON}
					tenancies={tenanciesData}
					isTenanciesLoading={isTenanciesLoading}
					image={PropertyOverview}
					floorplans={floorplans}
				/>
			</div>
			{tenanciesData?.length > 0 && (
				<AvailableTenanciesList loading={isTenanciesLoading} tenancies={tenanciesData} />
			)}
			<FactSection1
				background={background01}
				title={t("properties.skovstykket.factSection1.title")}
				textBase={t("properties.skovstykket.factSection1.textBase")}
				textAdditional={t("properties.skovstykket.factSection1.textAdditional")}
				buttonText={t("properties.skovstykket.factSection1.buttonText")}
				buttonOnClick={scrollToIsometrySection}
				boxes={factSectionBoxes}
			/>
			<CarouselSection1
				title={t("properties.skovstykket.carouselSection.title")}
				section1title={t("properties.skovstykket.carouselSection.section1title")}
				section1text={t("properties.skovstykket.carouselSection.section1text")}
				section2title={t("properties.skovstykket.carouselSection.section2title")}
				section2text={t("properties.skovstykket.carouselSection.section2text")}
				images={sliderImages}
			/>
			<FactSection2
				title1={t("properties.skovstykket.factSection2.title1")}
				text1={t("properties.skovstykket.factSection2.text1")}
				title2={t("properties.skovstykket.factSection2.title2")}
				text2={t("properties.skovstykket.factSection2.text2")}
			/>
			<FacilitiesSection1 title={t("properties.skovstykket.facilitySection.title")} facilities={facilities} />
			<ImageSection1 image={imageProperty07} />
			<ImageGridSection2 images={images} />
			{/* <div ref={isometrySectionRef}>
				<IsometrySelectorSection
					title={t("properties.skovstykket.isometrySection.title")}
					text={t("properties.skovstykket.isometrySection.text")}
					imageMapJSON={imageMapJSON}
					imageMapImage={ImageIsometryDesktop}
					imageMapOnClick={onImageMapClick}
					activeIsometry={activeIsometry}
					isometries={isometries}
					onReturnClick={onReturnClick}
				/>
			</div> */}
			
			<div ref={contactSectionRef}>
				<ContactSection backgroundColor='#fafafa' />
			</div>
		</Wrapper>
	);
};

export default Skovstykket;
