import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import Title from 'components/Title';
import Line from 'components/Line';
import Pdf from 'assets/pdfs/PrivacyPolicy.pdf';

const Wrapper = styled.div`
	max-width: 1120px;	
	min-height: 100vh;
	background-color: white;
	margin-top: 140px;
	margin-left: auto;
	margin-right: auto;
	padding: 32px 16px;	
	box-sizing: border-box;
	@media ${device.laptop} { 
		padding-left: 80px;
		padding-right: 80px;
	}

	.background {
		display: block;
		object-fit: cover;
		margin: 0 auto;
		width: 100%;

		@media ${device.laptop} { 
			max-width: 1200px;
			margin-bottom: 64px;
		}
	}
`;

const Text = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: -0.502564px;
	color: ${p => p.theme.colors.text.secondary};
	margin-bottom: 40px;
`;

const PrivacyPolicy = () => {

	return (
		<Wrapper>
			<Line/>
			<Title>Persondata- og Cookiepolitik</Title>
			<Text>NorsePMs persondata- og cookiepolitik kan downloades via linket nedenfor.</Text>
			<a href = {Pdf} target = "_blank" rel="noreferrer">Download persondata- og cookiepolitik</a>
		</Wrapper>
	);
};

export default PrivacyPolicy;