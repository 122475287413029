import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useTenancies from "features/tenancies";

import HeroSection2 from "components/HeroSection2";
import TextWithImageLeft2 from "components/TextWithImageLeft2";
import TextWithImageRight2 from "components/TextWithImageRight2";
import TextWithImageLeft3 from "components/TextWithImageLeft3";
import FacilitiesGrid from "components/FacilitiesGrid";
import ContactSection from "components/ContactSection";
import AvailableTenanciesList from "components/AvailableTenanciesList";
import ModalButton from "components/ModalButton ";
import GalleryButton from "components/GalleryButton";
import Isometry from "components/Isometry";
import InterestListButton from "components/InterestListButton";
import Scene from "./components/Scene";
import TenancyBoxes from "./components/tenancyBoxes";
import hero from "assets/properties/smedetoften/smedetoften-hero.jpg";
import imageTenancy01 from "assets/properties/smedetoften/smedetoften-tenancy-01.jpg";
import imageTenancy02 from "assets/properties/smedetoften/smedetoften-tenancy-02.jpg";
import imageTenancy03 from "assets/properties/smedetoften/smedetoften-tenancy-03.jpg";
import imageTenancy04 from "assets/properties/smedetoften/smedetoften-tenancy-04.jpg";
import imageTenancy05 from "assets/properties/smedetoften/smedetoften-tenancy-05.jpg";
import imageTenancy06 from "assets/properties/smedetoften/smedetoften-tenancy-06.jpg";
import imageTenancy07 from "assets/properties/smedetoften/smedetoften-tenancy-07.jpg";
import imageTenancy08 from "assets/properties/smedetoften/smedetoften-tenancy-08.jpg";
import imageTenancy09 from "assets/properties/smedetoften/smedetoften-tenancy-09.jpg";
import imageTenancy10 from "assets/properties/smedetoften/smedetoften-tenancy-10.jpg";
import imageTenancy11 from "assets/properties/smedetoften/smedetoften-tenancy-11.jpg";
import imageTenancy12 from "assets/properties/smedetoften/smedetoften-tenancy-12.jpg";
import imageTenancy13 from "assets/properties/smedetoften/smedetoften-tenancy-13.jpg";
import imageTenancy14 from "assets/properties/smedetoften/smedetoften-tenancy-14.jpg";
import imageTenancy15 from "assets/properties/smedetoften/smedetoften-tenancy-15.jpg";
import imageProperty01 from "assets/properties/smedetoften/smedetoften-property-01.jpg";
import imageProperty02 from "assets/properties/smedetoften/smedetoften-property-02.jpg";
import imageProperty03 from "assets/properties/smedetoften/smedetoften-property-03.jpg";
import imageProperty04 from "assets/properties/smedetoften/smedetoften-property-04.jpg";
import imageProperty05 from "assets/properties/smedetoften/smedetoften-property-05.jpg";
import imageProperty06 from "assets/properties/smedetoften/smedetoften-property-06.jpg";
import imageProperty07 from "assets/properties/smedetoften/smedetoften-property-07.jpg";

import { ReactComponent as FacilityIcon1 } from "assets/properties/gefion/facilities/gefion-facility-1.svg";
import { ReactComponent as FacilityIcon2 } from "assets/properties/gefion/facilities/gefion-facility-2.svg";
import { ReactComponent as FacilityIcon3 } from "assets/properties/gefion/facilities/gefion-facility-3.svg";
import { ReactComponent as FacilityIcon4 } from "assets/properties/gefion/facilities/gefion-facility-4.svg";
import { ReactComponent as FacilityIcon5 } from "assets/properties/gefion/facilities/gefion-facility-5.svg";
import { ReactComponent as FacilityIcon6 } from "assets/properties/gefion/facilities/gefion-facility-6.svg";
import { ReactComponent as FacilityIcon8 } from "assets/properties/gefion/facilities/gefion-facility-8.svg";
import { ReactComponent as FacilityIcon9 } from "assets/icons/curtains.svg";
import { ReactComponent as FacilityIcon10 } from "assets/properties/gefion/facilities/gefion-facility-10.svg";
import { ReactComponent as FacilityIcon11 } from "assets/properties/gefion/facilities/gefion-facility-11.svg";
import { ReactComponent as FacilityIcon12 } from "assets/icons/parking.svg";
import { ReactComponent as FacilityIcon13 } from "assets/properties/gefion/facilities/gefion-facility-13.svg";
import { ReactComponent as FacilityIcon14 } from "assets/properties/gefion/facilities/gefion-facility-14.svg";
import { ReactComponent as FacilityIcon15 } from "assets/properties/gefion/facilities/gefion-facility-15.svg";
import { ReactComponent as FacilityIcon16 } from "assets/properties/gefion/facilities/gefion-facility-16.svg";

const Wrapper = styled.div`
	//max-width: 1120px;
	margin: 0 auto;
	padding: 0px 0px;
	background-color: #f3f3f3;
`;

const Container = styled.div`
	max-width: 1120px;
	margin: 0 auto;
	padding: 0px 0px;
	background-color: white;
`;

const ModalImage = styled.img`
	max-width: 100%;
	padding-top: 16px;
`;
const ModalText = styled.p`
	padding-top: 16px;
	font-size: 18px;
`;
const TopInfo = styled.div`
	color: white;
	background-color: #384336;
	text-align: center;
	font-size: 20px;
	padding: 16px;
	cursor: pointer;
`;

const Smedetoften = () => {
	const { t } = useTranslation();

	const propertyId = "f7b60794-be2c-49a2-be42-810b05913012";

	const { isTenanciesLoading, tenanciesData, loadTenanciesByPropertyId } =
		useTenancies();

	const contactSectionRef = useRef(null);
	const scrollToContactSection = () => {
		contactSectionRef.current.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		loadTenanciesByPropertyId(propertyId);
	}, [loadTenanciesByPropertyId]);

	const facilities = [
		{
			icon: <FacilityIcon1 />,
			text: t("properties.smedetoften.facilities.text1"),
		},
		{
			icon: <FacilityIcon2 />,
			text: t("properties.smedetoften.facilities.text2"),
		},
		{
			icon: <FacilityIcon3 />,
			text: t("properties.smedetoften.facilities.text3"),
		},
		{
			icon: <FacilityIcon4 />,
			text: t("properties.smedetoften.facilities.text4"),
		},
		{
			icon: <FacilityIcon5 />,
			text: t("properties.smedetoften.facilities.text5"),
		},
		{
			icon: <FacilityIcon6 />,
			text: t("properties.smedetoften.facilities.text6"),
		},
		{
			icon: <FacilityIcon3 />,
			text: t("properties.smedetoften.facilities.text7"),
		},
		{
			icon: <FacilityIcon8 />,
			text: t("properties.smedetoften.facilities.text8"),
		},
		{
			icon: <FacilityIcon9 />,
			text: t("properties.smedetoften.facilities.text9"),
		},
		{
			icon: <FacilityIcon10 />,
			text: t("properties.smedetoften.facilities.text10"),
		},
		{
			icon: <FacilityIcon11 />,
			text: t("properties.smedetoften.facilities.text11"),
		},
		{
			icon: <FacilityIcon12 />,
			text: t("properties.smedetoften.facilities.text12"),
		},
		{
			icon: <FacilityIcon13 />,
			text: t("properties.smedetoften.facilities.text13"),
		},
		{
			icon: <FacilityIcon14 />,
			text: t("properties.smedetoften.facilities.text14"),
		},
		{
			icon: <FacilityIcon15 />,
			text: t("properties.smedetoften.facilities.text15"),
		},
		{
			icon: <FacilityIcon16 />,
			text: t("properties.smedetoften.facilities.text16"),
		},
	];
	const ModalContent1 = () => (
		<>
			<ModalImage src={imageTenancy04} alt='' />
			<ModalText>
				{t("properties.smedetoften.section1.modaltext1")}
			</ModalText>
			<ModalImage src={imageTenancy05} alt='' />
			<ModalText>
				{t("properties.smedetoften.section1.modaltext2")}
			</ModalText>
			<ModalImage src={imageTenancy03} alt='' />
			<ModalText>
				{t("properties.smedetoften.section1.modaltext3")}
			</ModalText>
			<ModalImage src={imageTenancy02} alt='' />
			<ModalText>
				{t("properties.smedetoften.section1.modaltext4")}
			</ModalText>
			<ModalText>
				{t("properties.smedetoften.section1.modaltext5")}
			</ModalText>
		</>
	);
	const ModalContent2 = () => (
		<>
			<ModalImage src={imageProperty07} alt='' />
			<ModalText>
				{t("properties.smedetoften.section2.modaltext1")}
			</ModalText>
			<ModalText>
				{t("properties.smedetoften.section2.modaltext2")}
			</ModalText>
			<ModalImage src={imageProperty04} alt='' />
			<ModalText>
				{t("properties.smedetoften.section2.modaltext3")}
			</ModalText>
			<ModalImage src={imageProperty05} alt='' />
			<ModalText>
				{t("properties.smedetoften.section2.modaltext4")}
			</ModalText>
			<ModalText>
				{t("properties.smedetoften.section2.modaltext5")}
			</ModalText>
			<ModalText>
				{t("properties.smedetoften.section2.modaltext6")}
			</ModalText>
		</>
	);
	const ModalContent3 = () => (
		<>
			<ModalImage src={imageProperty02} alt='' />
			<ModalText>
				{t("properties.smedetoften.section3.modaltext1")}
			</ModalText>
			<ModalText>
				{t("properties.smedetoften.section3.modaltext2")}
			</ModalText>
			<ModalText>
				{t("properties.smedetoften.section3.modaltext3")}
			</ModalText>
		</>
	);
	const slides = [
		{ src: imageTenancy11 },
		{ src: imageTenancy12 },
		{ src: imageTenancy13 },
		{ src: imageTenancy14 },
		{ src: imageTenancy15 },
		{ src: imageTenancy06 },
		{ src: imageTenancy07 },
		{ src: imageTenancy08 },
		{ src: imageTenancy10 },
	];

	return (
		<Wrapper>
			<TopInfo onClick={scrollToContactSection}>
				{t("properties.smedetoften.infoText")}
			</TopInfo>
			<HeroSection2
				background={hero}
				title={t("properties.smedetoften.title")}
				text={t("properties.smedetoften.subtitle")}
				buttons={<InterestListButton propertyId={propertyId} />}
			/>
			<Container>
				{tenanciesData && (
					<Isometry
						tenanciesJSON={TenancyBoxes}
						scene={Scene}
						tenancies={tenanciesData}
						target={[40, 10, -37.0]}
						initialRotation={0}
					/>
				)}
				<TextWithImageLeft2
					background={imageTenancy01}
					title={t("properties.smedetoften.section1.title")}
					text={t("properties.smedetoften.section1.text")}
					button={
						<ModalButton
							label={t("properties.smedetoften.section1.button")}
							content={<ModalContent1 />}
						/>
					}
				/>
				<TextWithImageRight2
					background={imageProperty06}
					title={t("properties.smedetoften.section2.title")}
					text={t("properties.smedetoften.section2.text")}
					button={
						<ModalButton
							label={t("properties.smedetoften.section2.button")}
							content={<ModalContent2 />}
						/>
					}
				/>
				<TextWithImageLeft2
					background={imageProperty03}
					title={t("properties.smedetoften.section3.title")}
					text={t("properties.smedetoften.section3.text")}
					button={
						<ModalButton
							label={t("properties.smedetoften.section3.button")}
							content={<ModalContent3 />}
						/>
					}
				/>
				<FacilitiesGrid facilities={facilities} />
				<TextWithImageLeft2
					background={imageProperty03}
					title={t("properties.smedetoften.section5.title")}
					text={t("properties.smedetoften.section5.text")}
					button={
						<GalleryButton
							label={t("properties.smedetoften.section5.button")}
							slides={slides}
						/>
					}
				/>
				<TextWithImageLeft3
					background={hero}
					title={t("properties.smedetoften.section4.title")}
					text={t("properties.smedetoften.section4.text")}
					button={<InterestListButton propertyId={propertyId} />}
				/>
				{tenanciesData?.length > 0 && (
					<AvailableTenanciesList
						loading={isTenanciesLoading}
						tenancies={tenanciesData}
					/>
				)}
				{/* {!isTenanciesLoading && <Map lng={tenanciesData[0].address.coordinates.longitude} lat={tenanciesData[0].address.coordinates.latitude} locations={tenanciesData[0].locations} />} */}
				<div ref={contactSectionRef}>
					<ContactSection />
				</div>
			</Container>
		</Wrapper>
	);
};

export default Smedetoften;
