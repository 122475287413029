import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import HeroSection from 'components/HeroSection';
import TextWithImageLeft from 'components/TextWithImageLeft2';
import TextWithImageRight from 'components/TextWithImageRight2';
import FacilitiesGrid from 'components/FacilitiesGrid';
import ContactSection from 'components/ContactSection';
import { useTranslation } from 'react-i18next';
import AvailableTenanciesList from 'components/AvailableTenanciesList';
import background0 from 'assets/properties/gefion/gefion-hero.jpeg';
import background1 from 'assets/properties/gefion/gefion1.jpeg';
import background2 from 'assets/properties/gefion/gefion2.jpeg';
import useTenancies from 'features/tenancies';
import { ReactComponent as FacilityIcon1 } from 'assets/properties/gefion/facilities/gefion-facility-1.svg';
import { ReactComponent as FacilityIcon2 } from 'assets/properties/gefion/facilities/gefion-facility-2.svg';
import { ReactComponent as FacilityIcon3 } from 'assets/properties/gefion/facilities/gefion-facility-3.svg';
import { ReactComponent as FacilityIcon4 } from 'assets/properties/gefion/facilities/gefion-facility-4.svg';
import { ReactComponent as FacilityIcon5 } from 'assets/properties/gefion/facilities/gefion-facility-5.svg';
import { ReactComponent as FacilityIcon6 } from 'assets/properties/gefion/facilities/gefion-facility-6.svg';
import { ReactComponent as FacilityIcon7 } from 'assets/properties/gefion/facilities/gefion-facility-7.svg';
import { ReactComponent as FacilityIcon8 } from 'assets/properties/gefion/facilities/gefion-facility-8.svg';
import { ReactComponent as FacilityIcon9 } from 'assets/properties/gefion/facilities/gefion-facility-9.svg';
import { ReactComponent as FacilityIcon10 } from 'assets/properties/gefion/facilities/gefion-facility-10.svg';
import { ReactComponent as FacilityIcon11 } from 'assets/properties/gefion/facilities/gefion-facility-11.svg';
import { ReactComponent as FacilityIcon12 } from 'assets/properties/gefion/facilities/gefion-facility-12.svg';
import { ReactComponent as FacilityIcon13 } from 'assets/properties/gefion/facilities/gefion-facility-13.svg';
import { ReactComponent as FacilityIcon14 } from 'assets/properties/gefion/facilities/gefion-facility-14.svg';
import { ReactComponent as FacilityIcon15 } from 'assets/properties/gefion/facilities/gefion-facility-15.svg';
import { ReactComponent as FacilityIcon16 } from 'assets/properties/gefion/facilities/gefion-facility-16.svg';
import Isometry from './components/Isometry';
import Button from 'components/Button';

const Wrapper = styled.div`
	//max-width: 1120px;
	margin: 0 auto;
	padding: 0px 0px;	
	background-color: #f3f3f3;
`;

const Container = styled.div`
	max-width: 1120px;
	margin: 0 auto;
	padding: 0px 0px;	
	background-color: white;
`;



const Telegrafkollegiet = () => {

	const { t } = useTranslation();

	const facilities = [
		{
			icon: <FacilityIcon1 />,
			text: t('properties.gefion.facilities.text1'),
		},
		{
			icon: <FacilityIcon2 />,
			text: t('properties.gefion.facilities.text2'),
		},
		{
			icon: <FacilityIcon3 />,
			text: t('properties.gefion.facilities.text3'),
		},
		{
			icon: <FacilityIcon4 />,
			text: t('properties.gefion.facilities.text4'),
		},
		{
			icon: <FacilityIcon5 />,
			text: t('properties.gefion.facilities.text5'),
		},
		{
			icon: <FacilityIcon6 />,
			text: t('properties.gefion.facilities.text6'),
		},
		{
			icon: <FacilityIcon7 />,
			text: t('properties.gefion.facilities.text7'),
		},
		{
			icon: <FacilityIcon8 />,
			text: t('properties.gefion.facilities.text8'),
		},
		{
			icon: <FacilityIcon9 />,
			text: t('properties.gefion.facilities.text9'),
		},
		{
			icon: <FacilityIcon10 />,
			text: t('properties.gefion.facilities.text10'),
		},
		{
			icon: <FacilityIcon11 />,
			text: t('properties.gefion.facilities.text11'),
		},
		{
			icon: <FacilityIcon12 />,
			text: t('properties.gefion.facilities.text12'),
		},
		{
			icon: <FacilityIcon13 />,
			text: t('properties.gefion.facilities.text13'),
		},
		{
			icon: <FacilityIcon14 />,
			text: t('properties.gefion.facilities.text14'),
		},
		{
			icon: <FacilityIcon15 />,
			text: t('properties.gefion.facilities.text15'),
		},
		{
			icon: <FacilityIcon16 />,
			text: t('properties.gefion.facilities.text16'),
		}
	];

	const {
		isTenanciesLoading,
		tenanciesData,
		loadTenanciesByPropertyId
	} = useTenancies();

	useEffect(() => {
		loadTenanciesByPropertyId('213ffaa6-d6e4-4895-9cd6-a063b7fb9736');
	}, [loadTenanciesByPropertyId]);

	const contactSectionRef = useRef(null);
	const scrollToContactSection = () => {
		contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	const buttons = (
		<>
			<Button onClick={scrollToContactSection}>Kontakt os</Button>
		</>
	);


	return (
		<Wrapper>
			<HeroSection background={background0} title={t('properties.gefion.title')} text={t('properties.gefion.subtitle')} buttons={buttons} />
			<Container>
				<TextWithImageLeft background={background1} title={t('properties.gefion.section1.title')} text={t('properties.gefion.section1.text')} />
				<TextWithImageRight background={background2} title={t('properties.gefion.section2.title')} text={t('properties.gefion.section2.text')} />
				<FacilitiesGrid facilities={facilities} />
				{ tenanciesData && <Isometry loading={isTenanciesLoading} tenancies={tenanciesData} />}
				{ tenanciesData && <AvailableTenanciesList loading={isTenanciesLoading} tenancies={tenanciesData} />}
				{/* {!isTenanciesLoading && <Map lng={tenanciesData[0].address.coordinates.longitude} lat={tenanciesData[0].address.coordinates.latitude} locations={tenanciesData[0].locations} />} */}
				<div ref={contactSectionRef}>
					<ContactSection />
				</div>
			</Container>
		</Wrapper>
	);
};

export default Telegrafkollegiet;