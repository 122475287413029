import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const FilterButtonWrapper = styled.button`
	cursor: pointer;
`;

const FilterButton = (props) => {

	const { onClick } = props;
	const { t } = useTranslation();

	return (
		<FilterButtonWrapper onClick={onClick}>
			{t('tenancies.filterButtonLabel')}
		</FilterButtonWrapper>
	);
};

FilterButton.propTypes = {
	onClick: PropTypes.func,
};

export default FilterButton;