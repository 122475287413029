import axios from 'axios';

export default class MapBoxSearchService {

	static async getPlaces(query, key) {
		try {
			const result = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${key}&autocomplete=true&country=dk&types=region%2Cdistrict%2Cpostcode%2Cneighborhood%2Clocality%2Cplace%2Caddress&language=da`);
			return result;
		}
		catch (err) {
			return null;
		}
	}
	
}