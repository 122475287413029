import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Modal from 'react-modal';
import styled from 'styled-components';
import {ReactComponent as CloseIcon} from 'assets/icons/close.svg';

const customStyles = {
	overlay: {
		zIndex: 1000,
		backgroundColor: 'rgb(56 67 54 / 90%)',	
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '24px',
		outline: 'none',
	},
	content: {
		position: 'relative',
		border: 'none',
		borderRadius: '25px',
		backgroundColor: '#fff',
		maxWidth: '800px',
		minHeight: '300px',
		minWidth: '380px',
		outline: 'none',
		display: 'flex',
		justifyContent: 'center',
		width: '80vw',
		padding: '24px',
		boxSizing: 'border-box',
		maxHeight: '90vh',
	},
};

const ModalButtonWrapper = styled.div`
`;

const ModalContentContainer = styled.div`
	max-width: 600px;
	min-width: 340px;
	overflow: auto;
`;

const CloseButton = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	svg {
		height: 60px;
		width: 60px;
	}
`;


const ModalButton  = props => {

	const [modalIsOpen, setModalIsOpen] = useState(false);

	const closeModal = () => setModalIsOpen(false);

	const {
		label,
		content,
	} = props;

	return (
		<ModalButtonWrapper>
			<Button onClick={() => setModalIsOpen(true)}>
				{ label }
			</Button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				className={'interestlist-modal'}
				ariaHideApp={false}
				scrollable={true}
				htmlOpenClassName={'modal-open'}
			>	
				<CloseButton onClick={closeModal}>
					<CloseIcon />
				</CloseButton>
				<ModalContentContainer>
					{ content }
				</ModalContentContainer>
			</Modal>
		</ModalButtonWrapper>
	);
};

ModalButton.propTypes = {
	label: PropTypes.string.isRequired,
	content: PropTypes.node.isRequired,
};


export default ModalButton ;