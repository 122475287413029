import React, { useState } from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import ArrowButton from 'components/ArrowButton';
import Line from 'components/Line';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { collection, addDoc } from 'firebase/firestore'; 
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	width: 100%;
	margin: auto;
	padding: 32px 16px;
	max-width: 960px;
    background-color: ${(props) => props.backgroundColor};
	box-sizing: border-box;
	
	@media ${device.laptop} { 
		padding-top: 80px;
		padding-bottom: 80px;
		padding-left: 80px;
		padding-right: 80px;
		box-sizing: content-box;
	}

	div.section-input {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-column-gap: 64px;
		grid-row-gap: 28px;
		margin-bottom: 40px;
		@media ${device.laptop} { 
			grid-template-columns: 1fr 1fr;
		}

		div.input-wrapper {
			padding: 12px 0px;
			display: block;
		}

		div.input-wrapper.message {
			height: 100%;
			textarea {
				border: 1px solid ${p => p.theme.colors.ascent.primary};
				box-sizing: border-box;
				height: calc(100% - 48px);
				padding: 16px;
			}
			label {
				padding-bottom: 16px;
			}
		}

		input, textarea {
			padding: 4px 0px 8px;
			width: 100%;
			border: unset;
			border-bottom: 1px solid ${p => p.theme.colors.ascent.primary};
			font-size: 16px;
			color: ${p => p.theme.colors.ascent.primary};
			background-color: ${(props) => props.backgroundColor};
		}

		input[type=textarea] {
			height: 200px;
		}
	
		label {
			font-size: 14px;
			display: block;
			color: ${p => p.theme.colors.text.secondary};
		}
	}

	.messageSent {
		text-align: center;
		padding-top: 130px;
		padding-bottom: 130px;
		padding-left: 80px;
		padding-right: 80px;
	}
`;

const ContactSection = (props) => {
	
	const { t } = useTranslation();
	const [name, setName] = useState('');
	const [mail, setMail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [sendingMessage, setSendingMessage] = useState(false);
	const [messageSent, setMessageSent] = useState(false);

	const {
		backgroundColor,
		ref		
	} = props;

	const onNameChange = (e) => {
		setName(e.target.value);
	};

	const onMailChange = (e) => {
		setMail(e.target.value);
	};

	const onPhoneChange = (e) => {
		setPhone(e.target.value);
	};

	const onMessageChange = (e) => {
		setMessage(e.target.value);
	};

	initializeApp({
		apiKey: 'AIzaSyCoIfG8XEBXDaGy9ntdckgrf-IFRpKaRaY',
		authDomain: 'norsepm-dk-fac16.firebaseapp.com',
		projectId: 'norsepm-dk-fac16'
	});

	const db = getFirestore();



	async function writeFormSubmit() {
		setSendingMessage(true);
		if (name && mail && phone && message) {
			try {
				await addDoc(collection(db, 'contact-formula'), {
					to: 'udlejning@norsepm.dk',
					message: {
						subject: 'Henvendelse fra Norsepm.dk',
						html: `Navn: ${name}</br>Email: ${mail}</br>Telefon: ${phone}</br>Url: ${window.location.href}</br>Besked: ${message}`,
					}
				});
				setName('');
				setMail('');
				setPhone('');
				setMessage('');
				setSendingMessage(false);
				setMessageSent(true);
			} catch (e) {
				console.error('Error adding document: ', e);
				alert('Et teknisk problem opstod. Beklager ulejligheden.');
			}
		} else {
			alert('Alle felter skal udfyldes');
		}
	}

	const inputFormula = (
		<>
			<div className="section-title">
				<Line />
				<Title>{t('contact.title')}</Title>
			</div>
			<div className="section-input">
				<div className='left'>
					<div className="input-wrapper">
						<label htmlFor="name">{t('contact.name')}</label>
						<input value={name} onChange={onNameChange} id="name" type="text"/>
					</div>
					<div className="input-wrapper">
						<label htmlFor="email">{t('contact.email')}</label>
						<input value={mail} onChange={onMailChange} id="email" type="text"/>
					</div>
					<div className="input-wrapper">
						<label htmlFor="phone">{t('contact.phone')}</label>
						<input value={phone} onChange={onPhoneChange} id="phone" type="text"/>
					</div>
				</div>
				<div className='right'>
					<div className="input-wrapper message">
						<label htmlFor="message">{t('contact.message')}</label>
						<textarea value={message} onChange={onMessageChange} rows={4}></textarea>
					</div>
				</div>
			</div>
			<ArrowButton onClick={() => writeFormSubmit()}>{t('contact.buttonLabel')}</ArrowButton>
		</>
	);

	const sendingMessageContent = (
		<div className='sendingMessage'>
			<Loader/>
		</div>
	);

	const MessageSentContent = (
		<div className='messageSent'>
			<p>Tak for din henvendelse. Vi vender tilbage hurtigst muligt.</p>
		</div>
	);

	return (
		<Wrapper backgroundColor={backgroundColor}>
			{ !messageSent && !sendingMessage ? inputFormula : 
				sendingMessage ? sendingMessageContent : MessageSentContent
			}
		</Wrapper>
	);
};

ContactSection.propTypes = {
	backgroundColor: PropTypes.string
};

ContactSection.defaultProps = {
	backgroundColor: '#fff'
};

export default ContactSection;