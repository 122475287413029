import styled from 'styled-components';

const Line = styled.div`
	min-height: 4px;
	height: 4px;
	width: 170px;
	background: ${p => p.theme.colors.ascent.secondary};
	margin-bottom: 24px;
`;

export default Line;