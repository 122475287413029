import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Line from 'components/Line';
import Title from 'components/Title';
import ButtonSmall from 'components/ButtonSmall';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';
import GalleryButton3 from 'components/GalleryButton3';

const Wrapper = styled.div`
	width: 100%;
	max-width: 1200px;
	min-width: 390px;
	margin-top: 30px;
	padding: 50px 16px;	
	box-sizing: border-box;
	margin: auto;
	@media ${device.laptop} { 
		padding-left: 80px;
		padding-right: 80px;
	}
`;

const Table = styled.table`
	margin-top: 20px;
	display: block;
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	width: 100%;
	border-spacing: 0px;
	font-size: 24px;
	th {
		font-weight: bold;
		text-align: left;	
		color: #384336;
		border-bottom: 1px solid #384336;
		padding-bottom: 16px;
		padding-right: 16px;
	}
	td {
		font-size: 18px;
		color: #384336a8;
		padding: 10px 32px 10px 0px;
		white-space: nowrap;
		text-align: center;
	}
	tr.extended {
		
	}
	tr.hidden {
		display: none;
	}
	.left-aligned {
		text-align: left;
		padding-left: 0px;
	}
`;

const ShowAllButton = styled.div`
`;

const AvailableTenanciesList = (props) => {

	const { t } = useTranslation();
	const [showExtended, setShowExtended] = useState(false);

	const {
		loading,
		tenancies,
		buildingName,
		roomFilter,
		floorplans
	} = props;

	const getTitle = useMemo(() => {
		if (buildingName === undefined) {
			return t('properties.general.availableTenanciesTitle');
		} else if (buildingName !== undefined && roomFilter) {
			return t('properties.general.available') + " " + roomFilter + " " + t('properties.general.tenanciesIn') + " " + buildingName;
		} else {
			return t('properties.general.availableTenanciesTitleWithBuildingName') + " " + buildingName;
		}
	}, [tenancies]);

	useEffect(() => {
		if (!loading) {
			setShowExtended(false);
		}
	}, [tenancies]);

	const getTranslatedStatus = (status) => {
		switch (status) {
		case 'Available':
			return t('properties.general.availableTenanciesTitleTable.available');
		case 'Reserved':
			return t('properties.general.availableTenanciesTitleTable.reserved');
		default:
			return t('properties.general.availableTenanciesTitleTable.available');
		}
	};

	const toggleExtended = () => {
		setShowExtended(!showExtended);
	};

	return (
		<Wrapper>
			<Line />
			<Title>{getTitle}</Title>
			{floorplans.length > 0 && <GalleryButton3 label={t('properties.general.seeFloorplan')} slides={floorplans} />}
			<Table>
				<thead>
					<tr>
						<th>{t('properties.general.availableTenanciesTitleTable.address')}</th>
						<th>{t('properties.general.availableTenanciesTitleTable.rooms')}</th>
						<th>{t('properties.general.availableTenanciesTitleTable.size')}</th>
						<th>{t('properties.general.availableTenanciesTitleTable.rent')}</th>
						<th>{t('properties.general.availableTenanciesTitleTable.status')}</th>
						<th>{t('properties.general.availableTenanciesTitleTable.moreInfo')}</th>
					</tr>
				</thead>
				<tbody>
					{!loading && tenancies.map((tenancy, index) => {
						return (
							<tr key={tenancy.id} className={index < 6 ? 'intial' : showExtended ? 'extended' : 'hidden'}>
								<td className="left-aligned">{tenancy.address.street}</td>
								<td>{tenancy.rooms}</td>
								<td>{tenancy.size.value} m2</td>
								<td>{tenancy.monthlyRent.value.toLocaleString('da-DK')} {t('properties.general.availableTenanciesTitleTable.unit')}</td>
								<td>{getTranslatedStatus(tenancy.state)}</td>
								<td><a href={`/bolig/${tenancy.id}`}><ButtonSmall>{t('properties.general.availableTenanciesTitleTable.moreInfo')}</ButtonSmall></a></td>
							</tr>
						);
					})}
				</tbody>
			</Table>
			{!showExtended && tenancies.length > 6 && <Button onClick={toggleExtended}>{t('properties.general.showAllAvailableTenancies')}</Button>}
		</Wrapper>
	);
};

AvailableTenanciesList.propTypes = {
	loading: PropTypes.bool,
	tenancies: PropTypes.array,
	buildingName: PropTypes.string,
	roomFilter: PropTypes.string, 
	floorplans: PropTypes.array
};

AvailableTenanciesList.defaultProps = {
	loading: true,
	tenancies: [],
	title: undefined,
	roomFilter: undefined,
	floorplans: []
};

export default AvailableTenanciesList;