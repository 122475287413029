import { Environment, RandomizedLight, AccumulativeShadows, OrbitControls } from '@react-three/drei';
import { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import React from 'react';

export default function Scene3() {
	const ref = useRef();
	const model = useGLTF('/b3.glb'); 
	const modelRef = useRef(null);
	return (
		<>
			<Environment preset="warehouse" />
			{/* <OrbitControls makeDefault /> */}
			<primitive object={model.scene} ref={modelRef} />
		</>
	);
}