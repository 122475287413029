import React from 'react';
import HeroSection from './components/HeroSection';
import IntroSection from './components/IntroSection';
import CitiesSection from './components/CitiesSection';
import FeaturedTenanciesSection from './components/FeaturedTenanciesSection';
import ContactSection from 'components/ContactSection';

const Home = () => {

	return (
		<div>
			<HeroSection/>
			<FeaturedTenanciesSection/>
			<IntroSection/>
			<CitiesSection/>
			<ContactSection/>
		</div>
	);
};

export default Home;