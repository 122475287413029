import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import norseLogo from 'assets/images/norse-logo-green-with-padding.png';
import {ReactComponent as PhoneIcon} from 'assets/icons/phone.svg';
import {ReactComponent as EmailIcon} from 'assets/icons/email.svg';
import { device } from 'helpers/device';

const Wrapper = styled.a`
	display: grid;
	grid-template-columns: 1fr 1fr;
	background-color: ${p => p.theme.colors.white.primary};
	box-shadow: 0px 22px 60px rgba(0, 0, 0, 0.2);
	cursor: pointer;

	@media ${device.laptop} { 	
		.name {
			font-size: 24px;
		}
		.title {
			padding-top: 4px;
			font-size: 16px;
			padding-bottom: 24px;
		}
		.phone {
			padding-bottom: 12px;
		}
		.phone, .email {
			font-size: 16px;
		}
	}

	.info-wrapper {
		padding: 24px;

		div {
			display: flex;
			align-items: center;
		}
	}
	.name {
		font-size: 20px;
	}
	.title {
		padding-top: 4px;
		font-size: 14px;
		color: ${p => p.theme.colors.ascent.secondary};
		font-weight: 700;
		padding-bottom: 4px;
	}
	.phone {
		padding-bottom: 0px;
	}

	.phone, .email {
		font-size: 12px;
		font-weight: 700;
	}
	svg {
		stroke: black;
		align-self: center;
		margin-right: 12px;
		max-width: 24px;
	}
`;
const Image = styled.img`
	object-fit: cover;
	width: 150px;
	height: 100%;
	@media ${device.laptop} { 
		width: 100%;
	}
`;

const TeamCard = (props) => {
	const { image, name, title, phone, email } = props;

	return (
		<Wrapper className="tenancyCard" key={name}>
			<Image src={image} />
			<div className="info-wrapper">
				<div className="name">{ name }</div>
				<div className="title">{ title }</div>
				<div className="phone"><PhoneIcon/>{ phone }</div>
				<div className="email"><EmailIcon/>{ email }</div>
			</div>
		</Wrapper>
	);
};

TeamCard.propTypes = {
	image: PropTypes.string, 
	name: PropTypes.string,
	title: PropTypes.string,
	phone: PropTypes.string,
	email: PropTypes.string,
};

TeamCard.defaultProps = {
	image: norseLogo,
	name: '-',
	title: '-',
	phone: '-',
	email: '-',
};

export default TeamCard;