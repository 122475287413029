import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Modal from "react-modal";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import Loader from "components/Loader";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const customStyles = {
	overlay: {
		zIndex: 1000,
		backgroundColor: "rgb(56 67 54 / 90%)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "24px",
		outline: "none",
	},
	content: {
		position: "relative",
		border: "none",
		borderRadius: "25px",
		backgroundColor: "#fff",
		maxWidth: "800px",
		minHeight: "300px",
		minWidth: "380px",
		outline: "none",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "80vw",
		padding: "24px",
		boxSizing: "border-box",
	},
};

const InterestListWrapper = styled.div``;

const ModalContentContainer = styled.div`
	max-width: 600px;
	min-width: 340px;
`;

const Title = styled.h1`
	font-size: 40px;
	color: #3e5042;
	font-weight: 700;
	text-align: center;
`;

const Description = styled.p`
	font-size: 24px;
	color: #3e5042;
	font-weight: 400;
	text-align: center;
	padding-bottom: 40px;
`;

const ConfirmedDescription = styled.p`
	font-size: 20px;
	color: #3e5042;
	font-weight: 400;
	text-align: center;
`;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 24px;

	label {
		color: rgb(56 67 54 / 60%);
	}
	input {
		color: #3e5042;
		border: none;
		font-size: 16px;
		padding-top: 4px;
		padding-bottom: 4px;
		border-bottom: 1px solid grey;
		outline: none;
		text-transform: capitalize;
	}
	input#email {
		text-transform: unset;
	}
	input#email::first-word {
		text-transform: capitalize;
	}
`;

const CloseButton = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	svg {
		height: 60px;
		width: 60px;
	}
`;

const InterestListButton = (props) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [sendingMessage, setSendingMessage] = useState(false);
	const [messageSent, setMessageSent] = useState(false);

	const { t } = useTranslation();

	const closeModal = () => setModalIsOpen(false);

	const onNameChange = (e) => {
		setName(e.target.value);
	};

	const onEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const onPhoneNumberChange = (e) => {
		setPhoneNumber(e.target.value);
	};

	initializeApp({
		apiKey: "AIzaSyCoIfG8XEBXDaGy9ntdckgrf-IFRpKaRaY",
		authDomain: "norsepm-dk-fac16.firebaseapp.com",
		projectId: "norsepm-dk-fac16",
	});

	const db = getFirestore();

	async function writeFormSubmit(event) {
		event.preventDefault();

		setSendingMessage(true);

		const data = {
			name,
			email,
			phoneNumber,
		};

		fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}/postToInterestList?propertyId=${props.propertyId}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((data) => {
				// If request succeeds, clear input fields and show success message
				setName("");
				setEmail("");
				setPhoneNumber("");
				setSendingMessage(false);
				setMessageSent(true);
			})
			.catch((error) => {
				console.error(error);
				setSendingMessage(false);
				setMessageSent(false);
			});
	}

	return (
		<InterestListWrapper>
			<Button onClick={() => setModalIsOpen(true)}>{t("general.interestList")}</Button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				className={"interestlist-modal"}
				ariaHideApp={false}
			>
				<CloseButton onClick={closeModal}>
					<CloseIcon />
				</CloseButton>
				<ModalContentContainer>
					{!sendingMessage && !messageSent && (
						<>
							<Title>Skriv dig på interesselisten</Title>
							<Description>Få besked om nye boliger og undgå at gå glip af drømmeboligen.</Description>
							<InputWrapper>
								<label htmlFor='name'>{t("contact.name")}*</label>
								<input value={name} onChange={onNameChange} id='name' type='text' />
							</InputWrapper>
							<InputWrapper>
								<label htmlFor='email'>{t("contact.email")}*</label>
								<input value={email} onChange={onEmailChange} id='email' type='text' />
							</InputWrapper>
							<InputWrapper>
								<label htmlFor='phone'>{t("contact.phone")}*</label>
								<input value={phoneNumber} onChange={onPhoneNumberChange} id='phone' type='text' />
							</InputWrapper>
							<Button
								onClick={(e) => writeFormSubmit(e)}
								style={{
									marginTop: "40px",
									marginLeft: "auto",
									marginRight: "auto",
									marginBottom: "20px",
									display: "block",
								}}
							>
								Tilmeld
							</Button>
						</>
					)}
					{sendingMessage && !messageSent && (
						<>
							<Loader />
						</>
					)}
					{!sendingMessage && messageSent && (
						<>
							<Title>Bekræft din e-mail</Title>
							<ConfirmedDescription>
								Du har modtaget en e-mail med en bekræftigelse. Tryk på knappen heri for at bekræfte din
								tilmelding.
							</ConfirmedDescription>
							<Button
								onClick={() => setModalIsOpen(false)}
								style={{ marginTop: "80px", marginLeft: "auto", marginRight: "auto", display: "block" }}
							>
								Afslut
							</Button>
						</>
					)}
				</ModalContentContainer>
			</Modal>
		</InterestListWrapper>
	);
};

export default InterestListButton;
