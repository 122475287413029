const theme = {
	colors: {
		ascent: {
			primary: '#384336',
			secondary: '#C49A36'
		},
		text: {
			primary: '#384336',
			secondary: '#384336bf;',
			tertiary: '#38443633'
		},
		white: {
			primary: '#fff'
		},
		off: {
			primary: '#D7D9D7'
		}
	}
	
};

export default theme; 