import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useTenancies from "features/tenancies";
import HeroSection4 from "components/HeroSection4";
import FactSection1 from "components/FactSection1";
import CarouselSection1 from "components/CarouselSection1";
import FactSection2 from "components/FactSection2";
import FacilitiesSection1 from "components/FacilitiesSection1";
import ImageSection1 from "components/ImageSection1";
import ImageGridSection2 from "components/ImageGridSection2";
import AvailableTenanciesList from "components/AvailableTenanciesList";
import ContactSection from "components/ContactSection";
import Isometry from "components/Isometry";
import Button from "components/Button";
import InterestListButton from "components/InterestListButton";
import tenanciesJSON from "./components/tenanciesSON";
import imageMapJSON from "./components/imageMapJSON";
import Scene from "./components/Scene";
import background01 from "assets/properties/labyrinthen/background-maze.png";
import imageProperty01 from "assets/properties/soesvinget/soesvinget-property-01.jpg";
import imageProperty02 from "assets/properties/soesvinget/soesvinget-property-02.jpg";
import imageProperty03 from "assets/properties/soesvinget/soesvinget-property-03.jpg";
import imageProperty04 from "assets/properties/soesvinget/soesvinget-property-04.jpg";
import imageProperty05 from "assets/properties/soesvinget/soesvinget-property-05.jpg";
import imageProperty06 from "assets/properties/soesvinget/soesvinget-property-06.jpg";
import imageProperty07 from "assets/properties/soesvinget/soesvinget-property-07.jpg";
import imageProperty08 from "assets/properties/soesvinget/soesvinget-property-08.jpg";
import imageProperty09 from "assets/properties/soesvinget/soesvinget-property-09.jpg";
import imageProperty10 from "assets/properties/soesvinget/soesvinget-property-10.jpg";
import imageProperty11 from "assets/properties/soesvinget/soesvinget-property-11.jpg";
import imageProperty12 from "assets/properties/soesvinget/soesvinget-property-12.jpg";
import imageProperty13 from "assets/properties/soesvinget/soesvinget-property-13.jpg";
import imageProperty14 from "assets/properties/soesvinget/soesvinget-property-14.jpg";
import imageProperty15 from "assets/properties/soesvinget/soesvinget-property-15.jpg";
import imageProperty16 from "assets/properties/soesvinget/soesvinget-property-16.jpg";
import imageProperty17 from "assets/properties/soesvinget/soesvinget-property-17.jpg";
import imageProperty18 from "assets/properties/soesvinget/soesvinget-property-18.jpg";
import imageProperty19 from "assets/properties/soesvinget/soesvinget-property-19.jpg";
import imageProperty20 from "assets/properties/soesvinget/soesvinget-property-20.jpg";
import backgroundMovie from "assets/properties/soesvinget/soesvinget.mp4";
import { ReactComponent as StarsIcon } from "assets/icons/stars.svg";
import { ReactComponent as ResizeIcon } from "assets/icons/resize-arrows.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as ParkingIcon } from "assets/icons/parking2.svg";
import { ReactComponent as WashingIcon } from "assets/icons/washer.svg";
import { ReactComponent as GreenAreasIcon1 } from "assets/icons/green-areas.svg";
import { ReactComponent as TerraceIcon } from "assets/icons/terrace.svg";
import { ReactComponent as ShedIcon } from "assets/icons/shed.svg";
import ImageMapSection from "components/ImageMapSection/ImageMapSection";
import ImageMapIsometrySection from "components/ImageMapIsometrySection";

const Wrapper = styled.div`
	margin: 0 auto;
	background-color: #fafafa;
`;

const Soesvinget = () => {
	const [activeIsometry, setActiveIsometry] = useState(null);

	const { t } = useTranslation();

	const { isTenanciesLoading, tenanciesData, loadTenanciesByPropertyId } =
		useTenancies();

	const propertyId = "ca0857ab-6395-4a7a-8e30-26b32dd5696f";

	useEffect(() => {
		loadTenanciesByPropertyId(propertyId);
	}, [loadTenanciesByPropertyId]);

	const factSectionBoxes = [
		{
			pre: t("properties.soesvinget.factSection1.boxes.text1.pre"),
			bold: t("properties.soesvinget.factSection1.boxes.text1.bold"),
			after: t("properties.soesvinget.factSection1.boxes.text1.after"),
			icon: <StarsIcon />,
		},
		{
			pre: t("properties.soesvinget.factSection1.boxes.text2.pre"),
			bold: t("properties.soesvinget.factSection1.boxes.text2.bold"),
			after: t("properties.soesvinget.factSection1.boxes.text2.after"),
			icon: <BuildingIcon />,
		},
		{
			pre: t("properties.soesvinget.factSection1.boxes.text3.pre"),
			bold: t("properties.soesvinget.factSection1.boxes.text3.bold"),
			after: t("properties.soesvinget.factSection1.boxes.text3.after"),
			icon: <ResizeIcon />,
		},
	];

	const facilities = [
		{
			icon: <ParkingIcon />,
			text: t("properties.soesvinget.facilitySection.text5"),
		},
		{
			icon: <TerraceIcon />,
			text: t("properties.soesvinget.facilitySection.text1"),
		},
		{
			icon: <WashingIcon />,
			text: t("properties.soesvinget.facilitySection.text4"),
		},
		{
			icon: <GreenAreasIcon1 />,
			text: t("properties.soesvinget.facilitySection.text3"),
		},
		{
			icon: <ShedIcon />,
			text: t("properties.soesvinget.facilitySection.text2"),
		},
	];

	const sliderImages = [
		imageProperty07,
		imageProperty18,
		imageProperty10,
		imageProperty19,
		imageProperty08,
	];

	const imagesGrid = [
		imageProperty10,
		imageProperty02,
		imageProperty03,
		imageProperty04,
		imageProperty05,
		imageProperty06,
	];

	const contactSectionRef = useRef(null);
	const scrollToContactSection = () => {
		contactSectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const isometrySectionRef = useRef(null);
	const scrollToIsometrySection = () => {
		isometrySectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const onImageMapClick = (area) => {
		setActiveIsometry(parseInt(area.id));
	};
	const onReturnClick = () => {
		setActiveIsometry(null);
	};

	return (
		<Wrapper>
			<HeroSection4
				background={backgroundMovie}
				backgroundType={"video"}
				title={t("properties.soesvinget.title")}
				text={t("properties.soesvinget.subtitle")}
				primaryButtons={<InterestListButton propertyId={propertyId} />}
				secondaryButtons={
					<Button onClick={scrollToContactSection}>
						{t("properties.general.contactUs")}
					</Button>
				}
			/>
			<FactSection1
				background={background01}
				title={t("properties.soesvinget.factSection1.title")}
				textBase={t("properties.soesvinget.factSection1.textBase")}
				textAdditional={t(
					"properties.soesvinget.factSection1.textAdditional"
				)}
				buttonText={t("properties.soesvinget.factSection1.buttonText")}
				buttonOnClick={scrollToIsometrySection}
				boxes={factSectionBoxes}
			/>
			<CarouselSection1
				title={t("properties.soesvinget.carouselSection.title")}
				section1title={t(
					"properties.soesvinget.carouselSection.section1title"
				)}
				section1text={t(
					"properties.soesvinget.carouselSection.section1text"
				)}
				images={sliderImages}
			/>
			<FactSection2
				title1={t("properties.soesvinget.factSection2.title1")}
				text1={t("properties.soesvinget.factSection2.text1")}
				title2={t("properties.soesvinget.factSection2.title2")}
				text2={t("properties.soesvinget.factSection2.text2")}
			/>
			<FacilitiesSection1
				title={t("properties.soesvinget.facilitySection.title")}
				facilities={facilities}
			/>
			<ImageSection1 image={imageProperty03} />
			<ImageGridSection2 images={imagesGrid} />
			<div ref={isometrySectionRef}>
				<ImageMapIsometrySection 
					title={t("properties.soesvinget.ImageMapSection.title")}
					text={t("properties.soesvinget.ImageMapSection.text")}
					imageWidth={2560}
					imageMapJSON={imageMapJSON}
					image={imageProperty13}
					tenancies={tenanciesData}
					loading={isTenanciesLoading}
				/>
			</div>
			{tenanciesData?.length > 0 && (
				<AvailableTenanciesList
					loading={isTenanciesLoading}
					tenancies={tenanciesData}
				/>
			)}
			<div ref={contactSectionRef}>
				<ContactSection backgroundColor='#fafafa' />
			</div>
		</Wrapper>
	);
};

export default Soesvinget;
