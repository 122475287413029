import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ArrowNextIcon from 'assets/icons/arrow-next.png';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as ThreeDIcon } from 'assets/icons/3d-box-loading.svg';
import GalleryButton2 from 'components/GalleryButton2';

const Wrapper = styled.div`
	width: 100%;
	background-color: #FAFAFA;
	position: relative;
	@media ${device.tablet} {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 92%);
		padding-bottom: 110px;
	}
	@media ${device.laptop} {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 88%);
		min-height: 850px;
	}
	@media ${device.desktop} {
		min-height: 1000px;
`;
const ContentWrapper = styled.div`
	// clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 92%);
	background-color: #FAFAFA;
	z-index: 10;
	position: relative;
	padding-bottom: 50px;
	max-width: 1280px;
	display: flex;
    flex-direction: column;
	margin: auto;
	@media ${device.tablet} { 
		background-color: transparent;
		padding-bottom: unset;
	}
	@media ${device.tablet} {
		// clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 92%);
	}
}
`;
const Title = styled.h1`
	margin-top: 0;
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	color: #3E5042;
	padding-left: 39px;
	padding-top: 60px;
	padding-bottom: 70px;
	max-width: 300px;
	@media ${device.tablet} {
		color: #fafafa;
		max-width: 380px;
		padding-top: 120px;
	}
`;
const Box = styled.div`
	margin-left: 39px;
	display: flex;
    flex-direction: column;
    max-width: 340px;
	padding-bottom: 60px;
`;
const Line = styled.div`
	width: 55px;
	height: 5px;
	background-color: #C49A36;
`;
const BoxTitle = styled.h3`
	font-weight: 700;
	font-size: 20px;
	line-height: 120%;
	color: #3E5042;
	@media ${device.tablet} {
		color: #fafafa;
		max-width: 380px;
	}
`;
const BoxText = styled.p`
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	color: rgba(62, 80, 66, 0.6);
	flex-wrap: wrap;
	@media ${device.tablet} {
		color: #fafafa;
		max-width: 380px;
	}
`;
const SliderWrapper = styled.div`
	margin-top: -60px;
	height: 620px;

	.sliderView {
		position: relative;
		height: 100%;
	
		@media ${device.tablet} { 
			/* Add the background overlay directly to the existing container */
			&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				271.5deg,
				rgba(0, 0, 0, 0.1) 6.35%,
				rgba(0, 0, 0, 0) 18.41%
				),
				linear-gradient(
				90deg,
				rgba(20, 27, 19, 0.8) 16.1%,
				rgba(0, 0, 0, 0) 48.88%
				);
			background-size: cover;
			background-position: center;
			z-index: 5; /* Ensure the overlay is below the slide content, but above images */
			}
		}
	}

	.slick-slide {
		height: 100%;
	 }
	 
	.slick-slide img {
		height: 100%;
		width: 100%;
	}
	.slick-next {
		right: 25px;
		height: 55px;
		width: 55px;
		z-index: 50;
	}
	.slick-next:before {
		font-size: 55px;
		color: transparent;
		background-image: url(${(props) => props.background});
	}
	.slick-dots {
		bottom: 35px;
		@media ${device.tablet} {
			bottom: 80px;
		}
	}
	.slick-dots li button:before {
		color: #FAFAFA;
		font-size: 10px;
		opacity: 0.5;
	}
	.slick-dots li.slick-active button:before {
		opacity: 1;
		color: #fff;
	}
	.slick-dots li {
		margin: 0 3px;
	}
	.slick-list, .slick-track {
		height: 100% !important;
	}
	.slick-slide div {
		height: 100%;
	}
	@media ${device.tablet} { 
		margin-top: unset;
		position: absolute;
		height: 100%;
		top: 0px;
		width: 100%;
		overflow: hidden;	
	}
`;

const Slide = styled.div`
	height: 100%;
	width: 100%;
	background-image: url(${(props) => props.background});
	background-position: center;
    background-size: cover;
`;

const CarouselSection1 = (props) => {

	const [slider, setSlider] = useState();

	const {
		title,
		section1title,
		section1text,
		section1buttonText,
		section1slides,
		section2title,
		section2text,
		section2buttonText,
		section2slides,
		images,		
	} = props;

	var settings = {
		adaptiveHeight: true,
		arrows: true,
		dots: true,
		infinite: true,
		speed: 500,
		swipeToSlide: true,
	};

	return (
		<Wrapper>
			<ContentWrapper>
				<Title>{title}</Title>
				<Box>
					<Line/>
					<BoxTitle>{section1title}</BoxTitle>
					<BoxText>{section1text}</BoxText>
					{ section1slides && <GalleryButton2 label={section1buttonText} slides={section1slides} />}
				</Box>
				{ section2title && (<Box>
					<Line/>
					<BoxTitle>{section2title}</BoxTitle>
					<BoxText>{section2text}</BoxText>
					{ section2slides && <GalleryButton2 label={section1buttonText} slides={section2slides} />}
				</Box>)}
			</ContentWrapper>
			<SliderWrapper background={ArrowNextIcon}>
				<Slider className='sliderView' ref={(slider) => setSlider(slider)}  {...settings}>
					{images.map((image, index) =>
						(
							<Slide key={index} background={image}>
								{/* <img src={image} className={''} /> */}
							</Slide>
						)
					)}

				</Slider>
			</SliderWrapper>
		</Wrapper>
	);
};

CarouselSection1.propTypes = {
	title: PropTypes.string,
	section1title: PropTypes.string,
	section1text: PropTypes.string,
	section1buttonText: PropTypes.string,
	section1slides: PropTypes.array,
	section2title: PropTypes.string,
	section2text: PropTypes.string,
	section2buttonText: PropTypes.string,
	section2slides: PropTypes.array,
	images: PropTypes.array,	
};

export default CarouselSection1;