import React, { useRef, useState, useEffect } from 'react';
import mapboxgl from '!mapbox-gl';
import useSiteConfig from 'features/site-config';
import styled from 'styled-components';
import TenancyCardSmall from 'components/TenancyCardSmall';
import PropTypes from 'prop-types';

const MapContainer = styled.div`
	padding: 60px 0px;
	width: 100%;
	max-width: 1200px;
	margin: auto;

	.map-container {
		width: 100%;
		height: 80vh;
	}

	.mapboxgl-marker {
		// background-color: ${p => p.theme.colors.ascent.primary};;
		// background-size: cover;
		// width: 50px;
		// height: 50px;
		// border-radius: 50%;
		cursor: pointer;
	}

	.mapboxgl-popup {
		max-width: 200px;
	}
	
	.mapboxgl-popup-content {
		text-align: center;
	}
	.tenancyCard {
		z-index: 9999999;
		position: absolute;
		right: 50px;
		top: 30px;
	}
	
`;

const MapView = props => {

	const { filteredTenancies, allTenancies, selectedPlace, floorplanImageMode } = props;
	const [map, setMap] = useState(null);
	const [selectedTenancy, setSelectedTenancy] = useState(null);
	const [selectedTenancyVisible, setSelectedTenancyVisible] = useState(false);
	const config = useSiteConfig();

	const mapContainer = useRef(null);
	mapboxgl.accessToken = config.mapBox.accessToken;

	const getCoordinates = () => {
		if (selectedPlace === null) {
			return [10.5169793, 55.8837213];
		}
		if (selectedPlace.geometry.type === 'Point') {
			return selectedPlace.geometry.coordinates;
		} else {
			return null;
		}
	};

	const getBbox = (tenancies) => {
		var bbox = null;
		for (let tenancy of tenancies) {
			if (!tenancy.address.coordinates) continue;
			if (bbox === null) {
				const LongLat = [tenancy.address.coordinates.longitude, tenancy.address.coordinates.latitude];
				bbox = [LongLat, LongLat];
			} else {
				// bw 
				if (tenancy.address.coordinates.longitude < bbox[0][0]) {
					bbox[0] = [tenancy.address.coordinates.longitude, bbox[0][1]];
				}
				// bs
				if (tenancy.address.coordinates.latitude < bbox[0][1]) {
					bbox[0] = [bbox[0][0], tenancy.address.coordinates.latitude];
				}
				// te
				if (tenancy.address.coordinates.longitude > bbox[1][0]) {
					bbox[1] = [tenancy.address.coordinates.longitude, bbox[1][1]];
				}
				// tn
				if (tenancy.address.coordinates.latitude > bbox[1][1]) {
					bbox[1] = [bbox[1][0], tenancy.address.coordinates.latitude];
				}
			}
		}
		return bbox;
	};

	const handleMarkerClick = (tenancy) => {
		setSelectedTenancy(tenancy);
		setSelectedTenancyVisible(true);
	};

	useEffect(() => {
		if (mapContainer.current) {
			const coordinates = getCoordinates();
			const temp = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/jorato/ckqp3hhht0onr18mklr1w6nf4',
				center: coordinates,
				zoom: 10,
				pitch: 20,
				antialias: true
			});
			temp.addControl(new mapboxgl.FullscreenControl());
			temp.addControl(new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true
				},
				trackUserLocation: true,
				showUserHeading: true
			}));
			const nav = new mapboxgl.NavigationControl({
				visualizePitch: true
			});
			// const markerHeight = 50;
			// const markerRadius = 10;
			// const linearOffset = 25;
			// const popupOffsets = {
			// 	'top': [0, 0],
			// 	'top-left': [0, 0],
			// 	'top-right': [0, 0],
			// 	'bottom': [0, -markerHeight],
			// 	'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
			// 	'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
			// 	'left': [markerRadius, (markerHeight - markerRadius) * -1],
			// 	'right': [-markerRadius, (markerHeight - markerRadius) * -1]
			// };
			// const popup = new mapboxgl.Popup({offset: popupOffsets, className: 'my-class'})
			// 	.setLngLat([12.568337, 55.676098])
			// 	.setHTML('<h1>Hello World!</h1>')
			// 	.setMaxWidth('300px')
			// 	.addTo(temp);
			// temp.addControl(nav, 'bottom-right');

			for (let tenancy of allTenancies) {
				if (!tenancy.address.coordinates) continue;
				const el = document.createElement('div');
				el.className = 'marker';
				el.onclick = () => handleMarkerClick(tenancy);
				
				const marker = new mapboxgl.Marker({
					color: '#384336',
					scale: 1
				})
					.setLngLat([tenancy.address.coordinates.longitude, tenancy.address.coordinates.latitude])
					.addTo(temp);
				marker.getElement().addEventListener('click', () => {
					handleMarkerClick(tenancy);
				});
			}
			var bbox = getBbox(filteredTenancies);
			if (bbox !== null) {
				temp.fitBounds(bbox, {
					padding: { top: 100, bottom: 100, left: 100, right: 100 }
				});
			}
			setMap(temp);
		}
	}, []);

	// if (selectedPlace === null) {
	// 	return (
	// 		<p>Loading</p>
	// 	);
	// }

	return (
		<>
			
			<MapContainer>
				<div ref={mapContainer} className="map-container">
					{selectedTenancyVisible && <TenancyCardSmall 
						key={selectedTenancy.id}
						name={selectedTenancy.title} 
						image={selectedTenancy.images[0]?.original}
						price={selectedTenancy.monthlyRent.value}
						availableFrom={selectedTenancy.availableFrom}
						rooms={selectedTenancy.rooms}
						size={selectedTenancy.size.value}
						id={selectedTenancy.id}
						street={selectedTenancy.address.street}
						floorplanImageMode={floorplanImageMode}
						floorplan={selectedTenancy.floorPlans[0]?.url}
					/> } 
				</div>
			</MapContainer>
		</>
	);
};

MapView.propTypes = {
	allTenancies: PropTypes.array.isRequired,
	filteredTenancies: PropTypes.array.isRequired,
	floorplanImageMode: PropTypes.bool.isRequired,
	selectedPlace: PropTypes.object.isRequired,
};

export default MapView;