const imageMapJSON = [
	{
		'id': '3',
		'title': 'Building 4',
		'shape': 'poly',
		'name': '4',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': '#3E5042',
		'preFillColor': 'transparent',
		'coords': [
			798,
			48,
			724,
			74,
			720,
			192,
			784,
			168,
			824,
			179,
			858,
			217,
			853,
			313,
			901,
			337,
			913,
			327,
			1021,
			276,
			1021,
			252,
			1100,
			206,
			926,
			124,
			798,
			48,
			799,
			50,
		]
	},
	{
		'id': '0',
		'title': 'Building 1',
		'shape': 'poly',
		'name': 'Building 1',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': '#3E5042',
		'preFillColor': 'transparent',
		'coords': [
			789,
			630,
			1177,
			430,
			1207,
			288,
			1122,
			218,
			1099,
			205,
			1019,
			255,
			1045,
			268,
			914,
			325,
			716,
			469,
			715,
			577,
			715,
			577,
			788,
			632,
			787,
			630,
			788,
			633,
			788,
			630,
			788,
			630,
			1179,
			429
		]  
	},
	{
		'id': '1',
		'title': 'Building 2',
		'shape': 'poly',
		'name': '2',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': '#3E5042',
		'preFillColor': 'transparent',
		'coords': [
			615,
			567,
			516,
			550,
			512,
			453,
			499,
			448,
			508,
			421,
			497,
			418,
			516,
			377,
			514,
			325,
			524,
			310,
			553,
			313,
			594,
			237,
			793,
			166,
			836,
			180,
			857,
			211,
			853,
			317,
			704,
			378
		]		  
	},
	{
		'id': '2',
		'title': 'Building 1',
		'shape': 'poly',
		'name': '1',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': '#3E5042',
		'preFillColor': 'transparent',
		'coords': [
			125,
			490,
			107,
			397,
			145,
			330,
			206,
			264,
			248,
			193,
			364,
			72,
			458,
			87,
			589,
			151,
			589,
			239,
			562,
			281,
			437,
			265,
			245,
			521
		]
	}
];

export default imageMapJSON;