import React from 'react';
import styled from 'styled-components';
import background from 'assets/images/home-agents.jpg';
import Title from 'components/Title';
import Line from 'components/Line';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';

const Wrapper = styled.div`
	padding: 32px 16px;
	box-sizing: border-box;
	width: 100%;
	max-width: 1120px;
	margin: auto;
	display: flex;
	flex-direction: column;
	background-color: white;
	
	@media ${device.laptop} { 
		padding: 90px 0px;	
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	img {
		width: 100%;
		order: 2;
		filter: drop-shadow(0px 22px 54px rgba(0, 0, 0, 0.2));

		@media ${device.laptop} { 
			margin-right: 30px;
			width: 560px;
			// height: 657px;
		}
	}
`;

const Image = styled.div`
	width: 100%;
	background-image: url(${background});
	background-position: center;
    background-size: cover;
`;


const ContentBox = styled.div`
	order: 1;
	padding: 32px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media ${device.laptop} { 
		margin-right:80px;
		order: 3;
		margin-left: 30px;
	}
`;
const Text = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: -0.502564px;
	color: ${p => p.theme.colors.text.secondary};
	max-width: 490px;
`;
const LimitedWidthTitle = styled(Title)`
	max-width: 428px;
`;



const IntroSection = () => {

	const { t } = useTranslation();

	return (
		<Wrapper>
			<Image />
			<ContentBox>
				<Line />
				<LimitedWidthTitle>{t('home.usp1.title')}</LimitedWidthTitle>
				<Text>{t('home.usp1.description1')}</Text>
			</ContentBox>
		</Wrapper>
	);
};

export default IntroSection;