import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';
import InterestListButton from 'components/InterestListButton';

const HeroWrapper = styled.div`
	padding-top: 137px;
	height: 600px;
	width: 100%;
	margin: auto;
	background-image: url(${(props) => props.background}), linear-gradient(rgba(74, 74, 74, 0.40), rgba(74, 74, 74, 0.40));
	background-blend-mode: overlay;
	background-size: auto;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	background-position: center;
	@media ${device.laptop} { 
		background-size: cover;
	}
`;

const Title = styled.h1`
	color: white;
	font-size: 34px;
	text-align: center;
	padding-bottom: 24px;
	margin: unset;
	@media ${device.laptop} { 
		font-size: 64px;
	}
`;

const Subtitle = styled.h2`
	color: white;
	font-size: 24px;
	text-align: center;
	padding-bottom: 24px;
	font-weight: normal;
	margin: unset;
	padding: 0 16px;
	padding-bottom: 24px;
	@media ${device.laptop} { 
		font-size: 24px;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 16px;	
`;

const HeroSection = (props) => {

	const {
		background,
		title,
		text,
		buttons,
	} = props;

	return (
		<HeroWrapper background={background}>
			<Title>{title}</Title>
			<Subtitle>{text}</Subtitle>
			<ButtonWrapper>
				{buttons}
			</ButtonWrapper>
		</HeroWrapper>
	);
};

HeroSection.propTypes = {
	background: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	buttons: PropTypes.object,
};

export default HeroSection;