const B3TenanciesJSON = [
	//  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------
	{
		position: [-4.9, 1.5, -3.2],
		scale: [10.0, 2.8, 6.8],
		id: 'f8874006-b700-4abd-932f-7fbf2f58532c',
		comment: 'Lejlighed nr. 73 - 37C, st. 3',
	},
	{
		position: [-4.9, 1.5, -8.2],
		scale: [10.0, 2.8, 3.2],
		id: '1010c47f-747f-44fb-869d-9ac46b38f9af',
		comment: 'Lejlighed nr. 72 - 37C, st. 2',
	},
	{
		position: [-4.9, 1.5, -11.4],
		scale: [10.0, 2.8, 3.2],
		id: '960bb2d7-1a83-49e5-81d0-8ad74879cf4f',
		comment: 'Lejlighed nr. 71 - 37C, st. 1',
	},
	{
		position: [-4.9, 1.5, -19.0],
		scale: [10.1, 2.8, 3.0],
		id: 'faa76cab-e81a-4819-b7a6-e5d0c710c153',
		comment: 'Lejlighed nr. 70 - 37C, st. 4',
	},
	{
		position: [-4.9, 1.5, -22.0],
		scale: [10.1, 2.8, 3.0],
		id: '561b7658-9148-48b2-a554-dcf8ecbd2596',
		comment: 'Lejlighed nr. 69 - 37C, st. 5',
	},
	{
		position: [-5.0, 1.5, -26.60],
		scale: [10.2, 2.8, 6.0],
		id: '27bd29c7-3d02-4c54-8bad-db3ac51e97d8',
		comment: 'Lejlighed nr. 68 - 37C, st. 6',
	},
	{
		position: [-5.0, 1.5, -32.90],
		scale: [10.2, 2.8, 6.4],
		id: '2de5aae2-56c2-4253-9c14-1ababaf5b19f',
		comment: 'Lejlighed nr. 67 - 37B, st. 8',
	},
	{
		position: [-5.1, 1.5, -37.60],
		scale: [10.1, 2.8, 3.0],
		id: '8666c4cf-08e6-4e0a-a80b-2dafd951a93e',
		comment: 'Lejlighed nr. 66 - 37B, st. 7',
	},
	{
		position: [-5.1, 1.5, -40.60],
		scale: [10.1, 2.8, 3.0],
		id: '5c3c2fcd-08ad-4a02-a84e-c54b7847fc5a',
		comment: 'Lejlighed nr. 65 - 37B, st. 6',
	},
	{
		position: [-8.3, 1.5, -49.20],
		scale: [3.8, 2.8, 6],
		id: 'd3c1d1b7-d6e2-489b-ab2b-6efec5438b09',
		comment: 'Lejlighed nr. 64 - 37B, st. 5',
	},
	{
		position: [-4.9, 1.5, -49.20],
		scale: [2.8, 2.8, 6],
		id: '05ba24ad-3a11-4ca7-9f08-5ad60241a647',
		comment: 'Lejlighed nr. 120 - 37B, st. 4',
	},
	{
		position: [2.5, 1.5, -47.20],
		scale: [3.2, 2.8, 10],
		id: '1e1c445c-a3f1-4921-b581-642850004e8c',
		comment: 'Lejlighed nr. 63 - 37B, st. 1',
	},
	{
		position: [5.7, 1.5, -47.20],
		scale: [3.2, 2.8, 10],
		id: '2eaa5c87-93f1-4389-ac93-6d060690aba7',
		comment: 'Lejlighed nr. 62 - 37B, st. 2',
	},
	{
		position: [10.7, 1.5, -47.30],
		scale: [6.8, 2.8, 10],
		id: '3120b357-a4bf-4531-8770-7cbf92c853c9',
		comment: 'Lejlighed nr. 61 - 37B, st. 3',
	},
	//  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------
	{
		position: [-4.9, 4.3, -3.2],
		scale: [10.0, 2.8, 6.8],
		id: 'fdc01b2e-195f-41ad-936f-58f809f52ab0',
		comment: 'Lejlighed nr. 87 - 37C, 1. 3',
	},
	{
		position: [-4.9, 4.3, -8.2],
		scale: [10.0, 2.8, 3.2],
		id: 'b7982d75-d7c6-42a7-9c85-f302f98793fc',
		comment: 'Lejlighed nr. 86 - 37C, 1. 2',
	},
	{
		position: [-4.9, 4.3, -11.4],
		scale: [10.0, 2.8, 3.2],
		id: '7a48fc1a-7387-4738-b694-6c48ee013bb6',
		comment: 'Lejlighed nr. 85 - 37C, 1. 1',
	},
	{
		position: [-4.9, 4.3, -19.0],
		scale: [10.1, 2.8, 3.0],
		id: 'fa3c7b82-4c70-4340-ae23-3c1ab2c11579',
		comment: 'Lejlighed nr. 84 - 37C, 1. 4',
	},
	{
		position: [-4.9, 4.3, -22.0],
		scale: [10.1, 2.8, 3.0],
		id: '095c47aa-2d71-4735-823d-2c543819c3bc',
		comment: 'Lejlighed nr. 83 - 37C, 1. 5',
	},
	{
		position: [-5.0, 4.3, -26.60],
		scale: [10.2, 2.8, 6.0],
		id: '3002eac9-1028-4b25-8389-0457614757cc',
		comment: 'Lejlighed nr. 82 - 37C, 1. 6',
	},
	{
		position: [-5.0, 4.3, -32.90],
		scale: [10.2, 2.8, 6.4],
		id: '8259eae0-5cba-477f-8367-0163f65743be',
		comment: 'Lejlighed nr. 81 - 37B, 1. 8',
	},
	{
		position: [-5.1, 4.3, -37.60],
		scale: [10.1, 2.8, 3.0],
		id: 'd32ece0d-9b91-471e-baa5-b6e6106834e2',
		comment: 'Lejlighed nr. 80 - 37B, 1. 7',
	},
	{
		position: [-5.1, 4.3, -40.60],
		scale: [10.1, 2.8, 3.0],
		id: '4ef42979-b507-4f82-af82-a6961d23e8d2',
		comment: 'Lejlighed nr. 79 - 37B, 1. 6',
	},
	{
		position: [-8.3, 4.3, -49.20],
		scale: [3.8, 2.8, 6],
		id: '9f1b3e8e-ba02-423c-8d58-429fc10b283f',
		comment: 'Lejlighed nr. 78 - 37B, 1. 5',
	},
	{
		position: [-4.9, 4.3, -49.20],
		scale: [2.8, 2.8, 6],
		id: 'c6083eb4-171a-41c9-99e4-d263da10089d',
		comment: 'Lejlighed nr. 77 - 37B, 1. 4',
	},
	{
		position: [2.5, 4.3, -47.20],
		scale: [3.2, 2.8, 10],
		id: 'ae4df16a-eb56-4adc-ba51-baf34a5a44c5',
		comment: 'Lejlighed nr. 76 - 37B, 1. 1',
	},
	{
		position: [5.7, 4.3, -47.20],
		scale: [3.2, 2.8, 10],
		id: '07acba91-920d-4d6e-9b0f-e466397a2d56',
		comment: 'Lejlighed nr. 75 - 37B, 1. 2',
	},
	{
		position: [10.7, 4.3, -47.30],
		scale: [6.8, 2.8, 10],
		id: '99707eb1-a244-4105-9b95-10a2cbbf2549',
		comment: 'Lejlighed nr. 74 - 37B, 1. 3',
	},
	//  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------
	{
		position: [-4.9, 7.1, -3.2],
		scale: [10.0, 2.8, 6.8],
		id: '155c03d6-d06b-4107-9778-82b50460025c',
		comment: 'Lejlighed nr. 101 - 37C, 2. 3',
	},
	{
		position: [-4.9, 7.1, -8.2],
		scale: [10.0, 2.8, 3.2],
		id: 'fdc14e9a-ab37-41e7-a9cc-268a5be5134e',
		comment: 'Lejlighed nr. 100 - 37C, 2. 2',
	},
	{
		position: [-4.9, 7.1, -11.4],
		scale: [10.0, 2.8, 3.2],
		id: '5c2916be-280e-4b91-a4b6-b563fd76dc75',
		comment: 'Lejlighed nr. 99 - 37C, 2. 1',
	},
	{
		position: [-4.9, 7.1, -19.0],
		scale: [10.1, 2.8, 3.0],
		id: '75ca3592-5d92-475b-bdb0-87f2d73a7585',
		comment: 'Lejlighed nr. 98 - 37C, 2. 4',
	},
	{
		position: [-4.9, 7.1, -22.0],
		scale: [10.1, 2.8, 3.0],
		id: 'e7d92186-ec61-4781-9de6-590e7e208e38',
		comment: 'Lejlighed nr. 97 - 37C, 2. 5',
	},
	{
		position: [-5.0, 7.1, -26.60],
		scale: [10.2, 2.8, 6.0],
		id: 'efa4b74e-0b63-4eca-b9ae-b8ddb4f8420b',
		comment: 'Lejlighed nr. 96 - 37C, 2. 6',
	},
	{
		position: [-5.0, 7.1, -32.90],
		scale: [10.2, 2.8, 6.4],
		id: '7724b3f6-f538-46e0-9a14-37ca1c9e09fd',
		comment: 'Lejlighed nr. 95 - 37B, 2. 8',
	},
	{
		position: [-5.1, 7.1, -37.60],
		scale: [10.1, 2.8, 3.0],
		id: 'fa328401-2e46-439c-a167-1d39c80dde60',
		comment: 'Lejlighed nr. 94 - 37B, 2. 7',
	},
	{
		position: [-5.1, 7.1, -40.60],
		scale: [10.1, 2.8, 3.0],
		id: '813d8d6d-91c6-4381-b89f-da7a58793b0f',
		comment: 'Lejlighed nr. 93 - 37B, 2. 6',
	},
	{
		position: [-8.3, 7.1, -49.20],
		scale: [3.8, 2.8, 6],
		id: '7c354ccb-a2e3-416a-b53e-7ca056766780',
		comment: 'Lejlighed nr. 92 - 37B, 2. 5',
	},
	{
		position: [-4.9, 7.1, -49.20],
		scale: [2.8, 2.8, 6],
		id: 'f07fd3a3-4387-4ecc-bb7a-015a8c0be577',
		comment: 'Lejlighed nr. 91 - 37B, 2. 4',
	},
	{
		position: [2.5, 7.1, -47.20],
		scale: [3.2, 2.8, 10],
		id: 'e659af3d-8bff-4708-8ac8-4a7d6412a96c',
		comment: 'Lejlighed nr. 90 - 37B, 2. 1',
	},
	{
		position: [5.7, 7.1, -47.20],
		scale: [3.2, 2.8, 10],
		id: '6c08421e-9185-417c-8c46-8a381904affe',
		comment: 'Lejlighed nr. 89 - 37B, 2. 2',
	},
	{
		position: [10.7, 7.1, -47.30],
		scale: [6.8, 2.8, 10],
		id: 'd9dd4a1f-b33c-41e7-957b-84e1b020b5f1',
		comment: 'Lejlighed nr. 88 - 37B, 2. 3',
	},
	//  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------
	{
		position: [-4.9, 9.9, -8.2],
		scale: [10.0, 2.8, 3.2],
		id: '0522e47d-f4ca-46c6-a4df-3c2a2325ae14',
		comment: 'Lejlighed nr. 113 - 37C, 3. 2',
	},
	{
		position: [-4.9, 9.9, -11.4],
		scale: [10.0, 2.8, 3.2],
		id: '36195cec-9fa8-42dc-80d0-413f76b1f0cd',
		comment: 'Lejlighed nr. 112 - 37C, 3. 1',
	},
	{
		position: [-4.9, 9.9, -19.0],
		scale: [10.1, 2.8, 3.0],
		id: '5e3ece23-6097-4c9b-8cc5-b929f04f1af2',
		comment: 'Lejlighed nr. 111 - 37C, 3. 3',
	},
	{
		position: [-4.9, 9.9, -22.0],
		scale: [10.1, 2.8, 3.0],
		id: '27f04f0a-4864-4fb1-983d-03866ac0c401',
		comment: 'Lejlighed nr. 110 - 37C, 3. 4',
	},
	{
		position: [-5.0, 9.9, -26.60],
		scale: [10.2, 2.8, 6.0],
		id: 'fe6d4876-f600-42a9-9d35-03a7db3c69e9',
		comment: 'Lejlighed nr. 109 - 37C, 3. 5',
	},
	{
		position: [-5.0, 9.9, -32.90],
		scale: [10.2, 2.8, 6.4],
		id: '414a7965-9e29-48c1-b1b2-2a11ab200893',
		comment: 'Lejlighed nr. 108 - 37B, 3. 7',
	},
	{
		position: [-5.1, 9.9, -37.60],
		scale: [10.1, 2.8, 3.0],
		id: '4decf3ae-d441-43f2-b14f-7f579b98bfe1',
		comment: 'Lejlighed nr. 107 - 37B, 3. 6',
	},
	{
		position: [-5.1, 9.9, -40.60],
		scale: [10.1, 2.8, 3.0],
		id: '356a50ed-479f-498c-bb30-cb0654376962',
		comment: 'Lejlighed nr. 106 - 37B, 3. 5',
	},
	{
		position: [-8.3, 9.9, -49.20],
		scale: [3.8, 2.8, 6],
		id: '6bd02050-5bbc-47a0-b236-063401c7e38b',
		comment: 'Lejlighed nr. 105 - 37B, 3. 4',
	},
	{
		position: [-4.9, 9.9, -49.20],
		scale: [2.8, 2.8, 6],
		id: '68039548-bc36-41dd-9f6f-87bd03dc4b43',
		comment: 'Lejlighed nr. 104 - 37B, 3. 3',
	},
	{
		position: [2.5, 9.9, -47.20],
		scale: [3.2, 2.8, 10],
		id: 'ce66bca8-7c40-42a0-8af7-ba2131c7fa1b',
		comment: 'Lejlighed nr. 103 - 37B, 3. 1',
	},
	{
		position: [5.7, 9.9, -47.20],
		scale: [3.2, 2.8, 10],
		id: '1cb6afa5-dae6-47b7-ac61-c28c3e3cac40',
		comment: 'Lejlighed nr. 102 - 37B, 3. 2',
	},
	//  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------
	{
		position: [-5.0, 12.7, -32.90],
		scale: [10.2, 2.8, 6.4],
		id: '45950290-6ecd-493a-9353-702bb1073559',
		comment: 'Lejlighed nr. 118 - 37B, 4. 5',
	},
	{
		position: [-5.1, 12.7, -37.60],
		scale: [10.1, 2.8, 3.0],
		id: '0d767aa3-1348-406d-8211-11dd73a2b8cb',
		comment: 'Lejlighed nr. 117 - 37B, 4. 4',
	},
	{
		position: [-5.1, 12.7, -40.60],
		scale: [10.1, 2.8, 3.0],
		id: '8c920530-b250-4839-8834-7764fd9d0e97',
		comment: 'Lejlighed nr. 116 - 37B, 4. 3',
	},
	{
		position: [-8.3, 12.7, -49.20],
		scale: [3.8, 2.8, 6],
		id: '43e53ffc-553b-4213-8352-fec368b58c4e',
		comment: 'Lejlighed nr. 115 - 37B, 4. 2',
	},
	{
		position: [-4.9, 12.7, -49.20],
		scale: [2.8, 2.8, 6],
		id: '68039548-bc36-41dd-9f6f-87bd03dc4b43',
		comment: 'Lejlighed nr. 104 - 37B, 4. 1',
	},
];
  
export default B3TenanciesJSON;