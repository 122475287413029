const imageMapJSON = [
	{
		"id": "MM-2-12",
		"title": "Myremalmsvej 2-12",
		"shape": "poly",
		"name": "MM-2-12",
		"fillColor": "rgb(100 108 255 / 50%)",
		"strokeColor": "#3E5042",
		"preFillColor": "rgb(100 108 255 / 50%)",
		"coords": [950, 347, 950, 314, 970, 305, 1182, 263, 1205, 272, 1202, 298, 1189, 314, 1137, 334, 1018, 369],
		"rooms": 5,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["b7c9c32a-2246-4f19-9ab2-3094e91f742e", "63b85b01-7c6b-45f2-bb76-0011bc03e0b4", "7ac461fc-7fbc-4067-b6ac-577defaab988", "11eaf30b-e52f-4ce5-84e8-2c40402fc0a2", "f233e5b1-2436-4044-9eeb-16eafb3e732a", "bcb71d96-cfcc-4904-bb6b-7617a405f291"]
	},
	{
		"id": "MM-14-26",
		"title": "Myremalmsvej 14-26",
		"shape": "poly",
		"name": "MM-14-26",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1166, 368, 1224, 377, 1279, 355, 1301, 358, 1353, 332, 1350, 287, 1288, 281, 1240, 303, 1166, 329],
		"rooms": 3,
		"type": "apartment",
		"tenancyIds": ["13eb6088-0f28-4a80-bc6e-767635781d26", "5ea93dc2-4e29-4fc8-acd3-2f2cd124283a", "0354ccb7-756b-44f5-ba87-7fdf05303aed", "3808e77d-8f22-4615-8ab1-6cbbd5ee2caa", "c9354b97-e49d-49b1-a7e9-55d0bee07a9e", "cbffc79b-7e9a-459a-bb55-4ad733f0e247", "2a6e7f05-12b3-4cc3-a8a7-2d5ac352bf68", "878626e1-b913-47ba-9c70-9484f93bc482"]
	},
	{
		"id": "MM-1-17",
		"title": "Myremalmsvej 1-17",
		"shape": "poly",
		"name": "MM-1-17",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1295, 228, 1317, 221, 1356, 228, 1404, 234, 1456, 237, 1530, 247, 1591, 254, 1639, 260, 1630, 276, 1626, 305, 1591, 318, 1359, 292, 1292, 276],
		"rooms": 4,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["5939da48-8627-4960-84ab-46ac368d0e3a", "fe02b93d-0646-4b65-abe9-b90620033cfb", "7cb16f29-232f-44b0-a621-a48849f158dc", "e9e0aae6-5d01-429c-bed5-96d2f8f3521c", "90a1b6e7-e240-4200-af8e-9ee9f34ee220", "9414e844-c185-4485-ba39-bc4b3e60000d", "6e745aa2-a81e-411b-a95d-cc61afd5e696", "6772a860-3709-4cc9-b2ec-07cd3b924ca2", "36abee7b-7081-497b-87b5-1f26792df23f"]
	},
	{
		"id": "MM-19-23",
		"title": "Myremalmsvej 19-23",
		"shape": "poly",
		"name": "MM-19-23",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1626, 316, 1633, 274, 1655, 261, 1752, 277, 1771, 281, 1771, 332, 1746, 335],
		"rooms": 5,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["137d5d41-ce72-461a-8602-d98c796d9b7b", "6aa09f67-808a-4212-ba03-2ad6c713081a", "69011218-819c-48c3-b0b1-f4166392911a"]
	},
	{
		"id": "MM-30-36",
		"title": "Myremalmsvej 30-36",
		"shape": "poly",
		"name": "MM-30-36",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1424, 394, 1424, 353, 1462, 343, 1466, 333, 1494, 324, 1543, 314, 1565, 327, 1569, 378, 1504, 414],
		"rooms": 5,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["b08e24e7-fd1d-4b65-8e4a-36d8c2123f9b", "54ed0d46-ed3f-4943-b1be-f2e83f9e78e1", "da36da03-0b85-4069-b900-a5237c7e67ce", "c77fe8df-e899-442a-84db-991c2d925a0a"]
	},
	{
		"id": "MM-38-46",
		"title": "Myremalmsvej 38-46",
		"shape": "poly",
		"name": "MM-38-46",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1601, 424, 1607, 382, 1678, 327, 1733, 337, 1739, 385, 1672, 443],
		"rooms": 4,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["6a7b5986-955a-417f-9458-3b7802dd2c76", "6b8a844e-f47e-4441-8c4b-5daee06a53a9", "72e82ad3-075e-4852-bf0e-6fc897518e9c", "ce484f78-9b63-41b8-b272-5e44f93da334", "0910ab72-a0b0-4167-ac9a-7de0c2121854"]
	},
	{
		"id": "MM-27-37",
		"title": "Myremalmsvej 27-37",
		"shape": "poly",
		"name": "MM-27-37",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1900, 310, 1903, 265, 1923, 252, 2158, 268, 2161, 310, 2148, 332, 2080, 326, 2074, 313, 2000, 313, 1990, 323, 2080, 326],
		"rooms": 4,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["8c35e557-fb5c-49a3-bf79-8fe71a59145c", "526be315-f274-4e80-b476-2e935d1c03b2", "1564a418-7891-479a-b886-4830c22eafda", "63d5640a-f42b-4c10-bd25-dff6d16b68b6", "76c4e241-572d-417e-945d-0b61951547ae", "3ec944e5-ce36-489d-9b1b-98ca0f8d7c12"]
	},
	{
		"id": "MM-48-70",
		"title": "Myremalmsvej 48-70",
		"shape": "poly",
		"name": "MM-48-70",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1948, 459, 1952, 404, 1974, 375, 1987, 349, 2006, 324, 2074, 330, 2074, 372, 2058, 407, 2038, 433, 2029, 465],
		"rooms": 4,
		"type": "apartment",
		"active": true,
		"tenancyIds": ["b4be1b91-5578-4e6e-a2a5-136a5dfcde42", "72cf2d7f-ad51-4659-9954-1be078378cab", "818e7ba4-633b-4174-8fc1-754db0b62bc4", "9a82eea1-a993-4ea0-9c70-aac438a1903f", "c686b875-23e4-4f58-beda-7c8cefb1b264", "5125ea50-3b65-4e5a-a75a-95ff907b2ad3", "50ebf1e6-0674-40d6-8bbc-54db55ed7264", "bd91a2c8-532b-400c-ae86-e955c289bc92", "372697a1-cea6-490a-a084-2dc4772797c4", "99b7cf94-0ea9-484d-811e-d37c6aa35ed3", "a7ffb0e2-7138-4029-ad28-cdc4713362b9"]
	},
	{
		"id": "MM-41-53",
		"title": "Myremalmsvej 41-53",
		"shape": "poly",
		"name": "MM-41-53",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [2206, 330, 2212, 291, 2241, 262, 2293, 278, 2309, 288, 2325, 278, 2415, 307, 2476, 327, 2508, 343, 2512, 359, 2470, 362, 2463, 394, 2434, 401, 2415, 401],
		"rooms": 6,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["18c2540b-996b-41a4-b29c-522b7933392b", "2117cbdf-cdee-4d30-b979-722087a82e04", "1316ec6b-1c08-46ce-80c9-fcb3c6fa3feb", "996bdad7-556f-4b88-91d8-9d3ec96983dd", "3060fa44-8dc4-4da3-89c8-27beacb0bbfd", "c59d4f6e-59ea-49fe-a29f-46498af9af97", "d583d60d-ebcf-430c-9c46-ea6db7050ffb"]
	},
	{
		"id": "MM-72-86",
		"title": "Myremalmsvej 72-86",
		"shape": "poly",
		"name": "MM-72-86",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [2125, 480, 2125, 429, 2180, 400, 2232, 374, 2306, 387, 2299, 445, 2209, 497],
		"rooms": 3,
		"type": "apartment",
		"active": true,
		"tenancyIds": ["531485b4-796e-4a50-a855-8be600439639", "49392673-7eb4-4f80-8557-be513fdc2c12", "623ba950-fa92-42d9-a873-be63cef5e3f5", "b6e55668-9ac7-479b-94af-ad70303116b0", "39b1de3f-8da9-4f5a-ba09-663549d8a16c", "61fac136-9262-43d1-acc7-b0779ce7d926", "7e432cd5-b7f3-402d-ad85-693e96e3b346", "fd0a4aca-c1fa-486b-8d55-d03a8b17de21"]
	},
	{
		"id": "MM-55-63",
		"title": "Myremalmsvej 55-63",
		"shape": "poly",
		"name": "MM-55-63",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [2460, 402, 2467, 360, 2518, 357, 2692, 412, 2702, 460, 2628, 480, 2486, 438],
		"rooms": 4,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["70504c4c-474c-4afd-ade7-96d1d5e1e467", "79a4f765-32e5-40e9-99e3-193a5fb0d6ad", "fd9e45c5-d8d7-418c-ba85-3d45dd965cc6", "50ca73c2-8701-4753-8ee4-279a07792f70", "b2c53dae-ed2c-43ff-bed3-d2d996bbf7d7"]
	},
	{
		"id": "MM-88-94",
		"title": "Myremalmsvej 88-94",
		"shape": "poly",
		"name": "MM-88-94",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [2312, 518, 2315, 467, 2357, 441, 2396, 418, 2447, 396, 2479, 431, 2479, 486, 2405, 538],
		"rooms": 6,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["df66a5fc-3b79-4c6f-90f6-aa61ea3530fa", "8dc9e5bb-533e-4201-8c27-88bb5ad187e7", "a5edcf06-c78a-455d-993e-fa58c1f70ada", "4c4f1749-9302-4a92-bd06-597743f84bf9"]
	},
	{
		"id": "Ejerbolig",
		"title": "Ejerbolig",
		"shape": "poly",
		"name": "Ejerbolig",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [2788, 497, 2795, 413, 2834, 404, 2875, 420, 2885, 468, 2927, 491, 2920, 558, 2830, 632, 2740, 616, 2747, 565, 2805, 520],
		"active": false,
	},
	{
		"id": "JM-1-11",
		"title": "Jernmagervej 1-11",
		"shape": "poly",
		"name": "JM-1-11",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [876, 482, 876, 434, 896, 399, 986, 408, 1066, 418, 1160, 428, 1128, 463, 1128, 514],
		"rooms": 6,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["ca63ca13-bdf0-4c78-951a-c7418dab97fe", "aa032bb3-7690-4ef3-b524-5c37d25c6e81", "b3ed8d98-5682-4ca1-a7d3-3924cbbd17f9", "3e7fc26b-f2da-4f23-b734-e380d3131732", "bf1d3ded-0285-44c4-8d2b-becad083a074", "783b4a07-7f1e-49fb-a683-ed27b1113872"]
	},
	{
		"id": "Ejerbolig2", 
		"title": "Ejerbolig",
		"shape": "poly",
		"name": "Ejerbolig",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1128, 535, 1131, 464, 1166, 448, 1205, 448, 1276, 493, 1295, 496, 1337, 525, 1382, 551, 1385, 573, 1305, 593, 1282, 612, 1253, 615],
		"active": false,
	},
	{
		"id": "Ejerbolig3",  
		"title": "Ejerbolig",
		"shape": "poly",
		"name": "Ejerbolig",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1070, 794, 1063, 736, 1118, 684, 1153, 678, 1185, 646, 1234, 639, 1256, 617, 1288, 610, 1305, 597, 1382, 578, 1420, 584, 1424, 646, 1385, 684, 1301, 804, 1211, 810, 1208, 771, 1111, 762],
		"active": false,
	},
	{
		"id": "JM-45-51",
		"title": "Jernmagervej 45-51",
		"shape": "poly",
		"name": "JM-45-51",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1041, 898, 1034, 824, 1111, 766, 1205, 773, 1214, 831, 1144, 911],
		"rooms": 3,
		"type": "apartment",
		"active": true,
		"tenancyIds": ["1c92e2ba-c7f0-47f4-b530-a358e5468ba4", "aa526987-7f09-45f2-9365-253eb6d20473", "a30919d6-0cd9-40a1-a611-dea3d13730ca", "995dd80c-4c0f-4236-88da-3d130adec35b"]
	},
	{
		"id": "Ejerbolig4", 
		"title": "Ejerbolig",
		"shape": "poly",
		"name": "Ejerbolig",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [896, 1012, 886, 944, 864, 922, 632, 919, 622, 931, 493, 928, 481, 915, 168, 915, 146, 977, 149, 1054, 217, 1060, 226, 1054, 313, 1054, 455, 1064, 613, 1070, 632, 1060, 703, 1064, 864, 1073],
		"active": false
	},
	{
		"id": "JM-32-42",
		"title": "Jernmagervej 32-42",
		"shape": "poly",
		"name": "JM-32-42",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [651, 908, 683, 870, 677, 815, 658, 805, 506, 802, 229, 799, 217, 841, 223, 902, 493, 912],
		rooms: 5,
		"type": "townhouse",
		"active": false,
		"tenancyIds": ["2f03d242-6da9-433f-8bad-129e7f111779", "781a7b80-cab4-49b6-b453-3c5c83818b85", "0a105501-b116-4680-b317-2fbfa5959290", "2a2eb8a9-ecd8-4ea2-88a5-b69d6e83de2c", "009795cb-d059-443f-ad04-d6dd75670fe0", "19397a96-b195-4ff1-a683-0a316bf89dbb"]
	},
	{
		"id": "JM-18-30",
		"title": "Jernmagervej 18-30",
		"shape": "poly",
		"name": "JM-18-30",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [796, 820, 686, 820, 355, 798, 348, 746, 361, 694, 609, 698, 805, 707, 828, 730, 831, 778],
		"rooms": 6,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["f473ee02-8920-4baa-8a97-62ebafa580c2", "b3bb7c71-15c6-466e-b3f3-29dce78c777c", "f5e8951b-158f-4ac6-8be9-c781fdd23c50", "14a0a946-6ae6-4bf3-86f9-6174228e35f3", "6a52c7ef-06cb-4aa9-8128-b8bf94bb9249", "90ad8869-e351-4f40-ba31-03a0f5c0ff45", "879d2ec8-1b3e-4274-80d3-223374d3289b"]
	},
	{
		"id": "JM-16",
		"title": "Jernmagervej 16",
		"shape": "poly",
		"name": "JM-16",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [870, 743, 963, 685, 960, 656, 918, 605, 822, 663, 780, 701, 815, 708, 828, 743],
		"active": false
	},
	{
		"id": "JM-2-14",
		"title": "Jernmagervej 2-14",
		"shape": "poly",
		"name": "JM-2-14",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [976, 642, 970, 581, 883, 545, 828, 520, 767, 497, 706, 510, 696, 568, 860, 632, 918, 607, 963, 658],
		"rooms": 4,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["c292ea4e-6639-4f0c-bd5b-b6459f5c4e24", "15845e89-33d7-42f6-aa9d-84a0e5d37b14", "736a28f4-72b0-480f-a338-a59136c30ce4", "6599e3bf-b607-40d9-aaf4-8fc3ad0873b3", "6b14c3a2-ca85-400c-8602-681c321d37a4", "b91eeae7-f4ba-4a5f-af4b-f83ccfd6451c", "8b33a43b-b0c7-45b4-93cd-9d38b67f00aa"]
	},
	{
		"id": "LG-2-8",
		"title": "Lergrubevej 2-8",
		"shape": "poly",
		"name": "LG-2-8",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1266, 1088, 1263, 1017, 1285, 953, 1433, 969, 1594, 988, 1620, 1007, 1626, 1085, 1581, 1136],
		"rooms": 6,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["9807325d-f116-45b8-9b0b-05c7aa145fdb", "4addb5f4-b406-4bec-be9d-831f7d9cfd45", "b92c60d9-aaa8-4703-a8a7-167009405a23", "ba7f519c-9849-4461-a2d8-8c0e5e031b49"]
	},
	{
		"id": "Ejerbolig5", 
		"title": "Ejerbolig",
		"shape": "poly",
		"name": "Ejerbolig",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1646, 1175, 1649, 1108, 1655, 1075, 1707, 1030, 1736, 1001, 1765, 969, 1913, 866, 1977, 850, 2010, 818, 2064, 808, 2113, 834, 2116, 924, 2035, 989, 1945, 1056, 1852, 1140, 1778, 1211],
		"active": false,
	},
	{
		"id": "LG-30-40",
		"title": "Lergrubevej 30-40",
		"shape": "poly",
		"name": "LG-30-40",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [2119, 890, 2209, 913, 2290, 858, 2393, 790, 2396, 735, 2347, 690, 2286, 710, 2232, 739, 2090, 819, 2116, 832],
		"rooms": 6,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["e52d9967-de3a-4529-93ef-b8d230244c9a", "7fe6972a-02d7-4e83-8c5b-89dcca74e142", "b555556e-976e-4875-b7cd-136ade353d76", "1e1e6137-3e04-481f-b3af-2ddb1a201fea", "c7169401-6ffb-44e3-9f9d-e4700e5b70ba", "2c9f148e-e9ab-461a-affb-efca728928a3"]
	},
	{
		"id": "Ejerbolig6",
		"title": "Ejerbolig",
		"shape": "poly",
		"name": "Ejerbolig",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [2486, 705, 2489, 641, 2447, 602, 2402, 612, 2306, 666, 2299, 699, 2347, 686, 2396, 731, 2399, 773],
		"active": false
	},
	{
		"id": "LG-37-47",
		"title": "Lergrubevej 37-47",
		"shape": "poly",
		"name": "LG-37-47",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [2064, 662, 2113, 646, 2116, 588, 2093, 584, 1775, 530, 1746, 552, 1749, 601, 1990, 649],
		"rooms": 5,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["50e72d74-e2a6-4da3-a997-352895fbff38", "99407f20-fe24-4beb-a95e-4cb19918d82e", "a55321a4-6a00-4941-8ff2-630e4cd5d8df", "d9480cc4-5fbd-4ef6-94be-d85192b6015d", "46a6bcbe-d246-4bf9-9859-d8e1e5f113b4", "435ed960-ac77-4db2-98f1-769b856094c9"]
	},
	{
		"id": "LG-25-35",
		"title": "Lergrubevej 25-35",
		"shape": "poly",
		"name": "LG-25-35",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1987, 700, 1984, 649, 1655, 584, 1636, 584, 1617, 613, 1614, 662, 1948, 726],
		"rooms": 5,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["5f2929c3-1459-464c-b0e9-98c85440c7d7", "50550df8-754f-49c5-aaf3-24251941335c", "141a7155-c23a-44f6-9e90-8fea80dd820d", "a72ecea2-1337-4514-a9e2-4bf900f43af0", "003d6b2a-d199-4681-897e-e926299a3847", "a2cdb688-3310-4549-b991-b5c2e89ff4b2"]
	},
	{
		"id": "LG-9-23",
		"title": "Lergrubevej 9-23",
		"shape": "poly",
		"name": "LG-9-23",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1900, 786, 1897, 724, 1546, 673, 1504, 702, 1504, 773, 1604, 747, 1655, 776, 1659, 795, 1865, 821],
		"rooms": 4,
		"type": "apartment",
		"active": true,
		"tenancyIds": ["15141628-f922-489b-8fa9-9f95640a46bc", "823a1269-7333-4722-926a-9ed1ac005748", "5d0487b9-d682-4551-84ab-f5b4e0d39f86", "8742725c-1922-460f-ab94-8864a1c26ea6", "00dd4aae-d5c9-40c9-816a-5d3e9d35dc77", "7f4e4641-44a0-463c-ab27-20d920a00e00", "51a49092-6ab3-4f79-829c-ccdadde8400b"]
	},
	{
		"id": "LG-1-7",
		"title": "Lergrubevej 1-7",
		"shape": "poly",
		"name": "LG-1-7",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "#3E5042",
		"preFillColor": "transparent",
		"coords": [1559, 936, 1626, 887, 1623, 865, 1655, 832, 1652, 781, 1604, 752, 1536, 771, 1501, 804, 1498, 829, 1462, 849, 1456, 919],
		"rooms": 5,
		"type": "townhouse",
		"active": true,
		"tenancyIds": ["6007a767-2b7b-41b3-98e2-569bda99fbc0", "bd18203a-c97f-4db6-ab36-b29493dc61d6", "820c0f9e-171e-455f-9b2c-97e201f7d53e", "4aa152de-24c9-4549-8b7b-b2cd373c93b6"]
	},
];
export default imageMapJSON; 