import { useState, useCallback } from 'react';
import TenanciesService from 'services/api/tenancies';
import OpenHousesService from 'services/api/open-houses';

const useTenancy = () => {

	const [isTenancyLoading, setTenancyLoading] = useState(true);
	const [tenancyData, setTenancyData] = useState(null);
	const [isTenancyNotFound, setTenancyNotFound] = useState(false);

	const loadTenancy = useCallback(async (tenancyId) => {
		const result = await TenanciesService.getTenancy(tenancyId);
		if (result !== null) {
			const openHouseResult = await OpenHousesService.getOpenHousesByTenancyId(tenancyId);
			const filteredOpenHouseResult = openHouseResult.data.filter((openHouse) => openHouse.cancelledAt === null);
			setTenancyData({
				...result.data,
				openHouses: filteredOpenHouseResult.length > 0 ? filteredOpenHouseResult : null
			});
		} else {
			setTenancyNotFound(true);
		}

		setTenancyLoading(false);
	}, []);

	return {
		isTenancyLoading,
		isTenancyNotFound,
		tenancyData,
		loadTenancy
	};
};

export default useTenancy;