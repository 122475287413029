import React from 'react';
import ContactInformationSection from './components/ContactInformationSection';
import TeamSection from './components/TeamSection';
import ContactSection from 'components/ContactSection';
import styled from 'styled-components';
import background from 'assets/images/contact-hero.jpg';
import { device } from 'helpers/device';

const ContactWrapper = styled.div`
	max-width: 1120px;
	margin: 0 auto;
	background-color: white;

	.top-image {
		display: block;
		object-fit: cover;
		width: 100%;
		margin: 0 auto;

		@media ${device.laptop} { 
			width: 100%;
			height: 620px;
		}
	}
`;

const Contact = () => {

	return (
		<ContactWrapper>
			<img className="top-image" src={background}/>
			<ContactInformationSection/>
			<TeamSection/>
			<ContactSection/>
		</ContactWrapper>
	);
};

export default Contact;