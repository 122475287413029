import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import calendarIcon from 'assets/icons/calendar.png';
import formatPrice from 'helpers/formatPrice';
import PropTypes from 'prop-types';
import formatAvailability from 'helpers/formatAvailability';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from 'assets/images/cross.svg';
import useGoogleTranslate from 'features/google-translate';

const Wrapper = styled.a`
	display: grid;
	background-color: ${p => p.theme.colors.white.primary};
	box-shadow: 0px 22px 60px rgba(0, 0, 0, 0.2);
	position: relative;
	&:hover {
		cursor: pointer;
	}
`;

const Close = styled.div`
	position: absolute;
	top: -20px;
	right: -20px;
	background-color: #fff;
	border-radius: 50%;
	color: #333;
	z-index: 50;
	cursor: pointer;
	width: 50px;
	height: 50px;
	&:hover {
		background-color: #aaa;
	}
	svg {
		width: 50px;
		height: 50px;
	}
`;

const Image = styled.img`
	width: 250px;
	height: 172px;
	object-fit: cover;
	@media (max-width: 480px) { 
		width: 100%;
	}
`;
const Title = styled.div`
	padding-top: 24px;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: ${p => p.theme.colors.text.primary};
	padding: 16px 16px 0px 16px;
`;
const TopSection = styled.div`
	width: 250px;
	min-width: 100%;
`;
const Address = styled.div`
	padding-top: 24px;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	color: ${p => p.theme.colors.text.primary};
	padding: 16px 16px 0px 16px;
`;
const Price = styled.p`
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;
	color: ${p => p.theme.colors.text.primary};
	padding: 16px 16px 0px 16px;
`;
const AvailableFrom = styled.p`
	display: flex;
	font-weight: 700;
	font-size: 17px;
	line-height: 31px;
	color: ${p => p.theme.colors.text.primary};
	padding: 16px 16px 16px 16px;
`;

const Icon = styled.img`
	align-self: center;
	margin-right: 12px;
`;


const TenancyCardSmall = (props) => {

	const { image, name, price, availableFrom, id, street, floorplanImageMode, floorplan, onCloseClick } = props;
	const { t, i18n } = useTranslation();
	const { isTranslationLoading, translationData, loadTranslation } = useGoogleTranslate();
	const [showdetail, setShowdetail] = useState(true);
	const [translatedName, setTranslatedName] = useState('');
	
	const link = `/${process.env.REACT_APP_ROUTER_TENANCY}/${encodeURI(street)}+${id}`;

	useEffect(() => {
		if (i18n.resolvedLanguage === 'en') {
			loadTranslation([name]);
		} else {
			setTranslatedName(name);
		}
	}, [i18n.resolvedLanguage, name]);

	useEffect(() => {
		if (!isTranslationLoading) {
			translationData[0] ? setTranslatedName(translationData[0]) : setTranslatedName(name);
		}
	}, [isTranslationLoading, translationData]);

	const handleCloseClick = () => {
		setShowdetail(false);
		if (onCloseClick) {
			onCloseClick();
		}
	};

	return (
		showdetail ?
			(
				<div>
					<Close onClick={handleCloseClick}>
						<CrossIcon />
					</Close>
					<Wrapper className="tenancyCard" href={link} key={id}>
						<Image src={floorplanImageMode ? floorplan : image} />
						<div>
							<TopSection>
								<Title>{translatedName}</Title>
								<Address>{street}</Address>
								<Price>{formatPrice(price)} {t('tenancyCard.prMonth')}</Price>
								<AvailableFrom>
									<Icon src={calendarIcon} />
									<span>{formatAvailability(availableFrom, t('tenancyCard.asap'))}</span>
								</AvailableFrom>
							</TopSection>
						</div>
					</Wrapper>
				</div>
			) : ''
	);
};

TenancyCardSmall.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string,
	price: PropTypes.number,
	availableFrom: PropTypes.string,
	rooms: PropTypes.number,
	size: PropTypes.number,
	id: PropTypes.string,
	floorplanImageMode: PropTypes.bool,
	floorplan: PropTypes.string,
	street: PropTypes.string,
	onCloseClick: PropTypes.func,
};

TenancyCardSmall.defaultProps = {
	image: null,
	name: '-',
	price: '-',
	availableFrom: '-',
	rooms: '-',
	size: '-',
	id: null,
	onCloseClick: null,
};

export default TenancyCardSmall;