import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import PagesRouter from 'pages/PagesRouter';
import Layout from 'layout';
import theme from 'theme';
import GlobalStyles from 'globalStyles';
import ReactGA from 'react-ga';

const App = () => {

	const [gaInitialized, setGaInitialized] = useState(false);

	useEffect(() => {
		if (gaInitialized) return;
		ReactGA.initialize('UA-194055183-1', { standardImplementation: true });
		setGaInitialized(true);
	}, [gaInitialized]);

	return (
		<Router>
			<GlobalStyles/>
			<ThemeProvider theme={theme}>
				<Layout>
					<PagesRouter />
				</Layout>
			</ThemeProvider>
		</Router>
	);
};

export default App;
