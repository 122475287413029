import { useState, useCallback } from 'react';
import MapBoxSearchService from 'services/mapbox/search';
import useSiteConfig from 'features/site-config';

const useMapBoxSearch = () => {

	const config = useSiteConfig();

	const [isLoading, setLoading] = useState(true);
	const [queryData, setQueryData] = useState(null);

	const getPlaces = useCallback(async (query) => {
		const result = await MapBoxSearchService.getPlaces(query, config.mapBox.accessToken);
		if (result !== null) {
			setQueryData([
				...result.data.features
			]);
		} else {
			setQueryData(null);
		}
		setLoading(false);
	}, [config.mapBox.accessToken]);

	return {
		isLoading,
		queryData,
		getPlaces
	};
};

export default useMapBoxSearch;