const B1TenanciesJSON = [
	//  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------
	{
		position: [8.5, 1.5, -45.5],
		scale: [4.0, 2.8, 8.2],
		id: '07a5a7a1-f6c7-4b80-8802-acedf2331ec6',
		comment: 'Jens Baggesens Vej 37, st. lejl 1',
		"rotation": [0, 4, 0]
	},
	{
		position: [6.1, 1.5, -42.6],
		scale: [3.4, 2.8, 8.2],
		id: '0a1f225c-d78f-484a-a53c-668387bd2e42',
		comment: 'Jens Baggesens Vej 37, st. lejl 2',
		"rotation": [0, 4, 0]
	},
	{
		position: [3.8, 1.5, -39.8],
		scale: [3.4, 2.8, 8.2],
		id: '7e316609-35e2-40d3-83e3-9855ebfce6f8',
		comment: 'Jens Baggesens Vej 37, st. lejl 3',
		"rotation": [0, 4.02, 0]
	},
	{
		position: [1.5, 1.5, -36.9],
		scale: [3.4, 2.8, 8.2],
		id: 'cbf58448-201b-4591-a85c-b48413ab0489',
		comment: 'Jens Baggesens Vej 37, st. lejl 4',
		"rotation": [0, 4.03, 0]
	},
	{
		position: [-1.2, 1.5, -33.5],
		scale: [5.2, 2.8, 8.2],
		id: '79752d84-e3d4-417c-a53d-3c30b84e5f95',
		comment: 'Jens Baggesens Vej 37, st. lejl 5',
		"rotation": [0, 4.04, 0]
	},
	{
		position: [-3.8, 1.5, -25.8],
		scale: [5.3, 2.8, 8.2],
		id: 'd71af402-a2e0-4ff0-b631-d8efc277cff6',
		comment: 'Jens Baggesens Vej 37, st. lejl 6',
		"rotation": [0, 4.70, 0]
	},
	{
		position: [-3.75, 1.5, -21.2],
		scale: [3.4, 2.8, 8.2],
		id: 'd2821dbb-566b-47ef-9729-1dfe221fa39b',
		comment: 'Jens Baggesens Vej 37, st. lejl 7',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.60, 1.5, -17.5],
		scale: [3.4, 2.8, 8.2],
		id: '8f01ca78-f023-42db-8581-5e702c73bd2f',
		comment: 'Jens Baggesens Vej 37, st. lejl 8',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.50, 1.5, -14.0],
		scale: [3.4, 2.8, 8.2],
		id: '58d22066-571a-4650-a98e-988337d9d2d3',
		comment: 'Jens Baggesens Vej 37, st. lejl 9',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.40, 1.5, -10.5],
		scale: [3.4, 2.8, 8.2],
		id: 'c763ef6b-b432-488d-b9bf-2fffaf8d7356',
		comment: 'Jens Baggesens Vej 37, st. lejl 10',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.20, 1.5, -4.4],
		scale: [8.6, 2.8, 8.2],
		id: '49849637-6810-4f98-a4d7-48ff2c8f9774',
		comment: 'Jens Baggesens Vej 37, st. lejl 11',
		"rotation": [0, 4.73, 0]
	},
	//  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------
	{
		position: [8.5, 4.3, -45.5],
		scale: [4.0, 2.8, 8.2],
		id: '6d89f3f5-2217-4d85-9013-7685d992c973',
		comment: 'Jens Baggesens Vej 37, 1. lejl 1',
		"rotation": [0, 4, 0]
	},
	{
		position: [6.1, 4.3, -42.6],
		scale: [3.4, 2.8, 8.2],
		id: 'e5fad118-475c-4246-876e-04811ce8c25c',
		comment: 'Jens Baggesens Vej 37, 1. lejl 2',
		"rotation": [0, 4, 0]
	},
	{
		position: [3.8, 4.3, -39.8],
		scale: [3.4, 2.8, 8.2],
		id: '45a17dac-3510-4cea-945a-df9b28f30df0',
		comment: 'Jens Baggesens Vej 37, 1. lejl 3',
		"rotation": [0, 4.02, 0]
	},
	{
		position: [1.5, 4.3, -36.9],
		scale: [3.4, 2.8, 8.2],
		id: 'ddd06c21-a659-4115-98f9-43a606febbc7',
		comment: 'Jens Baggesens Vej 37, 1. lejl 4',
		"rotation": [0, 4.03, 0]
	},
	{
		position: [-1.2, 4.3, -33.5],
		scale: [5.2, 2.8, 8.2],
		id: '9d98c3f6-670d-4827-8877-c8092b4c5acf',
		comment: 'Jens Baggesens Vej 37, 1. lejl 5',
		"rotation": [0, 4.04, 0]
	},
	{
		position: [-3.8, 4.3, -25.8],
		scale: [5.3, 2.8, 8.2],
		id: 'fcbf5e3d-2351-4a4d-9309-c05cfa600228',
		comment: 'Jens Baggesens Vej 37, 1. lejl 6',
		"rotation": [0, 4.70, 0]
	},
	{
		position: [-3.75, 4.3, -21.2],
		scale: [3.4, 2.8, 8.2],
		id: 'a790c28d-f4e4-4083-868f-0d51cd6216f9',
		comment: 'Jens Baggesens Vej 37, 1. lejl 7',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.60, 4.3, -17.5],
		scale: [3.4, 2.8, 8.2],
		id: '8bf5f7e4-432b-4a7f-b54b-441441a1447a',
		comment: 'Jens Baggesens Vej 37, 1. lejl 8',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.50, 4.3, -14.0],
		scale: [3.4, 2.8, 8.2],
		id: 'ade91040-4b88-43c1-9948-57502924a264',
		comment: 'Jens Baggesens Vej 37, 1. lejl 9',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.40, 4.3, -10.5],
		scale: [3.4, 2.8, 8.2],
		id: 'e937d532-3350-4173-8b00-2b51cf64975e',
		comment: 'Jens Baggesens Vej 37, 1. lejl 10',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.20, 4.3, -4.4],
		scale: [8.6, 2.8, 8.2],
		id: 'fd101a09-ead7-4a4a-97a8-5e624ede80f9',
		comment: 'Jens Baggesens Vej 37, 1. lejl 11',
		"rotation": [0, 4.73, 0]
	},
	//  2nd FLOOR ------  2nd FLOOR ------  2nd FLOOR ------  2nd FLOOR ------  2nd FLOOR ------
	{
		position: [8.5, 7.1, -45.5],
		scale: [4.0, 2.8, 8.2],
		id: '31420f8a-b0dd-4eb1-a553-f076ffba9f85',
		comment: 'Jens Baggesens Vej 37, 2. lejl 1',
		"rotation": [0, 4, 0]
	},
	{
		position: [6.1, 7.1, -42.6],
		scale: [3.4, 2.8, 8.2],
		comment: 'Jens Baggesens Vej 37, 2. lejl 2',
		id: '27f61496-f209-4cb8-b95d-fb3b6812a429',
		"rotation": [0, 4, 0]
	},
	{
		position: [3.8, 7.1, -39.8],
		scale: [3.4, 2.8, 8.2],
		id: 'c895508b-d978-4295-af3b-b0bb49f1d58f',
		comment: 'Jens Baggesens Vej 37, 2. lejl 3',
		"rotation": [0, 4.02, 0]
	},
	{
		position: [1.5, 7.1, -36.9],
		scale: [3.4, 2.8, 8.2],
		id: 'd83b2290-f0fa-4615-a50f-ae715d36e8a2',
		comment: 'Jens Baggesens Vej 37, 2. lejl 4',
		"rotation": [0, 4.03, 0]
	},
	{
		position: [-1.2, 7.1, -33.5],
		scale: [5.2, 2.8, 8.2],
		id: 'a9b40e54-3d7b-4ab9-9a72-5a9e90436e1d',
		comment: 'Jens Baggesens Vej 37, 2. lejl 5',
		"rotation": [0, 4.04, 0]
	},
	{
		position: [-3.8, 7.1, -25.8],
		scale: [5.3, 2.8, 8.2],
		id: 'b1aa5adb-eaf4-484f-84e5-8fa9192f1e4e',
		comment: 'Jens Baggesens Vej 37, 2. lejl 6',
		"rotation": [0, 4.70, 0]
	},
	{
		position: [-3.75, 7.1, -21.2],
		scale: [3.4, 2.8, 8.2],
		id: 'f820cc29-7ca6-4dfd-8044-2ecf288b5eb3',
		comment: 'Jens Baggesens Vej 37, 2. lejl 7',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.60, 7.1, -17.5],
		scale: [3.4, 2.8, 8.2],
		id: '726ab121-829b-40cd-ae3b-2a6bab1edded',
		comment: 'Jens Baggesens Vej 37, 2. lejl 8',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.50, 7.1, -14.0],
		scale: [3.4, 2.8, 8.2],
		id: '0eb52845-bd44-43cc-a5cf-c541d9c51aab',
		comment: 'Jens Baggesens Vej 37, 2. lejl 9',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.40, 7.1, -10.5],
		scale: [3.4, 2.8, 8.2],
		id: 'de866cd4-edfa-43ec-99c8-29c8a15f733d',
		comment: 'Jens Baggesens Vej 37, 2. lejl 10',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.20, 7.1, -4.4],
		scale: [8.6, 2.8, 8.2],
		id: 'b5b3f1ea-b7ce-4049-b33a-bf8e3832085a',
		comment: 'Jens Baggesens Vej 37, 2. lejl 11',
		"rotation": [0, 4.73, 0]
	},
	//  3rd FLOOR ------  3rd FLOOR ------  3rd FLOOR ------  3rd FLOOR ------  3rd FLOOR ------
	{
		position: [8.5, 9.9, -45.5],
		scale: [4.0, 2.8, 8.2],
		id: 'c8544f22-56d3-4a4e-ad2d-091d49c1b59b',
		comment: 'Jens Baggesens Vej 37, 3. lejl 1',
		"rotation": [0, 4, 0]
	},
	{
		position: [6.1, 9.9, -42.6],
		scale: [3.4, 2.8, 8.2],
		id: 'a1a6f448-2934-4bc2-a883-8476c35e7b88',
		comment: 'Jens Baggesens Vej 37, 3. lejl 2',
		"rotation": [0, 4, 0]
	},
	{
		position: [3.8, 9.9, -39.8],
		scale: [3.4, 2.8, 8.2],
		id: '89308289-a866-4ccc-9d95-bb4826d1eeb6',
		comment: 'Jens Baggesens Vej 37, 3. lejl 3',
		"rotation": [0, 4.02, 0]
	},
	{
		position: [1.5, 9.9, -36.9],
		scale: [3.4, 2.8, 8.2],
		id: 'ea6daad7-4b16-45c5-abb2-da72a967eb07',
		comment: 'Jens Baggesens Vej 37, 3. lejl 4',
		"rotation": [0, 4.03, 0]
	},
	{
		position: [-1.2, 9.9, -33.5],
		scale: [5.2, 2.8, 8.2],
		id: '500384db-8ab6-4030-bb02-27d09ccf2f08',
		comment: 'Jens Baggesens Vej 37, 3. lejl 5',
		"rotation": [0, 4.04, 0]
	},
	{
		position: [-3.8, 9.9, -25.8],
		scale: [5.3, 2.8, 8.2],
		id: '8687075e-ca20-4da1-b4c9-2d26eed3f2f4',
		comment: 'Jens Baggesens Vej 37, 3. lejl 6',
		"rotation": [0, 4.70, 0]
	},
	{
		position: [-3.75, 9.9, -21.2],
		scale: [3.4, 2.8, 8.2],
		id: '308bbca0-5b2b-40b7-9f6e-4f47f0da6161',
		comment: 'Jens Baggesens Vej 37, 3. lejl 7',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.60, 9.9, -17.5],
		scale: [3.4, 2.8, 8.2],
		id: '4b13570c-815c-4c07-bf1f-6f93cd7385e9',
		comment: 'Jens Baggesens Vej 37, 3. lejl 8',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.50, 9.9, -14.0],
		scale: [3.4, 2.8, 8.2],
		id: 'ecefc79f-9ccc-4be2-a33b-e32ae89bcad5',
		comment: 'Jens Baggesens Vej 37, 3. lejl 9',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.40, 9.9, -10.5],
		scale: [3.4, 2.8, 8.2],
		id: '41353e27-9808-4ad3-bf68-4c4db99fae7d',
		comment: 'Jens Baggesens Vej 37, 3. lejl 10',
		"rotation": [0, 4.73, 0]
	},
	{
		position: [-3.20, 9.9, -4.4],
		scale: [8.6, 2.8, 8.2],
		id: '0eb74904-f7f0-4235-8c3c-072891aa34a1',
		comment: 'Jens Baggesens Vej 37, 3. lejl 11',
		"rotation": [0, 4.73, 0]
	},
];

export default B1TenanciesJSON;