import { useState, useCallback } from 'react';
import TenanciesService from 'services/api/tenancies';
import OpenHouseService from 'services/api/open-houses';

const useTenancies = () => {

	const [isTenanciesLoading, setTenanciesLoading] = useState(true);
	const [tenanciesData, setTenanciesData] = useState(null);

	const loadTenancies = useCallback(async () => {
		const result = await TenanciesService.getTenancies();
		if (result !== null) {
			const openHouses = await OpenHouseService.getOpenHouses();
			openHouses.data.forEach(openHouse => {
				if (openHouse.propertyId && openHouse.announceForAllTenancies && openHouse.cancelledAt === null) {
					const tenancies = result.data.items.filter(tenancy => tenancy.propertyId === openHouse.propertyId);
					if (tenancies.length > 0) {
						tenancies.forEach(tenancy => {
							if (!tenancy.openHouses) {
								tenancy.openHouses = [];
							}
							tenancy.openHouses.push(openHouse);
						});
					}
				}
				else if (openHouse.tenancies.length > 0 && openHouse.cancelledAt === null) {
					openHouse.tenancies.forEach(tenancyId => {
						const tenancy = result.data.items.find(t => t.id === tenancyId);
						if (tenancy) {
							if (!tenancy.openHouses) {
								tenancy.openHouses = [];
							}
						}
					});

				}
			});
			setTenanciesData({
				...result.data
			});
		} else {
			setTenanciesData(null);
		}

		setTenanciesLoading(false);
	}, []);

	const loadTenanciesByPropertyId = useCallback(async (id) => {
		const result = await TenanciesService.getTenanciesByPropertyId(id);
		if (result !== null) {
			setTenanciesData([
				...result.data.items
			]);
		} else {
			setTenanciesData(null);
		}

		setTenanciesLoading(false);
	}, []);

	const loadTenanciesByPropertyIds = useCallback(async (propertyIds) => {
		const tenanciesPromises = propertyIds.map(async (id) => {
			const result = await TenanciesService.getTenanciesByPropertyId(id);
			return result !== null ? result.data.items : null;
		});

		const tenanciesDataArrays = await Promise.all(tenanciesPromises);
		// Merge all arrays into a single array
		const mergedTenanciesData = [].concat(...tenanciesDataArrays);
		setTenanciesData(mergedTenanciesData);
		setTenanciesLoading(false);
	}, []);

	return {
		isTenanciesLoading,
		tenanciesData,
		loadTenancies,
		loadTenanciesByPropertyId,
		loadTenanciesByPropertyIds
	};
};

export default useTenancies;