import React from 'react';
import styled from 'styled-components';
import kobenhavn from 'assets/images/kobenhavn.png';
import kolding from 'assets/images/kolding.png';
import fredericia from 'assets/images/fredericia.png';
import aarhus from 'assets/images/aarhus.png';
import esbjerg from 'assets/images/esbjerg.png';
import viborg from 'assets/images/viborg.png';
import Title from 'components/Title';
import ArrowButton from 'components/ArrowButton';
import Line from 'components/Line';
import arrowIcon from 'assets/icons/arrow-black.png';
import { device } from 'helpers/device';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	max-width: 960px;
	margin: auto;
	padding-top: 60px;
	background-color: white;
	padding: 32px 16px 8px 16px;

	@media ${device.laptop} { 
		padding-left: 80px;
		padding-right: 80px;
	}
`;
const TopSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
const ListSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;
	@media ${device.laptop} { 
		width: 960px;
		height: 1500px;
	}
`;
const Left = styled.div`
	display: flex;
	flex-direction: column;
`;
const Right = styled.div`
	display: none;
	@media ${device.laptop} { 
		display: block;
	}
`;
// TTT weird why display grid is needed
const CityWrapper = styled.a`
	display: grid; 
	max-width: 464px;
	box-shadow: 0px 22px 60px rgba(0, 0, 0, 0.2);
	margin-bottom: 24px;

	.city_image {
		max-width: 100%;
		object-fit: cover;
		height: 230px;
		width: 100%;
	}
	@media ${device.laptop} { 
		margin-bottom: unset;
		&.desktop-height-1 .city_image {
			height: 315px;
		}
		&.desktop-height-2 .city_image {
			height: 423px;
		}
		&.desktop-height-3 .city_image {
			height: 490px;
		}
		&.desktop-height-4 .city_image {
			height: 598px;
		}
	}
	
`;
const BelowImage = styled.div`
	height: 79px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 28px;
`;
const Text = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 31px;
	color: #384336;
`;
const Link = styled.div`
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 22px;
	color: #384336;

	img {
		padding-left: 16px;
		transition-duration: 0.4s;
	}
`;

const cities = [
	{
		name: 'København',
		image: kobenhavn,
		link: '/boliger/?q=København',
		desktopHeight: 4
	},
	{
		name: 'Aarhus',
		image: aarhus,
		link: '/boliger/?q=Aarhus',
		desktopHeight: 1
	},
	{
		name: 'Kolding',
		image: kolding,
		link: '/boliger/?q=Kolding',
		desktopHeight: 1
	},
	{
		name: 'Fredericia',
		image: fredericia,
		link: '/boliger/?q=Fredericia',
		desktopHeight: 1
	},
	{
		name: 'Viborg',
		image: viborg,
		link: '/boliger/?q=Viborg',
		desktopHeight: 3
	},
	{
		name: 'Esbjerg',
		image: esbjerg,
		link: '/boliger/?q=Esbjerg',
		desktopHeight: 2
	},
];


const CitiesSection = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<TopSection>
				<Left>
					<Line />
					<Title>{t('home.citiesSection.title')}</Title>
				</Left>
				<Right>
					<ArrowButton>{t('home.citiesSection.buttonLabel')} </ArrowButton>
				</Right>
			</TopSection>
			<ListSection>
				{cities.map(city => {
					return (
						<CityWrapper href={city.link} key={city.name} className={`desktop-height-${city.desktopHeight}`}>
							<img className="city_image" src={city.image} />
							<BelowImage>
								<Text>{city.name}</Text>
								<Link>{t('home.citiesSection.cityButtonLabel')} <img src={arrowIcon}/></Link>
							</BelowImage>
						</CityWrapper>
					);
				})}
			</ListSection>
		</Wrapper>
	);
};

export default CitiesSection;