import React, { useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import PropTypes from "prop-types";
import ImageMap from "../ImageMap/ImageMap";
import AvailableTenanciesList from "components/AvailableTenanciesList";
import InterestListPopup from "components/InterestListPopup";
import { ReactComponent as ShoppingCartIcon } from "assets/icons/shopping-cart.svg";
import { ReactComponent as CommunityHouseIcon } from "assets/icons/community-house.svg";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	background: #fef6e5;
	max-width: 1800px;
	margin: auto;
	padding-top: 50px;
	// clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%);
	max-width: 1280px;

	@media ${device.laptop} {
		// clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
		height: 100%;
	}
	position: relative;
`;
const Line = styled.div`
	width: 80px;
	height: 5px;
	background-color: #c49a36;
	margin: auto;
	min-height: 5px;
`;
const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 140%;
	margin-top: 30px;
	text-align: center;
	color: #3e5042;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
`;
const Text = styled.p`
	color: #384336;
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	margin-bottom: 40px;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
`;
const IsometryLegend = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 40px;
	margin-bottom: 20px;
`;
const LegendItem = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	svg {
		height: 40px;
		width: 40px;
	}
`;

const ImageMapSectionWithListOverlay = (props) => {
	const [type, setType] = useState(null);
	const [selectedPropertyID, setSelectedPropertyID] = useState(null);
	const [selectedPropertyTitle, setSelectedPropertyTitle] = useState(null);
	const [selectedPropertyDescription, setSelectedPropertyDescription] = useState(null);
	const [selectedPropertyCases, setSelectedPropertyCases] = useState(null);
	const [selectedPropertyFloorplans, setSelectedPropertyFloorplans] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [roomFilter, setRoomFilter] = useState(null);
	const [typeFilter, setTypeFilter] = useState(null);
	const { title, text, image, imageWidth, imageMapJSON, tenancies, isTenanciesLoading, floorplans } = props;
	const tenanciesListRef = useRef(null);
	const { t } = useTranslation();

	const onClick = (e) => {
		if (e.interestList === true) {
			setType("interestList");
			setModalIsOpen(true);
			setSelectedPropertyID(e.propertyId);
			setSelectedPropertyTitle(e.title);
			setSelectedPropertyDescription(
				`Ejendommen har ${e.rooms} værelses lejligheder i størrelsen ${e.sizes} m2. Ejendommen er klart til indflytning ${e.availableFrom}.`
			);
		} else if (e.active === true) {
			setType("availableTenancies");
			setSelectedPropertyID(e.id);
			setSelectedPropertyTitle(e.title);
			const key = `${e.rooms}${e.type}`;
			setSelectedPropertyFloorplans(e.rooms ? floorplans[key] : []);
			setSelectedPropertyCases(roomFilter ? e.tenancyRooms[roomFilter] : e.cases);
			tenanciesListRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	const imageMapJSONUpdatedBasedOnTenancies = useMemo(() => {
		if (!isTenanciesLoading && tenancies && tenancies.length > 0) {
			const updated = imageMapJSON.map((b) => {
				b.preFillColor = "rgb(100 108 255 / 50%)";
				b.fillColor = "rgb(100 108 255 / 50%)";
				
				const data = tenancies.filter(tenancy => b.id === tenancy.id);
				if (data.length > 0) {
					if (data[0].state === "Available") {
						console.log('available');
						b.preFillColor = 'rgb(50 205 50 / 50%)';
					}
					if (data[0].state === "Reserved") {
						console.log('reserved');
						b.preFillColor = "rgb(100 108 255 / 50%)";
						b.fillColor = "rgb(100 108 255 / 50%)";
					}
				} else {
					console.log("no results");
					console.log(data);
					console.log(b);
				}
				// if (b.interestList === true) {
				// 	b.preFillColor = 'rgb(100 108 255 / 50%)';
				// 	b.fillColor = 'rgb(100 108 255 / 50%)';
				// } else if (b.tenancyIds && b.tenancyIds.length > 0) {
				// 	const cases = tenancies.filter(tenancy => b.tenancyIds.includes(tenancy.id));
				// 	if (cases.length > 0) {
				// 		b.preFillColor = 'rgb(50 205 50 / 50%)';
				// 		b.cases = cases;
				// 	} else {
				// 		b.preFillColor = 'rgb(255 69 0 / 50%)';
				// 		b.fillColor = '#000';
				// 		b.active = false;
				// 	}
				// }
				return b;
			});
			return updated;
		} else {
			return [];
		}
	}, [tenancies, isTenanciesLoading]);

	return (
		<Wrapper>
			<Title>{title}</Title>
			<Text>{text}</Text>
			<IsometryLegend>
				<LegendItem>
					<CommunityHouseIcon /> <p>Fælleslokale</p>
				</LegendItem>
			</IsometryLegend>
			<ImageMap
				image={image}
				imageWidth={imageWidth}
				json={imageMapJSONUpdatedBasedOnTenancies}
				tenancies={tenancies}
				onClick={onClick}
			/>
			<div ref={tenanciesListRef}>
				{type === "availableTenancies" && selectedPropertyID !== null && (
					<AvailableTenanciesList
						loading={isTenanciesLoading}
						tenancies={selectedPropertyCases}
						buildingName={selectedPropertyTitle}
						roomFilter={roomFilter ? roomFilter : undefined}
						floorplans={selectedPropertyFloorplans}
					/>
				)}
			</div>
		</Wrapper>
	);
};

ImageMapSectionWithListOverlay.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
	imageMapJSON: PropTypes.array,
	imageWidth: PropTypes.number,
	tenancies: PropTypes.array,
	isTenanciesLoading: PropTypes.bool,
	floorplans: PropTypes.array,
};

export default ImageMapSectionWithListOverlay;
