import React, { useState, useEffect, useMemo } from 'react';
import ControlsSection from './components/ControlsSection';
import TenanciesSection from './components/TenanciesSection';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useTenancies from 'features/tenancies';
import useMapBoxSearch from 'features/mapbox-search';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Tenancies = () => {

	const { t } = useTranslation();

	const sortOptions = [
		{ value: 'price', label: t('tenancies.sortOptions.cheapest') },
		{ value: 'newest', label: t('tenancies.sortOptions.newest') },
		{ value: 'available', label: t('tenancies.sortOptions.earliestAvailable') }
	];
	const defaultFilterValues = useMemo(() => {
		return {
			rent: [0, 30000],
			size: [0, 200],
			rooms: [0, 5],
			acquisition: null
		};
	}, []);

	const [searchQuery, setSearchQuery] = useState('');
	const [selectedPlace, setSelectedPlace] = useState(null);
	const [searchedData, setSearchedData] = useState(null);
	const [sortedData, setSortedData] = useState(null);
	const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
	const [floorplanImageMode, setFloorplanImageMode] = useState(false);
	const [gridViewMode, setGridViewMode] = useState(true);
	const [rentFilterValue, setRentFilterValue] = useState(defaultFilterValues.rent);
	const [sizeFilterValue, setSizeFilterValue] = useState(defaultFilterValues.size);
	const [roomsFilterValue, setRoomsFilterValue] = useState(defaultFilterValues.rooms);
	const [acquisitionFilterValue, setAcquisitionFilterValue] = useState(null);
	const [balconyFilterValue, setBalconyFilterValue] = useState(false);
	const [petsFilterValue, setPetsFilterValue] = useState(false);
	const [elevatorFilterValue, setElevatorFilterValue] = useState(false);
	const [propertyFilterValue, setPropertyFilterValue] = useState(null);
	const [loading, setLoading] = useState(true);
	const { search } = useLocation();
	let history = useHistory();
	const queryParamValues = queryString.parse(search);

	const {
		isTenanciesLoading,
		tenanciesData,
		loadTenancies
	} = useTenancies();
	const {
		queryData,
		getPlaces
	} = useMapBoxSearch();	
	
	const checkIfInBbox = (point, bbox) => {
		// check for null values
		if (point === null || point?.longitude === null || point?.latitude === null) return false;
		if( bbox[0] <= point.longitude && point.longitude <= bbox[2] && bbox[1] <= point.latitude && point.latitude <= bbox[3] ) {
			return true;
		} else {
			return false;
		}
	};
	
	useEffect(() => {
		loadTenancies();
		if (queryParamValues.q) {
			getPlaces(queryParamValues.q);
		}
		if (queryParamValues.propertyId) {
			setPropertyFilterValue(queryParamValues.propertyId);
		}
		queryParamValues.rentFrom && queryParamValues.rentTo && setRentFilterValue([queryParamValues.rentFrom, queryParamValues.rentTo]);
		queryParamValues.sizeFrom && queryParamValues.sizeTo && setSizeFilterValue([queryParamValues.sizeFrom, queryParamValues.sizeTo]);
		queryParamValues.roomsFrom && queryParamValues.roomsTo && setRoomsFilterValue([queryParamValues.roomsFrom, queryParamValues.roomsTo]);
		queryParamValues.balcony && setBalconyFilterValue(true);
		queryParamValues.pets && setPetsFilterValue(true);
		queryParamValues.elevator && setElevatorFilterValue(true);
		queryParamValues.acquisition && setAcquisitionFilterValue(new Date(queryParamValues.acquisition));	
	}, [getPlaces, loadTenancies, queryParamValues.q]);

	useEffect(() => {
		if (queryData !== null) {
			setSelectedPlace(queryData[0]);
			setSearchQuery(queryData[0].text_da);
		}
	}, [queryData]);
	useEffect(() => {
		if (!isTenanciesLoading && selectedPlace !== null) {
			const searched = tenanciesData?.items.filter(t => {
				if (selectedPlace.bbox) {
					return checkIfInBbox(t.address?.coordinates, selectedPlace.bbox);
				}
				else {
					const streetnameValid = t.address.street.includes(selectedPlace.text_da);
					const postnumberValid = t.address.zipCode.includes(selectedPlace.context[0].text_da);
					return streetnameValid && postnumberValid;
				}
				
			});
			setSearchedData(searched);
		} else if (!isTenanciesLoading && !queryParamValues.q) {
			setSearchedData(tenanciesData.items);
		}
	}, [isTenanciesLoading, selectedPlace, queryParamValues.q, tenanciesData]);

	

	const updateUrl = (pathname) => {
		let s = '?';
		if (queryParamValues.q) {
			s = `?q=${queryParamValues.q}`;
		}
		if (queryParamValues.propertyId) {
			s = `?propertyId=${queryParamValues.propertyId}`;
		}
		if (JSON.stringify(rentFilterValue) !== JSON.stringify(defaultFilterValues.rent)) {
			s = s + `rentFrom=${rentFilterValue[0]}&rentTo=${rentFilterValue[1]}&`;
		}
		if (JSON.stringify(sizeFilterValue) !== JSON.stringify(defaultFilterValues.size)) {
			s = s + `sizeFrom=${sizeFilterValue[0]}&sizeTo=${sizeFilterValue[1]}&`;
		}
		if (JSON.stringify(roomsFilterValue) !== JSON.stringify(defaultFilterValues.rooms)) {
			s = s + `roomsFrom=${roomsFilterValue[0]}&roomsTo=${roomsFilterValue[1]}&`;
		}
		if (balconyFilterValue) {
			s = s + 'balcony=true&';
		}
		if (petsFilterValue) {
			s = s + 'pets=true&';
		}
		if (elevatorFilterValue) {
			s = s + 'elevator=true&';
		}
		if (acquisitionFilterValue) {
			const d = acquisitionFilterValue.toLocaleDateString('da-DK');
			s = s + `acquisition=${d}&`;
		}
		if (s.endsWith('&')) {
			s = s.slice(0,-1);
		}
		if (pathname) {
			history.push({ 
				pathname: pathname,
				search: s 
			});
		} else {
			history.push({ 
				search: s 
			});
		}
		
	};

	useEffect(() => {
		const sortData = (data) => {
			if (data !== null) {
				let sorted;
				switch(selectedSortOption.value) {
				case 'price':
					sorted = data.sort((a, b) => {
						return a.monthlyRent.value - b.monthlyRent.value;
					});
					break;
				case 'size':
					sorted = data.sort((a, b) => {
						return a.size.value - b.size.value;
					});
					break;
				case 'newest':
					sorted = data.sort((a, b) => {
						const d1 = new Date(a.publishedAt);
						const d2 = new Date(b.publishedAt);
						return d2.getTime() - d1.getTime();
					});
					break;
				case 'available':
					sorted = data.sort((a, b) => {
						const d1 = new Date(a.availableFrom);
						const d2 = new Date(b.availableFrom);
						return d1.getTime() - d2.getTime();
					});
					break;
				}
				sorted = sorted.sort((a, b) => (a.classification === 'Other') - (b.classification === 'Other'));
				return sorted;
			}
		};
		const filterData = (data) => {
			if (data !== null) {
				let filtered;
				filtered = data.filter(item => {
					if (rentFilterValue[1] === defaultFilterValues.rent[1]) {
						return item.monthlyRent.value > rentFilterValue[0];
					}
					return (item.monthlyRent.value > rentFilterValue[0] && item.monthlyRent.value < rentFilterValue[1]);
				});
				filtered = filtered.filter(item => {
					if (sizeFilterValue[1] === defaultFilterValues.size[1]) {
						return item.size.value > sizeFilterValue[0];
					}
					return (item.size.value > sizeFilterValue[0] && item.size.value < sizeFilterValue[1]);
				});
				filtered = filtered.filter(item => {
					if (roomsFilterValue[1] === defaultFilterValues.rooms[1]) {
						return item.rooms > roomsFilterValue[0];
					}
					return (item.rooms > roomsFilterValue[0] && item.rooms < roomsFilterValue[1]);
				});
				
				if (petsFilterValue === true) {
					filtered = filtered.filter(item => {
						return item.additionalDetails.petsAllowed === true;
					});
				}
				if (balconyFilterValue === true) {
					filtered = filtered.filter(item => {
						return item.tenancyFacilities.includes('Balcony');
					});
				}
				if (elevatorFilterValue === true) {
					filtered = filtered.filter(item => {
						return item.propertyFacilities.includes.includes('Elevator');
					});
				}
				
				if (acquisitionFilterValue !== null) {
					filtered = filtered.filter(item => {
						const ta = new Date(item.availableFrom);
						const fa = new Date(acquisitionFilterValue);
						return ta.getTime() < fa.getTime();
					});
				}
				if (propertyFilterValue !== null) {
					filtered = filtered.filter(item => {
						return item.propertyId === propertyFilterValue;
					});
				}
				return filtered;
			}
		};
		if (searchedData !== null) {
			const temp = sortData(searchedData);
			const filteredData = filterData([...temp]);
			setSortedData([...filteredData]);
			setLoading(false);
		}
		updateUrl();
	}, [selectedSortOption, searchedData, rentFilterValue, sizeFilterValue, roomsFilterValue, balconyFilterValue, petsFilterValue, elevatorFilterValue, acquisitionFilterValue, defaultFilterValues, propertyFilterValue]);

	const updateSelectedPlace = (data, type) => {
		if (type === 'string') {
			getPlaces(data);
		} else if (type === 'place') {
			setSelectedPlace(data);
			setSearchQuery(data.text_da);
		}
	};	

	return (
		<div>
			<ControlsSection 
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				updateSelectedPlace={updateSelectedPlace}
				sortOptions={sortOptions}
				selectedSortOption={selectedSortOption}
				setSelectedSortOption={setSelectedSortOption}
				gridViewMode={gridViewMode}
				setGridViewMode={setGridViewMode}
				floorplanImageMode={floorplanImageMode}
				setFloorplanImageMode={setFloorplanImageMode}
				rentFilterValue={rentFilterValue}
				setRentFilterValue={setRentFilterValue}
				sizeFilterValue={sizeFilterValue}
				setSizeFilterValue={setSizeFilterValue}
				roomsFilterValue={roomsFilterValue}
				setRoomsFilterValue={setRoomsFilterValue}
				defaultFilterValues={defaultFilterValues}
				balconyFilterValue={balconyFilterValue}
				setBalconyFilterValue={setBalconyFilterValue}
				petsFilterValue={petsFilterValue}
				setPetsFilterValue={setPetsFilterValue}
				elevatorFilterValue={elevatorFilterValue}
				setElevatorFilterValue={setElevatorFilterValue}
				updateUrl={updateUrl}
				acquisitionFilterValue={acquisitionFilterValue}
				setAcquisitionFilterValue={setAcquisitionFilterValue}

			/>
			<TenanciesSection
				loading={loading} 
				filteredTenancies={sortedData}
				allTenancies={tenanciesData?.items}
				floorplanImageMode={floorplanImageMode}
				gridViewMode={gridViewMode}
				selectedPlace={selectedPlace}
			/>
		</div>
	);
};

export default Tenancies;