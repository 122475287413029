import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "components/Title";
import Line from "components/Line";
import formatPrice from "helpers/formatPrice";
import PropTypes from "prop-types";
import formatAvailability from "helpers/formatAvailability";
import { useTranslation } from "react-i18next";
import { device } from "helpers/device";
import useGoogleTranslate from "features/google-translate";
import Button from "components/Button";

const Wrapper = styled.div`
	width: 100%;
	max-width: 1120px;
	margin: auto;
	padding: 24px 24px 0px 24px;
	background-color: white;
	box-sizing: border-box;

	@media ${device.laptop} {
		padding-top: 10px;
		padding-left: 80px;
		padding-right: 80px;
		padding-bottom: 0px;
	}

	div.top-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		padding-bottom: 24px;
	}

	div.description-container {
		padding-bottom: 32px;

		p.subtitle {
			font-weight: bold;
			font-size: 20px;
			line-height: 20px;
			color: ${(p) => p.theme.colors.text.primary};
			padding-bottom: 24px;
		}

		p.description {
			font-size: 16px;
			line-height: 24px;
			color: ${(p) => p.theme.colors.text.secondary};
			white-space: break-spaces;
		}
	}

	div.info-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 64px;
		grid-row-gap: 24px;

		@media ${device.laptop} {
			grid-template-columns: 2fr 1fr;
		}
	}

	div.info-section {
		border-top: 1px solid ${(p) => p.theme.colors.text.tertiary};
		padding: 40px 0px 24px 0px;

		div.info-container {
			display: flex;
			justify-content: space-between;
			padding-bottom: 16px;
		}

		p.value {
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			color: ${(p) => p.theme.colors.text.primary};
		}

		p.name {
			font-size: 16px;
			line-height: 24px;
			color: ${(p) => p.theme.colors.text.secondary};
		}
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
`;

const GeneralSection = (props) => {
	const {
		classification,
		tenancyDescription,
		areaDescription,
		propertyDescription,
		street,
		postnumber,
		city,
		rooms,
		size,
		availableFrom,
		monthlyRent,
		prepaidRent,
		deposit,
		petsAllowed,
		elevator,
		balcony,
		heating,
		water,
		propertyLink,
		propertyId,
	} = props;
	const { t, i18n } = useTranslation();
	const { isTranslationLoading, translationData, loadTranslation } = useGoogleTranslate();
	const [translatedTenancyDescription, setTranslatedTenancyDescription] = useState(null);
	const [translatedAreaDescription, setTranslatedAreaDescription] = useState(null);
	const [translatedPropertyDescription, setTranslatedPropertyDescription] = useState(null);

	useEffect(() => {
		if (i18n.resolvedLanguage === "en") {
			if (propertyId === "01d67e14-8447-4c3b-a5de-e47971d1a5f2" || propertyId === "44a19b00-c7ae-42df-b4f4-71ba6a1a0592") {
				setTranslatedTenancyDescription(
					"Come inside our attractive newly built rentals in Espergærde. The residences are ideal for families with young children as well as senior citizens, that wish for a weekday in a modern rental, in wonderful nature-filled areas. The rentals are designed with your comfort as focus. Modern day architecture, and stylish interior design create an inciting and relaxing atmosphere, where you can create wonderful family memories. Every apartment is spacious and well thought out, where there has been thought about every detail. With the ideal location, fantastic nature and comfortable residences, our newly built rentals are the perfect home for both a family with young children as well as senior citizens. Take the first step towards a life full of joy, community and nature-filled experiences! Contact us today and let us help you with finding your dream home in Espergærde. Welcome home!"
				);
				setTranslatedAreaDescription("The Skovstykke is newly build and modern apartments, that are accustomed to a family with young children as well as senior citizens. The residence is located in the new “town in town” in the charming area of Espergærde. Espergærde unites beautiful nature with modern comfort, which creates the perfect conditions for your next home. The short distances to the forest and the beach create the perfect set up for you to enjoy nature and a breath of fresh air.");
				setTranslatedPropertyDescription("Our beautiful properties in Espergærde offer 3- and 4-bedroom apartments, as well as 5- and 6-bedroom townhouses. The residences are built with lovely light and modern materials. All residences have spacious rooms, a wonderful kitchen-dining area, storeroom, as well as built in wardrobes. Furthermore, all residences have a sunny terrace and or balcony.");
			}
			loadTranslation([tenancyDescription, areaDescription, propertyDescription]);
		} else {
			setTranslatedTenancyDescription(tenancyDescription);
			setTranslatedAreaDescription(areaDescription);
			setTranslatedPropertyDescription(propertyDescription);
		}
	}, [i18n.resolvedLanguage, tenancyDescription, areaDescription, propertyDescription]);

	useEffect(() => {
		if (!isTranslationLoading) {
			translationData[0]
				? setTranslatedTenancyDescription(translationData[0])
				: setTranslatedTenancyDescription(tenancyDescription);
			translationData[1]
				? setTranslatedAreaDescription(translationData[1])
				: setTranslatedAreaDescription(areaDescription);
			translationData[2]
				? setTranslatedPropertyDescription(translationData[2])
				: setTranslatedPropertyDescription(propertyDescription);
		}
	}, [isTranslationLoading, translationData]);

	return (
		<Wrapper>
			<div className='top-section'>
				<Line />
				<Title>{t("tenancy.generalSection.title")}</Title>
			</div>
			<div className='info-wrapper'>
				<div className='descriptions-section'>
					{tenancyDescription && (
						<div className='description-container'>
							<p className='subtitle'>{t("tenancy.generalSection.tenancyDescription")}</p>
							<p className='description'>{translatedTenancyDescription}</p>
						</div>
					)}
					{areaDescription && (
						<div className='description-container'>
							<p className='subtitle'>{t("tenancy.generalSection.areaDescription")}</p>
							<p className='description'>{translatedAreaDescription}</p>
						</div>
					)}
					{propertyDescription && (
						<div className='description-container'>
							<p className='subtitle'>{t("tenancy.generalSection.propertyDescription")}</p>
							<p className='description'>{translatedPropertyDescription}</p>
						</div>
					)}
				</div>
				<div className='bullet-section'>
					<div className='info-section'>
						<div className='info-container'>
							<p className='name'>{t("tenancy.generalSection.address")}</p>
							<p className='value'>{street}</p>
						</div>
						<div className='info-container'>
							<p className='name'>{t("tenancy.generalSection.city")}</p>
							<p className='value'>
								{postnumber} {city}
							</p>
						</div>
						{classification !== "Other" && (
							<>
								<div className='info-container'>
									<p className='name'>{t("tenancy.generalSection.rooms")}</p>
									<p className='value'>{rooms}</p>
								</div>
								<div className='info-container'>
									<p className='name'>{t("tenancy.generalSection.size")}</p>
									<p className='value'>{size}</p>
								</div>
							</>
						)}
					</div>
					<div className='info-section'>
						<div className='info-container'>
							<p className='name'>{t("tenancy.generalSection.availableFrom")}</p>
							<p className='value'>{formatAvailability(availableFrom, t("tenancyCard.asap"))}</p>
						</div>
						<div className='info-container'>
							<p className='name'>{t("tenancy.generalSection.monthlyRent")}</p>
							<p className='value'>{formatPrice(monthlyRent)}</p>
						</div>
						<div className='info-container'>
							<p className='name'>{t("tenancy.generalSection.deposit")}</p>
							<p className='value'>{formatPrice(deposit)}</p>
						</div>
						<div className='info-container'>
							<p className='name'>{t("tenancy.generalSection.prepaidRent")}</p>
							<p className='value'>{formatPrice(prepaidRent)}</p>
						</div>
						{/* <div className="info-container">
							<p className="name">Price to move in</p>
							<p className="value">{size}</p>
						</div> */}
						{classification !== "Other" && (
							<>
								<div className='info-container'>
									<p className='name'>{t("tenancy.generalSection.heating")}</p>
									<p className='value'>{formatPrice(heating)}</p>
								</div>
								<div className='info-container'>
									<p className='name'>{t("tenancy.generalSection.water")}</p>
									<p className='value'>{formatPrice(water)}</p>
								</div>
								<div className='info-container'>
									<p className='name'>{t("tenancy.generalSection.petsAllowed")}</p>
									<p className='value'>{petsAllowed ? t("general.yes") : t("general.no")}</p>
								</div>
								<div className='info-container'>
									<p className='name'>{t("tenancy.generalSection.elevator")}</p>
									<p className='value'>{elevator ? t("general.yes") : t("general.no")}</p>
								</div>
								<div className='info-container'>
									<p className='name'>{t("tenancy.generalSection.balcony")}</p>
									<p className='value'>{balcony ? t("general.yes") : t("general.no")}</p>
								</div>
							</>
						)}
						{propertyLink && (
							<ButtonWrapper>
								<a href={propertyLink}>
									<Button>Om projektet</Button>
								</a>
							</ButtonWrapper>
						)}
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

GeneralSection.propTypes = {
	classification: PropTypes.string,
	tenancyDescription: PropTypes.string,
	areaDescription: PropTypes.string,
	propertyDescription: PropTypes.string,
	street: PropTypes.string,
	postnumber: PropTypes.string,
	city: PropTypes.string,
	rooms: PropTypes.number,
	size: PropTypes.number,
	availableFrom: PropTypes.string,
	monthlyRent: PropTypes.number,
	prepaidRent: PropTypes.number,
	deposit: PropTypes.number,
	heating: PropTypes.number,
	water: PropTypes.number,
	petsAllowed: PropTypes.bool,
	elevator: PropTypes.bool,
	balcony: PropTypes.bool,
	propertyLink: PropTypes.string,
	propertyId: PropTypes.string,
};

export default GeneralSection;
