import React, { useMemo } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import PropTypes from "prop-types";
import ImageMapIsommetry from "components/ImageMapIsometry";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fef6e5;
	background-size: cover;
	background-position: center;
	max-width: 1800px;
	margin: auto;
	padding-top: 50px;
	// clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%);
	max-width: 1280px;

	@media ${device.laptop} {
		// clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
		height: 100%;
	}
`;
const Line = styled.div`
	width: 80px;
	height: 5px;
	background-color: #c49a36;
	margin: auto;
	min-height: 5px;
`;
const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 140%;
	margin-top: 30px;
	text-align: center;
	color: #3e5042;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
`;
const Text = styled.p`
	color: #384336;
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	margin-bottom: 60px;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
`;

const Button = styled.div`
	cursor: pointer;
	margin-top: 31px;
	background-color: #003202;
	height: 42px;
	width: 263px;
	color: white;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;

	@media ${device.laptop} {
		top: -100px;
		position: relative;
		z-index: 100;
	}
`;

const ImageMapIsometrySection = (props) => {
	const { title, text, image, imageWidth, imageMapJSON, tenancies, loading } = props;

	const handleClick = () => {
		window.open("https://norsepm.dk/boliger/?q=Kong%20Georgs%20Vej,%202000%20Frederiksberg,%20Danmark", "_blank");
	};

	return (
		<Wrapper>
			<Title>{title}</Title>
			<Text>{text}</Text>
			<ImageMapIsommetry
				image={image}
				imageWidth={imageWidth}
				json={imageMapJSON}
				tenancies={tenancies}
				loading={loading}
			/>
			<Button onClick={handleClick}>Liste over ledige boliger</Button>
		</Wrapper>
	);
};

ImageMapIsometrySection.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	image: PropTypes.string,
	imageMapJSON: PropTypes.array,
	imageWidth: PropTypes.number,
	tenancies: PropTypes.array,
	loading: PropTypes.bool,
};

export default ImageMapIsometrySection;
