import React, { useRef, useEffect } from "react";
import mapboxgl from "!mapbox-gl";
import useSiteConfig from "features/site-config";
import styled from "styled-components";
import Line from "components/Line";
import Title from "components/Title";
import homeIconInversed from "assets/icons/locationHomeInversed.png";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";

const MapContainer = styled.div`
	padding: 32px 16px;
	box-sizing: border-box;
	width: 100%;
	max-width: 1200px;
	margin: auto;
	@media ${device.laptop} {
		padding: 60px 0px;
		padding-left: 80px;
		padding-right: 80px;
	}

	.map-container {
		width: 100%;
		height: 300px;

		@media ${device.laptop} {
			height: 500px;
		}
	}
	.mapboxgl-popup {
		z-index: 999;
	}
	.contact-info {
		margin-right: 16px;
		span {
			display: flex;
			align-items: center;
			padding: 16px 0px;
		}
		span.title {
			font-size: 24px;
			font-weight: 700;
		}
	}
	.contact-content {
		display: grid;
		grid-template-columns: 1fr;

		@media ${device.laptop} {
			grid-template-columns: 2fr 5fr;
		}
	}
	svg {
		stroke: ${(p) => p.theme.colors.ascent.secondary};
		align-self: center;
		margin-right: 24px;
	}
	a {
		color: #384336;
	}
`;

const ContactInformationSection = () => {
	const config = useSiteConfig();

	const mapContainer = useRef(null);
	const map = useRef(null);
	const { t } = useTranslation();
	mapboxgl.accessToken = config.mapBox.accessToken;

	useEffect(() => {
		if (map.current) return;
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/jorato/ckqp3hhht0onr18mklr1w6nf4",
			center: [12.589780640775398, 55.684105101009195],
			zoom: 15,
			pitch: 60,
			antialias: true,
		});

		map.current.once("load", () => {
			map.current.resize();
		});

		var marker = document.createElement("div");
		marker.className = "marker";
		marker.style.backgroundSize = "cover";
		marker.style.width = "40px";
		marker.style.height = "40px";
		marker.style.borderRadius = "40px";
		marker.style.zIndex = "999";
		marker.style.backgroundImage = `url(${homeIconInversed})`;

		new mapboxgl.Marker(marker)
			.setLngLat([12.589780640775398, 55.684105101009195])
			.addTo(map.current);
	});

	return (
		<MapContainer>
			<Line />
			<Title>{t("contactPage.title")}</Title>
			<div className='contact-content'>
				<div className='contact-info'>
					{/* <span>Norse Property Management A/S</span> */}
					<span>
						<PhoneIcon />
						<a href='tel:+4533331333'>+45 3333 1333</a>
					</span>
					<span>
						<EmailIcon />
						<a href='mailto:hello@norsepm.dk'>hello@norsepm.dk</a>
					</span>
					<span>
						<LocationIcon />
						Bredgade 45A, 2. sal
						<br />
						1260 København K
					</span>
					<span className='line' />
					<span className='title'>
						{t("contactPage.regionalOffice")}
					</span>
					<span>
						<LocationIcon />
						Marsallé 111
						<br />
						8700 Horsens
					</span>
					<span>
						<LocationIcon />
						Gøteborg Allé 7B, st.
						<br />
						8200 Aarhus
					</span>
				</div>
				<div ref={mapContainer} className='map-container' />
			</div>
		</MapContainer>
	);
};

export default ContactInformationSection;
