import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';
import ImageMap from 'components/ImageMap';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';

const Wrapper = styled.div`
	padding-top: 120px;
	display: flex;
	flex-direction: column;
	max-width: 1280px;
	margin: auto;
`;
const Line = styled.div`
	width: 80px;
	height: 5px;
	background-color: #C49A36;
	margin: auto;	
`;
const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 140%;
	margin-top: 30px;
	text-align: center;	
	color: #3E5042;
	max-width: 350px;
	margin-left: auto;
    margin-right: auto;
`;
const Text = styled.p`
	color: #384336;
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	margin-bottom: 60px;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
`;
const Container = styled.div`
	position: relative;
	min-height: 300px;
	@media ${device.tablet} {
		min-height: 400px;
	}
	@media ${device.laptop} {
		min-height: 720px;
	}
`;
const ReturnButton = styled.div`
	width: 70px;
	height: 70px;
	background-color: #C49A36;
	position: absolute;
	top: 50px;
	left: 50px;
	z-index: 10;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	svg {
		stroke: #fff;
		width: 30px;
		height: 30px;
		transform: scaleX(-1);
	}
`; 


const IsometrySelectorSection = (props) => {

	const {
		title,
		text,
		imageMapJSON,
		imageMapImage, // should be 1280px width
		imageMapOnClick,
		activeIsometry, // index of active isometry
		isometries, // array of isometries
		onReturnClick
	} = props;

	return (
		<Wrapper>
			<Line />
			<Title>{title}</Title>
			<Text>{text}</Text>
			<Container>
				{ activeIsometry !== null && <ReturnButton onClick={onReturnClick}><ArrowIcon /></ReturnButton> }
				{ activeIsometry === null && <ImageMap json={imageMapJSON} image={imageMapImage} onClick={imageMapOnClick} /> }
				{ activeIsometry !== null && isometries[activeIsometry]}
			</Container>
		</Wrapper>
	);
};

IsometrySelectorSection.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	imageMapJSON: PropTypes.array,
	imageMapImage: PropTypes.string,
	imageMapOnClick: PropTypes.func,
	activeIsometry: PropTypes.number,
	isometries: PropTypes.array,
	onReturnClick: PropTypes.func
};

export default IsometrySelectorSection;