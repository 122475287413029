import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
	font-weight: 700;
	min-width: 209px;
	height: 50px;
	padding: 0px 16px;
	border: unset;
	background: #C49A36;
	font-size: 16px;
	color: white;
	transition-duration: 1s;
	text-transform: uppercase;
	outline: none;

	img {
		padding-left: 16px;
		transition-duration: 0.4s;
	}

	&:hover {
		cursor: pointer;
		img {
			padding-left: 32px;
		}
	}
`;

const Button = props => {
	return (
		<StyledButton style={props.style} onClick={props.onClick}>
			{props.children}
		</StyledButton>
	);
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func,
	style: PropTypes.object,
};

export default Button;