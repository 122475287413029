const TenancyBoxes = [
	//  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------
	{
		position: [43.2, 8.2, -30.0],
		scale: [4.2, 3.2, 6],
		id: '4b7d1255-83d0-4403-ad43-f03b412e470d',
		comment: 'Smedetoften 8, 1. Dør 07 - Lej nr. arkitekt 1.20'
	},
	{
		position: [47.4, 8.2, -30.0],
		scale: [4.2, 3.2, 6],
		id: '17e43f07-d0d3-4659-9bd1-4a342972af57',
		comment: 'Smedetoften 8, 1. Dør 06 - Lej nr. arkitekt 1.19'
	},
	{
		position: [51.6, 8.2, -30.0],
		scale: [4.2, 3.2, 6],
		id: '45537545-19d6-48e1-800e-8b3b7a433067',
		comment: 'Smedetoften 8, 1. Dør 05 - Lej nr. arkitekt 1.18'
	},
	{
		position: [55.8, 8.2, -30.0],
		scale: [4.2, 3.2, 6],
		id: '8bd4a052-4a1a-4152-b9ca-59f82a33a072',
		comment: 'Smedetoften 8, 1. Dør 04 - Lej nr. arkitekt 1.17'
	},
	{
		position: [60.0, 8.2, -30.0],
		scale: [4.2, 3.2, 6],
		id: '22de6f2c-f64a-40b6-abde-653d972ad9d4',
		comment: 'Smedetoften 8, 1. Dør 03 - Lej nr. arkitekt 1.16'
	},
	{
		position: [64.2, 8.2, -30.0],
		scale: [4.2, 3.2, 6],
		id: '13d90747-a23f-4829-982c-735015f18a03',
		comment: 'Smedetoften 8, 1. Dør 02 - Lej nr. arkitekt 1.15'
	},
	{
		position: [68.8, 8.2, -30.0],
		scale: [5.0, 3.2, 6],
		id: 'e9295894-9a67-494a-996f-8ca834056436',
		comment: 'Smedetoften 8, 1. Dør 01 - Lej nr. arkitekt 1.14'
	},
	{
		position: [66.1, 8.2, -37.2],
		scale: [5.2, 3.2, 6],
		id: 'e5840c25-a943-4d05-ba6c-ac8b1572b132',
		comment: 'Smedetoften 8, 1. Dør 24 - Lej nr. arkitekt 1.13'
	},
	{
		position: [61.0, 8.2, -37.2],
		scale: [5.0, 3.2, 6],
		id: '00fa73ca-c629-4cbf-8b34-bb6785177c82',
		comment: 'Smedetoften 8, 1. Dør 23 - Lej nr. arkitekt 1.12'
	},
	{
		position: [55.0, 8.2, -37.2],
		scale: [7.0, 3.2, 6],
		id: '1d9ba18e-172c-4c44-be9c-dd8b5f8603e2',
		comment: 'Smedetoften 8, 1. Dør 22 - Lej nr. arkitekt 1.11'
	},
	{
		position: [53.4, 8.6, -43.2],
		scale: [7.0, 3.8, 6],
		id: '30e3890b-a0e6-45b2-b025-d05ab5194669',
		comment: 'Smedetoften 8, 1. Dør 21 - Lej nr. arkitekt 1.10'
	},
	{
		position: [47.9, 8.6, -43.2],
		scale: [4.0, 3.8, 6],
		id: '9bc89e09-19f6-432e-b1c1-93f06ffca616',
		comment: 'Smedetoften 8, 1. Dør 20 - Lej nr. arkitekt 1.09'
	},
	{
		position: [43.8, 8.6, -43.2],
		scale: [4.2, 3.8, 6],
		id: 'ec6e1498-92b7-4d48-848b-f815f2fba415',
		comment: 'Smedetoften 8, 1. Dør 19 - Lej nr. arkitekt 1.08'
	},
	{
		position: [39.7, 8.6, -43.2],
		scale: [4.0, 3.8, 6],
		id: '2776a490-581d-447f-863a-2b1e98cf2e13',
		comment: 'Smedetoften 8, 1. Dør 18 - Lej nr. arkitekt 1.07'
	},
	{
		position: [35.6, 8.6, -43.2],
		scale: [4.2, 3.8, 6],
		id: 'be33fae7-c892-452b-8ec4-cdad3e7759f8',
		comment: 'Smedetoften 8, 1. Dør 17 - Lej nr. arkitekt 1.06'
	},
	{
		position: [31.5, 8.6, -43.2],
		scale: [4.0, 3.8, 6],
		id: '417942a9-9892-4ef0-af9b-7da51ffaccd5',
		comment: 'Smedetoften 8, 1. Dør 16 - Lej nr. arkitekt 1.05'
	},
	{
		position: [27.5, 8.6, -43.2],
		scale: [4.0, 3.8, 6],
		id: 'e725dd1f-9d5f-43b1-b26d-866abfa801ba',
		comment: 'Smedetoften 8, 1. Dør 15 - Lej nr. arkitekt 1.04'
	},
	{
		position: [23.4, 8.6, -43.2],
		scale: [4.2, 3.8, 6],
		id: '29a0f6d1-492e-4514-8fce-d1b95cbaacb5',
		comment: 'Smedetoften 8, 1. Dør 14 - Lej nr. arkitekt 1.03'
	},
	{
		position: [16.9, 8.6, -43.2],
		scale: [8.8, 3.8, 6],
		id: '8fc15776-09a7-4815-bbfb-784c766d070c',
		comment: 'Smedetoften 8, 1. Dør 13 - Lej nr. arkitekt 1.02'
	},
	{
		position: [14.7, 8.6, -24.1],
		scale: [5.0, 3.8, 6],
		id: 'be2ceaa7-69ec-4f17-b730-ba57bf6e8f52',
		comment: 'Smedetoften 8, 1. Dør 12 - Lej nr. arkitekt 1.25'
	},
	{
		position: [19.3, 8.6, -24.1],
		scale: [4.2, 3.8, 6],
		id: '65209e3d-cc71-4294-bdcc-350ae7c76647',
		comment: 'Smedetoften 8, 1. Dør 11 - Lej nr. arkitekt 1.24'
	},
	{
		position: [23.40, 8.6, -24.1],
		scale: [4.0, 3.8, 6],
		id: '56ddee0d-1815-490f-a05a-bad799262f70',
		comment: 'Smedetoften 8, 1. Dør 10 - Lej nr. arkitekt 1.23'
	},
	{
		position: [27.90, 8.6, -24.1],
		scale: [5.0, 3.8, 6],
		id: '450b65e4-7e55-4ae5-999d-001f01aca96b',
		comment: 'Smedetoften 8, 1. Dør 09 - Lej nr. arkitekt 1.22'
	},
	{
		position: [32.8, 8.6, -24.1],
		scale: [4.8, 3.8, 6],
		id: '18d11448-e933-4eff-8ddd-6f3282263e26',
		comment: 'Smedetoften 8, 1. Dør 08 - Lej nr. arkitekt 1.21'
	},
	//  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------
	{
		position: [22.05, 11.3, -30.1],
		scale: [4.55, 3.0, 6],
		id: 'e16cf06e-45ca-40b0-92b8-4976ff988015',
		comment: 'Smedetoften 8, 2. Dør 12 - Lej nr. arkitekt 2.21'
	},
	{
		position: [26.45, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '672ac420-ac82-4738-b0a4-07c8c9588844',
		comment: 'Smedetoften 8, 2. Dør 11 - Lej nr. arkitekt 2.20'
	},
	{
		position: [30.65, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'ecc504a9-07e3-40f2-8ba3-c83088868dc0',
		comment: 'Smedetoften 8, 2. Dør 10 - Lej nr. arkitekt 2.19'
	},
	{
		position: [34.85, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'eb4e8de3-de45-4fdd-81f9-0ce4232d2917',
		comment: 'Smedetoften 8, 2. Dør 09 - Lej nr. arkitekt 2.18'
	},
	{
		position: [39.05, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'bfa134c1-e223-47f2-afa6-8b5c60650e22',
		comment: 'Smedetoften 8, 2. Dør 08 - Lej nr. arkitekt 2.17'
	},
	{
		position: [43.25, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'c419eebf-c9e4-4c27-bd66-b01875a70d6a',
		comment: 'Smedetoften 8, 2. Dør 07 - Lej nr. arkitekt 2.16'
	},
	{
		position: [47.45, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '99fe5a35-d03f-4488-90e0-b72a0ca46fb2',
		comment: 'Smedetoften 8, 2. Dør 06 - Lej nr. arkitekt 2.15'
	},
	{
		position: [51.65, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '0567dc95-0dc5-4679-831e-454b5fb7eee7',
		comment: 'Smedetoften 8, 2. Dør 05 - Lej nr. arkitekt 2.14'
	},
	{
		position: [55.85, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '09efb2da-47be-489f-93c2-690649bbb8b1',
		comment: 'Smedetoften 8, 2. Dør 04 - Lej nr. arkitekt 2.13'
	},
	{
		position: [60.05, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'ccf79a88-84ae-4718-8a3c-568395a54aaa',
		comment: 'Smedetoften 8, 2. Dør 03 - Lej nr. arkitekt 2.12'
	},
	{
		position: [64.25, 11.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'f37113ce-c15f-47bb-9c73-40b44de3b86b',
		comment: 'Smedetoften 8, 2. Dør 02 - Lej nr. arkitekt 2.11'
	},
	{
		position: [68.80, 11.3, -30.1],
		scale: [4.90, 3.0, 6],
		id: '87d00b5f-93b3-4f6a-8651-f3be9f4948aa',
		comment: 'Smedetoften 8, 2. Dør 01 - Lej nr. arkitekt 2.10'
	},
	{
		position: [66.25, 11.3, -37.2],
		scale: [5.10, 3.0, 6],
		id: 'a02873f7-65c0-4665-aac0-e894dda1bf2e',
		comment: 'Smedetoften 8, 2. Dør 21 - Lej nr. arkitekt 2.09'
	},
	{
		position: [61.15, 11.3, -37.2],
		scale: [5.10, 3.0, 6],
		id: '41336932-cbd4-4e06-bad2-1aab1215698e',
		comment: 'Smedetoften 8, 2. Dør 20 - Lej nr. arkitekt 2.08'
	},
	{
		position: [56.05, 11.3, -37.2],
		scale: [5.10, 3.0, 6],
		id: 'ba49be59-b609-453b-9b31-cf3154644e94',
		comment: 'Smedetoften 8, 2. Dør 19 - Lej nr. arkitekt 2.07'
	},
	{
		position: [50.85, 11.3, -37.2],
		scale: [5.30, 3.0, 6],
		id: '2884f527-1f19-4748-9936-7d11a8690557',
		comment: 'Smedetoften 8, 2. Dør 18 - Lej nr. arkitekt 2.06'
	},
	{
		position: [45.60, 11.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: '18079e1f-bcb1-4184-8e88-74ece9644f2d',
		comment: 'Smedetoften 8, 2. Dør 17 - Lej nr. arkitekt 2.05'
	},
	{
		position: [40.40, 11.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: 'c9f970ce-fbaa-4fd3-a9cd-3ef47f35bf09',
		comment: 'Smedetoften 8, 2. Dør 16 - Lej nr. arkitekt 2.04'
	},
	{
		position: [35.20, 11.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: '985b5a36-2b95-49aa-b86e-8d2b262dd667',
		comment: 'Smedetoften 8, 2. Dør 15 - Lej nr. arkitekt 2.03'
	},
	{
		position: [30.00, 11.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: '2cd935bc-38e1-479d-b17e-f41c91521588',
		comment: 'Smedetoften 8, 2. Dør 14 - Lej nr. arkitekt 2.02'
	},
	{
		position: [24.80, 11.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: 'a347ec06-8fc6-4874-8f23-f927f88447b3',
		comment: 'Smedetoften 8, 2. Dør 13 - Lej nr. arkitekt 2.01'
	},
	//  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------
	{
		position: [22.05, 14.3, -30.1],
		scale: [4.55, 3.0, 6],
		id: '416540f6-f234-491d-b717-4a7e9bc73e70',
		comment: 'Smedetoften 8, 3. Dør 12 - Lej nr. arkitekt 3.21'
	},
	{
		position: [26.45, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'e118cf2f-7379-4971-8027-be45ea9a88c6',
		comment: 'Smedetoften 8, 3. Dør 11 - Lej nr. arkitekt 3.20'
	},
	{
		position: [30.65, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'aa8b3516-f4f7-4b83-bb3b-22c188374804',
		comment: 'Smedetoften 8, 3. Dør 10 - Lej nr. arkitekt 3.19'
	},
	{
		position: [34.85, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '44850590-987a-4906-9705-530255e7e180',
		comment: 'Smedetoften 8, 3. Dør 09 - Lej nr. arkitekt 3.18'
	},
	{
		position: [39.05, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '7617edfe-7eb5-4b64-ae05-c562a5f77ba9',
		comment: 'Smedetoften 8, 3. Dør 08 - Lej nr. arkitekt 3.17'
	},
	{
		position: [43.25, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '4075f956-c3be-4ab6-bdf8-d85675f39fa3',
		comment: 'Smedetoften 8, 3. Dør 07 - Lej nr. arkitekt 3.16'
	},
	{
		position: [47.45, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '4adcc892-45bf-4f25-baae-88540c68e792',
		comment: 'Smedetoften 8, 3. Dør 06 - Lej nr. arkitekt 3.15'
	},
	{
		position: [51.65, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'cc6b7986-a241-40b9-9b9d-79f590365cd5',
		comment: 'Smedetoften 8, 3. Dør 05 - Lej nr. arkitekt 3.14'
	},
	{
		position: [55.85, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '60c945e7-f72b-408f-a443-dec3204ca9c8',
		comment: 'Smedetoften 8, 3. Dør 04 - Lej nr. arkitekt 3.13'
	},
	{
		position: [60.05, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: 'aab67164-091f-45b2-9a33-4b7df18c2b63',
		comment: 'Smedetoften 8, 3. Dør 03 - Lej nr. arkitekt 3.12'
	},
	{
		position: [64.25, 14.3, -30.1],
		scale: [4.20, 3.0, 6],
		id: '849f1e85-acc6-45bd-86a4-4c1289d51463',
		comment: 'Smedetoften 8, 3. Dør 02 - Lej nr. arkitekt 3.11'
	},
	{
		position: [68.80, 14.3, -30.1],
		scale: [4.90, 3.0, 6],
		id: '8da922ee-16c7-4677-bd4e-766da7633bd3',
		comment: 'Smedetoften 8, 3. Dør 01 - Lej nr. arkitekt 3.10'
	},
	{
		position: [66.25, 14.3, -37.2],
		scale: [5.10, 3.0, 6],
		id: '07b552b2-9f76-428b-af1e-4a2ffe118647',
		comment: 'Smedetoften 8, 3. Dør 21 - Lej nr. arkitekt 3.09'
	},
	{
		position: [61.15, 14.3, -37.2],
		scale: [5.10, 3.0, 6],
		id: 'b2bc4c40-6d75-448b-a73c-fe385e187893',
		comment: 'Smedetoften 8, 3. Dør 20 - Lej nr. arkitekt 3.08'
	},
	{
		position: [56.05, 14.3, -37.2],
		scale: [5.10, 3.0, 6],
		id: 'd228f370-8e7c-4619-97a4-7c7945c3240e',
		comment: 'Smedetoften 8, 3. Dør 19 - Lej nr. arkitekt 3.07'
	},
	{
		position: [50.85, 14.3, -37.2],
		scale: [5.30, 3.0, 6],
		id: 'f27a5c01-1cef-4435-8d31-193942e09c53',
		comment: 'Smedetoften 8, 3. Dør 18 - Lej nr. arkitekt 3.06'
	},
	{
		position: [45.60, 14.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: '8d629261-467a-4d97-bc6a-0e551b0cbff1',
		comment: 'Smedetoften 8, 3. Dør 17 - Lej nr. arkitekt 3.05'
	},
	{
		position: [40.40, 14.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: '7233bca4-89d3-4618-a46f-914179341a28',
		comment: 'Smedetoften 8, 3. Dør 16 - Lej nr. arkitekt 3.04'
	},
	{
		position: [35.20, 14.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: 'e28a9658-17e4-4cf5-b693-2411635a9270',
		comment: 'Smedetoften 8, 3. Dør 15 - Lej nr. arkitekt 3.03'
	},
	{
		position: [30.00, 14.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: '9fbd75ab-eb93-45ae-b9da-4def78558ca6',
		comment: 'Smedetoften 8, 3. Dør 14 - Lej nr. arkitekt 3.02'
	},
	{
		position: [24.80, 14.3, -37.2],
		scale: [5.20, 3.0, 6],
		id: '22059305-dd80-4999-b6e8-86c78b0738d4',
		comment: 'Smedetoften 8, 3. Dør 13 - Lej nr. arkitekt 3.01'
	},
];
  
export default TenancyBoxes;