import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const HeroWrapper = styled.div`
	min-height: 70vh;
	width: 100%;
	background-image: url(${(props) => props.background});
	background-size: auto;
	background-position: bottom;
	@media ${device.laptop} { 
		background-size: cover;
	}
	display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Container = styled.div`
	
    background-color: rgb(56 67 54 / 80%);
	top: 30%;
	padding: 50px;
	box-sizing: border-box;
	margin: 16px;
	

	@media ${device.laptop} {
		font-size: 50px;
		width: 540px;
		left: 100px;
		top: unset;
		bottom: 20%;
		margin: unset;
		margin-top: 40px;
		margin-bottom: 40px;
		margin-left: 10%;
	}
`;

const Title = styled.h1`
	color: white;
	font-size: 34px;
	padding-bottom: 24px;
	margin: unset;
	@media ${device.laptop} { 
		font-size: 50px;
	}
`;

const Subtitle = styled.h2`
	display: none;
	color: white;
	font-size: 24px;
	padding-bottom: 24px;
	font-weight: normal;
	margin: unset;
	margin-right: 60px;
	@media ${device.tablet} { 
		display: block;
	}
	@media ${device.laptop} { 
		font-size: 20px;
	}
`;

const HeroSection2 = (props) => {

	const {
		background,
		title,
		text,
		buttons,
	} = props;

	return (
		<HeroWrapper background={background}>
			<Container>
				<Title>{title}</Title>
				<Subtitle>{text}</Subtitle>
				{buttons}
			</Container>
		</HeroWrapper>
	);
};

HeroSection2.propTypes = {
	background: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	buttons: PropTypes.object,
};

export default HeroSection2;