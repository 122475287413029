const imageMapJSON = [
	// {
	// 	'id': '1',
	// 	'title': '112',
	// 	'shape': 'poly',
	// 	'name': '3',
	// 	'fillColor': 'rgba(56, 67, 54, 0.5)',
	// 	'strokeColor': 'black',
	// 	'preFillColor': 'transparent',
	// 	'coords': [569, 920, 567, 904, 576, 873, 562, 827, 592, 732, 677, 730, 650, 827, 661, 889, 650, 922]
	// },
	{
		'id': '2',
		'title': '114',
		'shape': 'poly',
		'name': '3',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': 'black',
		'preFillColor': 'transparent',
		'coords': [729, 922, 652, 922, 664, 882, 652, 830, 677, 731, 746, 729, 723, 821, 745, 884],
		"joratoId": "d33741e4-3620-42f3-9775-ad5158ccba9b"
	},
	{
		'id': '3',
		'title': '116',
		'shape': 'poly',
		'name': '3',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': 'black',
		'preFillColor': 'transparent',
		'coords': [732, 922, 747, 886, 724, 817, 749, 732, 831, 730, 808, 825, 820, 884, 809, 922],
		"joratoId": "cadc868c-7e25-4d41-b9f1-21a85d240e06"
	},
	{
		'id': '4',
		'title': '118',
		'shape': 'poly',
		'name': '3',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': 'black',
		'preFillColor': 'transparent',
		'coords': [886, 922, 895, 884, 884, 825, 900, 730, 831, 730, 807, 830, 818, 880, 807, 922],
		"joratoId": "b2d34cae-6581-41bd-aaec-34b21fad7361"
	},
	{
		'id': '5',
		'title': '120',
		'shape': 'poly',
		'name': '3',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': 'black',
		'preFillColor': 'transparent',
		'coords': [971, 919, 883, 921, 897, 883, 888, 826, 901, 731, 978, 729, 965, 822, 976, 885],
		"joratoId": "e538d021-7abc-45e5-98d5-c9fd3e7072a4"
	},
	// {
	// 	'id': '6',
	// 	'title': '122',
	// 	'shape': 'poly',
	// 	'name': '3',
	// 	'fillColor': 'rgba(56, 67, 54, 0.5)',
	// 	'strokeColor': 'black',
	// 	'preFillColor': 'transparent',
	// 	'coords': [1046, 918, 971, 919, 978, 878, 967, 822, 976, 729, 1057, 729, 1044, 817, 1051, 880]
	// },
	{
		'id': '7',
		'title': '124',
		'shape': 'poly',
		'name': '3',
		'fillColor': 'rgba(56, 67, 54, 0.5)',
		'strokeColor': 'black',
		'preFillColor': 'transparent',
		'coords': [1125, 916, 1050, 918, 1054, 879, 1046, 823, 1055, 730, 1132, 730, 1127, 821, 1127, 882],
		'joratoId': '0e6c0399-e81f-4896-ba7a-03156fadf40b'
	},
	// {
	// 	'id': '8',
	// 	'title': '126',
	// 	'shape': 'poly',
	// 	'name': '3',
	// 	'fillColor': 'rgba(56, 67, 54, 0.5)',
	// 	'strokeColor': 'black',
	// 	'preFillColor': 'transparent',
	// 	'coords': [1208, 916, 1123, 919, 1130, 882, 1127, 825, 1134, 730, 1211, 730]
	// },
	{
		"id": "9",
		"title": "128",
		"shape": "poly",
		"name": "3",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "transparent",
		"coords": [1070, 1196, 1084, 987, 1232, 984, 1230, 1190],
		"joratoId": "17be4dae-ca97-49cd-a561-a1899021002a"
	},
	{
		"id": "10",
		"title": "130",
		"shape": "poly",
		"name": "3",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "transparent",
		"coords": [1375, 1187, 1232, 1190, 1235, 984, 1367, 981],
		"joratoId": "5fec3e83-bea6-48b1-a811-a4cecc66d065"
	},
	{
		"id": "11",
		"title": "132",
		"shape": "poly",
		"name": "3",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "transparent",
		"coords": [1515, 1186, 1375, 1186, 1370, 982, 1493, 982],
		"joratoId": "9a1e0974-e4b5-45bf-b46b-a638c315baae"
	},
	{
		"id": "12",
		"title": "134",
		"shape": "poly",
		"name": "3",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "transparent",
		"coords": [1669, 1183, 1515, 1186, 1496, 982, 1639, 977],
		"joratoId": "eab36048-af49-423a-a426-aedc9116bc87"
	},
	{
		"id": "13",
		"title": "136",
		"shape": "poly",
		"name": "3",
		"fillColor": "rgba(56, 67, 54, 0.5)",
		"strokeColor": "black",
		"preFillColor": "transparent",
		"coords": [1795, 938, 1768, 823, 1787, 751, 1870, 754, 1886, 848, 1875, 905, 1878, 941],
		"joratoId": "6cd76c66-0f58-4bfe-a802-9ecefca2175e"
	}
];
export default imageMapJSON; 