// @ts-nocheck

import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ImageMapper from "react-img-mapper";
import RotateToLandScapeAnimation from "assets/lotties/rotate-to-landscape.json";
import { Player } from "@lottiefiles/react-lottie-player";
import TenancyCardSmall from "components/TenancyCardSmall";
import GenericPopup from "components/GenericPopup";
import { device } from 'helpers/device';

const Wrapper = styled.div`
	width: 100%;
	border: none;
	max-width: 1280px;
	margin: auto;
	position: relative;

	area {
		display: block;
		position: absolute;
	}

	area::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 255, 0.3);
	}
`;
const AnimationWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
`;


const TenancyCardWrapper = styled.div`
	position: absolute;
	z-index: 10;
	left: 5%;
	top: 5%;

	@media ${device.laptop} {
		
	}
`;

const ImageMapIsommetry = (props) => {
	const [parentWidth, setParentWidth] = useState(0);
	const [selectedTenancyId, setSelectedTenancyId] = useState(null);
	const imgMapperRef = useRef();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [modalDescription, setModalDescription] = useState('');

	const displayChangeOrientationIcon = window.matchMedia(
		"(max-width: 768px) and (orientation: portrait)"
	).matches;

	const {
		imageWidth,
		json,
		image, // should be 1280px width
		tenancies,
		loading,
	} = props;

	useEffect(() => {
		function updateSize() {
			const parentNode = imgMapperRef.current.parentNode;
			const parentStyle = window.getComputedStyle(parentNode);
			setParentWidth(parseFloat(parentStyle.width));
		}

		window.addEventListener("resize", updateSize);
		updateSize(); // initialize size

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	const updatedJson = useMemo(() => {
		if (!loading && tenancies) {
			const updated = json.map((b) => {
				if (b.joratoId) {
					const data = tenancies.find(
						(tenancy) => b.joratoId === tenancy.id
					);
					if (data) {
						if (data.state === "Available") {
							b.preFillColor = "rgb(50 205 50 / 50%)";
							b.fillColor = "rgb(50 205 50 / 50%)";
							b.state = "Active";
						} else if (data.state === "Reserved") {
							b.preFillColor = "rgb(100 108 255 / 50%)";
							b.fillColor = "rgb(100 108 255 / 50%)";
							b.state = "Reserved";
							b.active = false;
						}
					} else {
						b.preFillColor = "rgb(255 69 0 / 50%)";
						b.fillColor = "rgb(255 69 0 / 50%)";
						b.state = "Unavailable";
						b.active = false;
					}
				} else {
					b.state = "NotForRent";
					b.active = false;
				}
				return b;
			});
			return updated;
		} else {
			return [];
		}
	}, [tenancies, loading]);

	const selectedTenancy = useMemo(() => {
		if (!loading && tenancies) {
			const tenancy = tenancies.find(t => t.id === selectedTenancyId);
			if (tenancy) {
				return tenancy;
			}
		}
		return null;
	}, [selectedTenancyId, tenancies, loading]);

	const onClick = (e) => {
		if (e.state === "Active") {
			setSelectedTenancyId(e.joratoId);
		} else {
			setSelectedTenancyId(null);
		}
	};

	return (
		<Wrapper ref={imgMapperRef}>
			<AnimationWrapper
				style={{
					display: displayChangeOrientationIcon ? "bloack" : "none",
				}}
			>
				<Player
					autoplay
					loop
					src={RotateToLandScapeAnimation}
					style={{ height: "150px", width: "150px" }}
				></Player>
			</AnimationWrapper>
			<ImageMapper
				src={image}
				map={{
					name: "my-map",
					areas: updatedJson,
				}}
				width={imageWidth}
				imgWidth={imageWidth}
				onClick={onClick}
				responsive={true}
				parentWidth={parentWidth}
				areaStyles={(area, index) => ({
					cursor: area.isActive ? "pointer" : "default",
				})}
			/>
			
			{selectedTenancyId && (
				<TenancyCardWrapper>
					<TenancyCardSmall
						key={selectedTenancy.id}
						name={selectedTenancy.title}
						image={selectedTenancy.images[0]?.original}
						price={selectedTenancy.monthlyRent.value}
						availableFrom={selectedTenancy.availableFrom}
						rooms={selectedTenancy.rooms}
						size={selectedTenancy.size.value}
						id={selectedTenancy.id}
						street={selectedTenancy.address.street}
						floorplan={selectedTenancy.floorPlans[0]?.url}
						floorplanImageMode={true}
					/>
				</TenancyCardWrapper>
			)}
		</Wrapper>
	);
};

ImageMapIsommetry.propTypes = {
	imageWidth: PropTypes.number,
	json: PropTypes.array,
	image: PropTypes.string,
	tenancies: PropTypes.array,
	loading: PropTypes.bool,
};

ImageMapIsommetry.defaultProps = {
	imageWidth: 1280,
	json: [],
	image: "",
	tenancies: [],
	loading: true,
};

export default ImageMapIsommetry;
