import React, { useState } from 'react';
import styled from 'styled-components';
import background from 'assets/images/hero-background.jpg';
import SideDrawer from 'components/SideDrawer';
import SortButton from './SortButton';
import FilterButton from './FilterButton';
import SearchBox from 'components/SearchBox';
import DisplayModeButton from './DisplayModeButton';
import ImageModeButton from './ImageModeButton';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	width: 100%;
	height: 424px;
	margin: auto;
	background-image: url(${background});
	background-size: auto;
	position: relative;

	@media ${device.laptop} { 
		background-size: cover;
		height: 578px;
	}

	h1 {
		color: white;
		font-size: 32px;
		text-align: center;
		padding-top: 144px;
		padding-bottom: 24px;
		margin: unset;

		@media ${device.laptop} {
			font-size: 64px;
			padding-top: 206px;
		}
	}

	div.search-container {
		padding-top: 24px;
		display: flex;
		direction: column;
		margin: auto;
	}

	// input {
	// 	width: 414px;
	// 	height: 21px;
	// 	padding: unset;
	// 	border: unset;
	// 	border: 2px solid #384336;
	// 	padding: 13px 24px 12px 24px 
	// }

	button#search {
		width: 211px;
		height: 50px;
		padding: unset;
		border: unset;
		background: #384336;
		font-size: 16px;
		color: white;
	}

	div.controls-container {
		height: 50px;
		background: #D7D9D7;
		box-shadow: 0px 32px 34px rgba(0, 0, 0, 0.133714);
		z-index: 10;
		margin-right: auto;
		margin-left: auto;
		display: grid;
		grid-template-columns: auto auto auto;
		grid-column-gap: 2px;
		margin-top: 24px;
		width: 100%;
		position: absolute;
		bottom: 0px;
		left: 0; 
  		right: 0; 
  

		@media ${device.laptop} { 
			width: 960px;
			margin-top: 91px;
			grid-template-columns: auto auto auto auto;
			height: 100px;
			margin-top: 0px;
		}

		> button {
			cursor: pointer;
			color: ${p => p.theme.colors.ascent.primary};
			height: 50px;
			font-size: 17px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: -0.427179px;
			border: unset;
			background-color: white;
			z-index: 9999999;

			&:hover {
				background-color: ${p => p.theme.colors.ascent.primary};
				color: white;	
			}

			@media ${device.laptop} {
				width: 238px;
				height: 100px;
				font-weight: 600;
			}
			
		}

		.hidden {
			display: none;
		}
	}
`;

const ControlsSection = (props) => {

	const { 
		updateSelectedPlace, 
		searchQuery, 
		setSearchQuery, 
		sortOptions, 
		selectedSortOption, 
		setSelectedSortOption, 
		gridViewMode,
		setGridViewMode,
		floorplanImageMode,
		setFloorplanImageMode,
		rentFilterValue,
		setRentFilterValue,
		sizeFilterValue,
		setSizeFilterValue,
		roomsFilterValue,
		setRoomsFilterValue,
		defaultFilterValues,
		balconyFilterValue,
		setBalconyFilterValue,
		petsFilterValue,
		setPetsFilterValue,
		elevatorFilterValue,
		setElevatorFilterValue,
		updateUrl,
		acquisitionFilterValue,
		setAcquisitionFilterValue

	} = props;
	const { t } = useTranslation();
	const [filterOpen, setFilterOpen] = useState(false);
	const [sortOpen, setSortOpen] = useState(false);

	const toggleFilter = () => {
		setFilterOpen(!filterOpen);
	};

	return (
		<Wrapper>
			<SideDrawer 
				visible={filterOpen} 
				setVisible={setFilterOpen} 
				rentFilterValue={rentFilterValue}
				setRentFilterValue={setRentFilterValue}
				sizeFilterValue={sizeFilterValue}
				setSizeFilterValue={setSizeFilterValue}
				roomsFilterValue={roomsFilterValue}
				setRoomsFilterValue={setRoomsFilterValue}
				defaultFilterValues={defaultFilterValues}
				balconyFilterValue={balconyFilterValue}
				setBalconyFilterValue={setBalconyFilterValue}
				petsFilterValue={petsFilterValue}
				setPetsFilterValue={setPetsFilterValue}
				elevatorFilterValue={elevatorFilterValue}
				setElevatorFilterValue={setElevatorFilterValue}
				acquisitionFilterValue={acquisitionFilterValue}
				setAcquisitionFilterValue={setAcquisitionFilterValue}
			/>
			<h1>{t('home.title')}</h1>
			<SearchBox 
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				updateSelectedPlace={updateSelectedPlace}
				updateUrl={updateUrl}
			/>
			<div className="controls-container">
				<FilterButton 
					onClick={toggleFilter}
				/>
				<SortButton 
					visible={sortOpen} 
					setVisible={setSortOpen}
					options={sortOptions} 
					value={selectedSortOption} 
					setValue={setSelectedSortOption}  
				/>
				<DisplayModeButton
					gridViewMode={gridViewMode}
					setGridViewMode={setGridViewMode}
				/>
				<ImageModeButton
					floorplanImageMode={floorplanImageMode}
					setFloorplanImageMode={setFloorplanImageMode}
				/>
			</div>
		</Wrapper>
	);
};

ControlsSection.propTypes = {
	updateSelectedPlace: PropTypes.func,
	searchQuery: PropTypes.string,
	setSearchQuery: PropTypes.func,
	sortOptions: PropTypes.array,
	selectedSortOption: PropTypes.object,
	setSelectedSortOption: PropTypes.func,
	gridViewMode: PropTypes.bool,
	setGridViewMode: PropTypes.func,
	floorplanImageMode: PropTypes.bool,
	setFloorplanImageMode: PropTypes.func,
	rentFilterValue: PropTypes.array,
	setRentFilterValue: PropTypes.func,
	sizeFilterValue: PropTypes.array,
	setSizeFilterValue: PropTypes.func,
	roomsFilterValue: PropTypes.array,
	setRoomsFilterValue: PropTypes.func,
	defaultFilterValues: PropTypes.object,
	balconyFilterValue: PropTypes.bool,
	setBalconyFilterValue: PropTypes.func,
	petsFilterValue: PropTypes.bool,
	setPetsFilterValue: PropTypes.func,
	elevatorFilterValue: PropTypes.bool,
	setElevatorFilterValue: PropTypes.func,
	updateUrl: PropTypes.func,
	acquisitionFilterValue: PropTypes.array,
	setAcquisitionFilterValue: PropTypes.func
};

export default ControlsSection;