import React, { useState } from "react";
import PropTypes from "prop-types";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as ThreeDIcon } from 'assets/icons/3d-box-loading.svg';
import { device } from 'helpers/device';

const ModalButtonWrapper = styled.div``;

const ModalContentContainer = styled.div`
	max-width: 600px;
	min-width: 340px;
	overflow: auto;
`;

const CloseButton = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	svg {
		height: 60px;
		width: 60px;
	}
`;
const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 5px 25px;
	gap: 10px;
	cursor: pointer;
	width: 285px;
	height: 50px;
	background: #3E5042;
	border-radius: 60px;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	color: #fff;
	border: unset;
	svg {
		stroke: #fff;
	}
	margin-top: 24px;

	@media ${device.tablet} {
		background: #3E5042;
		color: #fff;
		svg {
			stroke: #fff;
		}
	}
`;

const GalleryButton3 = (props) => {
	const [open, setOpen] = useState(false);

	const { label, slides } = props;

	return (
		<ModalButtonWrapper>
			<Button onClick={() => setOpen(true)}><ThreeDIcon/>{label}<ArrowIcon/></Button>
			<Lightbox
				open={open}
				close={() => setOpen(false)}
				slides={slides}
			/>
		</ModalButtonWrapper>
	);
};

GalleryButton3.propTypes = {
	label: PropTypes.string.isRequired,
	slides: PropTypes.array.isRequired,
};

export default GalleryButton3;
