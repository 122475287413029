import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	height: 600px;
	width: 100%;
	display: flex;
    background: url(${props => props.background});
	background-size: cover;
	background-position: center;
	max-width: 1800px;
	margin: auto;
	clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%);
	max-width: 1280px;
	
	@media ${device.laptop} {
		clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
		height: 800px;
	}
`;


const ImageSection1 = (props) => {

	const {
		image
	} = props;

	return (
		<Wrapper background={image}>
		</Wrapper>
	);
};

ImageSection1.ImageSection1 = {
	image: PropTypes.object
};

export default ImageSection1;