import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
	background-color: #3e5042;
`;

const HeroWrapper = styled.div`
	min-height: calc((100vh - 137px) / 100 * 85);
	position: relative;
	width: 100%;
	background-size: auto;
	background-position: bottom;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 92%);
	padding-bottom: 50px;
	@media ${device.laptop} {
		background-size: cover;
		background-position: center;
		padding-bottom: 0px;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: ${(props) =>
		props.backgroundType === "video" ? "none" : `url(${props.background})`};

	/* Additional styles for video background */
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1;
	}
`;

const Container = styled.div`
	background-color: rgb(56 67 54 / 95%);
	top: 30%;
	padding: 50px;
	box-sizing: border-box;
	margin: 16px;

	@media ${device.laptop} {
		font-size: 50px;
		width: 540px;
		left: 100px;
		top: unset;
		bottom: 20%;
		margin: unset;
		margin-top: 40px;
		margin-bottom: 40px;
		margin-left: 10%;
	}
`;

const Title = styled.h1`
	color: white;
	font-size: 34px;
	padding-bottom: 24px;
	margin: unset;
	@media ${device.laptop} {
		font-size: 43px;
	}
`;

const Subtitle = styled.h2`
	// display: none;
	color: white;
	font-size: 20px;
	line-height: 140%;
	padding-bottom: 24px;
	font-weight: normal;
	margin: unset;
	@media ${device.tablet} {
		display: block;
	}
	@media ${device.laptop} {
		font-size: 20px;
	}
`;
const Banner = styled.div`
	height: calc((100vh - 137px) / 100 * 15);
	background-color: #3e5042;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
`;
const BannerContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	max-width: 1280px;
	margin: auto;
	padding: 20px;
	height: 100%;
	box-sizing: border-box;
`;

const Slogan = styled.div`
	font-size: 22px;
	font-weight: 700;
	margin-right: 20px;
	color: ${(p) => p.theme.colors.white.primary};

	@media ${device.laptop} {
		font-size: 42px;
	}

	span {
		color: ${(p) => p.theme.colors.ascent.secondary};
	}
`;

const PrimaryButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
    gap: 20px;
	@media ${device.tablet} {
		flex-direction: row;
		align-items: baseline;
	}
	
`; 

const HeroSection4 = (props) => {
	const videoRef = useRef(null);

	useEffect(() => {
		const handleLoadedData = () => {
			if (videoRef.current) {
				videoRef.current.play();
				document.dispatchEvent(new Event("click"));
			}
		};

		if (videoRef.current) {
			videoRef.current.addEventListener("loadeddata", handleLoadedData);
		}

		return () => {
			if (videoRef.current) {
				videoRef.current.removeEventListener(
					"loadeddata",
					handleLoadedData
				);
			}
		};
	}, []);

	const {
		background,
		backgroundType,
		title,
		text,
		primaryButtons,
		secondaryButtons,
	} = props;

	const { t } = useTranslation();

	return (
		<Wrapper>
			<HeroWrapper
				backgroundType={backgroundType}
				background={background}
			>
				{backgroundType === "video" && (
					<video ref={videoRef}
						muted
						autoPlay
						loop
						playsInline
						src={background}
						type='video/mp4'
					/>
				)}
				<Container>
					<Title>{title}</Title>
					<Subtitle>{text}</Subtitle>
					<PrimaryButtonWrapper>
						{primaryButtons}
					</PrimaryButtonWrapper>
				</Container>
			</HeroWrapper>
			<Banner>
				<BannerContentWrapper>
					<Slogan>
						{t("footer.sloganStart")}
						<span>{t("footer.sloganEnd")}</span>
					</Slogan>
					{secondaryButtons}
				</BannerContentWrapper>
			</Banner>
		</Wrapper>
	);
};

HeroSection4.propTypes = {
	background: PropTypes.string,
	backgroundType: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	primaryButtons: PropTypes.object,
	secondaryButtons: PropTypes.object,
};

HeroSection4.defaultProps = {
	background: "",
	backgroundType: "image",
	title: "",
	text: "",
	primaryButtons: {},
	secondaryButtons: {},
};

export default HeroSection4;
