import axios from './axios';

export default class TenanciesService {

	static async getTenancy(tenancyId) {
		try {
			const result = await axios.get(`/tenancies/${tenancyId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getTenancies() {
		try {
			let offset = 0;
			let allItems = [];
			let remainingItems = Infinity;
			let result;
	
			do {
				result = await axios.get('/tenancies', {
					params: { offset }
				});
	
				const { items, totalItems } = result.data;
				allItems = allItems.concat(items);
				offset += items.length;
				remainingItems = totalItems - offset;
	
			} while (remainingItems > 0);
	
			result.data.items = allItems;
			return result;
		} catch (err) {
			return null;
		}
	}
	


	static async getTenanciesByPropertyId(id) {
		try {
			const result = await axios.get(`/properties/${id}/tenancies`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

}