import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	
	max-width: 1280px;
	margin: auto;
`;
const BoxWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	@media ${device.tablet} {
		grid-template-columns: repeat(3, 1fr);
	}
`;
const Box = styled.div`
	width: 100%;
	background-image: url(${props => props.background});
	background-size: cover;
    background-position: center;
	aspect-ratio: 1;
	@media ${device.tablet} {
		aspect-ratio: 12/9;
	}
	@media ${device.tablet} {	
		&:nth-child(7), &:nth-child(8) {
			display: none;
		}
	}
`; 


const ImageGridSection2 = (props) => {

	const {
		images
	} = props;

	return (
		<Wrapper>
			<BoxWrapper>
				{images.map((image, index) => (
					<Box key={index} background={image} />
				))}
			</BoxWrapper>
		</Wrapper>
	);
};

ImageGridSection2.propTypes = {
	images: PropTypes.array
};

export default ImageGridSection2;