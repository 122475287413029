import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import ArrowButton from 'components/ArrowButton';
import Line from 'components/Line';
import TenancyCard from 'components/TenancyCard';
import Loader from 'components/Loader';
import useTenancies from 'features/tenancies';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	max-width: 1120px;
    background-color: white;
	padding: 32px 16px;
	margin: auto;
	box-sizing: border-box;
	@media ${device.laptop} { 
		padding-top: 120px;
		padding-left: 80px;
		padding-right: 80px;
	}
	
`;
const TopSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
const ListSection = styled.div`
	display: grid;
	grid-template-columns: auto;
    grid-column-gap: 19px;
	grid-row-gap: 19px;
	@media ${device.laptop} { 
		grid-template-columns: auto auto auto;
	}
`;
const Left = styled.div`
	display: flex;
	flex-direction: column;
`;
const Right = styled.div`
	display: none;
	@media ${device.laptop} { 
		display: block;
	}
`;

const FeaturedTenanciesSection = () => {
	const {
		isTenanciesLoading,
		tenanciesData,
		loadTenancies
	} = useTenancies();

	useEffect(() => {
		loadTenancies();
	}, [loadTenancies]);

	const { t } = useTranslation();

	const newestTenancies = useMemo(() => {
		if (!isTenanciesLoading) {
			return [...tenanciesData.items].sort((a, b) => {
				const d1 = new Date(a.publishedAt);
				const d2 = new Date(b.publishedAt);
				return d2.getTime() - d1.getTime();
			}).slice(0, 3);
		} else {
			return [];
		}
		
	}, [tenanciesData, isTenanciesLoading]);

	if (isTenanciesLoading) {
		return (
			<Wrapper>
				<Loader/>
			</Wrapper>
		);
	}
	
	return (
		<Wrapper>
			<TopSection>
				<Left>
					<Line />
					<Title>{t('home.newestTenancies.title')}</Title>
				</Left>
				<Right>
					<a href='/boliger'><ArrowButton>{t('home.newestTenancies.buttonLabel')}</ArrowButton></a>
				</Right>
			</TopSection>
			<ListSection>
				{newestTenancies.map(t => {
					return (
						<TenancyCard 
							key={t.id}
							name={t.title} 
							images={t.images}
							price={t.monthlyRent.value}
							availableFrom={t.availableFrom}
							rooms={t.rooms}
							size={t.size.value}
							id={t.id}
							street={t.address.street}
							floorplan={t.floorPlans[0]?.url}
							isReserved={t.state === 'Reserved'}
						/>
					);
				})}
			</ListSection>
		</Wrapper>
	);
};

export default FeaturedTenanciesSection;