const B1TenanciesJSON = [
	//  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------
	{
		position: [-7.6, 2.4, -42.4],
		scale: [10.0, 3.0, 6.6],
		id: '845cd9d2-a0bc-4bbd-aa57-8fcc37ec29e3',
		comment: 'Lejlighed nr. 1 - 35B, st 4'
	},
	{
		position: [-7.5, 2.4, -37.4],
		scale: [10.0, 3.0, 3.3],
		id: '46d0756a-334f-4221-a89b-f87fd1c4c199',
		comment: 'Lejlighed nr. 2 - 35B, st 3'
	},
	{
		position: [-7.5, 2.4, -34.0],
		scale: [10.0, 3.0, 3.3],
		id: 'b8056500-af09-4c6c-9396-9151b11f0d7d',
		comment: 'Lejlighed nr. 3 - 35B, st 2'
	},
	{
		position: [-7.4, 2.4, -29],
		scale: [10.0, 3.0, 6.6],
		id: '35c4f437-b615-4e0c-b284-eabd70eaecd8',
		comment: 'Lejlighed nr. 4 - 35B, st 1'
	},
	{
		position: [-7.2, 2.4, -20.2],
		scale: [10.0, 3.0, 3.1],
		id: 'e49cdd09-7a1f-4741-9473-f83764f41239',
		comment: 'Lejlighed nr. 5 - 35A, st 12'
	},
	{
		position: [-7.2, 2.4, -17.1],
		scale: [10.0, 3.0, 3.1],
		id: '5321c809-5158-4fec-8fce-e05a5d2bbf41',
		comment: 'Lejlighed nr. 6 - 35A, st 11'
	},
	{
		position: [-7.2, 2.4, -14.0],
		scale: [10.0, 3.0, 3.1],
		id: '007451db-551e-4444-ad21-571c65b17054',
		comment: 'Lejlighed nr. 7 - 35A, st 10'
	},
	{
		position: [-7.1, 2.4, -10.9],
		scale: [10.0, 3.0, 3.1],
		id: '403f70d7-5596-41ac-accf-d987c56414fe',
		comment: 'Lejlighed nr. 8 - 35A, st 09'
	},
	{
		position: [-8.1, 2.4, -4.7],
		scale: [7.8, 3.0, 9.4],
		id: '61bb395a-0678-4293-b67c-0d53e36c4b50',
		comment: 'Lejlighed nr. 9 - 35A, st 08'
	},
	{
		position: [-1.5, 2.4, -4.6],
		scale: [5.0, 3.0, 9.8],
		id: 'fbafbc8e-b379-40bd-869b-0c20b0c71387',
		comment: 'Lejlighed nr. 10 - 35A, st 07'
	},
	//  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------
	{
		position: [-7.6, 5.2, -42.4],
		scale: [10.0, 2.8, 6.6],
		id: 'd0a64e92-926b-4513-ba04-6ec36f68f7b4',
		comment: 'Lejlighed nr. 1 - 35B, 1. sal 4'
	},
	{
		position: [-7.5, 5.2, -37.4],
		scale: [10.0, 2.8, 3.3],
		id: 'b3e0515c-2138-4414-a0ec-477185b8b576',
		comment: 'Lejlighed nr. 2 - 35B, 1. sal 3'
	},
	{
		position: [-7.5, 5.2, -34.0],
		scale: [10.0, 2.8, 3.3],
		id: '972b023d-637d-4c39-a8b1-ebad9b1c74f9',
		comment: 'Lejlighed nr. 3 - 35B, 1. sal 2'
	},
	{
		position: [-7.4, 5.2, -29],
		scale: [10.0, 2.8, 6.6],
		id: 'ab6e4bf0-a82a-4ed4-9eff-aae0358641b8',
		comment: 'Lejlighed nr. 4 - 35B, 1. sal 1'
	},
	{
		position: [-7.2, 5.2, -20.2],
		scale: [10.0, 2.8, 3.1],
		id: 'a648bdc4-d1d7-4380-a2ed-87e2f00a2088',
		comment: 'Lejlighed nr. 5 - 35A, 1. sal 12'
	},
	{
		position: [-7.2, 5.2, -17.1],
		scale: [10.0, 2.8, 3.1],
		id: '754189c7-bf87-4580-b8db-925500438af2',
		comment: 'Lejlighed nr. 6 - 35A, 1. sal 11'
	},
	{
		position: [-7.2, 5.2, -14.0],
		scale: [10.0, 2.8, 3.1],
		id: '54288dbf-9a10-4fb4-b19f-0a9c86240a17',
		comment: 'Lejlighed nr. 7 - 35A, 1. sal 10'
	},
	{
		position: [-7.1, 5.2, -10.9],
		scale: [10.0, 2.8, 3.1],
		id: '09430569-1536-4c13-a281-10ddc848fe3a',
		comment: 'Lejlighed nr. 8 - 35A, 1. sal 09'
	},
	{
		position: [-8.1, 5.2, -4.7],
		scale: [7.8, 2.8, 9.4],
		id: '3292bdae-1b8b-4849-a13f-9d80b0c75f3d',
		comment: 'Lejlighed nr. 9 - 35A, 1. sal 08'
	},
	{
		position: [-1.5, 5.2, -4.6],
		scale: [5.0, 2.8, 9.8],
		id: '733de49f-fc50-429a-a718-91c0b6b69f17',
		comment: 'Lejlighed nr. 10 - 35A, 1. sal 07'
	},
	//  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------
	{
		position: [-7.6, 8.0, -42.4],
		scale: [10.0, 2.8, 6.6],
		id: '23147f08-6b06-4391-a9dc-03bb3560dd67',
		comment: 'Lejlighed nr. 1 - 35B, 2. sal 4'
	},
	{
		position: [-7.5, 8.0, -37.4],
		scale: [10.0, 2.8, 3.3],
		id: '35bf2a10-0195-466c-abe2-2e97c85936f1',
		comment: 'Lejlighed nr. 2 - 35B, 2. sal 3'
	},
	{
		position: [-7.5, 8.0, -34.0],
		scale: [10.0, 2.8, 3.3],
		id: '7716eee5-c895-47be-9aae-90d2f131ed44',
		comment: 'Lejlighed nr. 3 - 35B, 2. sal 2'
	},
	{
		position: [-7.4, 8.0, -29],
		scale: [10.0, 2.8, 6.6],
		id: '295c07a2-9ca1-4ec1-9fa9-89af33c2c691',
		comment: 'Lejlighed nr. 4 - 35B, 2. sal 1'
	},
	{
		position: [-7.2, 8.0, -20.2],
		scale: [10.0, 2.8, 3.1],
		id: '8956f060-40be-4e3d-9318-8259d7d0c8a5',
		comment: 'Lejlighed nr. 5 - 35A, 2. sal 14'
	},
	{
		position: [-7.2, 8.0, -17.1],
		scale: [10.0, 2.8, 3.1],
		id: 'f45b571e-00c8-4549-b6fa-6e64d69e2def',
		comment: 'Lejlighed nr. 6 - 35A, 2. sal 13'
	},
	{
		position: [-7.2, 8.0, -14.0],
		scale: [10.0, 2.8, 3.1],
		id: '474d733b-210b-40cc-8aef-9658f0639b8e',
		comment: 'Lejlighed nr. 7 - 35A, 2. sal 12'
	},
	{
		position: [-7.1, 8.0, -10.9],
		scale: [10.0, 2.8, 3.1],
		id: '7cd964ab-626c-45e5-b3ab-84b7d4a458f0',
		comment: 'Lejlighed nr. 8 - 35A, 2. sal 11'
	},
	{
		position: [-8.1, 8.0, -4.7],
		scale: [7.8, 2.8, 9.4],
		id: '6897041c-dc31-44a9-b316-63a737d2d2dd',
		comment: 'Lejlighed nr. 9 - 35A, 2. sal 10'
	},
	{
		position: [-1.5, 8.0, -4.6],
		scale: [5.0, 2.8, 9.8],
		id: 'b65b2f8f-eeaf-4873-a0de-9c06f8de53e8',
		comment: 'Lejlighed nr. 10 - 35A, 2. sal 09'
	},
	//  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------
	{
		position: [-7.5, 10.8, -37.4],
		scale: [10.0, 2.8, 3.3],
		id: '8fbb8980-e6fc-4fa5-a298-a020be87bccb',
		comment: 'Lejlighed nr. 2 - 35B, 3. sal 3'
	},
	{
		position: [-7.5, 10.8, -34.0],
		scale: [10.0, 2.8, 3.3],
		id: 'afd9d0c2-6710-470f-82f0-c18eef5b36ee',
		comment: 'Lejlighed nr. 3 - 35B, 3. sal 2'
	},
	{
		position: [-7.4, 10.8, -29],
		scale: [10.0, 2.8, 6.6],
		id: '3768d3b8-bc04-4bcf-83ef-ad1c6cba1b68',
		comment: 'Lejlighed nr. 4 - 35B, 3. sal 1'
	},
	{
		position: [-7.2, 10.8, -20.2],
		scale: [10.0, 2.8, 3.1],
		id: 'c5232a81-a6dc-4c56-9521-3b26d9b69b18',
		comment: 'Lejlighed nr. 5 - 35A, 3. sal 14'
	},
	{
		position: [-7.2, 10.8, -17.1],
		scale: [10.0, 2.8, 3.1],
		id: '422c2c0f-5b1c-488c-a9b7-364ac51a4bd9',
		comment: 'Lejlighed nr. 6 - 35A, 3. sal 13'
	},
	{
		position: [-7.2, 10.8, -14.0],
		scale: [10.0, 2.8, 3.1],
		id: '9fa1a099-ff3f-46c5-bc03-42395476e182',
		comment: 'Lejlighed nr. 7 - 35A, 3. sal 12'
	},
	{
		position: [-7.1, 10.8, -10.9],
		scale: [10.0, 2.8, 3.1],
		id: 'b9185bf0-518d-40aa-a9a4-b1f2f9e867bb',
		comment: 'Lejlighed nr. 8 - 35A, 3. sal 11'
	},
	{
		position: [-8.1, 10.8, -4.7],
		scale: [7.8, 2.8, 9.4],
		id: '937abb6f-7a6a-406a-a9ba-25b61b60e442',
		comment: 'Lejlighed nr. 9 - 35A, 3. sal 10'
	},
	{
		position: [-1.5, 10.8, -4.6],
		scale: [5.0, 2.8, 9.8],
		id: '58186c22-08ed-4afc-a7b1-f9e47d76fb68',
		comment: 'Lejlighed nr. 10 - 35A, 3. sal 09'
	},
	//  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------
	{
		position: [-7.2, 13.6, -20.2],
		scale: [10.0, 2.8, 3.1],
		id: '877355cc-9c5c-4e92-9cbe-b5d11040b132',
		comment: 'Lejlighed nr. 5 - 35A, 4. sal 14'
	},
	{
		position: [-7.2, 13.6, -17.1],
		scale: [10.0, 2.8, 3.1],
		id: '006a31e4-0b49-4138-ab7d-091274f01584',
		comment: 'Lejlighed nr. 6 - 35A, 4. sal 13'
	},
	{
		position: [-7.2, 13.6, -14.0],
		scale: [10.0, 2.8, 3.1],
		id: '950ac944-d184-4320-8d95-a15754308c71',
		comment: 'Lejlighed nr. 7 - 35A, 4. sal 12'
	},
	{
		position: [-7.1, 13.6, -10.9],
		scale: [10.0, 2.8, 3.1],
		id: '3a38d02c-5c55-40e8-bafc-9c9351a5cf7a',
		comment: 'Lejlighed nr. 8 - 35A, 4. sal 11'
	},
	{
		position: [-8.1, 13.6, -4.7],
		scale: [7.8, 2.8, 9.4],
		id: '673eda21-4585-4200-b21c-a50cf40c70b8',
		comment: 'Lejlighed nr. 9 - 35A, 4. sal 10'
	},
	{
		position: [-1.5, 13.6, -4.6],
		scale: [5.0, 2.8, 9.8],
		id: '366de862-8b38-4995-b8ca-cf33bc4de704',
		comment: 'Lejlighed nr. 10 - 35A, 4. sal 09'
	},
];
  
export default B1TenanciesJSON;