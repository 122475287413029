// @ts-nocheck

import React, { Suspense, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Model from './Model';
import PropTypes from 'prop-types';
import TenancyCardSmall from 'components/TenancyCardSmall';
import Loader from 'components/Loader';
import Slider from './Slider';
import * as THREE from 'three';

const Wrapper = styled.div`
  	height: 600px;
	width: 100%;
	border: none;
	position: relative
`;

const ClickedTenancyContainer = styled.div`
	position: absolute;
	right: 40px;
	z-index: 10;
	top: 40px;
	@media (max-width: 480px) { 
		right: unset;
		width: 90%;
		margin-right: 5%;
		margin-left: 5%;
	}
`;

const ModelWrapper = styled.div`
	height: 500px;
`;

const GefionsKollegietBoxes = [
	// 3. floor ---- 3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------
	{
		position: [78.1, 11.7, -32.0],
		scale: [2.9, 3, 6],
		id: '7bd3e97c-cb20-4806-bd77-f3b426dbe446',
		comment: 'Telegrafvej 5B, 3., dør 9'
	},
	{
		position: [75.5, 11.7, -32.0],
		scale: [2.50, 3, 6],
		id: 'd9e79f26-5bba-41ba-a5a2-a2a796901126',
		comment: 'Telegrafvej 5B, 3., dør 8'
	},
	{
		position: [73.50, 11.7, -32.0],
		scale: [1.60, 3, 6],
		id: '5d49cb9e-07ff-40e2-a597-8ea32235d249',
		comment: 'Telegrafvej 5B, 3., dør 7'
	},
	{
		position: [71.85, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'c0239405-a18e-4be7-8b7f-5a17b8d22603',
		comment: 'Telegrafvej 5B, 3., dør 6'
	},
	{
		position: [70.20, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '656d9452-ea84-4d80-a731-c8afd49c43d1',
		comment: 'Telegrafvej 5B, 3., dør 5'
	},
	{
		position: [68.55, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'e7c834d8-7b7c-492a-bca7-51331aa3994e',
		comment: 'Telegrafvej 5B, 3., dør 4'
	},
	{
		position: [66.90, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '03cf3f0a-5aa4-4ce2-8e42-9e6c61359419',
		comment: 'Telegrafvej 5B, 3., dør 3'
	},
	{
		position: [64.80, 11.7, -33.0],
		scale: [2.50, 3, 4],
		id: '5f9ef1f6-af83-4957-b12d-81ddd6932b4c',
		comment: 'Telegrafvej 5B, 3., dør 2'
	},
	{
		position: [62.30, 11.7, -33.0],
		scale: [2.50, 3, 4],
		id: '28417ccb-c242-4710-bec1-acc88dd01b33',
		comment: 'Telegrafvej 5B, 3., dør 1'
	},
	{
		position: [59.50, 11.7, -33.0],
		scale: [3.20, 3, 4],
		id: 'd955dbb2-9325-49be-b4cf-a4c4991e011a',
		comment: 'Telegrafvej 5B, 3., dør 34'
	},
	{
		position: [57.05, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '5deda446-a04a-4dc6-a288-841a1ba7070a',
		comment: 'Telegrafvej 5B, 3., dør 33'
	},
	{
		position: [55.45, 11.7, -32.0],
		scale: [1.60, 3, 6],
		id: '670a671d-b11d-41c9-8f5f-0bf12f7667ac',
		comment: 'Telegrafvej 5B, 3., dør 32'
	},
	{
		position: [53.85, 11.7, -32.0],
		scale: [1.60, 3, 6],
		id: 'd58d50bf-85b8-454f-951f-c9ddfcf39eb8',
		comment: 'Telegrafvej 5B, 3., dør 31'
	},
	{
		position: [52.25, 11.7, -32.0],
		scale: [1.60, 3, 6],
		id: 'f723e4ca-1756-4f4a-9604-fd3ace2a8fbd',
		comment: 'Telegrafvej 5B, 3., dør 30'
	},
	{
		position: [50.65, 11.7, -32.0],
		scale: [1.60, 3, 6],
		id: 'd0f45276-9af1-4448-9b65-e56301c8ea25',
		comment: 'Telegrafvej 5B, 3., dør 29'
	},
	{
		position: [49.05, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '85adad87-c758-4615-ac40-592de88bb7f4',
		comment: 'Telegrafvej 5B, 3., dør 28'
	},
	{
		position: [47.40, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '79d0235f-31c8-42e8-953e-6c2d1b534e3e',
		comment: 'Telegrafvej 5B, 3., dør 27'
	},
	{
		position: [45.75, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '503a1d1e-9e89-46ec-b1e1-458c18a51487',
		comment: 'Telegrafvej 5A, 3., dør 8'
	},
	{
		position: [44.10, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '8d9d1613-0849-4754-8230-980eff550f20',
		comment: 'Telegrafvej 5A, 3., dør 7'
	},
	{
		position: [42.45, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '1505adb4-88e3-4a71-b914-751af8e7a931',
		comment: 'Telegrafvej 5A, 3., dør 6'
	},
	{
		position: [40.80, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '18f28938-9893-4609-a775-018b42da28c4',
		comment: 'Telegrafvej 5A, 3., dør 5'
	},
	{
		position: [39.2, 11.7, -32.0],
		scale: [1.55, 3, 6],
		id: '81b88282-7acf-4f50-97a7-09b21c8fb6b1',
		comment: 'Telegrafvej 5A, 3., dør 4'
	},
	{
		position: [37.65, 11.7, -32.0],
		scale: [1.55, 3, 6],
		id: '569b9eec-090a-4845-a401-b27fc0d7ac0a',
		comment: 'Telegrafvej 5A, 3., dør 3'
	},
	{
		position: [35.60, 11.7, -33.0],
		scale: [2.50, 3, 4],
		id: '85b53d08-1cb2-41b6-b70c-146de1d59869',
		comment: 'Telegrafvej 5A, 3., dør 2'
	},
	{
		position: [33.10, 11.7, -33.0],
		scale: [2.50, 3, 4],
		id: '3e24e38f-1509-414f-a7f5-46561cccb8eb',
		comment: 'Telegrafvej 5A, 3., dør 1'
	},
	{
		position: [30.30, 11.7, -33.0],
		scale: [3.20, 3, 4],
		id: '0586ad5d-1e3b-4757-8466-51114e4c02ef',
		comment: 'Telegrafvej 5A, 3., dør 32'
	},
	{
		position: [27.90, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: '7e5403ab-118e-444d-b4ef-c82184870f01',
		comment: 'Telegrafvej 5A, 3., dør 31'
	},
	{
		position: [26.25, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'da03c8dd-27c8-40f6-9950-91ec7248db0d',
		comment: 'Telegrafvej 5A, 3., dør 30'
	},
	{
		position: [24.60, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'da9f744c-aa45-4ff4-a953-8d2b840fabbe',
		comment: 'Telegrafvej 5A, 3., dør 29'
	},
	{
		position: [22.95, 11.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'f313093b-46b5-49f9-907b-d5e7f7039fff',
		comment: 'Telegrafvej 5A, 3., dør 28'
	},
	{
		position: [21.35, 11.7, -32.0],
		scale: [1.60, 3, 6],
		id: '5896ebd3-952c-4816-b35c-0b229b63c68f',
		comment: 'Telegrafvej 5A, 3., dør 27'
	},
	{
		position: [19.30, 11.7, -32.0],
		scale: [2.50, 3, 6],
		id: '6b9e0e69-d900-478d-8e3a-1dbcd14ab2b7',
		comment: 'Telegrafvej 5A, 3., dør 26'
	},
	{
		position: [16.6, 11.7, -32.0],
		scale: [2.85, 3, 6],
		id: 'a8cfe49a-19de-4ac5-a181-2e3855536e8d',
		comment: 'Telegrafvej 5A, 3., dør 25'
	},
	{
		position: [16.6, 11.7, -21.2],
		scale: [2.85, 3, 6],
		id: '6b9e0e69-d900-478d-8e3a-1dbcd14ab2b7',
		comment: 'Telegrafvej 5A, 3., dør 24'
	},
	{
		position: [19.3, 11.7, -21.2],
		scale: [2.5, 3, 6],
		id: 'b7d3e6fa-c7b9-45c4-94a4-f584975936e5',
		comment: 'Telegrafvej 5A, 3., dør 23'
	},
	{
		position: [21.3, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '924dc8b8-ba81-4169-bda1-e1a297a51e98',
		comment: 'Telegrafvej 5A, 3., dør 22'
	},
	{
		position: [22.9, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '3ca5e132-5b03-4a60-bc61-d0f3d6b90a4b',
		comment: 'Telegrafvej 5A, 3., dør 21'
	},
	{
		position: [24.55, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'ea269985-fb70-44d4-ba7d-9817055d062a',
		comment: 'Telegrafvej 5A, 3., dør 20'
	},
	{
		position: [26.20, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'c9292639-44f7-4bbf-9a20-c0fbdaa4b74a',
		comment: 'Telegrafvej 5A, 3., dør 19'
	},
	{
		position: [27.85, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '365717bb-ca7f-4bce-ab5b-b4e714a17e90',
		comment: 'Telegrafvej 5A, 3., dør 18'
	},
	{
		position: [30.30, 11.7, -20.2],
		scale: [3.20, 3, 4],
		id: '3dedf84b-bb00-4548-afd0-a365f638419f',
		comment: 'Telegrafvej 5A, 3., dør 17'
	},
	{
		position: [33.10, 11.7, -20.2],
		scale: [2.50, 3, 4],
		id: 'bf0e4af9-1324-421d-9a18-96e72578097b',
		comment: 'Telegrafvej 5A, 3., dør 16'
	},
	{
		position: [35.60, 11.7, -20.2],
		scale: [2.50, 3, 4],
		id: 'e64565a4-3828-4632-90e8-8ddba85dbbd2',
		comment: 'Telegrafvej 5A, 3., dør 15'
	},
	{
		position: [37.65, 11.7, -21.2],
		scale: [1.55, 3, 6],
		id: '48a09c96-260b-46b2-b142-49a846f639ed',
		comment: 'Telegrafvej 5A, 3., dør 14'
	},
	{
		position: [39.2, 11.7, -21.2],
		scale: [1.55, 3, 6],
		id: 'ee1470bb-c48c-46ef-be5d-0ab7e80d295c',
		comment: 'Telegrafvej 5A, 3., dør 13'
	},
	{
		position: [40.80, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '95058ee5-8d05-4b1d-8ff9-153f7820ef39',
		comment: 'Telegrafvej 5A, 3., dør 12'
	},
	{
		position: [42.45, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'c8dbe5a8-5084-4cfb-b9fb-cee2bdfe5d66',
		comment: 'Telegrafvej 5A, 3., dør 11'
	},
	{
		position: [44.10, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '47b54604-94fd-45f8-96a8-32d5024ffb0c',
		comment: 'Telegrafvej 5A, 3., dør 10'
	},
	{
		position: [45.75, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '384d195f-c253-40a2-b4fe-e42ac15b4fa5',
		comment: 'Telegrafvej 5A, 3., dør 9'
	},
	{
		position: [47.40, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '93cc8815-4997-4bb2-86b3-1a09867748b8',
		comment: 'Telegrafvej 5B, 3., dør 26'
	},
	{
		position: [49.05, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '281badf1-abc3-4955-a947-564df333067c',
		comment: 'Telegrafvej 5B, 3., dør 25'
	},
	{
		position: [50.65, 11.7, -21.2],
		scale: [1.60, 3, 6],
		id: '8ee1d8e7-1937-40ca-9a88-ea296890421b',
		comment: 'Telegrafvej 5B, 3., dør 24'
	},
	{
		position: [52.25, 11.7, -21.2],
		scale: [1.60, 3, 6],
		id: 'c9d49290-cc06-47ec-b917-0379cb074865',
		comment: 'Telegrafvej 5B, 3., dør 23'
	},
	{
		position: [53.85, 11.7, -21.2],
		scale: [1.60, 3, 6],
		id: 'cd5d0905-7302-46d2-8a19-a2175866dfd3',
		comment: 'Telegrafvej 5B, 3., dør 22'
	},
	{
		position: [55.45, 11.7, -21.2],
		scale: [1.60, 3, 6],
		id: 'e315a3ef-2f41-4028-8db1-a355871ac4d5',
		comment: 'Telegrafvej 5B, 3., dør 21'
	},
	{
		position: [57.05, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '29bc4ddd-d749-49aa-b6b3-4f1071284216',
		comment: 'Telegrafvej 5B, 3., dør 20'
	},
	{
		position: [59.50, 11.7, -20.2],
		scale: [3.20, 3, 4],
		id: 'd1638711-f82a-4215-afaf-61762d476fa0',
		comment: 'Telegrafvej 5B, 3., dør 19'
	},
	{
		position: [62.30, 11.7, -20.2],
		scale: [2.50, 3, 4],
		id: 'da547071-cde8-4be1-a795-7c9a80d9a45d',
		comment: 'Telegrafvej 5B, 3., dør 18'
	},
	{
		position: [64.80, 11.7, -20.2],
		scale: [2.50, 3, 4],
		id: '5cabf949-f128-4f31-97f5-1f0bbc405d71',
		comment: 'Telegrafvej 5B, 3., dør 17'
	},
	{
		position: [66.90, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'a2b31c2b-56d7-4992-8ebd-401abafc86f9',
		comment: 'Telegrafvej 5B, 3., dør 16'
	},
	{
		position: [70.20, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '6ab988ef-cec4-4cf9-8a00-db770e95bb1a',
		comment: 'Telegrafvej 5B, 3., dør 15'
	},
	{
		position: [68.55, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: '8e08ca49-8f08-42f5-9d71-66e2db2b61c6',
		comment: 'Telegrafvej 5B, 3., dør 14'
	},
	{
		position: [71.85, 11.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'bd13c122-ee41-46ab-ba21-4708f668422e',
		comment: 'Telegrafvej 5B, 3., dør 13'
	},
	{
		position: [73.50, 11.7, -21.2],
		scale: [1.60, 3, 6],
		id: 'ad0f63b5-e285-424a-abe5-d9066e3a8a94',
		comment: 'Telegrafvej 5B, 3., dør 12'
	},
	{
		position: [75.5, 11.7, -21.2],
		scale: [2.50, 3, 6],
		id: 'c3ddc555-87ab-46e0-8fd1-b9e50ff585a8',
		comment: 'Telegrafvej 5B, 3., dør 11'
	},
	{
		position: [78.1, 11.7, -21.2],
		scale: [2.8, 3, 6],
		id: '72cfbbec-92e0-4c63-9fb1-082185dca084',
		comment: 'Telegrafvej 5B, 3., dør 10'
	},
	// ------ 2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------
	// 2. floor
	// 2. floor
	// 2. floor
	// 2. floor
	{
		position: [78.1, 8.7, -32.0],
		scale: [2.9, 3, 6],
		id: '5ec26a33-a4e6-4d29-a057-6ab2de33032b',
		comment: 'Telegrafvej 5B, 2., dør 9'
	},
	{
		position: [75.5, 8.7, -32.0],
		scale: [2.50, 3, 6],
		id: '7c1b098b-2f0c-46c1-9709-0e5d01a89233',
		comment: 'Telegrafvej 5B, 2., dør 8'
	},
	{
		position: [73.50, 8.7, -32.0],
		scale: [1.60, 3, 6],
		id: '48d2530a-6aa7-4e01-ae1e-3055db92e3a5',
		comment: 'Telegrafvej 5B, 2., dør 7'
	},
	{
		position: [71.85, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '8421a75d-3fff-4f4c-aa56-201eaf751e7e',
		comment: 'Telegrafvej 5B, 2., dør 6'
	},
	{
		position: [70.20, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '3fb46ce4-b997-4b09-a38c-ca679ce77d23',
		comment: 'Telegrafvej 5B, 2., dør 5'
	},
	{
		position: [68.55, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '25e136ab-d942-4d6d-a788-eabec0ebe2ab',
		comment: 'Telegrafvej 5B, 2., dør 4'
	},
	{
		position: [66.90, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '71df7ac2-55c3-48f1-baec-c02c2a37443b',
		comment: 'Telegrafvej 5B, 2., dør 3'
	},
	{
		position: [64.80, 8.7, -33.0],
		scale: [2.50, 3, 4],
		id: '48b476c6-9b39-41d0-9408-65a8f9f09887',
		comment: 'Telegrafvej 5B, 2., dør 2'
	},
	{
		position: [62.30, 8.7, -33.0],
		scale: [2.50, 3, 4],
		id: 'aebafd9a-364e-4c90-927a-1d07f9f0caef',
		comment: 'Telegrafvej 5B, 2., dør 1'
	},
	{
		position: [59.50, 8.7, -33.0],
		scale: [3.20, 3, 4],
		id: '3f76c89a-bf89-4780-b34e-9d4960d10020',
		comment: 'Telegrafvej 5B, 2., dør 34'
	},
	{
		position: [57.05, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '5a5698a0-49d8-47bc-8c25-075cbee0830c',
		comment: 'Telegrafvej 5B, 2., dør 33'
	},
	{
		position: [55.45, 8.7, -32.0],
		scale: [1.60, 3, 6],
		id: '4e7e19c6-29d1-4e6f-95b4-29c3d9baa523',
		comment: 'Telegrafvej 5B, 2., dør 32'
	},
	{
		position: [53.85, 8.7, -32.0],
		scale: [1.60, 3, 6],
		id: '0754cba3-f764-4dc2-bfc9-b25774d33ed8',
		comment: 'Telegrafvej 5B, 2., dør 31'
	},
	{
		position: [52.25, 8.7, -32.0],
		scale: [1.60, 3, 6],
		id: '86f63eb6-68a7-49ed-afb2-c3bf52b37ba2',
		comment: 'Telegrafvej 5B, 2., dør 30'
	},
	{
		position: [50.65, 8.7, -32.0],
		scale: [1.60, 3, 6],
		id: '6cd0ae73-ab3e-4ec1-ab39-7018dd3d0762',
		comment: 'Telegrafvej 5B, 2., dør 29'
	},
	{
		position: [49.05, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '859e6035-3a65-4ae5-a0af-65cb1867de6c',
		comment: 'Telegrafvej 5B, 2., dør 28'
	},
	{
		position: [47.40, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '13562131-0af5-4a8e-ac01-c69c6f73569b',
		comment: 'Telegrafvej 5B, 2., dør 27'
	},
	{
		position: [45.75, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '0f1186eb-886b-41d8-88ac-1ae6076c296b',
		comment: 'Telegrafvej 5A, 2., dør 8'
	},
	{
		position: [44.10, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '283af998-c951-48ac-98fd-e9d087e4e94c',
		comment: 'Telegrafvej 5A, 2., dør 7'
	},
	{
		position: [42.45, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '499e8d74-40d6-4861-b09e-9d4757195156',
		comment: 'Telegrafvej 5A, 2., dør 6'
	},
	{
		position: [40.80, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '4cca9e7f-2fee-45f6-a9db-029aa7451646',
		comment: 'Telegrafvej 5A, 2., dør 5'
	},
	{
		position: [39.2, 8.7, -32.0],
		scale: [1.55, 3, 6],
		id: '2cdeb16f-5a53-4fae-b9cd-9ad6e16e56c0',
		comment: 'Telegrafvej 5A, 2., dør 4'
	},
	{
		position: [37.65, 8.7, -32.0],
		scale: [1.55, 3, 6],
		id: '79b89bb6-83fd-4f63-85a4-866bad1ea23e',
		comment: 'Telegrafvej 5A, 2., dør 3'
	},
	{
		position: [35.60, 8.7, -33.0],
		scale: [2.50, 3, 4],
		id: '2111de74-1bc8-47c5-b7a8-59a6c2ca891b',
		comment: 'Telegrafvej 5A, 2., dør 2'
	},
	{
		position: [33.10, 8.7, -33.0],
		scale: [2.50, 3, 4],
		id: '65f35a26-c047-4799-9123-2606491e32f2',
		comment: 'Telegrafvej 5A, 2., dør 1'
	},
	{
		position: [30.30, 8.7, -33.0],
		scale: [3.20, 3, 4],
		id: 'f1e253f4-142a-4dcd-a948-b20573edba01',
		comment: 'Telegrafvej 5A, 2., dør 32'
	},
	{
		position: [27.90, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '12004375-e17f-45f2-85e3-3f07ecdac625',
		comment: 'Telegrafvej 5A,23., dør 31'
	},
	{
		position: [26.25, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '91f1b397-fcb5-4004-858c-0d693100b5e7',
		comment: 'Telegrafvej 5A, 2., dør 30'
	},
	{
		position: [24.60, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: '6c99d27f-1ef1-4ed2-9291-37c4dde31cf5',
		comment: 'Telegrafvej 5A, 2., dør 29'
	},
	{
		position: [22.95, 8.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'f09abbf2-2e1f-4883-852e-02fd14d57d82',
		comment: 'Telegrafvej 5A, 2., dør 28'
	},
	{
		position: [21.35, 8.7, -32.0],
		scale: [1.60, 3, 6],
		id: '542555d5-b2b7-46e9-9474-248956daa1f9',
		comment: 'Telegrafvej 5A, 2., dør 27'
	},
	{
		position: [19.30, 8.7, -32.0],
		scale: [2.50, 3, 6],
		id: '304eec11-d985-4f82-89a5-ad9107db63e5',
		comment: 'Telegrafvej 5A, 2., dør 26'
	},
	{
		position: [16.6, 8.7, -32.0],
		scale: [2.85, 3, 6],
		id: '9ad4ea05-aebd-4cce-a5a7-3bd8b89f1fc9',
		comment: 'Telegrafvej 5A, 2., dør 25'
	},
	{
		position: [16.6, 8.7, -21.2],
		scale: [2.85, 3, 6],
		id: '87d481dc-79fe-413c-8122-bc3103dde274',
		comment: 'Telegrafvej 5A, 2., dør 24'
	},
	{
		position: [19.3, 8.7, -21.2],
		scale: [2.5, 3, 6],
		id: '0ac3a04a-5315-49ad-8de1-c7f7e1a6f382',
		comment: 'Telegrafvej 5A, 2., dør 23'
	},
	{
		position: [21.3, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'de4ce920-9481-4fdb-836e-10db39eaf445',
		comment: 'Telegrafvej 5A, 2., dør 22'
	},
	{
		position: [22.9, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '4600b42b-e4b0-4a29-a4ae-d6bf3857bba9',
		comment: 'Telegrafvej 5A, 2., dør 21'
	},
	{
		position: [24.55, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'd84ade3d-65eb-48a2-a9bd-5a468d96d4ae',
		comment: 'Telegrafvej 5A, 2., dør 20'
	},
	{
		position: [26.20, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '772a65ab-5d5c-481e-b960-f645a2d2eed0',
		comment: 'Telegrafvej 5A, 2., dør 19'
	},
	{
		position: [27.85, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'c9b37ac9-af4c-46aa-b28a-ff4dde3f0459',
		comment: 'Telegrafvej 5A, 2., dør 18'
	},
	{
		position: [30.30, 8.7, -20.2],
		scale: [3.20, 3, 4],
		id: '94b70271-34b7-44be-ac98-251e0239c771',
		comment: 'Telegrafvej 5A, 2., dør 17'
	},
	{
		position: [33.10, 8.7, -20.2],
		scale: [2.50, 3, 4],
		id: 'bedcc9d5-f08c-4f52-8174-202974cbb4dc',
		comment: 'Telegrafvej 5A, 2., dør 16'
	},
	{
		position: [35.60, 8.7, -20.2],
		scale: [2.50, 3, 4],
		id: '66257cd5-9eb2-43a8-9ad1-cc66ebdaa8e2',
		comment: 'Telegrafvej 5A, 2., dør 15'
	},
	{
		position: [37.65, 8.7, -21.2],
		scale: [1.55, 3, 6],
		id: '08dc0eb0-5b04-4988-87e0-c234eddfa213',
		comment: 'Telegrafvej 5A, 2., dør 14'
	},
	{
		position: [39.2, 8.7, -21.2],
		scale: [1.55, 3, 6],
		id: '74e03a7c-6d34-488a-850f-33feed9f9802',
		comment: 'Telegrafvej 5A, 2., dør 13'
	},
	{
		position: [40.80, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '535e19c9-f724-4676-9801-72f3d7bc7606',
		comment: 'Telegrafvej 5A, 2., dør 12'
	},
	{
		position: [42.45, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '118d998a-559d-4a03-870c-eaf859241afc',
		comment: 'Telegrafvej 5A, 2., dør 11'
	},
	{
		position: [44.10, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'ffeae45c-3456-450a-9eea-86e0ecffc2a5',
		comment: 'Telegrafvej 5A, 2., dør 10'
	},
	{
		position: [45.75, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '3cb6b4f7-fd9a-45b0-a5ca-e8e60dc6d6fc',
		comment: 'Telegrafvej 5A, 2., dør 9'
	},
	{
		position: [47.40, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '08f2477e-39a0-4ba5-90e7-db2547b8a1a3',
		comment: 'Telegrafvej 5B, 2., dør 26'
	},
	{
		position: [49.05, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '97bfa5c3-ecfb-4963-bb54-af6b795f9ea1',
		comment: 'Telegrafvej 5B, 2., dør 25'
	},
	{
		position: [50.65, 8.7, -21.2],
		scale: [1.60, 3, 6],
		id: 'e1b09353-c28d-4f67-8df0-c8e958656b6d',
		comment: 'Telegrafvej 5B, 2., dør 24'
	},
	{
		position: [52.25, 8.7, -21.2],
		scale: [1.60, 3, 6],
		id: '13ce9b35-967d-4d79-b241-25241f99fac4',
		comment: 'Telegrafvej 5B, 2., dør 23'
	},
	{
		position: [53.85, 8.7, -21.2],
		scale: [1.60, 3, 6],
		id: '3927713b-3e78-4a38-9244-35754e5f8f6f',
		comment: 'Telegrafvej 5B, 2., dør 22'
	},
	{
		position: [55.45, 8.7, -21.2],
		scale: [1.60, 3, 6],
		id: '6c18864c-6f45-4049-b842-af48a0113a65',
		comment: 'Telegrafvej 5B, 2., dør 21'
	},
	{
		position: [57.05, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '2e21948d-1245-4455-bd3e-0d4199c08b89',
		comment: 'Telegrafvej 5B, 2., dør 20'
	},
	{
		position: [59.50, 8.7, -20.2],
		scale: [3.20, 3, 4],
		id: '17ca0c7d-2644-48a4-b136-7d5b4ede2a3c',
		comment: 'Telegrafvej 5B, 2., dør 19'
	},
	{
		position: [62.30, 8.7, -20.2],
		scale: [2.50, 3, 4],
		id: '28172cc2-254b-420a-83ae-67c15422f78b',
		comment: 'Telegrafvej 5B, 2., dør 18'
	},
	{
		position: [64.80, 8.7, -20.2],
		scale: [2.50, 3, 4],
		id: 'f7c0972c-ee9d-4b84-a97c-2925211c763b',
		comment: 'Telegrafvej 5B, 2., dør 17'
	},
	{
		position: [66.90, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'd5a940a0-11de-4b8c-952d-8c5a1d1addd8',
		comment: 'Telegrafvej 5B, 2., dør 16'
	},
	{
		position: [70.20, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'd80cdae9-ee9c-45e0-a4e2-93d36e4c5454',
		comment: 'Telegrafvej 5B, 2., dør 15'
	},
	{
		position: [68.55, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: '32624564-f63e-4825-8679-f1c4b3284a3a',
		comment: 'Telegrafvej 5B, 2., dør 14'
	},
	{
		position: [71.85, 8.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'ac7f19fe-22c0-4e5d-8a7f-08ddebdc1215',
		comment: 'Telegrafvej 5B, 2., dør 13'
	},
	{
		position: [73.50, 8.7, -21.2],
		scale: [1.60, 3, 6],
		id: '1e502c81-2398-45a5-bd82-177b3fde3caa',
		comment: 'Telegrafvej 5B, 2., dør 12'
	},
	{
		position: [75.5, 8.7, -21.2],
		scale: [2.50, 3, 6],
		id: '06ded21a-4e02-4d23-95a0-55ca20ee5149',
		comment: 'Telegrafvej 5B, 2., dør 11'
	},
	{
		position: [78.1, 8.7, -21.2],
		scale: [2.8, 3, 6],
		id: '75118841-997f-45e4-848c-359e4a394efd',
		comment: 'Telegrafvej 5B, 2., dør 10'
	},
	//  ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------
	//  ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------
	//  ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------
	//  ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------
	//  ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------ 1ST FLOOR ------
	{
		position: [78.1, 5.7, -32.0],
		scale: [2.9, 3, 6],
		id: '47135fc9-c932-472d-adc5-8ec954bce55a',
		comment: 'Telegrafvej 5B, 1., dør 9'
	},
	{
		position: [75.5, 5.7, -32.0],
		scale: [2.50, 3, 6],
		id: 'a647f6a9-8fc9-4c07-bb74-050f58ad2497',
		comment: 'Telegrafvej 5B, 1., dør 8'
	},
	{
		position: [73.50, 5.7, -32.0],
		scale: [1.60, 3, 6],
		id: '45e22f03-0e2f-4cfa-97cd-690435bf8f4c',
		comment: 'Telegrafvej 5B, 1., dør 7'
	},
	{
		position: [71.85, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'a868ae7c-cf0e-400f-9df5-cd0e0d156f8c',
		comment: 'Telegrafvej 5B, 1., dør 6'
	},
	{
		position: [70.20, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '19fcef7d-465e-4a16-adc0-42c71380f11a',
		comment: 'Telegrafvej 5B, 1., dør 5'
	},
	{
		position: [68.55, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'aa1aa4c6-ee52-4764-bd1e-3d612aa83f04',
		comment: 'Telegrafvej 5B, 1., dør 4'
	},
	{
		position: [66.90, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '773b5270-385d-408d-b855-d2dcc5c29f56',
		comment: 'Telegrafvej 5B, 1., dør 3'
	},
	{
		position: [64.80, 5.7, -33.0],
		scale: [2.50, 3, 4],
		id: '4ce85ce1-ac8d-4afb-b592-a420df82205b',
		comment: 'Telegrafvej 5B, 1., dør 2'
	},
	{
		position: [62.30, 5.7, -33.0],
		scale: [2.50, 3, 4],
		id: '32d6b173-dc5a-4717-96b7-418b6a0db726',
		comment: 'Telegrafvej 5B, 1., dør 1'
	},
	{
		position: [59.50, 5.7, -33.0],
		scale: [3.20, 3, 4],
		id: '59dd3053-4c93-4ce3-b8e6-d7cf5f6b91ea',
		comment: 'Telegrafvej 5B, 1., dør 34'
	},
	{
		position: [57.05, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '2179d830-e989-4306-8212-d19a2c3f15d3',
		comment: 'Telegrafvej 5B, 1., dør 33'
	},
	{
		position: [55.45, 5.7, -32.0],
		scale: [1.60, 3, 6],
		id: '3c6b8011-b384-4143-b0c4-e162285202a4',
		comment: 'Telegrafvej 5B, 1., dør 32'
	},
	{
		position: [53.85, 5.7, -32.0],
		scale: [1.60, 3, 6],
		id: '82a27150-b4b0-48cd-b497-a7a7f2956eff',
		comment: 'Telegrafvej 5B, 1., dør 31'
	},
	{
		position: [52.25, 5.7, -32.0],
		scale: [1.60, 3, 6],
		id: 'f5b51f30-4e7f-4621-8d6f-543bb159ccbf',
		comment: 'Telegrafvej 5B, 1., dør 30'
	},
	{
		position: [50.65, 5.7, -32.0],
		scale: [1.60, 3, 6],
		id: 'e2fa3dd3-4465-4f95-9430-1d45623343a9',
		comment: 'Telegrafvej 5B, 1., dør 29'
	},
	{
		position: [49.05, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '650e9581-db0d-4b04-94da-1734a80a3fc8',
		comment: 'Telegrafvej 5B, 1., dør 28'
	},
	{
		position: [47.40, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '10a33809-0f7e-4bce-beff-0d3cea94b3c1',
		comment: 'Telegrafvej 5B, 1., dør 27'
	},
	{
		position: [45.75, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '9779781a-ffe1-4bad-a017-fed41b98d255',
		comment: 'Telegrafvej 5A, 1., dør 8'
	},
	{
		position: [44.10, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '32b28a0f-64af-4fad-886b-f986b9f8dd9c',
		comment: 'Telegrafvej 5A, 3., dør 7'
	},
	{
		position: [42.45, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '6cf22f9e-ab49-4a0f-9734-7c2a47129ccd',
		comment: 'Telegrafvej 5A, 1., dør 6'
	},
	{
		position: [40.80, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '48aadac8-d7a5-4c82-9325-6eaed2801974',
		comment: 'Telegrafvej 5A, 1., dør 5'
	},
	{
		position: [39.2, 5.7, -32.0],
		scale: [1.55, 3, 6],
		id: '25afd182-e252-4b10-9767-aebb71c49e97',
		comment: 'Telegrafvej 5A, 1., dør 3'
	},
	{
		position: [37.65, 5.7, -32.0],
		scale: [1.55, 3, 6],
		id: '569b9eec-090a-4845-a401-b27fc0d7ac0a',
		comment: 'Telegrafvej 5A, 1., dør 3'
	},
	{
		position: [35.60, 5.7, -33.0],
		scale: [2.50, 3, 4],
		id: '20c1a937-ca56-445d-8db4-f12c061578c8',
		comment: 'Telegrafvej 5A, 1., dør 2'
	},
	{
		position: [33.10, 5.7, -33.0],
		scale: [2.50, 3, 4],
		id: 'becf7c27-2435-43b5-81b5-bb573fc12dbb',
		comment: 'Telegrafvej 5A, 1., dør 1'
	},
	{
		position: [30.30, 5.7, -33.0],
		scale: [3.20, 3, 4],
		id: '3e3c976f-05de-4b7a-bad5-6eb2dc395ed7',
		comment: 'Telegrafvej 5A, 1., dør 32'
	},
	{
		position: [27.90, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'ad98c1e2-7499-4050-b0bd-5843d2892621',
		comment: 'Telegrafvej 5A, 1., dør 31'
	},
	{
		position: [26.25, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'cbeef8a8-507b-4503-92c8-e21b4bef3081',
		comment: 'Telegrafvej 5A, 1., dør 30'
	},
	{
		position: [24.60, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '54706994-8499-4e7c-84a1-1042d6793657',
		comment: 'Telegrafvej 5A, 1., dør 29'
	},
	{
		position: [22.95, 5.7, -32.0],
		scale: [1.65, 3, 6],
		id: '563d449e-04c7-42db-9160-d9251ad183a6',
		comment: 'Telegrafvej 5A, 1., dør 28'
	},
	{
		position: [21.35, 5.7, -32.0],
		scale: [1.60, 3, 6],
		id: 'ee8189e0-7540-4872-9145-02687c9a79ee',
		comment: 'Telegrafvej 5A, 1., dør 27'
	},
	{
		position: [19.30, 5.7, -32.0],
		scale: [2.50, 3, 6],
		id: 'ea33b8e9-280b-4770-b940-bfe577a79e8e',
		comment: 'Telegrafvej 5A, 3., dør 26'
	},
	{
		position: [16.6, 5.7, -32.0],
		scale: [2.85, 3, 6],
		id: 'c35b88dd-d66d-436d-9e92-ef049ae4cd21',
		comment: 'Telegrafvej 5A, 1., dør 25'
	},
	{
		position: [16.6, 5.7, -21.2],
		scale: [2.85, 3, 6],
		id: 'a7a2446b-35b7-44ff-8b86-a7fd956b3867',
		comment: 'Telegrafvej 5A, 1., dør 24'
	},
	{
		position: [19.3, 5.7, -21.2],
		scale: [2.5, 3, 6],
		id: 'cc9471d3-2251-49c9-a210-b35ff8f49d1c',
		comment: 'Telegrafvej 5A, 1., dør 23'
	},
	{
		position: [21.3, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'abd09b06-80ef-4969-91f3-3ff6bf6a6cf9',
		comment: 'Telegrafvej 5A, 1., dør 22'
	},
	{
		position: [22.9, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: '97cb468d-1852-4329-9a3d-74e75c3b906d',
		comment: 'Telegrafvej 5A, 1., dør 21'
	},
	{
		position: [24.55, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: '8745851f-657b-4a15-8431-c225d65846d1',
		comment: 'Telegrafvej 5A, 1., dør 20'
	},
	{
		position: [26.20, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'b43ea791-c52c-484b-8c0d-8aeb6819de61',
		comment: 'Telegrafvej 5A, 1., dør 19'
	},
	{
		position: [27.85, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'c2006d95-06a6-408b-8087-39a44eaaf2fa',
		comment: 'Telegrafvej 5A, 1., dør 18'
	},
	{
		position: [30.30, 5.7, -20.2],
		scale: [3.20, 3, 4],
		id: '65e5198b-c44a-4422-ad0c-98d50884d701',
		comment: 'Telegrafvej 5A, 1., dør 17'
	},
	{
		position: [33.10, 5.7, -20.2],
		scale: [2.50, 3, 4],
		id: '303ca521-9eea-416c-b694-0f8d97ed4866',
		comment: 'Telegrafvej 5A, 1., dør 16'
	},
	{
		position: [35.60, 5.7, -20.2],
		scale: [2.50, 3, 4],
		id: '424bbf7e-1c5c-4068-88d2-9e544c78f9c8',
		comment: 'Telegrafvej 5A, 1., dør 15'
	},
	{
		position: [37.65, 5.7, -21.2],
		scale: [1.55, 3, 6],
		id: '04469dbd-f2c6-427d-ac58-31ff084f954b',
		comment: 'Telegrafvej 5A, 1., dør 14'
	},
	{
		position: [39.2, 5.7, -21.2],
		scale: [1.55, 3, 6],
		id: 'cb897bd3-e5cc-4ad2-b74e-f1b89ed815e5',
		comment: 'Telegrafvej 5A, 1., dør 13'
	},
	{
		position: [40.80, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'ac5f764d-94f6-4612-b100-6f98e232fe6f',
		comment: 'Telegrafvej 5A, 1., dør 12'
	},
	{
		position: [42.45, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: '2c8bd9b2-1602-4f05-9afe-24423791cd27',
		comment: 'Telegrafvej 5A, 1., dør 11'
	},
	{
		position: [44.10, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: '8155640e-b2ca-4e15-939f-e652cb8bad29',
		comment: 'Telegrafvej 5A, 1., dør 10'
	},
	{
		position: [45.75, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'cd432ca5-c3f6-4211-9d8e-6140506b52cd',
		comment: 'Telegrafvej 5A, 1., dør 9'
	},
	{
		position: [47.40, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'a9cc69cc-778a-4891-b9d4-79cc16d0d0bb',
		comment: 'Telegrafvej 5B, 1., dør 26'
	},
	{
		position: [49.05, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: '61651d90-9ae0-4229-81c0-fac719f021c7',
		comment: 'Telegrafvej 5B, 1., dør 25'
	},
	{
		position: [50.65, 5.7, -21.2],
		scale: [1.60, 3, 6],
		id: 'd8977ef6-5612-4404-9baa-7b9c4fb5e857',
		comment: 'Telegrafvej 5B, 1., dør 24'
	},
	{
		position: [52.25, 5.7, -21.2],
		scale: [1.60, 3, 6],
		id: '0ea799f2-0fcb-4173-8e50-158d32f5f83c',
		comment: 'Telegrafvej 5B, 1., dør 23'
	},
	{
		position: [53.85, 5.7, -21.2],
		scale: [1.60, 3, 6],
		id: '1518b7fe-e7ff-4168-9308-1612a0c89dcc',
		comment: 'Telegrafvej 5B, 1., dør 22'
	},
	{
		position: [55.45, 5.7, -21.2],
		scale: [1.60, 3, 6],
		id: '98705437-1092-410a-8004-b9c7bd22ef8d',
		comment: 'Telegrafvej 5B, 1., dør 21'
	},
	{
		position: [57.05, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: '529ed03e-222a-49b6-a2cc-bd86e42d1977',
		comment: 'Telegrafvej 5B, 1., dør 20'
	},
	{
		position: [59.50, 5.7, -20.2],
		scale: [3.20, 3, 4],
		id: 'ed7fd71c-7d12-4f30-bf3d-e6a818e33827',
		comment: 'Telegrafvej 5B, 1., dør 19'
	},
	{
		position: [62.30, 5.7, -20.2],
		scale: [2.50, 3, 4],
		id: '2ed39983-45b2-435c-adfd-9bc81eefe8ba',
		comment: 'Telegrafvej 5B, 1., dør 18'
	},
	{
		position: [64.80, 5.7, -20.2],
		scale: [2.50, 3, 4],
		id: '57bde88a-f08b-4c75-9eda-43236b8ebe78',
		comment: 'Telegrafvej 5B, 1., dør 17'
	},
	{
		position: [66.90, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: '7ecec0fc-65b9-4fd8-8808-9239b821578a',
		comment: 'Telegrafvej 5B, 1., dør 16'
	},
	{
		position: [70.20, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'ab5add21-fb55-4556-81e8-4c078919f908',
		comment: 'Telegrafvej 5B, 1., dør 15'
	},
	{
		position: [68.55, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'a93ae5ea-606a-46fc-9fd6-e183c5bedd09',
		comment: 'Telegrafvej 5B, 1., dør 14'
	},
	{
		position: [71.85, 5.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'c51ccc07-ba33-46b0-8a39-b4c89d829c57',
		comment: 'Telegrafvej 5B, 1., dør 13'
	},
	{
		position: [73.50, 5.7, -21.2],
		scale: [1.60, 3, 6],
		id: '5d75b776-ff38-4574-b1c0-b3f34fc98c81',
		comment: 'Telegrafvej 5B, 1., dør 12'
	},
	{
		position: [75.5, 5.7, -21.2],
		scale: [2.50, 3, 6],
		id: 'c3a4da59-a1ff-460f-b9d6-f10ede8a3628',
		comment: 'Telegrafvej 5B, 1., dør 11'
	},
	{
		position: [78.1, 5.7, -21.2],
		scale: [2.8, 3, 6],
		id: 'fab19873-8ebe-4b63-959a-69b13c91f27b',
		comment: 'Telegrafvej 5B, 1., dør 10'
	},
	//  ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------
	//  ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------
	//  ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------
	//  ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------
	//  ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------ GROUND FLOOR ------
	{
		position: [78.1, 2.7, -32.0],
		scale: [2.9, 3, 6],
		id: 'f81b185b-45f5-4abb-912b-00c488040848',
		comment: 'Telegrafvej 5B, st., dør 9'
	},
	{
		position: [75.5, 2.7, -32.0],
		scale: [2.50, 3, 6],
		id: '6a6ba19a-0819-47d5-9e62-e6d84995b0a1',
		comment: 'Telegrafvej 5B, st., dør 8'
	},
	{
		position: [73.50, 2.7, -32.0],
		scale: [1.60, 3, 6],
		id: 'f760160c-f024-4bbb-8db3-c981bc3107a1',
		comment: 'Telegrafvej 5B, st., dør 7'
	},
	{
		position: [71.85, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '3afedb11-0cc9-429a-a109-ab3839bafa60',
		comment: 'Telegrafvej 5B, st., dør 6'
	},
	{
		position: [70.20, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '91bf5596-e910-408e-aa3f-3f09400d050a',
		comment: 'Telegrafvej 5B, st., dør 5'
	},
	{
		position: [68.55, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '6fecf28b-ed18-4042-b70a-456a3610acd2',
		comment: 'Telegrafvej 5B, st., dør 4'
	},
	{
		position: [66.90, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'ae813076-eb73-4bf0-a6ea-950502a5bccd',
		comment: 'Telegrafvej 5B, st., dør 3'
	},
	{
		position: [64.80, 2.7, -33.0],
		scale: [2.50, 3, 4],
		id: 'e9b8c220-0d29-4732-8c7f-75e0e51dc6f4',
		comment: 'Telegrafvej 5B, st., dør 2'
	},
	{
		position: [62.30, 2.7, -33.0],
		scale: [2.50, 3, 4],
		id: '97e89f36-3d39-4bf3-98bb-f3c62571e44f',
		comment: 'Telegrafvej 5B, 1., dør 1'
	},
	{
		position: [57.05, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '95c5ec2d-6c19-4e74-a117-42f2fc0e321a',
		comment: 'Telegrafvej 5B, st., dør 33'
	},
	{
		position: [55.45, 2.7, -32.0],
		scale: [1.60, 3, 6],
		id: '64c0414e-5926-420f-8ca0-5be36bac0597',
		comment: 'Telegrafvej 5B, st., dør 32'
	},
	{
		position: [53.85, 2.7, -32.0],
		scale: [1.60, 3, 6],
		id: '5a5eaa69-0c21-4239-8422-29a64a63f5e6',
		comment: 'Telegrafvej 5B, st., dør 31'
	},
	{
		position: [52.25, 2.7, -32.0],
		scale: [1.60, 3, 6],
		id: '8d628ff3-ebc1-4265-9fab-c6b90ca64be7',
		comment: 'Telegrafvej 5B, st., dør 30'
	},
	{
		position: [50.65, 2.7, -32.0],
		scale: [1.60, 3, 6],
		id: '546121f4-0235-4d0c-af3b-1b69d16f1ca6',
		comment: 'Telegrafvej 5B, st., dør 29'
	},
	{
		position: [49.05, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '7871494b-c3aa-4f6a-b0ff-d3ded229d609',
		comment: 'Telegrafvej 5B, st., dør 28'
	},
	{
		position: [47.40, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '2f6f3282-14fa-4199-b2c9-56d35005ef9c',
		comment: 'Telegrafvej 5B, st., dør 27'
	},
	{
		position: [45.75, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '7ca20493-756e-43c2-9863-00f7ae990bf9',
		comment: 'Telegrafvej 5A, st., dør 8'
	},
	{
		position: [44.10, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '006dd788-a8c7-40f1-bad1-bc7a48f6f301',
		comment: 'Telegrafvej 5A, st., dør 7'
	},
	{
		position: [42.45, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '3355c95d-79e7-4ca3-b593-4592ad6b63e7',
		comment: 'Telegrafvej 5A, st., dør 6'
	},
	{
		position: [40.80, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'b994cb7d-93ee-412c-bcb3-37e438c3df1d',
		comment: 'Telegrafvej 5A, st., dør 5'
	},
	{
		position: [39.2, 2.7, -32.0],
		scale: [1.55, 3, 6],
		id: '3dcd8355-3b8f-4ade-b3ef-4ac42eb55e56',
		comment: 'Telegrafvej 5A, st., dør 4'
	},
	{
		position: [37.65, 2.7, -32.0],
		scale: [1.55, 3, 6],
		id: '57409bcc-96d6-445f-aa5e-9a2850c49a30',
		comment: 'Telegrafvej 5A, st., dør 3'
	},
	{
		position: [35.60, 2.7, -33.0],
		scale: [2.50, 3, 4],
		id: 'b0032494-cd27-4e68-b0d2-19f1de427a50',
		comment: 'Telegrafvej 5A, st., dør 2'
	},
	{
		position: [33.10, 2.7, -33.0],
		scale: [2.50, 3, 4],
		id: 'ae5b501d-ce8e-4892-98da-33723021d33c',
		comment: 'Telegrafvej 5A, st., dør 1'
	},
	{
		position: [27.90, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '80d076e4-c9e1-40d1-8091-f4f8fbd1a383',
		comment: 'Telegrafvej 5A, st., dør 31'
	},
	{
		position: [26.25, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '5b66cc43-9dbe-40f5-abe6-bc2a9ca8c8ad',
		comment: 'Telegrafvej 5A, st., dør 30'
	},
	{
		position: [24.60, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: 'ed35e634-fdd4-45ac-83f9-03decfad2831',
		comment: 'Telegrafvej 5A, st., dør 29'
	},
	{
		position: [22.95, 2.7, -32.0],
		scale: [1.65, 3, 6],
		id: '4378f63e-ae5e-4071-b355-7682b430df34',
		comment: 'Telegrafvej 5A, st., dør 28'
	},
	{
		position: [21.35, 2.7, -32.0],
		scale: [1.60, 3, 6],
		id: 'fc7957b1-c4f4-4438-9926-f7a25266cf55',
		comment: 'Telegrafvej 5A, st., dør 27'
	},
	{
		position: [19.30, 2.7, -32.0],
		scale: [2.50, 3, 6],
		id: 'a9834896-d5e4-496f-9c92-35deda842858',
		comment: 'Telegrafvej 5A, st., dør 26'
	},
	{
		position: [16.6, 2.7, -32.0],
		scale: [2.85, 3, 6],
		id: '3962e415-b089-4c74-9b90-03ab935610ef',
		comment: 'Telegrafvej 5A, st., dør 25'
	},
	{
		position: [16.6, 2.7, -21.2],
		scale: [2.85, 3, 6],
		id: 'd0702b43-6ed4-481e-8c45-4ea848dabc23',
		comment: 'Telegrafvej 5A, st., dør 24'
	},
	{
		position: [19.3, 2.7, -21.2],
		scale: [2.5, 3, 6],
		id: 'ead784cf-17d4-46c3-8c4f-6eb089ec4d99',
		comment: 'Telegrafvej 5A, st., dør 23'
	},
	{
		position: [21.3, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '46d9921b-65bf-4b94-b005-2bf632543014',
		comment: 'Telegrafvej 5A, st., dør 22'
	},
	{
		position: [22.9, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '2dcb9bcc-710e-4568-947e-330bd1c30dab',
		comment: 'Telegrafvej 5A, st., dør 21'
	},
	{
		position: [24.55, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '0b624745-66b9-4b88-8bb9-833e7f08964c',
		comment: 'Telegrafvej 5A, st., dør 20'
	},
	{
		position: [26.20, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '817f4be5-ec60-433c-affd-9604397502af',
		comment: 'Telegrafvej 5A, 3., dør 19'
	},
	{
		position: [27.85, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '63136eeb-e2e6-4a13-932f-6702572e908d',
		comment: 'Telegrafvej 5A, 3., dør 18'
	},
	{
		position: [30.30, 2.7, -20.2],
		scale: [3.20, 3, 4],
		id: '14307b66-6847-4b2b-8a27-cd448df6ce1d',
		comment: 'Telegrafvej 5A, st., dør 17'
	},
	{
		position: [33.10, 2.7, -20.2],
		scale: [2.50, 3, 4],
		id: '69f8f313-47d4-4129-b0e6-79a125af58bc',
		comment: 'Telegrafvej 5A, st., dør 16'
	},
	{
		position: [35.60, 2.7, -20.2],
		scale: [2.50, 3, 4],
		id: '04773170-aba2-4928-89db-2d606a60c676',
		comment: 'Telegrafvej 5A, st., dør 15'
	},
	{
		position: [37.65, 2.7, -21.2],
		scale: [1.55, 3, 6],
		id: '8863480d-eb74-431b-b0da-e7bf1754d3ee',
		comment: 'Telegrafvej 5A, st., dør 14'
	},
	{
		position: [39.2, 2.7, -21.2],
		scale: [1.55, 3, 6],
		id: 'e98181ed-036c-473b-9f84-0037f4faaa4e',
		comment: 'Telegrafvej 5A, st., dør 13'
	},
	{
		position: [40.80, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'a85accea-c167-4496-8f6e-c9ea7b2222c8',
		comment: 'Telegrafvej 5A, st., dør 12'
	},
	{
		position: [42.45, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '15b17791-3237-42d6-8e1e-900f04912d96',
		comment: 'Telegrafvej 5A, st., dør 11'
	},
	{
		position: [44.10, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '228b10aa-cfab-462a-90f3-c52336f0862a',
		comment: 'Telegrafvej 5A, st., dør 10'
	},
	{
		position: [45.75, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'c2f450a0-e767-40cf-91a4-d4c30258968e',
		comment: 'Telegrafvej 5A, st., dør 9'
	},
	{
		position: [47.40, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '62185471-7d49-43fc-a9a5-200d4f4e577e',
		comment: 'Telegrafvej 5B, st., dør 26'
	},
	{
		position: [49.05, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '75259ac2-3915-478f-9d7a-a04cfd3bed90',
		comment: 'Telegrafvej 5B, st., dør 25'
	},
	{
		position: [50.65, 2.7, -21.2],
		scale: [1.60, 3, 6],
		id: 'db81b5f8-0fdd-4779-aee3-94b3ba567fd0',
		comment: 'Telegrafvej 5B, st., dør 24'
	},
	{
		position: [52.25, 2.7, -21.2],
		scale: [1.60, 3, 6],
		id: '136e2577-a92b-44eb-9051-03e07e36f8e5',
		comment: 'Telegrafvej 5B, st., dør 23'
	},
	{
		position: [53.85, 2.7, -21.2],
		scale: [1.60, 3, 6],
		id: '68940c37-a5e8-4748-9a47-0c4c5872dfd9',
		comment: 'Telegrafvej 5B, st., dør 22'
	},
	{
		position: [55.45, 2.7, -21.2],
		scale: [1.60, 3, 6],
		id: '63cb3a5e-f2ed-410c-8bb7-269c394399bb',
		comment: 'Telegrafvej 5B, st., dør 21'
	},
	{
		position: [57.05, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '75470a07-498c-4622-99bc-e9d9c152e59d',
		comment: 'Telegrafvej 5B, st., dør 20'
	},
	{
		position: [59.50, 2.7, -20.2],
		scale: [3.20, 3, 4],
		id: '7a28937e-fb8c-44fd-a8d0-78f7d6fa3602',
		comment: 'Telegrafvej 5B, st., dør 19'
	},
	{
		position: [62.30, 2.7, -20.2],
		scale: [2.50, 3, 4],
		id: '318bec0b-349e-4fa8-8757-e0147ba390ef',
		comment: 'Telegrafvej 5B, st., dør 18'
	},
	{
		position: [64.80, 2.7, -20.2],
		scale: [2.50, 3, 4],
		id: '5a85726e-1787-4f25-bd94-5fa3be0716a7',
		comment: 'Telegrafvej 5B, 3., dør 17'
	},
	{
		position: [66.90, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '7edacbd0-afed-4eb5-bc99-bc1208e0bb70',
		comment: 'Telegrafvej 5B, st., dør 16'
	},
	{
		position: [70.20, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'f8d87d24-c2b0-419e-a087-eb452e83c245',
		comment: 'Telegrafvej 5B, st., dør 15'
	},
	{
		position: [68.55, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: '9908aa0e-13aa-4f6f-9e19-2a526f854728',
		comment: 'Telegrafvej 5B, st., dør 14'
	},
	{
		position: [71.85, 2.7, -21.2],
		scale: [1.65, 3, 6],
		id: 'eefc93fd-0e83-4529-a818-a50be8109eab',
		comment: 'Telegrafvej 5B, st., dør 13'
	},
	{
		position: [73.50, 2.7, -21.2],
		scale: [1.60, 3, 6],
		id: 'e4c8fa10-ee20-46b0-8ee8-96970d12e891',
		comment: 'Telegrafvej 5B, st., dør 12'
	},
	{
		position: [75.5, 2.7, -21.2],
		scale: [2.50, 3, 6],
		id: '2029588a-dc7d-4611-b159-49a77e63f23d',
		comment: 'Telegrafvej 5B, st., dør 11'
	},
	{
		position: [78.1, 2.7, -21.2],
		scale: [2.8, 3, 6],
		id: '69c6511d-69ee-4d63-b409-cf181bbc1948',
		comment: 'Telegrafvej 5B, st., dør 10'
	},
];

const camera = new THREE.PerspectiveCamera(
	45,
	window.innerWidth / window.innerHeight,
	1,
	1000
);


const Isometry = (props) => {

	const [clickedTenancy, setClickedTenancy] = useState(false);

	function Box(props) {
		const mesh = useRef();
		const [baseColor, setBaseColor] = useState(false);
		const [data, setData] = useState(null);

		useEffect(() => {
			if (props.joratoId) {
				const temp = getTenancyDataFromId(props.joratoId);
				setData(temp);
				if (temp === 'Unavailable') {
					setBaseColor('#8d4a58');
				} else if (temp.state === 'Available') {
					setBaseColor('#7eb080');
				} else if (temp.state === 'Reserved') {
					setBaseColor('#c6c61b');
				}
			}
		}, [props.joratoId]);

		const handleClick = (e) => {
			if (data.state === 'Available' || data.state === 'Reserved') {
				setClickedTenancy(data);
				
			}
		};

		return (
			<group {...props} onClick={handleClick}>
				<mesh ref={mesh}>
					<boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
					<meshBasicMaterial attach="material" color={baseColor} opacity={0.5} transparent />
				</mesh>
				<mesh ref={mesh}>
					<boxBufferGeometry attach="geometry" args={[1.02, 1.02, 1.02]} />
					<meshBasicMaterial attach="material" color="#000000" wireframe  />
				</mesh>
			</group>
		);
	}

	const getTenancyDataFromId = (id) => {
		const tenancy = props.tenancies.find((tenancy) => tenancy.id === id);
		return tenancy ? tenancy : 'Unavailable';
	};

	var radius = 65;

	// camera.position.set(47, 40, 40);
	function animate(val) {
		camera.position.x = radius * Math.sin(-val / 100 * 2 * Math.PI) + 47;
		camera.position.z = radius * Math.cos(-val / 100 * 2 * Math.PI) - 25;
	}

	return (
		<Wrapper>
			<ClickedTenancyContainer>
				{clickedTenancy && (
					<TenancyCardSmall
						key={clickedTenancy.id}
						name={clickedTenancy.title}
						image={clickedTenancy.images[0]?.original}
						price={clickedTenancy.monthlyRent.value}
						availableFrom={clickedTenancy.availableFrom}
						rooms={clickedTenancy.rooms}
						size={clickedTenancy.size.value}
						id={clickedTenancy.id}
						street={clickedTenancy.address.street}
						floorplan={clickedTenancy.floorPlans[0]?.url}
					/>
				)}
			</ClickedTenancyContainer>
			<ModelWrapper>
				<Suspense fallback={<Loader />}>
					<Canvas camera={camera}>
						<ambientLight />
						<OrbitControls enableZoom={false} enableRotate={false} target={[47,10,-25.0]} maxPolarAngle={1.25} minPolarAngle={1.25} />
						<Model />
						{GefionsKollegietBoxes.map((box, index) => (
							<Box key={index} position={box.position} scale={box.scale} joratoId={box.id} />
						))}
					</Canvas>
					<Slider changeSlider={(val) => animate(val)} />
				</Suspense>
			</ModelWrapper>
		</Wrapper>
	);
};

Isometry.propTypes = {
	loading: PropTypes.bool,
	tenancies: PropTypes.array,
};

export default Isometry;