import React from 'react';
import styled from 'styled-components';
import background from 'assets/images/about-us-4.jpg';
import Intro from './components/Intro';
import Services from './components/Services';
import Norse from './components/Norse';
import Vision from './components/Vision';
import { device } from 'helpers/device';

const Wrapper = styled.div`
	max-width: 1120px;
	margin: 0 auto;
	padding: 0px 0px;	
	background-color: white;

	.background {
		display: block;
		object-fit: cover;
		margin: 0 auto;
		width: 100%;

		@media ${device.laptop} { 
			max-width: 1200px;
			margin-bottom: 64px;
		}
	}
`;

const AboutUs = () => {

	return (
		<Wrapper>
			<img className="background" src={background} />
			<Intro/>
			<Services/>
			<Norse/>
			<Vision/>
		</Wrapper>
	);
};

export default AboutUs;