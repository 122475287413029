// @ts-nocheck

import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ImageMapper from "react-img-mapper";
import RotateToLandScapeAnimation from "assets/lotties/rotate-to-landscape.json";
import { Player } from "@lottiefiles/react-lottie-player";

const Wrapper = styled.div`
	width: 100%;
	border: none;
	max-width: 1280px;
	margin: auto;
	position: relative;

	area {
		display: block;
		position: absolute;
	}

	area::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 255, 0.3);
	}
`;
const AnimationWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
`;

const ImageMap = (props) => {
	const [parentWidth, setParentWidth] = useState(0);
	const imgMapperRef = useRef();

	const displayChangeOrientationIcon = window.matchMedia(
		"(max-width: 768px) and (orientation: portrait)"
	).matches;

	const {
		imageWidth,
		json,
		image, // should be 1280px width
		onClick
	} = props;

	useEffect(() => {
		function updateSize() {
			const parentNode = imgMapperRef.current.parentNode;
			const parentStyle = window.getComputedStyle(parentNode);
			setParentWidth(parseFloat(parentStyle.width));
		}

		window.addEventListener("resize", updateSize);
		updateSize(); // initialize size

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	return (
		<Wrapper ref={imgMapperRef}>
			<AnimationWrapper
				style={{
					display: displayChangeOrientationIcon ? "bloack" : "none",
				}}
			>
				<Player
					autoplay
					loop
					src={RotateToLandScapeAnimation}
					style={{ height: "150px", width: "150px" }}
				></Player>
			</AnimationWrapper>
			<ImageMapper
				src={image}
				map={{
					name: "my-map",
					areas: json,
				}}
				width={imageWidth}
				imgWidth={imageWidth}
				onClick={onClick}
				responsive={true}
				parentWidth={parentWidth}
				areaStyles={(area, index) => ({
					cursor: area.isActive ? "pointer" : "default",
				})}
			/>
		</Wrapper>
	);
};

ImageMap.propTypes = {
	imageWidth: PropTypes.number,
	json: PropTypes.array,
	image: PropTypes.string,
	onClick: PropTypes.func
};

ImageMap.defaultProps = {
	imageWidth: 1280,
	json: [],
	image: "",
	onClick: () => {}
};

export default ImageMap;
