import React from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import Line from 'components/Line';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	width: 100%;
	max-width: 1440px;
	margin: auto;
	display: flex;
	flex-direction: column;
	position: relative;
    z-index: 1;
	overflow: hidden;
	
	@media ${device.laptop} { 
		padding: 60px 0px;	
		flex-direction: row;
	}
`;
const ImageWrapper = styled.div`
	position: relative;
	z-index: -1;
	flex: 1.6;
	height: 500px;
    padding: 32px 16px;
    min-height: 400px;
	margin-bottom: 32px;
	

	@media ${device.laptop} { 
		padding: 0px;
		max-width: unset;
	}
`;
const Image = styled.div`
	background-image: url(${(props) => props.background});
	background-size: cover;
	background-position-x: center;
	width: 100%;
	height: 100%;
	z-index: 10;
	position: relative;
	min-height: 400px;
	max-width: 490px;
    margin: auto;
	@media ${device.laptop} { 
		margin: unset;
		max-width: unset;
	}
`;
const BackgroundSquare = styled.div`
	position: absolute;
    width: 80%;
    height: 80%;
	background: #C49A36;
	z-index: 0;
    bottom: 7px;
	right: -9px;

	@media ${device.laptop} { 
		bottom: -25px;
		right: -25px;
	}
`;
const ContentBox = styled.div`
	margin: 32px 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;

	@media ${device.laptop} { 
		order: 1;
		margin-right: 46px;
    	margin-left: 80px;
	}
`;
const Text = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: -0.502564px;
	color: ${p => p.theme.colors.text.secondary};
	max-width: 490px;
	margin-bottom: 40px;
	
`;
const LimitedWidthTitle = styled(Title)`
	max-width: 428px;
`;

const TextWithImageLeft2 = (props) => {

	const {
		background,
		title,
		text,
		button
	} = props;

	return (
		<Wrapper>
			<ImageWrapper>
				<Image background={background} />
				<BackgroundSquare />
			</ImageWrapper>
			<ContentBox>
				<Line />
				<LimitedWidthTitle>{title}</LimitedWidthTitle>
				<Text>{text}</Text>
				{button}
			</ContentBox>
		</Wrapper>
	);
};

TextWithImageLeft2.propTypes = {
	background: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	button: PropTypes.node
};

export default TextWithImageLeft2;