import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";
import BookShowcasingButton from "../BookShowcasingButton";
import { ReactComponent as Logo } from "assets/properties/kgv/frederiksberg.svg";
import Image from "assets/properties/kgv/kgv-05.jpg";
import Background from "assets/properties/kgv/background.png";

const Wrapper = styled.div`
	position: relative;
	background-color: #fff;
	display: flex;
	display: grid;
	min-height: 400px;
	padding-top: 100px;
	padding-bottom: 100px;
	background-image: url(${Background});

	p,
	h2 {
	}

	h2 {
		font-size: 24px;
		font-weight: 500;
	}
	p {
		font-size: 12px;
		font-weight: 400;
	}
	img {
		margin-left: auto;
	}

	.inner {
		display: flex;
		flex-direction: column;
	}

	.section1 {
		margin-left: 20px;
		margin-right: 20px;
	}

	@media ${device.laptop} {
		width: 100vw;
		margin-left: -50vw;
		margin-right: -50vw;
		position: relative;
		left: 50%;
		right: 50%;
		background-color: #fff;

		.inner {
			width: 1120px;
			max-width: 1120px;
			margin: auto;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.section1 {
				max-width: 420px;
				margin-left: 0px;
				margin-right: 0px;
			}

			img {
				width: 593px;
				height: 559px;
				right: -77px;
        		position: relative;
			}
		}
	}
`;

const Button = styled.div`
	margin-top: 31px;
	background-color: #003202;
	height: 42px;
	padding-left: 20px;
	width: fit-content;
	padding-right: 20px;
	color: white;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-left: 20px;
	margin-bottom: 80px;
`;

const InfoSection = () => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<div className='inner'>
				<div className='section1'>
					<h2>Med tanke for dig og dine næste.</h2>
					<p>
						Der er ikke kun lagt tid og tanke i de indvendige løsninger. Ejendommen er opført med de gamle
						mursten, der er blevet renset og har fået nyt liv. Udover at have en positiv effekt på
						CO2-aftrykket medvirker de genbrugte materialer til en charme og fører historien videre og det,
						der engang var en ikonisk vaffelfabrik.
					</p>
					<BookShowcasingButton />
				</div>
				<img height={337} width={318} src={Image} />
			</div>
		</Wrapper>
	);
};

export default InfoSection;
