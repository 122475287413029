import styled from 'styled-components';

const Title = styled.h2`
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 40px;
	letter-spacing: -1.25641px;
	color: ${p => p.theme.colors.text.primary};
	margin: unset;
	margin-bottom: 24px;
`;

export default Title;