const formatPhoneNumber = (phoneNumber) => {
	if (phoneNumber) {
		const noSpaces = phoneNumber.replace(/\s/g, '');
		let noCountryCode;
		noCountryCode = noSpaces.startsWith('+45') ? noSpaces.substring(3) : noSpaces;
		if (noCountryCode.length === 8) {
			const withSpaces = noCountryCode.substring(0, 4) + ' ' + noCountryCode.substring(4, noCountryCode.length);
			const withCountryPrefix = '+45 ' +  withSpaces;
			return withCountryPrefix;
		} else {
			return '-';
		} 
	} else {
		return null;
	}
	
};	

export default formatPhoneNumber;