import React from 'react';
import styled from 'styled-components';
import Line from 'components/Line';
import Title from 'components/Title';
import IconWithText from 'components/IconWithText';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding: 32px 16px;	
	box-sizing: border-box;
	@media ${device.laptop} { 
		padding-left: 80px;
		padding-right: 80px;
	}
`;

const FacilitiesContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: top;
	justify-content: top;
	flex-wrap: wrap;

	@media ${device.laptop} { 
		
	}
`;

const FacilitiesGrid = (props) => {

	const { t } = useTranslation();

	const {
		facilities
	} = props;

	return (
		<Wrapper>
			<Line />
			<Title>{t('properties.general.facilitiesTitle')}</Title>
			<FacilitiesContainer>
				{facilities.map(f => {
					return (
						<IconWithText
							icon={f.icon}
							text={f.text}
							key={f.text}
						/>
					);
				})}
			</FacilitiesContainer>
		</Wrapper>
	);
};

FacilitiesGrid.propTypes = {
	facilities: PropTypes.array,
};

export default FacilitiesGrid;