import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as ThreeDIcon } from 'assets/icons/3d-box-loading.svg';

const Wrapper = styled.div`
	width: 100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
	background-color: #FAFAFA;
	padding-bottom: 80px;
`;
const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(${(props) => props.background});
	background-opacity: 0.1;
	background-size: cover;
	background-position: center;
	width: 100%;
	padding-left: 33px;
    padding-right: 33px;
    box-sizing: border-box;
	margin-top: -60px;
	padding-top: 60px;
	background-color: #fafafa;
`;
const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 140%;
	margin-top: 140px;
	text-align: center;
	max-width: 800px;
`;
const Text = styled.p`
	color: #384336;
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	leading-trim: both;
	text-edge: cap;
	text-align: center;
	margin-bottom: 60px;
	max-width: 600px;
`;
const TextAdditional = styled.span`
	display: none;
	@media ${device.laptop} {
		display: inline;
	}
`;
const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 5px 25px;
	gap: 10px;
	cursor: pointer;
	
	width: 226px;
	height: 50px;

	background: #3E5042;
	border-radius: 60px;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	color: #FAFAFA;
	border: unset;
	svg {
		stroke: #fff;
	}
	margin-bottom: 40px;
`;

const BoxesContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 1280px;
	margin: auto;
	gap: 25px;
    margin: 20px;
	@media ${device.tablet} {
		gap: 50px;
	}
	@media ${device.laptop} {
		margin: 0 auto;
	}
`;

const BoxContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 300px;
	padding: 28px;
	background-color: #fff;
	border-radius: 4px;
	flex: 1;
	
`;
const BoxIcon = styled.div`
	display: flex;
	margin-bottom: 24px;

	svg {
		stroke: #3E5042;
	}
`;
const BoxText = styled.p`
	font-size: 18px;
	max-width: 280px;
`;

const FactSection1 = (props) => {

	const {
		background,
		title,
		textBase,
		textAdditional,
		buttonText,
		buttonOnClick,
		boxes,
	} = props;

	return (
		<Wrapper>
			<MainContainer background={background}>
				<Title>{title}</Title>
				<Text>{textBase}<TextAdditional>{textAdditional}</TextAdditional></Text>
				{buttonText && <Button onClick={buttonOnClick}><ThreeDIcon/>{buttonText}<ArrowIcon/></Button>}
			</MainContainer>
			<BoxesContainer>
				{boxes.map((box, index) => (
					<BoxContainer key={index}>
						<BoxIcon>{box.icon}</BoxIcon>
						<BoxText>{box.pre}<strong>{box.bold}</strong>{box.after}</BoxText>
					</BoxContainer>
				))}
			</BoxesContainer>
		</Wrapper>
	);
};

FactSection1.propTypes = {
	background: PropTypes.string,
	title: PropTypes.string,
	textBase: PropTypes.string,
	textAdditional: PropTypes.string,
	buttonText: PropTypes.string,
	buttonOnClick: PropTypes.func,
	boxes: PropTypes.array,
};

export default FactSection1;