import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";
import { ReactComponent as FacilityIcon1 } from "assets/icons/parking3.svg";
import { ReactComponent as FacilityIcon2 } from "assets/icons/elevator.svg";
import { ReactComponent as FacilityIcon3 } from "assets/icons/deposit.svg";
import { ReactComponent as FacilityIcon4 } from "assets/icons/nature.svg";
import { ReactComponent as FacilityIcon5 } from "assets/icons/bicycle-parking.svg";
import { ReactComponent as FacilityIcon6 } from "assets/icons/community-house.svg";
import BuildingImage from "assets/properties/kgv/building.png";

const Wrapper = styled.div`
	position: relative;
	background-color: #fef6e5;
	display: flex;
	display: grid;
	min-height: 400px;
	padding-top: 100px;
	padding-bottom: 100px;

	p,
	h2 {
		margin-left: 20px;
		margin-right: 20px;
	}

	h2 {
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 60px;
	}
	p {
		font-size: 12px;
		font-weight: 400;
	}

	img {
		height: 1157px;
		width: 100%;
		object-fit: cover;
		object-position: -82px;
	}

	svg {
		height: 60px;
		width: 60px;
		stroke: #003202;
		fill: #003202;

		path {
			fill: #003202;
		}
	}

	@media ${device.laptop} {
		padding-top: 120px;
		width: 100vw;
		margin-left: -50vw;
		margin-right: -50vw;
		position: relative;
		left: 50%;
		right: 50%;

		img {
			height: unset;
			object-position: unset;
		}

		.inner {
			width: 1120px;
			max-width: 1120px;
			margin: auto;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			position: relative;
			left: 460px;
			margin-bottom: 340px;

			h2 {
				margin-left: 200px;
			}
		}
	}

	@media ${device.laptopL} {
		.inner {
			left: 510px;
			margin-bottom: 240px;
		}
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 40px 16px;
	padding: 0px 20px;
	margin-bottom: 100px;

	@media ${device.laptop} {
		grid-template-columns: repeat(1, 1fr);
		width: fit-content;
		position: absolute;
		left: 160px;
		top: 130px;
	}
`;

const GridItem = styled.div`
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 16px;

	p {
		max-width: 110px;
	}

	.icon {
		height: 40px;
		width: 40px;
		background-color: #f0f0f0;
	}
`;

const FacilitiesSection = () => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<div className='inner'>
				<h2>Faciliteter</h2>
				<Grid>
					<GridItem>
						<FacilityIcon1 />
						<p>Privat parkeringskælder</p>
					</GridItem>
					<GridItem>
						<FacilityIcon2 />
						<p>Elevator</p>
					</GridItem>
					<GridItem>
						<FacilityIcon3 />
						<p>Særskilt opbevaring</p>
					</GridItem>
					<GridItem>
						<FacilityIcon4 />
						<p>Grønne fællesarealer</p>
					</GridItem>
					<GridItem>
						<FacilityIcon5 />
						<p>Aflåst cykelparkering</p>
					</GridItem>
					<GridItem>
						<FacilityIcon6 />
						<p>Fælleslokale</p>
					</GridItem>
				</Grid>
			</div>
			<img src={BuildingImage} />
		</Wrapper>
	);
};

export default FacilitiesSection;
