import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {ReactComponent as CloseIcon} from 'assets/icons/close.svg';

const customStyles = {
	overlay: {
		zIndex: 1000,
		backgroundColor: 'rgb(56 67 54 / 90%)',	
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '24px',
		outline: 'none',
	},
	content: {
		position: 'relative',
		border: 'none',
		borderRadius: '25px',
		backgroundColor: '#fff',
		maxWidth: '800px',
		minHeight: '300px',
		minWidth: '380px',
		outline: 'none',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '80vw',
		padding: '24px',
		boxSizing: 'border-box',
	},
};

const InterestListWrapper = styled.div`
`;

const ModalContentContainer = styled.div`
	max-width: 600px;
	min-width: 340px;
`;

const Title = styled.h1`
	font-size: 40px;
	color: #3E5042;
	font-weight: 700;
	text-align: center;
`;

const Description = styled.p`
	font-size: 24px;
	color: #3E5042;
	font-weight: 400;
	text-align: center;
	padding-bottom: 40px;
`;

const ConfirmedDescription = styled.p`
	font-size: 20px;
	color: #3E5042;
	font-weight: 400;
	text-align: center;
`;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 24px;

	label {
		color: rgb(56 67 54 / 60%);
	}
	input {
		color: #3E5042;
		border: none;
		font-size: 16px;
		padding-top: 4px;
		padding-bottom: 4px;
		border-bottom: 1px solid grey;
		outline: none;
		text-transform: capitalize;
	}
	input#email {
		text-transform: unset;
	}
	input#email::first-word {
		text-transform: capitalize;
	}
`;

const CloseButton = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	svg {
		height: 60px;
		width: 60px;
	}
`;


const GenericPopup = props => {

	const {
		modalIsOpen,
		setModalIsOpen,
		title,
		description
	} = props;
	
	const { t } = useTranslation();

	const closeModal = () => setModalIsOpen(false);
	
	return (
		<InterestListWrapper>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				className={'interestlist-modal'}
				ariaHideApp={false}
			>	
				<CloseButton onClick={closeModal}>
					<CloseIcon />
				</CloseButton>
				<ModalContentContainer>
					<>
						<Title>{title}</Title>
						<Description>{description}</Description>
					</>
				</ModalContentContainer>
			</Modal>
		</InterestListWrapper>
	);
};

export default GenericPopup;