import React from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import Line from 'components/Line';
import TenancyCard from 'components/TenancyCard';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	padding: 0px 16px;	
	padding-top: 40px;
	width: 100%;
	max-width: 1120px;
	box-sizing: border-box;
	margin: auto;
	background-color: white;

	@media ${device.laptop} { 
		padding: 16px 0px;
		padding-left: 80px;
    	padding-right: 80px;
	}
`;
const TopSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
const ListSection = styled.div`
	display: grid;
	grid-template-columns: auto;
	grid-row-gap: 19px;
	grid-column-gap: 19px;
	@media ${device.laptop} { 
		grid-template-columns: auto auto auto;
	}
`;
const Left = styled.div`
	display: flex;
	flex-direction: column;
`;


const SimilarTenanciesSection = (props) => {

	const { tenancy, tenancies, loading } = props;
	const { t } = useTranslation();

	if (loading) {
		return (
			<Loader/>
		);
	}
	function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
		// set default valeu if any long/lats are missing
		if (!lat1 || !lon1 || !lat2 || !lon2) return 9999;

		var R = 6371; // Radius of the earth in km
		var dLat = deg2rad(lat2-lat1);  // deg2rad below
		var dLon = deg2rad(lon2-lon1); 
		var a = 
			Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
			Math.sin(dLon/2) * Math.sin(dLon/2); 
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
		var d = R * c; // Distance in km
		return d;
	}
	function deg2rad(deg) {
		return deg * (Math.PI/180);
	}
	const nearbyTenancies = tenancies.items
		.filter((t) => {
			return t.id !== tenancy.id;
		})
		.filter((t) => {
			return t.visibility === 'Public';
		})
		.map((t) => {
			const d = getDistanceFromLatLonInKm(tenancy.address.coordinates?.latitude, tenancy.address.coordinates?.longitude, t.address.coordinates?.latitude, t.address.coordinates?.longitude);
			t.distance = d; 
			return t; })
		.sort((a, b) => {
			return a.distance- b.distance;
		}).slice(0, 3);

	return (
		<Wrapper>
			<TopSection>
				<Left>
					<Line />
					<Title>{t('tenancy.nearbyTenancies')}</Title>
				</Left>
				{/* <Right>
					<Button>See All</Button>
				</Right> */}
			</TopSection>
			<ListSection>
				{nearbyTenancies.map(t => {
					return (
						<TenancyCard 
							key={t.id}
							name={t.title} 
							images={t.images}
							price={t.monthlyRent.value}
							availableFrom={t.availableFrom}
							rooms={t.rooms}
							size={t.size.value}
							id={t.id}
							street={t.address.street}
							location={`${t.address.zipCode} ${t.address.city}`}
							floorplan={t.floorPlans[0]?.url}
							isReserved={t.state === 'Reserved'}
						/>
					);
				})}
			</ListSection>
		</Wrapper>
	);
};

SimilarTenanciesSection.propTypes = {
	tenancy: PropTypes.object,
	tenancies: PropTypes.object,
	loading: PropTypes.bool,
};

export default SimilarTenanciesSection;