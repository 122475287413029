import React from 'react';
import styled from 'styled-components';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	margin-top: 80px;
	margin-bottom: 80px;
	display: flex;
	flex-direction: column;
	max-width: 1280px;
	margin: auto;
	margin-bottom: 80px;
	margin-top: 80px;
`;
const Line = styled.div`
	width: 80px;
	height: 5px;
	background-color: #C49A36;
	margin: auto;	
`;
const BoxWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 80px;
`;
const Box = styled.div`
	margin: 0px 20px;
	padding: 20px;
	width: calc(20% - 80px);
	max-width: 300px;
	svg {
		max-width: 45px;
		height: 60px;
		margin-bottom: 15px;
	}
`; 
const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 140%;
	margin-top: 30px;
	text-align: center;	
	color: #3E5042;
	max-width: 350px;
	margin-left: auto;
    margin-right: auto;
`;
const Text = styled.p`
	color: #384336;
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	// margin-bottom: 60px;
	max-width: 350px;
`;


const FacilitiesSection1 = (props) => {

	const {
		title,
		facilities
	} = props;

	return (
		<Wrapper>
			<Line />
			<Title>{title}</Title>
			<BoxWrapper>
				{facilities.map((facility, index) => (
					<Box key={index}>
						{facility.icon}
						<Text>{facility.text}</Text>
					</Box>
				))}
			</BoxWrapper>
		</Wrapper>
	);
};

FacilitiesSection1.propTypes = {
	title: PropTypes.string,
	facilities: PropTypes.array
};

export default FacilitiesSection1;