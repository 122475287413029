import React from 'react';
import styled from 'styled-components';
import useKeypress from 'components/useKeyPress';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import ReactSlider from 'react-slider';
import formatPrice from 'helpers/formatPrice';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import da from 'date-fns/locale/da';
import { useTranslation } from 'react-i18next';
registerLocale('da', da);
import { device } from 'helpers/device';
import PropTypes from 'prop-types';


const SideDrawerWrapper = styled.nav`
	min-width: 280px;
	height: 100vh;
	z-index: 1000;
	position: fixed;
	background-color: white;
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
	padding: 24px;
    box-sizing: border-box;
	overflow-y: scroll;
	@media ${device.laptop} { 
		width: 400px;
		padding: 24px 48px;
	}


	&.visible {
		transform: translateX(0);
	}

	div.title {
		font-size: 18px;
		padding: 48px 24px;
		text-align: center;

		svg {
			padding-right: 16px;
		}
	}

	.filter {
		padding: 24px 0px;

		input[type='checkbox'] {
			margin: 10px;
		}
	}

	.slider-wrapper {
		padding: 12px 0px;
		height: 24px;
		width: 100%;
	}

	.slider-thumb {
		color: ${p => p.theme.colors.white.primary};
		background: ${p => p.theme.colors.ascent.primary};
		border-radius: 12px;
		padding: 12px;
	}
	.slider-track {
		top: 22px;
		height: 4px;
		background: ${p => p.theme.colors.off.primary};
	}

	.slider-track-1 {
		background: ${p => p.theme.colors.ascent.secondary};
	}
	.filter-value {
		text-align: center;
	}

	.features-filter {
		/* The container */
		.container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-top: 8px;
		margin-bottom: 12px;
		cursor: pointer;
		
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		}

		/* Hide the browser's default checkbox */
		.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		}

		/* Create a custom checkbox */
		.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		}

		/* On mouse-over, add a grey background color */
		.container:hover input ~ .checkmark {
		background-color: #ccc;
		}

		/* When the checkbox is checked, add a blue background */
		.container input:checked ~ .checkmark {
		background-color: ${p => p.theme.colors.ascent.primary};
		}

		/* Create the checkmark/indicator (hidden when not checked) */
		.checkmark:after {
		content: "";
		position: absolute;
		display: none;
		}

		/* Show the checkmark when checked */
		.container input:checked ~ .checkmark:after {
		display: block;
		}

		/* Style the checkmark/indicator */
		.container .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		}
	}

	.availability-filter {
		.react-datepicker-wrapper {
			padding: 12px 0px;

			input {
				height: 24px;
				padding: 4px 8px;
				font-size: 16px;
				border: 1px solid ${p => p.theme.colors.ascent.primary};
				border-radius: 3px;
			}

			
		}
		.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
			background-color: ${p => p.theme.colors.ascent.primary};
		}
		.react-datepicker__header {
			background-color: ${p => p.theme.colors.ascent.primary};
		}
		.react-datepicker__current-month, .react-datepicker__day-name {
			color: white;
		}
	}
`;

const Backdrop = styled.div`
	width: 100vw;
	height: 100vh;
	display: none;
	position: fixed;
	background-color: #0000006b;
	z-index: 900;

	&.visible {
		display: block;
	}
`;

const SideDrawer = (props) => {

	const { 
		visible, 
		setVisible, 
		rentFilterValue, 
		setRentFilterValue, 
		sizeFilterValue, 
		setSizeFilterValue, 
		roomsFilterValue, 
		setRoomsFilterValue, 
		defaultFilterValues, 
		balconyFilterValue,
		setBalconyFilterValue,
		petsFilterValue,
		setPetsFilterValue,
		elevatorFilterValue,
		setElevatorFilterValue,
		acquisitionFilterValue,
		setAcquisitionFilterValue
	} = props;
	const { t } = useTranslation();

	const handleBackdropClick = () => {
		setVisible(false);
	};

	useKeypress('Escape', () => {
		if (visible) {
			setVisible(false);
		}
	});

	const onRentFilterChange = (value) => {
		setRentFilterValue(value);
	};
	const onSizeFilterChange = (value) => {
		setSizeFilterValue(value);
	};
	const onRoomsFilterChange = (value) => {
		setRoomsFilterValue(value);
	};
	const onBalconyFilterChange = () => {
		setBalconyFilterValue(!balconyFilterValue);
	};
	const onPetsFilterChange = () => {
		setPetsFilterValue(!petsFilterValue);
	};
	const onElevatorFilterChange = () => {
		setElevatorFilterValue(!elevatorFilterValue);
	};

	return (
		<>
			<Backdrop onClick={handleBackdropClick} className={visible ? 'visible' : ''} />
			<SideDrawerWrapper className={visible ? 'visible' : ''}>
				<div className="title"><FilterIcon />{t('filters.title')}</div>
				<div className="rent-filter filter">
					<div>{t('filters.monthlyRent')}</div>
					<ReactSlider
						className="slider-wrapper"
						thumbClassName="slider-thumb"
						trackClassName="slider-track"
						min={defaultFilterValues.rent[0]}
						max={defaultFilterValues.rent[1]}
						minDistance={1000}
						step={1000}
						value={rentFilterValue}
						onChange={onRentFilterChange}
						ariaLabel={['Lower thumb', 'Upper thumb']}
						ariaValuetext={state => `Thumb value ${state.valueNow}`}
						renderThumb={(props) => <div {...props}></div>}
						pearling
					/>
					<div className="filter-value">{formatPrice(rentFilterValue[0])} {t('filters.to')} {rentFilterValue[1] === defaultFilterValues.rent[1] ? `${defaultFilterValues.rent[1].toLocaleString('da-DK')}+ kr.` : formatPrice(rentFilterValue[1])}</div>
				</div>
				<div className="size-filter filter">
					<div>{t('filters.size')}</div>
					<ReactSlider
						className="slider-wrapper"
						thumbClassName="slider-thumb"
						trackClassName="slider-track"
						min={defaultFilterValues.size[0]}
						max={defaultFilterValues.size[1]}
						minDistance={10}
						step={10}
						value={sizeFilterValue}
						onChange={onSizeFilterChange}
						ariaLabel={['Lower thumb', 'Upper thumb']}
						ariaValuetext={state => `Thumb value ${state.valueNow}`}
						renderThumb={(props) => <div {...props}></div>}
						pearling
					/>
					<div className="filter-value">{sizeFilterValue[0]}m² {t('filters.to')}  {sizeFilterValue[1]}+ m²</div>
				</div>
				<div className="size-filter filter">
					<div>{t('filters.rooms')}</div>
					<ReactSlider
						className="slider-wrapper"
						thumbClassName="slider-thumb"
						trackClassName="slider-track"
						min={defaultFilterValues.rooms[0]}
						max={defaultFilterValues.rooms[1]}
						minDistance={1}
						step={1}
						value={roomsFilterValue}
						onChange={onRoomsFilterChange}
						ariaLabel={['Lower thumb', 'Upper thumb']}
						ariaValuetext={state => `Thumb value ${state.valueNow}`}
						renderThumb={(props) => <div {...props}></div>}
						pearling
					/>
					<div className="filter-value">{roomsFilterValue[0]} {t('filters.rooms')}  {t('filters.to')} {roomsFilterValue[1]}+ {t('filters.rooms')} </div>
				</div>
				<div className="availability-filter filter">
					<div>{t('filters.acquisitionDate')}</div>
					<ReactDatePicker
						locale='da'
						dateFormat='dd/MM/yyyy'
						selected={acquisitionFilterValue} 
						onChange={(date) => setAcquisitionFilterValue(date)} 
						onKeyDown={(e) => e.preventDefault()}
						placeholderText={t('filters.selectDate')}
						onFocus={e => e.target.blur()}
					/>
				</div>
				<div className="features-filter filter">
					<div>{t('filters.features')}</div>
					<label className="container">{t('filters.balcony')}
						<input checked={balconyFilterValue} onChange={onBalconyFilterChange} type="checkbox" />
						<span className ="checkmark"></span>
					</label>
					<label className="container">{t('filters.petsAllowed')}
						<input checked={petsFilterValue} onChange={onPetsFilterChange} type="checkbox"/>
						<span className ="checkmark"></span>
					</label>
					<label className="container">{t('filters.elevator')}
						<input checked={elevatorFilterValue} onChange={onElevatorFilterChange} type="checkbox"/>
						<span className ="checkmark"></span>
					</label>
				</div>

			</SideDrawerWrapper>
		</>
	);
};

SideDrawer.propTypes = {
	visible: PropTypes.bool,
	setVisible: PropTypes.func,
	rentFilterValue: PropTypes.array,
	setRentFilterValue: PropTypes.func,
	sizeFilterValue: PropTypes.array,
	setSizeFilterValue: PropTypes.func,
	roomsFilterValue: PropTypes.array,
	setRoomsFilterValue: PropTypes.func,
	balconyFilterValue: PropTypes.bool,
	setBalconyFilterValue: PropTypes.func,
	petsFilterValue: PropTypes.bool,
	setPetsFilterValue: PropTypes.func,
	defaultFilterValues: PropTypes.object,
	elevatorFilterValue: PropTypes.bool,
	setElevatorFilterValue: PropTypes.func,
	acquisitionFilterValue: PropTypes.object,
	setAcquisitionFilterValue: PropTypes.func,
};

export default SideDrawer;