import { useState, useCallback } from 'react';
import GoogleTranslateService from 'services/gTranslate/google-translate-service';

const useGoogleTranslate = () => {

	const [isTranslationLoading, setIsTranslationLoading] = useState(true);
	const [translationData, setTranslationData] = useState(null);
	const [translationNotFound, setTranslationNotFound] = useState(false);

	const loadTranslation = useCallback(async (array) => {
		const promises = [];
		array.forEach((item) => {
			promises.push(GoogleTranslateService.translate(item));
		});
		const results = await Promise.all(promises);
		const cleanedResults = [];
		results.forEach((result) => {
			cleanedResults.push(result.data.data.translations[0].translatedText);
		});
		if (results !== null) {			
			setTranslationData(cleanedResults);
		} else {
			setTranslationNotFound(true);
		}

		setIsTranslationLoading(false);
	}, []);

	return {
		isTranslationLoading,
		translationNotFound,
		translationData,
		loadTranslation
	};
};

export default useGoogleTranslate;