import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "assets/properties/kgv/frederiksberg.svg";
import Image02 from "assets/properties/kgv/kgv-02.jpg";
import Image03 from "assets/properties/kgv/kgv-03.jpg";
import Image04 from "assets/properties/kgv/kgv-04.jpg";

const Wrapper = styled.div`
	position: relative;
	background-color: #fef6e5;
	display: flex;
	flex-direction: column;
	// padding-top: 50px;
	// padding-left: 20px;
	// padding-right: 20px;
	min-height: 400px;
	padding-bottom: 100px;

	@media ${device.laptop} {
	}
`;

const Section1 = styled.div`
	padding-top: 90px;
	height: 198px;
	padding-bottom: 80px;
	svg {
		position: absolute;
		top: -8px;
		left: -52px;
		z-index: 10;
		height: 300px;
		width: 300px;
	}
	img {
		position: absolute;
		right: 0;
	}
	@media ${device.laptop} {
		order: 2;
		padding-top: 0px;
		height: 0px;
		padding-bottom: 0px;
		svg {
			height: 1000px;
			width: 1000px;
			top: 140px;
			position: relative;
			left: 771px;
		}
		img {
			height: 393px;
			width: 393px;
			top: 240px;
		}
	}
`;

const Section2 = styled.div`
	font-size: 14px;
	padding-left: 20px;
	padding-right: 20px;
	width: 270px;

	@media ${device.laptop} {
		order: 1;
		margin-top: 120px;
	}
`;
const Section3 = styled.div`
	display: flex;
	flex-direction: column;
	.small {
		padding-top: 93px;
		padding-left: 20px;
	}
	.large {
		position: relative;
		margin-top: 44px;
		margin-left: auto;
		margin-right: 20px;
	}
	.openhouse {
		width: 198px;
		background-color: #003202;
		color: white;
		font-weight: 500;
		font-size: 12px;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 16px;
		padding-bottom: 16px;
		position: relative;
		top: -23px;
		.title {
			font-size: 16px;
		}
	}

	@media ${device.laptop} {
		order: 3;

		.small {
			height: 247px;
			width: 349px;
			position: relative;
			left: 308px;
			top: 112px;
		}
		.large {
			height: 730px;
			width: 704px;
			position: relative;
			left: -168px;
			top: 118px;
		}

		.openhouse {
			padding-right: 50px;
		}
	}
`;

const Button = styled.div`
	cursor: pointer;
	margin-top: 31px;
	background-color: #003202;
	height: 42px;
	width: 263px;
	color: white;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ImagesSection = () => {
	const { t } = useTranslation();

	const handleClick = () => {
		window.open("https://norsepm.dk/boliger/?q=Kong%20Georgs%20Vej,%202000%20Frederiksberg,%20Danmark", "_blank");
	};

	return (
		<Wrapper>
			<Section1>
				<Logo />
				<img height={198} width={198} src={Image02} />
			</Section1>
			<Section2>
				<p>
					2-5 værelses lejligheder til dig, der hverken ønsker at gå på kompromis med kvalitet eller
					beliggenhed.
				</p>
				<br />
				<p>Fra 16.000-33.000 kr./md.</p>
				<Button onClick={handleClick}>SE LEDIGE BOLIGER</Button>
			</Section2>
			<Section3>
				<img className='small' height={124} width={175} src={Image03} />
				<img className='large' height={295} width={284} src={Image04} />
				{/* <div className='openhouse'>
					<p className='title'>Åbent hus</p>
					<p>Åbent hus hver torsdag kl 16:00-17:00.</p>
				</div> */}
			</Section3>
		</Wrapper>
	);
};

export default ImagesSection;
