import React from 'react';
import Header from './components/Header';
import PropTypes from 'prop-types';
import Page from './components/Page';
import PageContainer from './components/PageContainer';
import Footer from './components/Footer';

const Layout = ({ children }) => {
	return (
		<Page>
			<Header />
			<PageContainer>
				{children}
			</PageContainer>
			<Footer />
		</Page>
	);
};

Layout.propTypes = {
	children: PropTypes.node
};

export default Layout;