import axios from './axios';

export default class OpenHousesService {

	static async getOpenHousesByTenancyId(tenancyId) {
		try {
			const result = await axios.get(`/tenancies/${tenancyId}/open-houses`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getOpenHouses() {
		try {
			const result = await axios.get('/open-houses');
			return result;
		}
		catch (err) {
			return null;
		}
	}
	
}