// @ts-nocheck

import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ImageMapper from "react-img-mapper";
import RotateToLandScapeAnimation from "assets/lotties/rotate-to-landscape.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { ReactComponent as ShoppingCartIcon } from "assets/icons/shopping-cart.svg";
import { ReactComponent as CommunityHouseIcon } from "assets/icons/community-house.svg";
import { device } from "helpers/device";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
	width: 100%;
	border: none;
	max-width: 1280px;
	margin: auto;
	position: relative;

	area {
		display: block;
		position: absolute;
	}

	area::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 255, 0.3);
	}
`;
const AnimationWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
`;

const Icon1Wrapper = styled.div`
	position: absolute;
	top: 26%;
	left: 61%;
	z-index: 10;
	height: 30px;
	width: 30px;
	background-color: white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background-color: #3e5042;
		svg {
			stroke: white;
			fill: white;
		}
	}

	svg {
		width: 20px;
		height: 20px;
	}

	@media ${device.laptop} {
		height: 40px;
		width: 40px;
		svg {
			width: 30px;
			height: 30px;
		}
	}
`;

const Icon2Wrapper = styled.div`
	position: absolute;
	top: 82%;
    left: 60.5%;
	border: 1px solid #003202;
	z-index: 10;
	height: 30px;
	width: 30px;
	background-color: white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background-color: #3e5042;
		svg {
			stroke: white;
			fill: white;
		}
	}

	svg {
		width: 20px;
		height: 20px;
	}
	@media ${device.laptop} {
		height: 40px;
		width: 40px;
		svg {
			width: 30px;
			height: 30px;
		}
	}
`;

const SelectWrapper = styled.div`
	position: absolute;
	display: none;
	top: 30px;
	left: 30px;
	z-index: 10;
	height: 30px;
	width: 200px;
	background-color: white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	@media ${device.laptop} {
		display: block;
	}
`;



const ImageMap = (props) => {
	const {
		imageWidth,
		json,
		image, // should be 1280px width
		onClick,
		tenancies,
		setRoomFilter,
		setTypeFilter,
	} = props;

	const [parentWidth, setParentWidth] = useState(0);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [modalDescription, setModalDescription] = useState("");
	const { t } = useTranslation();

	const imgMapperRef = useRef();
	const displayChangeOrientationIcon = window.matchMedia("(max-width: 768px) and (orientation: portrait)").matches;
	
	const map = useMemo(() => {
		const randomName = Math.random().toString(36).substring(7);
		return {
			name: `my-map-${randomName}`,
			areas: [...json],
		};
	}, [json]);

	useEffect(() => {
		function updateSize() {
			const parentNode = imgMapperRef.current.parentNode;
			const parentStyle = window.getComputedStyle(parentNode);
			setParentWidth(parseFloat(parentStyle.width));
		}

		window.addEventListener("resize", updateSize);
		updateSize(); // initialize size

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	const handleSelectChange = (selectedOption) => {
		setRoomFilter(selectedOption.rooms);
		setTypeFilter(selectedOption.type);
	};

	const placeholder = t('general.choseType');

	return (
		<Wrapper ref={imgMapperRef}>
			<AnimationWrapper
				style={{
					display: displayChangeOrientationIcon ? "bloack" : "none",
				}}
			>
				<Player
					autoplay
					loop
					src={RotateToLandScapeAnimation}
					style={{ height: "150px", width: "150px" }}
				></Player>
			</AnimationWrapper>
			<ImageMapper
				src={image}
				map={map}
				width={imageWidth}
				imgWidth={imageWidth}
				onClick={onClick}
				responsive={true}
				parentWidth={parentWidth}
				areaStyles={(area, index) => ({
					cursor: area.isActive ? "pointer" : "default",
				})}
			/>
			
			<Icon2Wrapper>
				<CommunityHouseIcon />
			</Icon2Wrapper>
		</Wrapper>
	);
};

ImageMap.propTypes = {
	imageWidth: PropTypes.number,
	json: PropTypes.array,
	image: PropTypes.string,
	onClick: PropTypes.func,
	setRoomFilter: PropTypes.func,
};

ImageMap.defaultProps = {
	imageWidth: 1280,
	json: [],
	image: "",
	onClick: () => {},
};

export default ImageMap;
