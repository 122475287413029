const B3TenanciesJSON = [
	//  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------  GROUND FLOOR ------
	{
		position: [-3.2, 2.4, -4.4],
		scale: [8.0, 2.8, 8.6],
		id: 'a140d695-763d-4f90-b4f3-2692b21ded72',
		comment: '35A, st. 6',
	},
	{
		position: [-3.2, 2.4, -10.5],
		scale: [8.0, 2.8, 3.4],
		id: '51c217dd-40f3-407a-80b8-6b48c3eb1146',
		comment: '35A, st. 5',
	},
	{
		position: [-3.2, 2.4, -14.0],
		scale: [8.0, 2.8, 3.4],
		id: 'cd3ae9f0-6cd5-4ea9-9531-a533076df483',
		comment: '35A, st. 4',
	},
	{
		position: [-3.2, 2.4, -17.6],
		scale: [8.0, 2.8, 3.4],
		id: '2466c1df-59fd-4b66-aa9f-cb2fa42e237d',
		comment: '35A, st. 3',
	},
	{
		position: [-3.2, 2.4, -23.5],
		scale: [9.0, 2.8, 8.0],
		id: 'ec10532f-a227-437a-89ce-7ee7b647bf5a',
		comment: '35A, st. 2',
	},
	//  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------  1ST FLOOR ------
	{
		position: [-3.2, 5.2, -4.4],
		scale: [8.0, 2.8, 8.6],
		id: '3aa3ed47-996a-4c56-810c-246dc5795b76',
		comment: '35A, 1. 6',
	},
	{
		position: [-3.2, 5.2, -10.5],
		scale: [8.0, 2.8, 3.4],
		id: '99ab39a6-8a78-49cc-8099-e34936b28f93',
		comment: '35A, 1. 5',
	},
	{
		position: [-3.2, 5.2, -14.0],
		scale: [8.0, 2.8, 3.4],
		id: '21919219-a803-4f36-b74a-72189644188a',
		comment: '35A, 1. 4',
	},
	{
		position: [-3.2, 5.2, -17.6],
		scale: [8.0, 2.8, 3.4],
		id: '49bb99c5-f836-4f26-b661-74750c0c2185',
		comment: '35A, 1. 3',
	},
	{
		position: [-3.2, 5.2, -23.5],
		scale: [9.0, 2.8, 8.0],
		id: '5746c22d-19d3-45e4-be51-91981aa6a288',
		comment: '35A, 1. 2',
	},
	{
		position: [-3.2, 5.2, -31.3],
		scale: [8.0, 2.8, 7.2],
		id: '0ea4b8ae-7fd5-45d7-9050-7b313963ee52',
		comment: '35A, 1. 1',
	},
	//  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------  2ND FLOOR ------
	{
		position: [-3.2, 8.0, -4.4],
		scale: [8.0, 2.8, 8.6],
		id: '2b776284-e5f7-4e09-8cd8-a29ac8467cef',
		comment: '35A, 2. 8',
	},
	{
		position: [-3.2, 8.0, -10.5],
		scale: [8.0, 2.8, 3.4],
		id: '9add8b85-a124-485e-9dea-2b068f26dd29',
		comment: '35A, 2. 7',
	},
	{
		position: [-3.2, 8.0, -14.0],
		scale: [8.0, 2.8, 3.4],
		id: '1b6d7905-ec67-495e-8001-db5c91577e05',
		comment: '35A, 2. 6',
	},
	{
		position: [-3.2, 8.0, -17.6],
		scale: [8.0, 2.8, 3.4],
		id: 'd5879fb9-fb40-4e7f-818e-48fe50f169f8',
		comment: '35A, 2. 5',
	},
	{
		position: [-3.2, 8.0, -23.5],
		scale: [9.0, 2.8, 8.0],
		id: 'c9882fa2-8617-442f-9fa0-2619fb7d64ab',
		comment: '35A, 2. 4',
	},
	{
		position: [-3.2, 8.0, -31.1],
		scale: [8.0, 2.8, 6.8],
		id: 'a1ce4d5b-cda4-47de-9542-a8d0c28526a8',
		comment: '35A, 2. 3',
	},
	{
		position: [-3.2, 8.0, -36.0],
		scale: [8.0, 2.8, 3.0],
		id: '056d3da3-8e8b-43fb-8ee2-8d427852a507',
		comment: '35A, 2. 2',
	},
	{
		position: [-4.4, 8.0, -41.0],
		scale: [10.0, 2.8, 6.8],
		id: '7160dd9d-b736-4b90-8681-b384b8e2f3ed',
		comment: '35A, 2. 1',
	},
	//  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------  3RD FLOOR ------
	{
		position: [-3.2, 10.8, -4.4],
		scale: [8.0, 2.8, 8.6],
		id: '6059d975-1373-40ff-913a-d31180895baf',
		comment: '35A, 3. 8',
	},
	{
		position: [-3.2, 10.8, -10.5],
		scale: [8.0, 2.8, 3.4],
		id: 'fdb4fcdd-c9a3-4d8e-b1af-89608f925e91',
		comment: '35A, 3. 7',
	},
	{
		position: [-3.2, 10.8, -14.0],
		scale: [8.0, 2.8, 3.4],
		id: '906ee76f-9b2a-4496-b82c-238785a3a03d',
		comment: '35A, 3. 6',
	},
	{
		position: [-3.2, 10.8, -17.6],
		scale: [8.0, 2.8, 3.4],
		id: '0f938e68-c744-4610-a85f-d45bb0df472b',
		comment: '35A, 3. 5',
	},
	{
		position: [-3.2, 10.8, -23.5],
		scale: [9.0, 2.8, 8.6],
		id: 'a4b5a125-c7d4-4cbf-bd94-0583252fe3a5',
		comment: '35A, 3. 4',
	},
	{
		position: [-3.2, 10.8, -31.1],
		scale: [8.0, 2.8, 6.8],
		id: 'e03a9e21-a178-4ab2-b65b-e1373c9ad814',
		comment: '35A, 3. 3',
	},
	{
		position: [-3.2, 10.8, -36.0],
		scale: [8.0, 2.8, 3.0],
		id: 'eca2a070-28fe-48ff-8563-2e07783374e0',
		comment: '35A, 3. 2',
	},
	{
		position: [-4.4, 10.8, -41.0],
		scale: [10.0, 2.8, 6.8],
		id: 'ef01f805-451c-4385-be9c-a445f56359e0',
		comment: '35A, 3. 1',
	},
	//  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------  4TH FLOOR ------
	{
		position: [-3.0, 13.6, -4.4],
		scale: [8.0, 2.8, 8.6],
		id: 'bf8094df-723e-48e0-a5ff-08ba79cd3f6c',
		comment: '35A, 4. 8',
	},
	{
		position: [-3.0, 13.6, -10.5],
		scale: [8.0, 2.8, 3.4],
		id: 'c0c7ed16-9572-4881-91f5-b3c23e4c07f5',
		comment: '35A, 4. 7',
	},
	{
		position: [-3.0, 13.6, -14.0],
		scale: [8.0, 2.8, 3.4],
		id: '80ac0fc4-c4a9-4bfb-8cdd-83116bdd4220',
		comment: '35A, 4. 6',
	},
	{
		position: [-3.2, 13.6, -17.6],
		scale: [8.0, 2.8, 3.4],
		id: 'c30591a3-8224-4815-89d0-1b8a173e3fef',
		comment: '35A, 4. 5',
	},
	{
		position: [-3.2, 13.6, -23.5],
		scale: [9.0, 2.8, 8.6],
		id: '3706edfd-af30-41b3-8d0a-d73b79e4d8de',
		comment: '35A, 4. 4',
	},
	{
		position: [-3.2, 13.6, -31.1],
		scale: [8.0, 2.8, 6.8],
		id: '644e8bfb-e93a-4507-a680-13fe0b82c59f',
		comment: '35A, 4. 3',
	},
	{
		position: [-3.2, 13.6, -36.0],
		scale: [8.0, 2.8, 3.0],
		id: '238d477f-88cd-4b79-9620-40c1774f5505',
		comment: '35A, 4. 2',
	},
	{
		position: [-4.4, 13.6, -41.0],
		scale: [10.0, 2.8, 6.8],
		id: 'b8f13c02-059c-4af7-b754-fe648ded2824',
		comment: '35A, 4. 1',
	},
];

export default B3TenanciesJSON;