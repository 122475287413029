import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useTenancies from "features/tenancies";
import HeroSection4 from "components/HeroSection4";
import FactSection1 from "components/FactSection1";
import CarouselSection1 from "components/CarouselSection1";
import FactSection2 from "components/FactSection2";
import FacilitiesSection1 from "components/FacilitiesSection1";
import ImageSection1 from "components/ImageSection1";
import ImageGridSection2 from "components/ImageGridSection2";
import AvailableTenanciesList from "components/AvailableTenanciesList";
import ContactSection from "components/ContactSection";
import Isometry from "components/Isometry";
import Button from "components/Button";
import InterestListButton from "components/InterestListButton";
import tenanciesJSON from "./components/tenanciesSON";
import imageMapJSON from "./components/imageMapJSON";
import Scene from "./components/Scene";
import background01 from "assets/properties/labyrinthen/background-maze.png";
import imageProperty01 from "assets/properties/baunebakken/baunebakken-property-01.jpg";
import imageProperty02 from "assets/properties/baunebakken/baunebakken-property-02.jpg";
import imageProperty03 from "assets/properties/baunebakken/baunebakken-property-03.jpg";
import imageProperty04 from "assets/properties/baunebakken/baunebakken-property-04.jpg";
import imageProperty05 from "assets/properties/baunebakken/baunebakken-property-05.jpg";
import imageProperty06 from "assets/properties/baunebakken/baunebakken-property-06.jpg";
import backgroundMovie from "assets/properties/baunebakken/baunebakken.mp4";
import floorPlan4Rooms from "assets/properties/baunebakken/baunebakken-floorplan-4-rooms.png";
import floorPlan3Rooms from "assets/properties/baunebakken/baunebakken-floorplan-3-rooms.png";
import floorPlan4RoomsSmall from "assets/properties/baunebakken/baunebakken-floorplan-4-rooms-small.png";
import { ReactComponent as StarsIcon } from "assets/icons/stars.svg";
import { ReactComponent as ResizeIcon } from "assets/icons/resize-arrows.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as ParkingIcon } from "assets/icons/parking2.svg";
import { ReactComponent as WashingIcon } from "assets/icons/washer.svg";
import { ReactComponent as GreenAreasIcon1 } from "assets/icons/green-areas.svg";
import { ReactComponent as TerraceIcon } from "assets/icons/terrace.svg";
import { ReactComponent as ShedIcon } from "assets/icons/shed.svg";
import { ReactComponent as UtilityRoomIcon } from "assets/icons/utility-room.svg";
import ImageMapSection from "components/ImageMapSection/ImageMapSection";

const Wrapper = styled.div`
	margin: 0 auto;
	background-color: #fafafa;
`;

const Baunebakken = () => {
	const [activeIsometry, setActiveIsometry] = useState(null);

	const { t } = useTranslation();

	const { isTenanciesLoading, tenanciesData, loadTenanciesByPropertyId } =
		useTenancies();

	const propertyId = "c886b784-64f4-4cc8-ab3b-ac6c8de7bfdd";

	useEffect(() => {
		loadTenanciesByPropertyId(propertyId);
	}, [loadTenanciesByPropertyId]);

	const factSectionBoxes = [
		{
			pre: t("properties.baunebakken.factSection1.boxes.text1.pre"),
			bold: t("properties.baunebakken.factSection1.boxes.text1.bold"),
			after: t("properties.baunebakken.factSection1.boxes.text1.after"),
			icon: <StarsIcon />,
		},
		{
			pre: t("properties.baunebakken.factSection1.boxes.text2.pre"),
			bold: t("properties.baunebakken.factSection1.boxes.text2.bold"),
			after: t("properties.baunebakken.factSection1.boxes.text2.after"),
			icon: <BuildingIcon />,
		},
		{
			pre: t("properties.baunebakken.factSection1.boxes.text3.pre"),
			bold: t("properties.baunebakken.factSection1.boxes.text3.bold"),
			after: t("properties.baunebakken.factSection1.boxes.text3.after"),
			icon: <ResizeIcon />,
		},
	];

	const facilities = [
		{
			icon: <ParkingIcon />,
			text: t("properties.baunebakken.facilitySection.text5"),
		},
		{
			icon: <TerraceIcon />,
			text: t("properties.baunebakken.facilitySection.text1"),
		},
		{
			icon: <WashingIcon />,
			text: t("properties.baunebakken.facilitySection.text4"),
		},
		{
			icon: <GreenAreasIcon1 />,
			text: t("properties.baunebakken.facilitySection.text3"),
		},
		{
			icon: <ShedIcon />,
			text: t("properties.baunebakken.facilitySection.text2"),
		},
		{
			icon: <GreenAreasIcon1 />,
			text: t("properties.baunebakken.facilitySection.text6"),
		},
		{
			icon: <WashingIcon />,
			text: t("properties.baunebakken.facilitySection.text7"),
		},
	];

	const sliderImages = [imageProperty02, imageProperty01, imageProperty01];

	const imagesGrid = [
		imageProperty01,
		imageProperty02,
		imageProperty03,
		imageProperty04,
		imageProperty05,
		imageProperty06,
	];
	

	const contactSectionRef = useRef(null);
	const scrollToContactSection = () => {
		contactSectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const isometrySectionRef = useRef(null);
	const scrollToIsometrySection = () => {
		isometrySectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const onImageMapClick = (area) => {
		setActiveIsometry(parseInt(area.id));
	};
	const onReturnClick = () => {
		setActiveIsometry(null);
	};
	const isometries = [
		<Isometry
			tenanciesJSON={tenanciesJSON}
			scene={Scene}
			key={1}
			tenancies={tenanciesData}
		/>,
		<Isometry
			tenanciesJSON={tenanciesJSON}
			scene={Scene}
			key={2}
			tenancies={tenanciesData}
		/>,
		<Isometry
			tenanciesJSON={tenanciesJSON}
			scene={Scene}
			key={3}
			tenancies={tenanciesData}
		/>,
	];

	const CarouselSection1slides = [
		{ src: floorPlan4Rooms },
		{ src: floorPlan4RoomsSmall },
	];
	const CarouselSection2slides = [
		{ src: floorPlan3Rooms },
	];

	

	return (
		<Wrapper>
			<HeroSection4
				background={backgroundMovie}
				backgroundType={"video"}
				title={t("properties.baunebakken.title")}
				text={t("properties.baunebakken.subtitle")}
				primaryButtons={<InterestListButton propertyId={propertyId} />}
				secondaryButtons={
					<Button onClick={scrollToContactSection}>
						{t("properties.general.contactUs")}
					</Button>
				}
			/>
			<FactSection1
				background={background01}
				title={t("properties.baunebakken.factSection1.title")}
				textBase={t("properties.baunebakken.factSection1.textBase")}
				textAdditional={t(
					"properties.baunebakken.factSection1.textAdditional"
				)}
				buttonText={t("properties.baunebakken.factSection1.buttonText")}
				buttonOnClick={scrollToIsometrySection}
				boxes={factSectionBoxes}
			/>
			<CarouselSection1
				title={t("properties.baunebakken.carouselSection.title")}
				section1title={t("properties.baunebakken.carouselSection.section1title")}
				section1text={t("properties.baunebakken.carouselSection.section1text")}
				section1buttonText={t("properties.baunebakken.carouselSection.button1text")}
				section1slides={CarouselSection1slides}
				section2title={t("properties.baunebakken.carouselSection.section2title")}
				section2text={t("properties.baunebakken.carouselSection.section2text")}
				section2buttonText={t("properties.baunebakken.carouselSection.button1tex")}
				section2slides={CarouselSection2slides}
				images={sliderImages}
			/>
			<FactSection2
				title1={t("properties.baunebakken.factSection2.title1")}
				text1={t("properties.baunebakken.factSection2.text1")}
				title2={t("properties.baunebakken.factSection2.title2")}
				text2={t("properties.baunebakken.factSection2.text2")}
			/>
			<FacilitiesSection1
				title={t("properties.baunebakken.facilitySection.title")}
				facilities={facilities}
			/>
			<ImageSection1 image={imageProperty03} />
			<ImageGridSection2 images={imagesGrid} />
			<div ref={isometrySectionRef}>
				<ImageMapSection 
					title={t("properties.baunebakken.ImageMapSection.title")}
					text={t("properties.baunebakken.ImageMapSection.text")}
					imageWidth={3456}
					imageMapJSON={imageMapJSON}
					image={imageProperty01}
				/>
			</div>
			{tenanciesData?.length > 0 && (
				<AvailableTenanciesList
					loading={isTenanciesLoading}
					tenancies={tenanciesData}
				/>
			)}
			<div ref={contactSectionRef}>
				<ContactSection backgroundColor='#fafafa' />
			</div>
		</Wrapper>
	);
};

export default Baunebakken;
