import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useTenancies from "features/tenancies";
import HeroSection4 from "components/HeroSection4";
import FactSection1 from "components/FactSection1";
import CarouselSection1 from "components/CarouselSection1";
import FactSection2 from "components/FactSection2";
import FacilitiesSection1 from "components/FacilitiesSection1";
import ImageSection1 from "components/ImageSection1";
import ImageGridSection2 from "components/ImageGridSection2";
import AvailableTenanciesList from "components/AvailableTenanciesList";
import ContactSection from "components/ContactSection";
import Isometry from "components/Isometry";
import Button from "components/Button";
import IsometrySelectorSection from "components/IsometrySelectorSection";
import InterestListButton from "components/InterestListButton";
import B1TenanciesJSON from "./components/B1TenanciesJSON";
import B3TenanciesJSON from "./components/B3TenanciesJSON";
import imageMapJSON from "./components/imageMapJSON";
import Scene1 from "./components/Scene1";
import Scene2 from "./components/Scene2";
import Scene3 from "./components/Scene3";
import background01 from "assets/properties/labyrinthen/background-maze.png";
import imageProperty01 from "assets/properties/labyrinthen/labyrinthen-property-01.jpg";
import imageProperty02 from "assets/properties/kgv/kgv-image-01.jpg";
import imageProperty03 from "assets/properties/labyrinthen/labyrinthen-property-03.jpg";
import imageTenancy01 from "assets/properties/labyrinthen/labyrinthen-tenancy-01.jpg";
import imageTenancy02 from "assets/properties/labyrinthen/labyrinthen-tenancy-02.jpg";
import imageTenancy03 from "assets/properties/labyrinthen/labyrinthen-tenancy-03.jpg";
import imageTenancy04 from "assets/properties/labyrinthen/labyrinthen-tenancy-04.jpg";
import imageTenancy05 from "assets/properties/labyrinthen/labyrinthen-tenancy-05.jpg";
import imageTenancy06 from "assets/properties/labyrinthen/labyrinthen-tenancy-06.jpg";
import imageTenancy07 from "assets/properties/labyrinthen/labyrinthen-tenancy-07.jpg";
import floorplanA1A2 from "assets/properties/labyrinthen/labyrinthen-floorplan-A1-A2.jpg";
import floorplanB1 from "assets/properties/labyrinthen/labyrinthen-floorplan-B1.jpg";
import floorplanC1 from "assets/properties/labyrinthen/labyrinthen-floorplan-C1.jpg";
import floorplanD1D2 from "assets/properties/labyrinthen/labyrinthen-floorplan-D1-D2.jpg";
import floorplanF1F4 from "assets/properties/labyrinthen/labyrinthen-floorplan-F1-F4.jpg";
import floorplanG1 from "assets/properties/labyrinthen/labyrinthen-floorplan-G1.jpg";
import floorplanH1 from "assets/properties/labyrinthen/labyrinthen-floorplan-H1.jpg";
import floorplanI1 from "assets/properties/labyrinthen/labyrinthen-floorplan-I1.jpg";
import floorplanJ1J2 from "assets/properties/labyrinthen/labyrinthen-floorplan-J1-J2.jpg";
import { ReactComponent as StarsIcon } from "assets/icons/stars.svg";
import { ReactComponent as ResizeIcon } from "assets/icons/resize-arrows.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as ParkingIcon } from "assets/icons/parking2.svg";
import { ReactComponent as WashingIcon } from "assets/icons/washer.svg";
import { ReactComponent as GreenAreasIcon1 } from "assets/icons/green-areas.svg";
import { ReactComponent as StorageIcon } from "assets/icons/storage.svg";
import { ReactComponent as BicycleIcon } from "assets/icons/bicycle.svg";
import ImageIsometryDesktop from "assets/properties/labyrinthen/labyrinthen-isometry-desktop.jpg";

const Wrapper = styled.div`
	margin: 0 auto;
	background-color: #fafafa;
`;

const KGV = () => {
	const [activeIsometry, setActiveIsometry] = useState(null);

	const { t } = useTranslation();

	const { isTenanciesLoading, tenanciesData, loadTenanciesByPropertyId } =
		useTenancies();

	const propertyId = "067770f0-748d-426a-8068-e0de9846f10a";

	useEffect(() => {
		loadTenanciesByPropertyId(propertyId);
	}, [loadTenanciesByPropertyId]);

	const factSectionBoxes = [
		{
			pre: t("properties.kgv.factSection1.boxes.text1.pre"),
			bold: t("properties.kgv.factSection1.boxes.text1.bold"),
			after: t("properties.kgv.factSection1.boxes.text1.after"),
			icon: <StarsIcon />,
		},
		{
			pre: t("properties.kgv.factSection1.boxes.text2.pre"),
			bold: t("properties.kgv.factSection1.boxes.text2.bold"),
			after: t("properties.kgv.factSection1.boxes.text2.after"),
			icon: <BuildingIcon />,
		},
		{
			pre: t("properties.kgv.factSection1.boxes.text3.pre"),
			bold: t("properties.kgv.factSection1.boxes.text3.bold"),
			after: t("properties.kgv.factSection1.boxes.text3.after"),
			icon: <ResizeIcon />,
		},
	];

	const facilities = [
		{
			icon: <ParkingIcon />,
			text: t("properties.kgv.facilitySection.text5"),
		},
		{
			icon: <BicycleIcon />,
			text: t("properties.kgv.facilitySection.text1"),
		},
		{
			icon: <WashingIcon />,
			text: t("properties.kgv.facilitySection.text4"),
		},
		{
			icon: <GreenAreasIcon1 />,
			text: t("properties.kgv.facilitySection.text3"),
		},
		{
			icon: <StorageIcon />,
			text: t("properties.kgv.facilitySection.text2"),
		},
	];

	const images = [
		imageTenancy07,
		imageTenancy02,
		imageTenancy03,
		imageTenancy04,
		imageTenancy05,
		imageTenancy06,
		imageProperty01,
		imageProperty02,
	];

	const CarouselSection1slides = [
		{ src: floorplanA1A2 },
		{ src: floorplanB1 },
		{ src: floorplanC1 },
		{ src: floorplanD1D2 }
	];

	const CarouselSection2slides = [
		{ src: floorplanF1F4 },
		{ src: floorplanG1 },
		{ src: floorplanH1 },
		{ src: floorplanI1 },
		{ src: floorplanJ1J2 }
	];

	const sliderImages = [imageProperty02, imageProperty01, imageProperty03];

	const contactSectionRef = useRef(null);
	const scrollToContactSection = () => {
		contactSectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const isometrySectionRef = useRef(null);
	const scrollToIsometrySection = () => {
		isometrySectionRef.current.scrollIntoView({ behavior: "smooth" });
	};
	const onImageMapClick = (area) => {
		setActiveIsometry(parseInt(area.id));
	};
	const onReturnClick = () => {
		setActiveIsometry(null);
	};
	const isometries = [
		<Isometry
			// rightmost building on overview
			tenanciesJSON={B1TenanciesJSON}
			scene={Scene1}
			key={1}
			tenancies={tenanciesData}
			target={[-10, 10, -23.0]}
			// target={[-20, 2.4, -0]}
			initialRotation={125}
			fov={{ desktop: 35, mobile: 50}}
		/>,
		<Isometry
			// center building - bottom
			scene={Scene2}
			key={2}
			tenancies={tenanciesData}
			target={[0, 10, -30.0]}
			initialRotation={-20}
			fov={{ desktop: 25, mobile: 50}}
		/>,
		<Isometry
			// leftmost building on overview
			tenanciesJSON={B3TenanciesJSON}
			// tenanciesJSON={tenanciesJSON}
			scene={Scene3}
			key={3}
			tenancies={tenanciesData}
			fov={{ desktop: 45, mobile: 50}}
			target={[-20, 2.8, -27.0]}
			// target={[10, 10, -27.0]}
			initialRotation={80}
		/>,
		<Isometry
			// center building - top
			scene={Scene3}
			key={4}
			tenancies={tenanciesData}
			target={[10, 10, -27.0]}
			initialRotation={12}
		/>
	];

	return (
		<Wrapper>
			<HeroSection4
				background={imageProperty02}
				title={t("properties.kgv.title")}
				text={t("properties.kgv.subtitle")}
				primaryButtons={<InterestListButton propertyId={propertyId} />}
				secondaryButtons={
					<Button onClick={scrollToContactSection}>
						{t("properties.general.contactUs")}
					</Button>
				}
			/>
			<FactSection1
				background={background01}
				title={t("properties.kgv.factSection1.title")}
				textBase={t("properties.kgv.factSection1.textBase")}
				textAdditional={t(
					"properties.kgv.factSection1.textAdditional"
				)}
				boxes={factSectionBoxes}
			/>
		</Wrapper>
	);
};

export default KGV;
