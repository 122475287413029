import React, { useEffect, useState } from "react";
import styled from "styled-components";
import locationSecondaryIcon from "assets/icons/location-secondary.png";
import calendarSecondaryIcon from "assets/icons/calendar-secondary.png";
import phoneIcon from "assets/icons/phone.png";
import emailIcon from "assets/icons/email.png";
import PropTypes from "prop-types";
import formatAvailability from "helpers/formatAvailability";
import formatPhoneNumber from "helpers/formatPhoneNumber";
import { device } from "helpers/device";
import TeamCard from "pages/Contact/components/TeamCard";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import useGoogleTranslate from "features/google-translate";

const Wrapper = styled.div`
	width: 100%;
	max-width: 1120px;
	margin: auto;
	padding: 24px;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	padding-bottom: 48px;

	@media ${device.laptop} {
		padding-left: 80px;
		padding-right: 80px;
		right: 75px;
		flex-direction: row;
		padding-top: 64px;
		padding-bottom: 74px;
	}

	div.title-container {
		padding-right: 24px;
		h1 {
			color: ${(p) => p.theme.colors.text.primary};
			margin: unset;
			font-weight: 600;
			font-size: 26px;
			line-height: 32px;
			max-width: 448px;
			margin-bottom: 12px;
		}
		.subtitle {
			padding: 8px 0px;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.427179px;
			color: ${(p) => p.theme.colors.text.primary};
			display: flex;
			align-items: center;
		}
		img {
			width: 26px;
			align-self: center;
			margin-right: 12px;
		}
	}

	div.contact-card {
		width: 590px;
		height: 265px;
		background: #ffffff;
		box-shadow: 0px 22px 60px rgba(0, 0, 0, 0.2);
		padding-left: 24px;

		justify-content: space-between;
		display: none;

		@media ${device.laptop} {
			display: flex;
		}

		div.info {
			min-width: 250px;
		}

		div.name {
			font-size: 24px;
			padding-top: 32px;
			padding-bottom: 4px;
		}
		div.title {
			padding-top: 4px;
			font-size: 20px;
			color: #c49a36;
			font-weight: 700;
			padding-bottom: 34px;
		}
		a.phone,
		a.email {
			padding: 8px 0px;
			font-weight: bold;
			font-size: 17px;
			line-height: 22px;
			letter-spacing: -0.427179px;
			display: flex;
			align-items: center;
			font-weight: 300;
			color: #384336;
			cursor: pointer;
			img {
				align-self: center;
				margin-right: 12px;
			}
		}
		div.email {
			margin-bottom: 8px;
		}
		img.picture {
			height: 100%;
			width: 250px;
		}
	}
	div.contact-card-mobile {
		padding-top: 16px;
		@media ${device.laptop} {
			display: none;
		}
	}
`;

const HeaderSection = (props) => {
	const {
		title,
		rooms,
		size,
		street,
		postnumber,
		city,
		availableFrom,
		contactName,
		contactPhone,
		contactEmail,
		contactPicture,
	} = props;
	const displayTitle = title ? title : `${rooms} rooms apartment on ${size} m²`;
	const { t, i18n } = useTranslation();
	const { isTranslationLoading, translationData, loadTranslation } = useGoogleTranslate();
	const [translatedTitle, setTranslatedTitle] = useState(null);

	const defaultEmail = "udlejning@norsepm.dk";
	const condtionalPhone = contactName === "Emma Sundsvold" ? "+45 3333 1333" : contactPhone;
	const condtionalEmail = contactName === "Sofie Friis Kjærgaard" ? "sfk@norsepm.dk" : defaultEmail;



	useEffect(() => {
		if (i18n.resolvedLanguage === "en") {
			loadTranslation([title]);
		} else {
			setTranslatedTitle(title);
		}
	}, [i18n.resolvedLanguage, title]);

	useEffect(() => {
		if (!isTranslationLoading) {
			translationData[0] ? setTranslatedTitle(translationData[0]) : setTranslatedTitle(title);
		}
	}, [isTranslationLoading, translationData]);

	return (
		<Wrapper>
			<div className='title-container'>
				<Title>{translatedTitle}</Title>
				<div className='subtitle'>
					<img src={locationSecondaryIcon} />
					{street}
					<br />
					{postnumber} {city}
				</div>
				<div className='subtitle'>
					<img src={calendarSecondaryIcon} />
					{formatAvailability(availableFrom, t("tenancyCard.asap"))}
				</div>
			</div>
			{contactName && (
				<>
					<div className='contact-card'>
						<div className='info'>
							<div className='name'>{contactName}</div>
							<div className='title'>{t("general.agentTitle")}</div>
							<a className='phone' href={`tel:${condtionalPhone}`}>
								<img src={phoneIcon} />
								{formatPhoneNumber(condtionalPhone)}
							</a>
							<a className='email' href={`mailto:${condtionalEmail}`}>
								<img src={emailIcon} />
								{condtionalEmail}
							</a>
						</div>
						<img className='picture' src={contactPicture} />
					</div>
					<div className='contact-card-mobile'>
						<TeamCard
							key={contactName}
							image={contactPicture}
							name={contactName}
							title={t("general.agentTitle")}
							phone={formatPhoneNumber(contactPhone)}
							email={contactEmail}
						/>
					</div>
				</>
			)}
		</Wrapper>
	);
};

HeaderSection.propTypes = {
	title: PropTypes.string,
	rooms: PropTypes.number,
	size: PropTypes.number,
	street: PropTypes.string,
	postnumber: PropTypes.string,
	city: PropTypes.string,
	availableFrom: PropTypes.string,
	contactName: PropTypes.string,
	contactPhone: PropTypes.string,
	contactEmail: PropTypes.string,
	contactPicture: PropTypes.string,
};

export default HeaderSection;
