import React, { useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl';
import useSiteConfig from 'features/site-config';
import styled from 'styled-components';
import Line from 'components/Line';
import Title from 'components/Title';
import busIcon from 'assets/icons/locationBus.png';
import trainIcon from 'assets/icons/locationTrain.png';
import subwayIcon from 'assets/icons/locationMetro.png';
import airportIcon from 'assets/icons/locationAirport.png';
import schoolIcon from 'assets/icons/locationSchool.png';
import kindergartenIcon from 'assets/icons/locationKindergarten.png';
import nurseryIcon from 'assets/icons/locationNursery.png';
import playgroundIcon from 'assets/icons/locationPlayground.png';
import groceryStoreIcon from 'assets/icons/locationGroceryStore.png';
import homeIconInversed from 'assets/icons/locationHomeInversed.png';
import { useTranslation } from 'react-i18next';
import { device } from 'helpers/device';
import PropTypes from 'prop-types';

const MapContainer = styled.div`
	padding: 0px 16px;
	padding-top: 40px;
	width: 100%;
	max-width: 1120px;
	box-sizing: border-box;
	margin: auto;
	background-color: white;

	@media ${device.laptop} { 
		padding: 60px 0px;
		padding-left: 80px;
    	padding-right: 80px;
	}

	.map-container {
		width: 100%;
		height: 500px;
	}
	.mapboxgl-popup {
		z-index: 999;
	}
`;

const Map = props => {

	const { lng, lat, locations } = props;
	const config = useSiteConfig();
	const { t } = useTranslation();

	const mapContainer = useRef(null);
	const map = useRef(null);
	mapboxgl.accessToken = config.mapBox.accessToken;
	
	useEffect(() => {
		if (map.current) return;
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/jorato/ckqp3hhht0onr18mklr1w6nf4',
			center: [lng, lat],
			zoom: 15,
			pitch: 60,
			antialias: true,
		});

		var marker = document.createElement('div');
		marker.className = 'marker';
		marker.style.backgroundSize = 'cover';
		marker.style.width = '40px';
		marker.style.height = '40px';
		marker.style.borderRadius = '40px';
		marker.style.zIndex = '999';
		marker.style.backgroundImage = `url(${homeIconInversed})`;

		new mapboxgl.Marker(marker)
			.setLngLat([lng, lat])
			.addTo(map.current);

		for (let location of locations) {
			if (!location.coordinates) continue;
			var el = document.createElement('div');
			el.className = 'marker';
			el.style.backgroundSize = 'cover';
			el.style.width = '40px';
			el.style.height = '40px';
			el.style.borderRadius = '40px';
			const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
				`<div className='popup-container'>
					<div>${location.type}</div>
					<div>Name: ${location.name}</div>
					<div>Distance: ${Math.round(location.distance)} meter</div>
				</div>
				`
			);

			switch (location.type) {
			case 'Bus':
				el.style.backgroundImage = `url(${busIcon})`;
				break;
			case 'Train':
				el.style.backgroundImage = `url(${trainIcon})`;
				break;
			case 'Subway':
				el.style.backgroundImage = `url(${subwayIcon})`;
				break;
			case 'Airport':
				el.style.backgroundImage = `url(${airportIcon})`;
				break;
			case 'School':
				el.style.backgroundImage = `url(${schoolIcon})`;
				break;
			case 'Kindergarden':
				el.style.backgroundImage = `url(${kindergartenIcon})`;
				break;
			case 'Nursery':
				el.style.backgroundImage = `url(${nurseryIcon})`;
				break;
			case 'Playground':
				el.style.backgroundImage = `url(${playgroundIcon})`;
				break;
			case 'GroceryStore':
				el.style.backgroundImage = `url(${groceryStoreIcon})`;
				break;
			default:
				console.log('This location type is missing in icon:', location.type);
			}
			new mapboxgl.Marker(el)
				.setLngLat([location.coordinates.longitude, location.coordinates.latitude])
				.setPopup(popup)
				.addTo(map.current);
		}
		
	});


	return (
		<>
			<MapContainer>
				<Line />
				<Title>{t('tenancy.locationInformation')}</Title>
				<div style={{width: '100%', minHeight: '500px'}} ref={mapContainer} className="map-container"/>
			</MapContainer>
		</>
	);
};

Map.propTypes = {
	lng: PropTypes.number,
	lat: PropTypes.number,
	locations: PropTypes.array,
};

export default Map;