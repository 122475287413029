import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {ReactComponent as AvailableIcon} from 'assets/icons/available.svg';
import {ReactComponent as PriceIcon} from 'assets/icons/price.svg';
import {ReactComponent as RoomsIcon} from 'assets/icons/rooms.svg';
import {ReactComponent as SizeIcon} from 'assets/icons/size.svg';
import formatPrice from 'helpers/formatPrice';
import PropTypes from 'prop-types';
import norseLogo from 'assets/images/norse-logo-green-with-padding.png';
import formatAvailability from 'helpers/formatAvailability';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useGoogleTranslate from 'features/google-translate';
import parkingImage from 'assets/images/parking.png';

const Wrapper = styled.a`
	max-width: calc(100vh - 32px);
	display: flex;
	flex-direction: column;
	background-color: ${p => p.theme.colors.white.primary};
	box-shadow: 0px 22px 60px rgba(0, 0, 0, 0.2);
	overflow-x: auto;
	position: relative;
	&:hover {
		cursor: pointer;
	}

	.slick-next {
		right: 5px;
	}
	.slick-prev {
		left: 5px;
		z-index: 10;
	}
	.slick-dots {
		bottom: 20px;
	}
	.slick-dots li {
		width: 10px;
		height: 10px;
		margin: 0 5px;
	}
	.slick-slide img {
		display: block;
		width: 100%;
		height: 250px;
		object-fit: cover;
	}
	.slick-dots li:nth-of-type(1n+7) { 
		display: none 
	}
`;
const Image = styled.img`
	width: 100%;
	display: flex;
	flex: 1;
	object-fit: cover;
	max-height: 300px;
`;
const TopSection = styled.div`
	height: 221px;
`;
const Title = styled.div`
	height: 56px;
	padding-top: 24px;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	color: ${p => p.theme.colors.text.primary};
	padding: 24px 28px 0px 28px;
	display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
	width: 251px;
	overflow: hidden;
`;
const Address = styled.p`
	font-size: 18px;
	line-height: 31px;
	color: ${p => p.theme.colors.text.primary};
	padding: 10px 28px 0px 28px;
	height: 29px;
	display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
`;
const Location = styled.p`
	font-size: 18px;
	line-height: 31px;
	color: ${p => p.theme.colors.text.primary};
	padding: 0px 28px 10px 28px;
	height: 29px;
	display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
`;

const BottomSection = styled.div`
	display: grid;
	grid-template-columns: 0.5fr 0.5fr;
	grid-column-gap: 1px;
	grid-row-gap: 1px;
	background-color: #D7D9D7;
	border-top: 1px solid #D7D9D7;
`;

const Box = styled.div`
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;

	svg {
		height: 24px;
    	width: 24px;
		padding: 8px;
	}
`;

const BoxContent = styled.div`
	display: flex;
	font-size: 17px;
	line-height: 40px;
	color: ${p => p.theme.colors.text.primary};  
`;

const ReservedOverlay = styled.div`
	background-color: ${p => p.theme.colors.text.primary};
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	z-index: 1000;
	padding: 16px;
	color: white;
	font-size: 24px;
	position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 160px;
    text-align: center;
	top: 93px;
`;

const ParkingCard = (props) => {

	const { images, name, price, availableFrom, rooms, size, id, street, location, floorplanImageMode, floorplan, isReserved, openHouses } = props;
	const cleanedName = street.replace(/\s/g, '');
	const cleanedId = id.replace(/-/g, '');
	const link = `/${process.env.REACT_APP_ROUTER_TENANCY}/${cleanedName}+${cleanedId}`;
	const { t, i18n } = useTranslation();
	const { isTranslationLoading, translationData, loadTranslation } = useGoogleTranslate();
	const [slider, setSlider] = useState();
	const [translatedName, setTranslatedName] = useState('');

	var settings = {
		adaptiveHeight: true,
		arrows: true,
		dots: true,
		lazyLoad: true,
	};

	const mappedImages = [{
		id: 1,
		img: parkingImage,
		class: 'image'
	}];

	// const mappedImages = images.map((i) => {
	// 	return {
	// 		id: i.id,
	// 		img: i.alternatives[0].url,
	// 		class: 'image'
	// 	};
	// });


	useEffect(() => {
		if (i18n.resolvedLanguage === 'en') {
			//loadTranslation([name]);
			setTranslatedName(name);
		} else {
			setTranslatedName(name);
		}
	}, [i18n.resolvedLanguage, name]);

	useEffect(() => {
		if (!isTranslationLoading) {
			translationData[0] ? setTranslatedName(translationData[0]) : setTranslatedName(name);	
		}
	}, [isTranslationLoading, translationData]);

	return (
		<Wrapper className="tenancyCard" href={link} key={id}>
			{ floorplanImageMode ? (
				<Image src={floorplan} />
					
			):(
				<>
					<Slider className='sliderView' ref={() => setSlider(slider)}  {...settings}>
						{mappedImages.map(e =>
							(
								<div key={e.id}>
									<img src={e.img} className={e.class} />
								</div>
							)
						)}
					</Slider>
				</>
			)}
			{ isReserved && (
				<ReservedOverlay>
					{t('general.reserved')}
				</ReservedOverlay>
			)}
			<div>
				<TopSection>
					<Title>{translatedName}</Title>
					<Address>{street}</Address>
					<Location>{location}</Location>
				</TopSection>
				<BottomSection>
					<Box>
						<BoxContent>
							<PriceIcon/>
							<span>{formatPrice(price)}</span>
						</BoxContent>
					</Box>
					<Box>
						<BoxContent>
							<AvailableIcon/>
							<span>{formatAvailability(availableFrom, t('tenancyCard.asap'))}</span>
						</BoxContent>
					</Box>
					{/* <Box>
						<BoxContent>
							<RoomsIcon/>
							<span>{rooms} {t('tenancyCard.rooms')}</span>
						</BoxContent>
					</Box>
					<Box>
						<BoxContent>
							<SizeIcon/>
							<span>{size} m²</span>
						</BoxContent>
					</Box> */}
				</BottomSection>
			</div>
		</Wrapper>
	);
};

ParkingCard.propTypes = {
	images: PropTypes.array,
	image: PropTypes.string,
	name: PropTypes.string,
	price: PropTypes.number,
	availableFrom: PropTypes.string,
	rooms: PropTypes.number,
	size: PropTypes.number,
	id: PropTypes.string,
	street: PropTypes.string,
	location: PropTypes.string,
	floorplanImageMode: PropTypes.bool,
	floorplan: PropTypes.string,
	isReserved: PropTypes.bool,
	openHouses: PropTypes.array,
};

ParkingCard.defaultProps = {
	image: norseLogo,
	name: '-',
	price: '-',
	availableFrom: '-',
	rooms: '-',
	size: '-',
	id: null,
	street: '-',
	location: '-',
	floorplanImageMode: false,
	floorplan: norseLogo,
	isReserved: false,
	openHouses: [],
};

export default ParkingCard;