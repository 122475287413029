import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const marks = [
	{
		value: 0
	},
	{
		value: 25,
	},
	{
		value: 50,
	},
	{
		value: 75,
	},
	{
		value: 100,
	},
];

function AirbnbThumbComponent(props) {
	const { children, ...other } = props;
	return (
		<SliderThumb {...other}>
			{children}
			<svg xmlns='http://www.w3.org/2000/svg' viewBox='3 3 42 42' style={{ 'overflow': 'visible', 'width': '100%', 'height': '100%' }}><defs><clipPath id='ab'><path fill='none' d='M0 0h53.823v53.822H0z'></path></clipPath></defs><g clipPath='url(#ab)' transform='translate(-3.0634689,-1.5639996)'><path fill='#A0978B' d="
        m27.195 1.564
        a24.127 24.127 0 1 0 12.06 3.233 24.128 24.128 0 0 0-12.06-3.233
        m-8.12 28.079
        a1.725 1.725 0 1 1-2.43 2.448l-5.171-5.167
        a1.725 1.725 0 0 1-.009-2.438l.009-.009 5.171-5.171
        a1.725 1.725 0 1 1 2.43 2.448l-3.947 3.937Z
        m8.119-.5
        a3.446 3.446 0 1 1 2.438-1.009 3.446 3.446 0 0 1-2.437 1.008
        m15.72-2.218-5.171 5.167
        a1.725 1.725 0 0 1-2.43-2.448l3.945-3.952-3.947-3.947
        a1.725 1.725 0 1 1 2.43-2.448l5.171 5.171
        a1.724 1.724 0 0 1 .009 2.438l-.009.009z"></path></g></svg>
		</SliderThumb>
	);
}

const AirbnbSlider = styled(Slider)(({ theme }) => ({
	color: '#3a8589',
	height: 3,
	padding: '13px 0',
	'& .MuiSlider-thumb': {
		height: 40,
		width: 40,
		backgroundColor: '#fff',
		border: '1px solid currentColor',
		'&:hover': {
			boxShadow: 'unset',
		},
		'path': {
			fill: '#384336',
		},
		'& .airbnb-bar': {
			height: 9,
			width: 1,
			backgroundColor: 'currentColor',
			marginLeft: 1,
			marginRight: 1,
		},
	},
	'& .MuiSlider-track': {
		height: 0,
		color: 'transparent'
	},
	'& .MuiSlider-rail': {
		backgroundColor: '#C49A36',
		color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
		opacity: theme.palette.mode === 'dark' ? undefined : 1,
		paddingRight: 15,
		height: 20,
	},
	'& .MuiSlider-mark': {
		width: 10,
		height: 10,
		borderRadius: '50%',
		backgroundColor: '#fff'
	}
}));


export default function DiscreteSliderValues({ changeSlider }) {
	const [value, setValue] = useState(0);
	useEffect(() => {
		setValue(marks[0].value);
	}, []);

	useEffect(() => {
		changeSlider(value);
	}, [value]);

	const onChange = (e) => {
		const target = e.target.value;
		setValue(target);
	};

	const onMouseUp = (e) => {
		const target = value;
		let i = 0;
		for (i = 0; i < marks.length; i++) {
			if (marks[i].value >= target) {
				break;
			}
		}
		if (i === marks.length) i--;
		if (i !== 0) {
			if ((marks[i].value + marks[i - 1].value) / 2 >= target) {
				setValue(marks[i - 1].value);
			} else {
				setValue(marks[i].value);
			}
		}
	};

	return (
		<Box sx={{ width: 800, maxWidth: '80%' }} style={{ paddingTop: '20px', paddingBottom: '20px'}} margin={'auto'}>
			<AirbnbSlider
				defaultValue={20}
				step={1}
				slots={{ thumb: AirbnbThumbComponent }}
				marks={marks}
				style={{ 'color': '#000' }}
				onChange={onChange}
				onChangeCommitted={onMouseUp}
				value={value}
			/>
		</Box>
	);
}
