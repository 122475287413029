import React from 'react';
import styled from 'styled-components';
import Line from 'components/Line';
import Title from 'components/Title';
import TeamCard from './TeamCard';
import { device } from 'helpers/device';
import cw from 'assets/images/cw.png';
import lb from 'assets/images/lb.png';

const TeamSectionWrapper = styled.div`
	padding: 32px 16px;
	box-sizing: border-box;
	width: 100%;
	max-width: 1200px;
	margin: auto;

	@media ${device.laptop} { 
		padding: 60px 0px;
		padding-left: 80px;
		padding-right: 80px;
	}

	.team-list {
		padding: 32px 0px;
		box-sizing: border-box;
		width: 100%;
		max-width: 1200px;
		margin: auto;
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 50px;

		@media ${device.laptop} { 
			grid-template-columns: 1fr 1fr;
		}
	}
`;

const team = [
	{
		name: 'Leif Boje Espesen',
		title: 'Adm. direktør',
		phone: '+45 3333 1333',
		email: 'lbe@norsepm.dk',
		image: lb
	},
	{
		name: 'Christian Warburg',
		title: 'Direktør',
		phone: '+45 3333 1333',
		email: 'cw@norsepm.dk',
		image: cw
	},
];

const TeamSection = () => {


	return (
		<TeamSectionWrapper>
			<Line />
			<Title>Management Team</Title>
			<div className='team-list'>
				{team.map((t) => {
					return (
						<TeamCard
							key={t.name}
							name={t.name}
							title={t.title}
							phone={t.phone}
							email={t.email}
							image={t.image}
						/>
					);
				})}
			</div>
		</TeamSectionWrapper>
	);
};

export default TeamSection;