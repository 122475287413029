import React from "react";
import styled from "styled-components";
import useKeypress from "components/useKeyPress";
import "react-datepicker/dist/react-datepicker.css";
import { device } from "helpers/device";
import { useTranslation } from "react-i18next";
import LogoGreen from "assets/images/norse-logo-green.png";
import linkedInIcon from "assets/icons/linkedin.png";
import instagramIcon from "assets/icons/instagram.png";
import facebookIcon from "assets/icons/facebook.png";
import PropTypes from "prop-types";

const MobileNavigationWrapper = styled.nav`
	width: 250px;
	height: 100vh;
	z-index: 1000;
	top: 0;
	right: 0px;
	position: fixed;
	background-color: white;
	transform: translateX(100%);
	transition: transform 0.3s ease-out;
	padding: 32px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	@media ${device.laptop} {
		width: 400px;
		padding: 24px 48px;
	}

	&.visible {
		transform: translateX(0);
	}

	div.title {
		font-size: 18px;
		padding: 24px;
		text-align: center;

		svg {
			padding-right: 16px;
		}
	}

	.logo {
		max-width: 170px;
		object-fit: fill;
		padding-bottom: 32px;
	}
	.text-link {
		font-size: 24px;
		font-weight: 700;
		padding: 8px;
		color: ${(p) => p.theme.colors.ascent.primary};
	}
	.social img {
		max-width: 24px;
		padding: 8px;
	}
`;

const Backdrop = styled.div`
	width: 100vw;
	height: 100vh;
	display: none;
	position: fixed;
	background-color: #0000006b;
	z-index: 900;
	top: 0;
	left: 0;

	&.visible {
		display: block;
	}
`;

const MobileNavigation = (props) => {
	const { visible, setVisible } = props;
	const { t } = useTranslation();

	const handleBackdropClick = () => {
		setVisible(false);
	};

	useKeypress("Escape", () => {
		if (visible) {
			setVisible(false);
		}
	});

	return (
		<>
			<Backdrop
				onClick={handleBackdropClick}
				className={visible ? "visible" : ""}
			/>
			<MobileNavigationWrapper
				className={visible ? "visible" : "invisible"}
			>
				{/* <div className="title">{t('general.navigationTitle')}</div> */}
				<img className='logo' src={LogoGreen} />
				<a href='/boliger' className='text-link'>
					{t("header.findTenancy")}
				</a>
				<a href='/om-os' className='text-link'>
					{t("header.aboutUs")}
				</a>
				<a href='/kontakt' className='text-link'>
					{t("header.contact")}
				</a>
				<div className='social'>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.facebook.com/NorsePM'
					>
						<img className='' src={facebookIcon} />
					</a>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.linkedin.com/company/norse-pm/'
					>
						<img className='' src={linkedInIcon} />
					</a>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.instagram.com/norsepm'
					>
						<img className='' src={instagramIcon} />
					</a>
				</div>
			</MobileNavigationWrapper>
		</>
	);
};

MobileNavigation.propTypes = {
	visible: PropTypes.bool,
	setVisible: PropTypes.func,
};

export default MobileNavigation;
